
  <template>
  <div class="toolbar">
    <br>
    <div class="feature-intro title">
      <h1>UFIs</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="CategoriaUfi"
        reference="categoriaUfi"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        apiversion="v2"
        :modal="modal"
        :keyShow="keyShow"
        :filtersFields="filtersFields"
        :multiSelect="true"
        :enableSelectAllCheckbox="true"
        :enableEditButtons="true"
        :enableGlobalSearch="false"
      />
    </div>
    <!-- <div class="ml-5">
        <Button label="Seleccionar" @click="submit"/>
      </div> -->
    <!-- POR AHORA,  enableGlobalSearch REQUIERE QUE enableEditButtons SEA TRUE!!-->
    <!-- <button>submit</button> -->
  </div>
</template>

  <script>
// import axios from 'axios'

export default {
  name: "CategoriaUfiList",
  props: {
    modal: {
      type: Boolean
    }
  },
  data() {
    return {
      filteredLocation: [],
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/categoria-ufi",
      customName: [],
      data: null,
      customColumn: [],
      idTable: "CategoriaUfiID",
      loading: true,
      filtersFields: [
        { name: "Nombre", field: "Nombre" },
        // { name: "Admitesumarios", field: "Admitesumarios" }
      ],
      keyShow: [
        "Nombre",
        "Descripcion",
      ]
    };
  },
  methods: {
    submit() {
      let userTableData = this.$refs.tableData.getTableData();
      this.$setCookie("userCategoriaUfi", userTableData.Nombre);
      this.$setCookie("userCategoriaUfiID", userTableData.UsuarioID);
      // this.$router.push({ path: "/gestionUsers" });
    }
  }
};
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.title {
  margin-left: 30px;
}
</style>