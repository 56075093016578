// GENERA COMPATIBILIDAD CON VERSIONES ANTERIORES DEL COMPONENTE
export default {
  data: ()=> ({}),
  methods: {
    prepareOldVersion(){
      //genera compatibilidad con anterior configuracion
      
      for (let i in this.data){
        // console.log("read keyshow", i, this.keyShow[i])
        this.params[i] = { ...this.data[i], ...this.params[this.data[i]] }
      }

      for (let i in this.gallery) {
        this.params[i] ??= {}
        this.params[i].gallery = this.gallery[i];
      }

      // console.log('this.uploadOptions', this.uploadOptions)
      for (let i in this.uploadOptions) {
        this.params[i].upload = this.uploadOptions[i];
      }
      // console.log('this.labels', this.labels)
      for (let i in this.labels) {
        this.params[i].label = this.labels[i];
      }
      // console.log('this.placeholder', this.placeholder)
      for (let i in this.placeholder) {
        this.params[i].placeholder = this.placeholder[i];
      }


      for (let i in this.dropdown) {
        this.params[i].dropdown = this.dropdown[i];
      }

      for (let i in this.params){        
        if (this.params[i].dropdown) this.dropdown[i] ??= this.params[i].dropdown
        if (this.params[i].gallery) this.gallery[i] ??= this.params[i].gallery
      }

      return Promise.resolve()
    }, 
    formatOldDesign(design){
      if (Array.isArray(design[0])) {
        design = [
          {
            cards: [
              {
                design: design,
              },
            ],
          },
        ];
      }
      return Promise.resolve(design)
    }
  },
  props: {
    placeholder: {
      //DEPRECATED
      type: Object,
      default: () => ({}),
    },
    
    gallery: {
      //DEPRECATED
      type: Object,
      default: () => ({})
    },
    
    uploadOptions: { 
      //DEPRECATED
      type: Object,
      default: () => ({}),
    },
    
    responsiveOptions: {
      type: Array,
      default: () => [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ]
    },
    dropdown: {
      type: Object,
      default: () => ({}),
    },
    
    pageName: {
      type: String, //string que se usará para para identificar casos especiales a tratar(Por ejemplo Contraseña)
      default: "",
    },
    
    errMsg: {
      type: Object, //string que se usará para el http request
    },
    
    messages: {
      type: Object,
      default: () => ({}),
    },
    
    labels: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    }
  }
}