import axios from "axios";

export default {
  data: () => ({
    chartData: {
      labels: ['UFIs'],
      datasets: [
        {
          data: [0],
          backgroundColor: [
            "#3b82f6", "#d32f2f", "#fbc02d", "#689f38", "#8E44AD",  // Azul, Rojo, Amarillo, Verde, Púrpura
            "#95A5A6", "#16A085", "#2980B9", "#D35400", "#2C3E50",  // Gris hormigón, Verde mar, Azul brillante, Naranja, Azul oscuro
            "#7F8C8D", "#BDC3C7", "#9B59B6", "#3498DB", "#2ECC71",  // Gris, Gris claro, Amatista, Azul Peter River, Esmeralda
            "#1ABC9C", "#F39C12", "#E67E22", "#ECF0F1", "#C0392B",  // Turquesa, Naranja sol, Zanahoria, Nube, Granada
            "#D98880", "#F1948A", "#C39BD3", "#BB8FCE", "#7D3C98",  // Rosa pálido, Salmón claro, Lavanda, Lila, Ciruela
            "#76D7C4", "#73C6B6", "#F7DC6F", "#F8C471", "#E59866",  // Menta, Verde agua, Amarillo pastel, Arena, Cobre
            "#D7DBDD", "#85929E", "#34495E", "#17202A"               // Plata, Acero, Medianoche, Ébano
          ],
          
          hoverBackgroundColor: [
            "#3b82f6", "#d32f2f", "#fbc02d", "#689f38", "#8E44AD",  // Azul, Rojo, Amarillo, Verde, Púrpura
            "#95A5A6", "#16A085", "#2980B9", "#D35400", "#2C3E50",  // Gris hormigón, Verde mar, Azul brillante, Naranja, Azul oscuro
            "#7F8C8D", "#BDC3C7", "#9B59B6", "#3498DB", "#2ECC71",  // Gris, Gris claro, Amatista, Azul Peter River, Esmeralda
            "#1ABC9C", "#F39C12", "#E67E22", "#ECF0F1", "#C0392B",  // Turquesa, Naranja sol, Zanahoria, Nube, Granada
            "#D98880", "#F1948A", "#C39BD3", "#BB8FCE", "#7D3C98",  // Rosa pálido, Salmón claro, Lavanda, Lila, Ciruela
            "#76D7C4", "#73C6B6", "#F7DC6F", "#F8C471", "#E59866",  // Menta, Verde agua, Amarillo pastel, Arena, Cobre
            "#D7DBDD", "#85929E", "#34495E", "#17202A"               // Plata, Acero, Medianoche, Ébano
          ],
        }
      ]
    },
    chartOptions: {
      legend: {
        labels: {
          fontColor: '#495057'
        }
      }
    },
    chartLabels: [],
    sumarios: { pendientes: 0, vencidos: 0 },
    expedientes: { pendientes: 0, vencidos: 0 },
    UFIs: { pendientes: 0, vencidos: 0 },
    preliminares: { pendientes: 0, vencidos: 0 },
    allActuations: { pendientes: 0, vencidos: 0 },
    policeCardsData: [
      { cardName: "Sumarios", statisticsName: "sumarios", color: "primary" },
      { cardName: "Expedientes", statisticsName: "expedientes", color: "danger" },
      { cardName: "UFI", statisticsName: "UFIs", color: "warning" },
      { cardName: "Preliminares", statisticsName: "preliminares", color: "success" },
    ],
    prosecutorCardsData: [],
    UFIsCardsDataStatisticsName: "allActuations",
    UFIsCardsData:  {
      "ufi-anivi": { cardName: "UFI - ANIVI", color: "primary"},
      "ufi-cavig": { cardName: "UFI - CAVIG", color: "danger"},
      "ufi-flagrancia": { cardName: "UFI -  FLAGRANCIA", color: "success"},
      "ufi-delitos-especiales": { cardName: "UFI - D. ESPECIALES", color: "primary"},
      "ufi-delitos-informaticos-y-estafas": { cardName: "UFI - D. INFOR. Y ESTAFAS", color: "danger"},
      "ufi-generica": { cardName: "UFI - GENÉRICA", color: "warning" },
      "ufi-delitos-contra-la-propiedad": { cardName: "UFI - D. CONTRA LA PROPIEDAD", color: "success" },
      "ufis-todas": { cardName: "UFI", statisticsName: "UFIs", color: "warning"},
    },
    categorizedActuationsNames: { 
      'Unidades Fiscales de Investigación Delitos Contra la Propiedad': 'Delitos Contra la Propiedad', 
      'Unidades Fiscales de Investigación de Delitos Informáticos y Estafas': 'Delitos Informáticos y Estafas', 
      'Unidades Fiscales de Investigación Genérica': 'Delitos Genéricos',
      'Unidades Fiscales de Investigacion Flagrancia': 'Flagrancia',
      'Unidades Fiscales de Investigacion CAVIG': 'Cavig', 
      'Unidades Fiscales de Investigacion ANIVI': 'Anivi', 
      'Unidades Fiscales de Investigacion D.E': 'Delitos Especiales' 
    },
    actuationsType: [
      {"Sumario": 'sumarios'}, {"Expediente": 'expedientes'}, {"Unidades Fiscales de Investigacion": 'UFIs'},
      {"Actuacion Preliminar": 'preliminares'}
    ],
    inputSwitchActuationStatus: false,
    showProgressSpinner: false
  }),
  computed: {
    cardsData: {
      get(){
        let cardsData = [];
        if (this.prosecutorUserTypes && Array.isArray(this.prosecutorUserTypes)) {
          if(this.prosecutorUserTypes.includes(this.$store.state.user.userdata.tipoUsuario)){
            let currentUFI = {...this.UFIsCardsData[this.$store.state.user.userdata.CategoriaUfiID]};
            currentUFI.statisticsName = this.UFIsCardsDataStatisticsName;
            cardsData = [currentUFI];
          } else{
            cardsData = this.policeCardsData;
          }
        }
        return cardsData;
      }
    },
    actuationsTypeName(){
      return this.$store.state.user.userdata.claseDeUsuario === "UsuarioFiscal" ? 'UFI' : 'ACTUACIONES';
    },
    showPieChart(){
      if(this.$store.state.user.userdata.claseDeUsuario === "UsuarioFiscal" && this.CategoriaUfiID !== "ufis-todas")
        return false;
      return true;
    }
  },
  watch: {
    inputSwitchActuationStatus(){
      this.showProgressSpinner = true;
      this.setDependencia(this.dependencia);
    }
  },
  methods: {
    async getData(urls) {
      let pendientes = await axios.get(urls[0], { withCredentials: true });
      let vencidos = await axios.get(urls[1], { withCredentials: true });
      this.structureActuationsData(pendientes, vencidos);
    },
    structureActuationsData(pendings, defeateds) {
      this.allActuations.pendientes = pendings?.data?.data?.length;
      this.allActuations.vencidos = defeateds?.data?.data?.length;
      if(this.$store.state.user.userdata.claseDeUsuario === "UsuarioFiscal"){
        let UFIsForCategorize = this.inputSwitchActuationStatus ? defeateds?.data?.data : pendings?.data?.data;
        this.categorizeByTiposumarioID(UFIsForCategorize);
      } else{
        const actuationsDataObtained = [{ pendientes: pendings.data.data }, { vencidos: defeateds.data.data }];
        this.categorizeByActuationsAmount(actuationsDataObtained);
      }
    },
    getStatistics(statisticsName){
      return this[statisticsName];
    },
    categorizeByTiposumarioID(allActuations){
      let categorizedActuations = {};
      allActuations.forEach(actuation => {
        if (!categorizedActuations[actuation.TiposumarioID])  categorizedActuations[actuation.TiposumarioID] = [];
        categorizedActuations[actuation.TiposumarioID].push(actuation);
      });
      this.changeTheNamesFromActuations(categorizedActuations);
    },
    changeTheNamesFromActuations(actuations){
      let statisticsWithNewNames = {};
      for (const actuationProp in actuations) {
        for (const actuationName in this.categorizedActuationsNames) {
          if(actuations[actuationProp][0].TiposumarioID.includes(actuationName)) statisticsWithNewNames[this.categorizedActuationsNames[actuationName]] = actuations[actuationProp];
        }
      }
      this.getStatisticsLabelsAndValues(statisticsWithNewNames);
    },
    getStatisticsLabelsAndValues(statistics){
      let statisticsData = { statisticsLabels: [], statisticsValues: [] };
      for (const prop in statistics) {
        statisticsData.statisticsLabels.push(prop);
        statisticsData.statisticsValues.push(statistics[prop]?.length ?? statistics[prop]);
      }
      if(statisticsData.statisticsValues.length === 0){
        this.chartData.datasets[0].data = [0];
        this.chartData.labels = ['Actuaciones'];
      } else{
        this.chartData.datasets[0].data = statisticsData.statisticsValues;
        this.chartData.labels = statisticsData.statisticsLabels;
      }
      this.showProgressSpinner = false;
    },
    countAmountOfActuations(actuationsDataObtained){
      this.actuationsType.map( actuation => {
        //                                ↓  "actuationState" es igual a "pendientes" o "vencidos"
        actuationsDataObtained.map( actuationState => {
          const actuationDataObtainedType = Object.keys(actuationState)[0];
          let actuationName = actuation[Object.keys(actuation)[0]];
          this[actuationName][actuationDataObtainedType] = actuationState[actuationDataObtainedType].filter((e) =>
            e.TiposumarioID.includes(Object.keys(actuation)[0])
          ).length;
        });
      });
    },
    categorizeByActuationsAmount(actuationsDataObtained){
      this.countAmountOfActuations(actuationsDataObtained);
      var statistics = {};
      for (const key in this.policeCardsData) {
        let statistic = this.getStatistics(this.policeCardsData[key].statisticsName);
        let statisticsName = this.policeCardsData[key].statisticsName;
        let statisticsNameCapitalized = statisticsName.charAt(0).toUpperCase() + statisticsName.slice(1);
        statistics[statisticsNameCapitalized] = statistic[!this.inputSwitchActuationStatus ? 'pendientes' : 'vencidos'];
      }
      this.getStatisticsLabelsAndValues(statistics);
    }
  }
}