import axios from 'axios'
import Vue from 'vue'

var offlineServer = false

var activeJWT = localStorage.getItem('activeJWT')
var onlineMode = offlineServer ? window.navigator.onLine : true;
/* axios.get('https://local.electro3.com.ar:3000', {timeout: 2000})
.then(response => {
  offlineServer = 'https://local.electro3.com.ar:3000'
  
  onlineMode = window.navigator.onLine
  console.log("locale3 >>>", onlineMode)
  
})
.catch(e => {
  // console.log("server offline no disponible")
}) */


// console.log("offlineServer>>>>", offlineServer)
// var onlineMode = false

window.addEventListener('online', () => {
  onlineMode = true
  console.log("onlineMode", onlineMode)
})

window.addEventListener('offline', () => {
  onlineMode = offlineServer ? false : true;
  console.log("onlineMode", onlineMode)
});
const setActiveJWT = (token) => {
  console.log("setActiveJWT",token)
  activeJWT = token
  localStorage.setItem('activeJWT', token)
}

const setOfflineMode = (status) => {
  console.log("setOfflineMode",status)
  onlineMode = !status
  Vue.prototype.$toast.open({message: "(setOfflineMode) Online: " + !status, position: 'top-right', type: 'success', duration: 5000});
}


const setOfflineServer = (val) => new Promise(async (resolve, reject) => {
  console.log("new offlineServer>>>>", val)
  offlineServer = val;
  localStorage.setItem('offlineServer', val)
  resolve()
})

const setList = async (model, doc, options) => {
  let data = {
    update: {
      idList: "list"
    },
    data: {
      idList: "list",
      list: doc 
    }
  }
  return setItem(model, data) 
}

const getList = async (model) => getItem(model, { idList: 'list' })

const setItem = async (model, doc, options) => new Promise(async (resolve, reject) => {
  try {
    // console.log("setItem", model, doc)
    if (!offlineServer) return resolve()
    if (!options) options = {}
    if (!options.success) {
      options.success = "SIIS OFFLINE ACTUALIZADO";
    }
    let response = await axios.post(`${offlineServer}/api/set/${model}`, doc, {
      headers: { Authorization: `Bearer ${activeJWT}` }
    })
    let result = await processResponse(response, options)
    return resolve(result);
  }
  catch (e) {
    console.log("sale x aqui", e.response, e)
    let result = await processResponse(e.response || e, options)
    return resolve(result)
  }
})

const getItem = async (model, params, options) => new Promise(async (resolve, reject) => {
  try {
    if (!offlineServer) return resolve()
    if (!options) options = {}
    if (!options.success) {
      options.success = "Datos extraidos desde SIIS OFFLINE";
    }
    params = params || {}
    let url = `${offlineServer}/api/get/${model}`;
    // console.log("url getItem >>", url)
    let response = await axios.get(url, { params,
      headers: { Authorization: `Bearer ${activeJWT}` }
    })
    let result = await processResponse(response, options)
    // console.log("getItem", result)
    if (!result.data.data)return resolve(result);
    if (result.data.data.length<1) return resolve(result);
    if (result.data.data[0].doc){
      let data = []
      for (let i in result.data.data){
        data.push(result.data.data[i].doc)
      }
      result.data.data = data
    }
    return resolve(result);
  }
  catch (e) {
    console.log("sale x aqui", e.response, e)
    let result = await processResponse(e.response || e, options)
    return resolve(result)
  }
})
// setTimeout(()=>{
//   console.log("eliminar>>>>>>>>")
//   let deleteParams = {
//     update: {
//       ActuacionID: "offline-1650407281806"
//     },
//     delete: true,
//     data: false
//   }
  
//   setItem("actuaciones", deleteParams)
// }, 3000)

var toastQueue = []

const toastPermission = (msg) => {
  // console.log("toastPermission",toastQueue, msg)
  if (toastQueue.indexOf(msg)>=0) return false
  toastQueue.push(msg)
  
  setTimeout(() => {
    // console.log("clear toast", msg)
    toastQueue.splice(toastQueue.indexOf(msg), 1)
  }, 5000)
  return true
  
}

function processResponse(response, options = {}) {
  return new Promise(async (resolve, reject) => {
    let successMsg = options.success || "La carga se realizo con exito."
    let errAlert = () => {
      let msg = typeof response.data == 'object' ? JSON.stringify(response.data) : response.data;
      alert(msg);
    }
    if (response.status == '401') {
      try {
        console.log("err 401>", response.data)
        if (response.data.error == 'Unauthorized') {
          await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/logout`, { withCredentials: true })
          let msg = "La sesion ha caducado o no tiene permiso para la accion que desea realizar. Abriremos una nueva pestaña para que vuelva a loguearse. Luego de loguearse vuelva a intentarlo."
          if (toastPermission(msg)) {
            VueCli.$toast.open({message: msg, position: 'top-right', type: 'error', duration: 5000, onClick: errAlert});
            setTimeout(() => {
              window.open('/login?closeTab', '_blank')
            }, 3000)
          }
          return reject(response);
        }
      } catch (e) {
        console.log("e92", e)
      }
      Vue.prototype.$toast.open({ message: "OFFLINE SIIS: Ups! Algo fallo, te pedimos disculpas y te ayudaremos a resolverlo. Por favor, haz click en este cartel rojo, te aparecera un nuevo mensaje, envia una captura de pantalla con esa información a la mesa de ayuda tecnica.", position: 'top-right', type: 'error', duration: 0, onClick: errAlert });
      // let result = await processResponse(e, options)
      return reject(response)
    } else if ((response.status != '200') || (response.data && response.data.error != '')) {
      Vue.prototype.$toast.open({ message: "OFFLINE SIIS: Ocurrio un error al cargar.", position: 'top-right', type: 'error', duration: 3000, onClick: errAlert });
      return reject(response)
    }
    if (options.success) {
      if (toastPermission('OFFLINE SIIS: ' + successMsg)) Vue.prototype.$toast.open({ message: 'OFFLINE SIIS: ' + successMsg, position: 'top-right', type: 'success', duration: 200 });
    }
      return resolve(response)
  })
}

export {

  setOfflineServer, setItem, getItem, onlineMode, offlineServer, activeJWT, setActiveJWT, setOfflineMode, setList, getList

}