const commonRepository = (table) => ({
    async getAll() {
      try {
        return await table.toArray();
      } catch (error) {
        console.error(`Error getting all items from ${table.name}:`, error);
        throw error;
      }
    },
    async getById(id) {
      try {
        return await table.get(id);
      } catch (error) {
        console.error(`Error getting item with id ${id} from ${table.name}:`, error);
        throw error;
      }
    },
    async add(item) {
      try {
        return await table.add(item);
      } catch (error) {
        console.error(`Error adding item to ${table.name}:`, error);
        throw error;
      }
    },
    async update(id, item) {
      try {
        return await table.update(id, item);
      } catch (error) {
        console.error(`Error updating item with id ${id} in ${table.name}:`, error);
        throw error;
      }
    },
    async delete(id) {
      try {
        return await table.delete(id);
      } catch (error) {
        console.error(`Error deleting item with id ${id} from ${table.name}:`, error);
        throw error;
      }
    }
  });
  
  export default commonRepository;  