import actuationsModel from './models/actuations';

const models = [actuationsModel];

const tableSchemas = models.reduce((schemas, model) => {
  schemas[model.name] = Object.keys(model.schema).join(', ');
  return schemas;
}, {});

export default tableSchemas;
