import generalFunctions from '@/store/modules/generalFunctions.js';
import dataPreloadFunctions from '@/store/modules/sumario/dataPreloadFunctions.js';
import AltaDenuncia from './../../src/data/sumario_denuncia_alta.js';
import store from '@/store';

async function transformActuationsToJSON(actuationsPromise) {
  try {
    const actuations = await actuationsPromise;
    const transformedActuationsInJSON = actuations.map(actuation => {
      let newActuationFormat = {};
      for (let key in actuation) {
        if (actuation.hasOwnProperty(key)) {
          let value = actuation[key];
          try { newActuationFormat[key] = JSON.parse(value); } catch (e) { newActuationFormat[key] = value; }
        }
      }
      return newActuationFormat;
    });
    return transformedActuationsInJSON;
  } catch (error) {
    throw error;
  }   
}

async function transformToOnlineFormat(actuationWithOldFormat) {
  let departamento = AltaDenuncia.departamentos.find(depto => depto.nombre.toLowerCase() === actuationWithOldFormat.fechaUbicacion[0].departamento.toLowerCase());
  let actuationName = dataPreloadFunctions.translateActuationNameFromAppOffline(actuationWithOldFormat.nombreActuacion);
  let personalInterviniente = dataPreloadFunctions.formatPersonalInterviniente(actuationWithOldFormat.personalInterviniente)
  let actuationWithNewFormat = {
    "type": actuationWithOldFormat.type,
    "denunciantesBD": [],
    "ubicacion": {},
    "preUbicacion": {
      "calle": actuationWithOldFormat.fechaUbicacion[0].calle,
      "numero": actuationWithOldFormat.fechaUbicacion[0].numero,
      "departamento": {
        // "id": departamento ? departamento.id : null, 
        "nombre": departamento ? departamento.nombre : "Sin Departamento"
      },
      "coordinates": {
        "lat": -31.541588216116875,
        "lng": -68.53766083717348
      }
    },
    "moment": {
      "date": formatDate(actuationWithOldFormat.fechaUbicacion[0].desdeFechaHora),
      "date2": formatDate(actuationWithOldFormat.fechaUbicacion[0].hastaFechaHora)
    },
    "preMoment": {
      "date": formatDate(actuationWithOldFormat.fechaUbicacion[0].desdeFechaHora),
      "date2": formatDate(actuationWithOldFormat.fechaUbicacion[0].hastaFechaHora)
    },
    "estadoCivil": {},
    "residencia": {},
    "denuncia": [
      {
        "name": "InputNestor",
        "value": ""
      }
    ],
    "fechaDenuncia":  new Date(actuationWithOldFormat.fechaCreacion),
    "fechaCertificadoDenuncia": null,
    "denunciaText": "Relato de Prueba para V2",
    "causaNumero": actuationWithOldFormat.datosLegales.nroLegajo,
    "anio":  String(actuationWithOldFormat.datosLegales.selectYear),
    "modusOperandi": await store.state.siis.modusoperandi.find(modusoperandi => modusoperandi.Nombre === actuationWithOldFormat.datosLegales.selectModusOperandi),
    "causaCaratula": await store.state.siis.delitos.find(delito => delito.Nombre === actuationWithOldFormat.datosLegales.selectDelito),
    "listaCaratulas": await dataPreloadFunctions.formatInfractions(
      actuationWithOldFormat.datosLegales.opcionesCausaCaratula,
      store.state.siis.causaCaratula,
      'Nombre'
    ),
    "listaArticulos": await dataPreloadFunctions.formatInfractions(
      actuationWithOldFormat.datosLegales.opcionesArticulosRelacionados, 
      store.state.siis.articulos,
      'Numeroarticulo'
    ),
    "causaJuzgado": await store.state.siis.juzgados.find(court => court.Nombre === actuationWithOldFormat.juzgadoInterviniente),
    "SumarioUfi":  await store.state.siis.ufis.find(ufi => ufi.Numero	 === actuationWithOldFormat.datosLegales.selectUfiNro),
    "prosecutor": await store.state.siis.prosecutors.find(prosecutor => prosecutor.Denominacion === actuationWithOldFormat.datosLegales.selectUfiNro),
    "assistant": await store.state.siis.assistants.find(prosecutor => prosecutor.Denominacion === actuationWithOldFormat.datosLegales.selectAyudanteFiscal),
    "id": actuationWithOldFormat.id.toString(),
    "saveStatus": "new",
    "timer": false,
    "isSaving": false,
    "type": null,
    "deposition": actuationWithOldFormat.relato ,
    "formType": actuationName,
    "dependencia": null,
    "Sitio": await store.state.siis.sitio.find(site => site.Nombre === actuationWithOldFormat.datosLegales.selectSitio),
    "effectsType": "empty",
    "PersonalInterviniente": personalInterviniente,
    "textEditsCounter": {
      "Preform": 0,
      "Contravener": 0,
      "Postform": "",
      "LegalData": "",
      "instructionDecree": 0
    },
    "textEstaticos": {
      "Preform": "",
      "Contravener": "",
      "Postform": "",
      "LegalData": "",
      "instructionDecree": ""
    },
    "spinnerOrderTablePrintFrom": false,
    "actuationEditModeActive": false,
    "robados": {
      "list": dataPreloadFunctions.formatEffects(actuationWithOldFormat.efectos),
    },
    "acusados": {
      "list": dataPreloadFunctions.formatVinculateds(actuationName, actuationWithOldFormat.vinculados),
    },
    "denunciante": 
    {
      "list":dataPreloadFunctions.formatAffecteds(actuationName, actuationWithOldFormat.afectados),
    },
    "diligencia": [],
    "idDependencia": null,
    "idUser": null,
    "DependenciaID": null,
    "offlineActuation": true,
  }
  return actuationWithNewFormat;
}

function formatActuations(actuations) {
  let formattedActuations = []
  actuations.map(async actuation => {
    formattedActuations.push({
      type: 'offlineAppActuation',
      id: actuation.id,
      siis_state: transformToOnlineFormat(actuation)
    });
  });
  return formattedActuations;
}

async function prepareStoreData() {
  await store.dispatch('siis/setSitio');
  await store.dispatch("siis/setJuzgados");
  await store.dispatch('siis/setModusoperandi', true)
  await store.dispatch('siis/setCausaCaratula');
  await store.dispatch('siis/setArticulos');
  await store.dispatch("siis/setDelitos");
  await store.dispatch("siis/setProsecutorOrAssistant", { en: "prosecutors", es: "fiscal" });
  await store.dispatch("siis/setProsecutorOrAssistant", { en: "assistants", es: "ayudante" });
  await store.dispatch("siis/setUFIs");
}

export async function translateActuations(roughActuations) {
  // linea comentada para evitar consultas al back antes de cargar la actuaciones offline desde el navegador
  // await prepareStoreData();
  let actuationsInJSON = transformActuationsToJSON(roughActuations);
  // console.log('actuationsInJSON', await actuationsInJSON);
  let formattedActuations = await formatActuations( await actuationsInJSON);
  
  return formattedActuations;
}

function formatDate(dateString) {
  let date = new Date(dateString);

  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0'); // Obtener el mes y agregar el 0 inicial si es necesario
  let day = String(date.getDate()).padStart(2, '0'); // Obtener el día y agregar el 0 inicial si es necesario
  let hours = String(date.getHours()).padStart(2, '0'); // Obtener la hora y agregar el 0 inicial si es necesario
  let minutes = String(date.getMinutes()).padStart(2, '0'); // Obtener los minutos y agregar el 0 inicial si es necesario
  let seconds = String(date.getSeconds()).padStart(2, '0'); // Obtener los segundos y agregar el 0 inicial si es necesario

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}