import { ModelosConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/modelos'
        path: 'modelos',
        name: 'modelos',
        beforeEnter: isUserRolGuard,
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { //'parametros/modelos/new'
        path: 'modelos/new',
        name: 'modelos-new',
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/modelos/edit/:id'
        path: 'modelos/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]
     
