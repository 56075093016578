export default {
    computed: {
        PreformContravencionalDenuncia:{
            get (){
                let dependency = 
                    this.userState.dependencia !== null && this.userState.dependencia !== undefined
                    ?
                        this.userState.dependencia + ', ' + (this.userState.userdata.dependenciaDepartamento !== null ?  ', ' + this.userState.userdata.dependenciaDepartamento : '')
                    :
                        ''
                let dateAndTimeDenuncia = {
                    withStyles:   ` <p style="color: #55b5ea ; display: inline">${this.dateDenuncia}</p>,`,
                    noStyles: this.dateDenuncia,
                }
                let constance = {
                    withStyles:   ` el Funcionario de Policía que suscribe, con la intervención del Secretario de Actuaciones que refrenda, a los fines legales<b style="text-decoration: underline"> HACE CONSTAR:</b> `,
                    noStyles: ` el Funcionario de Policía que suscribe, con la intervención del Secretario de Actuaciones que refrenda, a los fines legales HACE CONSTAR: `,
                }
                let constanceText = {
                    withStyles:   `Que en la fecha y hora precitada, comparece ante esta Instrucción la persona mencionada precedentemente a fin de poner en conocimiento la supuesta Comisión de un hecho de carácter CONTRAVENCIONAL, que da lugar a la intervención de esta Autoridad Policial, acto seguido y luego de ser impuesto del contenido del Art. 245° del Código Penal, se le pregunta por sus nombres apellidos y demás circunstancias personales `,
                    noStyles: `Que en la fecha y hora precitada, comparece ante esta Instrucción la persona mencionada precedentemente a fin de poner en conocimiento la supuesta Comisión de un hecho de carácter CONTRAVENCIONAL, que da lugar a la intervención de esta Autoridad Policial, acto seguido y luego de ser impuesto del contenido del Art. 245° del Código Penal, se le pregunta por sus nombres apellidos y demás circunstancias personales `,
                }
                let people = {
                    withStyles:   this.dataResumen.denunciantes !== undefined ? `manifestó llamarse: <p style="color: #55b5ea ; display: inline">${this.dataResumen.denunciantes}. </p>Que se presenta ante esta Autoridad policial a fin de manifestar que` : '',
                    noStyles: this.dataResumen.denunciantes !== undefined ? ` manifestó llamarse: ${this.dataResumen.denunciantes}. Que se presenta ante esta Autoridad policial a fin de manifestar que` : '',
                }
                if(this.textEditsCounter.Preform == 0)
                {
                    let Preform = {
                        withStyles: this.Preforma.withStyles + dependency + dateAndTimeDenuncia.withStyles + constance.withStyles + constanceText.withStyles + people.withStyles  ,
                        noStylesFirtPart : this.Preforma.noStyles  + dependency + dateAndTimeDenuncia.noStyles + constance.noStyles,
                        noStylesSecondPart : constanceText.noStyles + people.noStyles ,
                        noStyles : this.Preforma.noStyles + dependency + dateAndTimeDenuncia.noStyles + constance.noStyles + constanceText.noStyles +people.noStyles 
                    };
                    
                    return Preform
                }else
                {   
                    let Preform = {
                        withStyles : "vacio por que no se muestra, es solo para que no de error por undefined",
                        noStyles : this.textEstaticos.Preform,
                        noStylesFirtPart : this.Preforma.noStyles + dependency + dateAndTimeDenuncia.noStyles + constance.noStyles,
                        noStylesSecondPart : this.AuxTextSecond

                    }
                    return Preform
                }
            }
        },
        Postform:{
            get (){
                if(this.textEditsCounter.Postform == 0)
                {
                    let Postform = {
                        withStyles:  `Lo que solicita es que se tomen las medidas legales de rigor. No siendo para más la presente acta la que leída y ratificada en todo su contenido se firma al pie por ante mí que lo CERTIFICA. --------`,
                        noStyles:   `Lo que solicita es que se tomen las medidas legales de rigor. No siendo para más la presente acta la que leída y ratificada en todo su contenido se firma al pie por ante mí que lo CERTIFICA. --------`
                    }
                    return Postform
                }   else
                {
                    let Postform = {
                        noStyles : this.textEstaticos.Postform
                    }
                    return Postform                    
                }
            },
        },   
        actOfStartContravencionalDenuncia:{
            get (){
                let actOfStart = this.PreformContravencionalDenuncia.noStyles + ' ' + this.deposition + '. ' + this.Postform.noStyles
                return actOfStart   
            }
        } 
    } 
};