import { PersonalFiscalConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/personal-fiscal'
        path: 'personal-fiscal',
        name: 'personal-fiscal',
        beforeEnter: isUserRolGuard,
        component:  ()=> import('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { //'parametros/personal-fiscal/new'
        path: 'personal-fiscal/new',
        name: 'personal-fiscal-new',
        component:  ()=> import('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/personal-fiscal/edit/:id'
        path: 'personal-fiscal/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]
     
