const actions = {
  post: async ({ dispatch }, payload) => new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}${payload.url}`, payload.formData, { withCredentials: true }, {
        headers: { ...payload.headers }
      })
      if (response.status == '401'){
        window.open('/login', '_blank');
        return;
      } else if (response.status != '200') {
        return reject(response)  
      } else if (response.data && response.data.error != ''){
        return reject(response)
      }
      return resolve(response);
    }
    catch (e) {
      return reject(e)
    }
  }),
  test: async ({ dispatch }, payload) => new Promise(async (resolve, reject) => {
    console.log("this stiore", this)
    // this._vm.$toast.open({message: "HI", position: 'top-right', type: 'warning', duration: 3000});
  }),  
}


// this.$toast.open({message: "HI", position: 'top-right', type: 'warning', duration: 3000});


export default {
  namespaced: true,
  // state,
  // getters,
  actions,
  // mutations
}