<template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">
      <div v-if="getMode() == 'new'" class="feature-intro">
        <h1>Nuevo archivo</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
      <div v-else class="feature-intro">
        <h1>Editando archivo</h1>
        <p>Modifique los datos del archivo</p>
      </div>
    </div>

    <TForm
      idFieldName="ArchivopdfID"
      :params="params"
      :mode="getMode()"
      :cards="false"
      :fileForm="true"
      :designEdit="designEdit"
      :designNew="design"
      apiversion="2"
      :url="url"
      :viewer="viewer"
      :viewerID="viewerID"
      :toListAfterSave="true"
      ref="TFormAddEditFileRef"
    >
      <template #pdfLink="slotProps">
        <div style="width: 120px; display: block;" v-if="$refs?.TFormAddEditFileRef?.values?.ExistingFile">
          <a class="file__link" :href="`${url}/${slotProps.data['ArchivopdfID']}?download=true`" target="_blank"><Button>Ver archivo</Button></a>
        </div>
      </template>
    </TForm>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    },
  },
  data() {
    return {
      params: {
        "Nombre": {
          "validation": "required|max_length[100]",
          "type": "input",
          "label": "Nombre del archivo",
          "placeholder": "Nombre del archivo",
        },
        "Detalle": {
          "validation": "max_length[100]",
          "type": "input",
          "label": "Detalle del archivo",
          "placeholder": "Detalle del archivo",
        },
        "Tipo": {
          "validation": "max_length[150]",
          "type": "dropdown",
          "label": "Tipo de archivo",
          "placeholder": "Tipo de archivo",
          "dropdown": {
            "optionValue": "Nombre",
            "optionLabel": "Nombre",
            "options": [],
          },
        },
        "file": {
          "validation": "required|min_length[1]",
          "type": "FileUpload",
          "upload": {
            "fileExtension": "PDF",
            "accept": ".pdf",
            "maxFileSize": 15000000,
            "multiple": false,
            "enableWhenNew": true,
            "uploadAndSave": true,
            "enabledToUpdate": true,
            "types": [
              "application/pdf"
            ],
          },
          "label": "Subir PDF",
        },
        "pdfLink": {
          type: "template"
        }
        
      },
      design: [
        {
          cards: [
            {
              design: [
                ["Nombre"],
                ["Tipo"],
                ["Detalle"],
              ],
            },
            {
              design: [
                ["file"]
              ],
            },
          ],
        },
      ],
      designEdit: [
        {
          cards: [
            {
              design: [
                ["Nombre"],
                ["Tipo"],
                ["Detalle"],
                ["pdfLink"]
              ],
            },
            {
              design: [
                ["file"]
              ],
            },
          ],
        },

      ],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/files/archivopdf",
      pageName: "Archivo para la Biblioteca",
    };
  },
  created() {
    let { id } = this.$route.params;
    this.id = id;
    this.$store.dispatch("siis/setCategoriaTipoArchivoPDF");
    // console.log(this.params);
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      categoria: (state) => state.siis.categoriaTipoArchivoPDF,
    }),
  },
  methods: {
    getMode() {
      return this.id == "add" ? "new" : "edit";
    },
  },
  watch: {
    categoria(v) {
      this.params.Tipo.dropdown.options = v;
    },
  },
};
</script>

<style scoped>
.file__link {
  text-decoration: none;
}
</style>
