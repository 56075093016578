<template>
   <div :class="containerClass" @click="onClick($event)">
       <div class="p-hidden-accessible">
            <input type="checkbox" :checked="checked" :value="value" v-bind="$attrs" @focus="onFocus($event)" @blur="onBlur($event)">
        </div>
        <div :ref="idc" :id="idc" :class="['p-checkbox-box', {'p-highlight': checked, 'p-disabled': $attrs.disabled, 'p-focus': focused}]" role="checkbox" :aria-checked="checked">
            <span :class="['p-checkbox-icon', {'pi pi-check': checked}]"></span>
        </div>
    </div>
</template>

<script>
//import ObjectUtils from '../utils/ObjectUtils';

export default {
    inheritAttrs: false,
    props: {
        value: null,
        modelValue: null,
        binary: Boolean,
        idc: String
    },
    model: {
        prop: 'modelValue',
        event: 'input',
    },
    data() {
        return {
            focused: false,
            activate: false
        };
    },
    methods: {
        onClick(event) {
            this.activate = true
            // console.log("onclick")
            // console.log("onclick", this.$refs[this.idc])
            if (!this.$attrs.disabled) {
                let newModelValue;

                // if (this.binary) {
                    newModelValue = !this.modelValue;
                // }
                // else {
                //     if (this.checked)
                //         newModelValue = this.modelValue.filter(val => !ObjectUtils.equals(val, this.value));
                //     else
                //         newModelValue = this.modelValue ? [...this.modelValue, this.value] : [this.value];
                // }

                this.$emit('click', event);
                this.$emit('input', newModelValue);
                this.$emit('change', event);
                // this.$refs.input.focus();
            }
        },
        onFocus(event) {
            if (!this.activate) return
            this.focused = true;
            // console.log(this.$refs[this.idc])
            // console.log("onFocus from custom-checkbox")
            this.$emit('focus', event);
        },
        onBlur(event) {
            if (!this.activate) return
            // console.log("onBlur from custom-checkbox")
            this.activate = false;
            this.focused = false;
            this.$emit('blur', event);
        }
    },
    computed: {
        checked() {
            return this.modelValue; //this.binary ? this.modelValue : ObjectUtils.contains(this.value, this.modelValue);
        },
        containerClass() {
            return ['p-checkbox p-component', {'p-checkbox-checked': this.checked, 'p-checkbox-disabled': this.$attrs.disabled, 'p-checkbox-focused': this.focused}];
        }
    }
}
</script>