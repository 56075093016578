<template >
  <div v-if="actuaciones.length > 0" class="shadow-4 border-round  w-full  lg:w-30rem ">
    <h4 class="pl-3 pt-3 m-0">Bandeja de entrada</h4>
    <p class="pl-3 text-sm">Recepcion de extractos y reasignaciones</p>
    <ul class="p-3 pr-3 border-right-1 border-200">
      <li class="flex flex-row">
        <span>Numeros</span>
        <span class="pl-5 ml-2">Actuaciones</span>
      </li>
      <div v-for="(item, index) in actuaciones" :key="index">
        <template>
          <li  :class="liClass(index)">
            <span
              class="pl-1 p-3 pb-2 w-5 max-w-6rem "
              :test-id="'Numero ' + index"
              >{{ item.Numero ? item.Numero : item.Extracto }}</span
            >
            <div
              class="
                h-4rem
                flex
                w-10
                align-items-center
                justify-content-between 
                border-left-2 border-200
              "
            >
              <span
                class="p-2 pl-1 w-6rem   "
                :test-id="'Actuacion ' + index"
                >{{ item.Tipo.includes("Expediente") ? "Expediente" : "Sumario" }}
              </span>

              <!-- el oficial sumariante recibe el sumario -->
              <div
                v-if="
                  !$rest.esTipoUsuario(
                    [
                      'Jefe de Sumario',
                      'Jefe de Dependencia',
                      'Super User',
                      'User Tester',
                      'Super Usuario de E3',
                      'Dpto. Asuntos Judiciales D-5',
                      'Dpto.Criminalistica',
                      'Lab. Informatico Forense'

                    ],
                    user
                  )
                "
                class="mr-3"
              >
                <Button
                  class="m-1 w-8rem  p-button-danger pi pi-check lg:max-h-2rem lg:w-15rem"
                  type="button"
                  @click="openModal(item, 'recibir sumario')"
                  v-if="item.Numero && item.Usuario == user.userdata.userId"
                >
                  Cambio de oficial
                </Button>
                <!-- pide asignacion de numero de sumario solo a su usuario asignado -->
                <Button
                  class="m-1 w-8rem p-button-danger pi pi-check lg:max-h-2rem lg:w-15rem"
                  type="button"
                  @click="openModal(item, 'cambio numero')"
                  v-else-if="item.Usuario == user.userdata.userId"
                >
                  Extracto de denuncia
                </Button>
              </div>
              <!-- pide cambio de usuario Habilitado para admins y jefes de sumario/dep -->
              <!-- solo deberia mostrarle los sumarios sin usuario asignado-->
              <div class="flex flex-row mr-2 lg:mr-5" v-else>
                <!-- {{item}} -->
                <div class="pr-2">
                  <Button
                    v-tooltip.left="'Visualizar esta Actuacion'"
                    icon="pi pi-eye"
                    class="p-button-rounded p-button-outlined"
                    @click="loadActuacion(item.ActuacionID)"
                    :test-id="'visualizar-act' + item.ActuacionID"
                  />
                </div>
                <div>
                  <Button
                    v-if="
                      !$rest.esTipoUsuario(
                        ['Oficial Sumariante', 'Deposito Judicial'],
                        user
                      ) && $offline.onlineMode
                    "
                    v-tooltip.left="'Reasignar secretario'"
                    icon="pi pi-user-plus"
                    class="
                      p-button-rounded p-button-outlined p-button-seccess
                      mr-2
                    "
                    @click="openModal(item, 'asignar usuario')"
                  />
                </div>
              </div>
            </div>
          </li>
        </template>
      </div>
    </ul>

    <ModalForm
      :Show="showModalFormDep"
      @sendInfo="setNumSum"
      Title="Ingrese un Nro° de Sumario  "
      :Inputs="InputsNum"
    />
    <ModalForm
      :Show="showModalFormUser"
      @sendInfo="setUsuario"
      Title="Ingrese un usuario"
      :Inputs="InputsUser"
    />
  </div>
</template>

<script>
import dateMixing from "@/mixins/dateMixin";
export default {
  name: "Bandeja",
  mixins: [dateMixing],
  props: {
    user: {
      type: Object,
    },
    dependencia: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      InputsNum: [
        {
          type: "input",
          name: "NumeroSumario",
          typeI: "number",
          label: "Numero de sumario",
        },
        {
          type: "dropdown",
          options: [
            { value: 22, name: "2022" },
            { value: 21, name: "2021" },
          ],
          name: "year",
          typeI: "number",
          placeholder: "Año",
          label: "Año",
          valueDrop: {},
        },
      ],

      InputsUser: [
        {
          type: "dropdown",
          class: "absolute",
          options: [],
          valueKey: "UsuarioID",
          nameKey: "NombreUsuario",
          name: "usuario",
          typeI: "text",
          placeholder: "Secretario",
          label: "Secretario",
          valueDrop: {},
        },
      ],

      showModalFormDep: { show: false },
      showModalFormUser: { show: false },
      actuaciones: [],
      sumarioActual: null,
      users: [],
    };
  },
  async created() {
    this.getData(this.dependencia);
    this.InputsUser[0].options = (
      await this.$rest.get({ url: "/usuario" })
    ).data.data.filter((e) => e.DependenciaID === this.dependencia);
  },
  methods: {
    openModal(sumario, tipo) {
      switch (tipo) {
        case "recibir sumario":
          this.recibirSumario(sumario);
          break;
        case "cambio numero":
          this.showModalFormDep.show = true;
          break;
        case "asignar usuario":
          this.showModalFormUser.show = true;
          break;
      }
      this.sumarioActual = sumario;
    },
    //esta funcion es para admins y es para asignar un oficial al sumario
    async setUsuario({ usuario }) {
      let sumario = this.sumarioActual;
      let options = {};
      options.url = "/actuacion-usuario-dependencia/" + sumario.ActuacionUsuarioDependenciaID;

      options.formData = {
        DependenciaID: sumario.DependenciaID,
        ActuacionUsuarioDependenciaID: sumario.ActuacionUsuarioDependenciaID,
        UsuarioID: usuario,
        RecienEnviada: "1",
        ActuacionID: sumario.ActuacionID,
        Fechadesde: this.actualDate(),
      };
      await this.$rest.put(options);
      this.getData(this.dependencia);
    },
    //esta funcion y la que esta abajo de esta son para el oficial suimariante una asigna numero a la actuacion y la oitra simplemente la recibe(cambia valor de RecienEnviada)
    async setNumSum(res) {
      let numero = res.NumeroSumario + "/" + res.year;
      let sumarioID = this.sumarioActual.ActuacionID;
      let options = {
        url: `/sumario/${sumarioID}`,
        formData: {
          DenunciaID: this.sumarioActual.DenunciaID,
          DependenciaID: this.sumarioActual.DependenciaID,
          FechaCreacion: this.sumarioActual.FechaCreacion,
          ActuacionID: this.sumarioActual.ActuacionID,
          TiposumarioID: this.sumarioActual.TiposumarioID,
          Numero: numero,
        },
        params: { method: "methodCommon" },
      };
      await this.$rest.put(options);
      await this.recibirSumario(this.sumarioActual);
      this.getData(this.dependencia);
      this.loadActuacion(sumarioID);
    },
    async recibirSumario(sumario) {
      let uSumario = {
        ActuacionUsuarioDependenciaID: sumario.ActuacionUsuarioDependenciaID,
        UsuarioID: sumario.Usuario,
        Fechahasta: null,
        DependenciaID: sumario.DependenciaID,
        Fechadesde: sumario.FechadesdeUs,
        ActuacionID: sumario.ActuacionID,
        cambioRecienEnviada: true,
        RecienEnviada: "0",
      };
      let options = {
        url: `/actuacion-usuario-dependencia/${uSumario.ActuacionUsuarioDependenciaID}`,
        formData: { ...uSumario },
        params: {
          method: "own",
        },
      };
      await this.$rest.put(options);
      this.getData(this.dependencia);
      this.loadActuacion(sumario.ActuacionID);
    },
    async getData(dependencia) {
      let basicUrl = process.env.VUE_APP_BACKEND_ENVIRONMENT;
      let options = {};
      options.url = basicUrl + "/Actuacion/estadisticas";

      // se fija si es oficial sumariante y solo pregunta por sus sumarios recientemente asignados else trae los que no tenga usuario asigando
      if (this.$rest.esTipoUsuario("Oficial Sumariante", this.user)) {
        options.params = {
          search: {
            "exp_pre_ActuacionUsuarioDependencia.RecienEnviada": [
              { operator: "=", value: 1 },
            ],
          },
        };
      } else {
        options.params = {
          search: {
            "exp_pre_ActuacionUsuarioDependencia.Fechahasta": [
              { operator: "=", value: null },
            ],
            "exp_pre_ActuacionUsuarioDependencia.UsuarioID": [
              { operator: "=", value: "Sin Usuario Asignado" },
            ],
          },
        };
        if (dependencia) {
          options.params.search["exp_pre_Actuacion.DependenciaID"] = [
            { operator: "=", value: dependencia },
          ];
        }
      }
      let recienAsignada = await this.$rest.get(options);
      this.actuaciones = recienAsignada.data.data;
    },
    liClass(i) {
      if (i == 0 && i != this.actuaciones.length - 1)
        return "flex flex-row flex-auto border-3 border-x-none border-200  align-items-center";
      else if (i == 0 && !(i != this.actuaciones.length - 1))
        return "flex flex-row flex-auto  border-top-3 border-200 align-items-center";
      else if (i != this.actuaciones.length - 1)
        return "flex flex-row flex-auto  border-bottom-3 border-200 align-items-center";
      else return "flex flex-row  flex-auto align-items-center";
    },
    async loadActuacion(sumarioid) {
      await this.$store.dispatch("sumario/setSumario", sumarioid);
      this.$router.push(`/sumario/denuncia/detalle/${sumarioid}`);
    },
  },

  watch: {
    dependencia(dependencia) {
      this.getData(dependencia);
    },
  },
};
</script>

<style>
.break-word {
  word-wrap: break-word;
}
</style>