<template>
  <Dialog
    test-id="modalSuccessAndFail"
    :visible="Show.show"
    :style="{ width: '500px' }"
    :contentStyle="{}"
    :modal="true"
    :closable="false"
    ref="modalUpdate"
  >
    <template #header>
      <div class="text-center" :style="{marginRight:'35%'}">
        <h4 test-id="" class="ml-3">{{ Title }}</h4>
      </div>
      <Button
      :style="{
        border:'none',
        backgroundColor:'transparent',
        color: 'red',
      }"
        @click="close()"
        >X</Button
      >
    </template>

    <div class="flex justify-content-center flex-column m-3">
      <template v-for="(element, i) in Inputs">
        <span v-bind:key="i">
          <div v-if="element.type === 'input'" class=" m-3">
            <p>{{ element.label ? element.label : "" }}</p>
            <Input
              :name="element.name"
              :class="element.class ? element.class : 'col-fixed'"
              :type="element.typeI"
              :test-id="element.testid"
              :placeholder="element.placeholder"
              @keyup="setValue"             
            ></Input>
            <!-- :type="element.type" -->
          </div>
          <div v-if="element.type === 'dropdown'" >
            <p>{{ element.label ? element.label : "" }}</p>
            <Dropdown
              v-model="element.valueDrop"
              :options="element.options"
              :filter="element.filter"
              :optionValue=" element.valueKey?element.valueKey :'value'"
              :optionLabel="element.nameKey?element.nameKey :'name'"
              :placeholder="element.placeholder"
              :class="element.class ? element.class : 'col-fixed'"
              :test-id="element.testid"
              @change="(e)=>{setValueDrop(e.value,element.name, element.needsValue)}"
            ></Dropdown>
          </div>

        </span>
      </template>
    </div>
    <template #footer>
      <div class="text-center"></div>
      <Button type="button" @click="send" :disabled="disable()">Enviar</Button>
    </template>
  </Dialog>
</template>

<script>
/**
   * The only true ModalForm.
   * 
   * @displayName Best ModalForm
   * DEVUELVE LA INFO AL PADRE EN 'sendInfo' 
   * EJEMPLO :<ModalForm @sendInfo="addNsumario"  />
   */
export default {
  name: "ModalForm",
  data() {
    return {};
  },
  beforeCreate() {
    this.dataFormulario = {};     
  },
  props: {
    /**
     * Un arreglo con los inputs 
     * [{
     * -------------------inputs/dropdowns-------------------
     *  type:'input' || 'dropdown',
     *  name:String,
     *  typeI:String es el tipo de input ejemplo un input de tipo text o number,
     *  label:String,
     *  class:String,
     *  test-id:String,
     *  placeholder:String, 
     * -------------------dropdowns-------------------
     *  valueDrop:{} este es necesario en caso de ser un dropdown,
     *  filter:Boolean
     *  options:[] en este van opciones del dropdown,
     *  valueKey:String este especifica la propiedad de las options que vas a utilizar como valor,
     *  nameKey:String este especifica la propiedad de las options que vas a utilizar como nombre    
     * }]
     * --------------------------------------------------------
     * Ejemplo:[{type:'input',name:'NumeroSumario',typeI:'text', label:'Numero de sumario'},
     * {type:'dropdown',options:[ {value:123,name:"algo"}, {value:123423,name:"algo,as"} ]
     * ,name: 'cosas',typeI: 'text', placeholder: "drop de prueba", label: 'drop de prueba', valueDrop: {}}]
     */
    Inputs: {
      type: Array,
      default: [],
    },
    /**
     * titulo del modal
     * @type String
     */
    Title: {
      type: String,
      default: "",
    },
    /**
     * este debe ser un objeto con una propiedad show = boolean para abrir y cerrar el modal
     * Ejemplo: showModalForm:{show:false}
     */
    Show: {
      type: Object,
      default: false,
    },
    /**
     * este nombre es el nombre del evento que despacha la informacion al padre 
     * por defecto es sendInfo
     */
    NameEvent:{
      type:String,
      default:'sendInfo'
    },
    NameEventDrop:{
      type:String,
      
    },
    OptionDisable:{
      type:Boolean,
      default: false,
    }
    ,
  },
  computed: {
    dataForm: {
      get() {
        return this.dataFormulario;
      },
      set(value) {
        return (this.dataFormulario = value);
      },
    },
  },
  methods: {
    close(){
      //Al cerrar borramos el valor del drop
      this.Inputs.map(e=>{
        if(e.valueDrop){
          e.valueDrop={}
        }
      })
      //Tambien borramos el valor del input
      this.dataForm['NumeroSumario'] = undefined
      this.Show.show = false;
    },
    disable(){
      /*Si la opcion para que el boton sea disable esta activada */
      if(this.OptionDisable){
        //Analizamos si alguno de los datos a ingresar no fue cargado correctament entonces desabilitamos el boton
        if(this.dataForm['NumeroSumario'] == undefined|| this.dataForm['NumeroSumario'] == '' || this.dataForm['year'] == undefined){
            return true
        }     
      }
      return false
    },
    send() {
      this.$emit(this.NameEvent, this.dataForm);
      this.Inputs.map(e=>{
        if(e.valueDrop){
          e.valueDrop={}
        }
      })
      this.Show.show=false;
    },
    setValue(e){
      this.dataFormulario[e.target.name]=e.target.value
    },
    setValueDrop(value,name,needsValue){
      this.dataFormulario[name]=value
      if(needsValue)
      this.$emit(this.NameEventDrop,{name:value})
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}</style>
