const state = () => ({
  siteRoute: "home",
  isLoading: null,
  test: true

})


// actions
const actions = {
  setRoute ({ commit }, siteRoute) {
    commit('setIsLoadingStatus', true)
    commit('setRoute', siteRoute)
  }
}


// mutations
const mutations = {
  setRoute (state, siteRoute) {
    state.siteRoute = siteRoute
  },
  setIsLoadingStatus (state, status) {
    state.isLoading = status
  }
}


export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations
}