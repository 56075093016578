<template>
    <div>
      <PageMenu :formType="formType" />
    </div>
  </template>
  <script>
  import PageMenu from "@/components/MenuButtons/PageMenu.vue"; 
  export default {
    name: 'MenuPage',
    props: {
        formType: String,
    },
    components: {
      PageMenu,  
    },
    //menu de preliminares y ufis
    
  }
  </script>