const actuationsModel = {
  name: 'actuations',
  schema: {
    '++id': Number,
    ActuacionArticulo: Object,
    ActuacionUsuarioDependencia: Array,
    Acusado: Object,
    Denuncia: Object,
    DenunciaID: String,
    Denunciante: Object,
    Efectos: Array,
    Juzgadointerviniente: Object,
    ModusOperandi: Object,
    MomentoUbicacion: Array,
    PersonalFiscal: Array,
    PersonalInterviniente: Array,
    Sitio: Object,
    State: Object,
    Sumario: Object,
    SumarioUfi: Object,
    Sumariocausacartula: Object,
    TipoSitioID: String,
    assistant: Object,
    causaCaratula: Object,
    causaJuzgado: String, // Cambiado a String
    causaNumero: String,
    denunciaText: String,
    dependencia: String,
    dependenciaDepartamento: String,
    dependenciaDomicilio: String,
    dependenciaID: String,
    dependenciaPolicial: String,
    deposition: String,
    diligencia: Array,
    effectsType: String,
    estadoCivil: Object,
    fechaCertificadoDenuncia: String, // Cambiado a String
    fechaDenuncia: Date,
    formType: String,
    id: String, // Cambiado a String
    idDependencia: String,
    idUser: String,
    isSaving: Boolean,
    listaArticulos: Array,
    listaCaratulas: Array,
    modusOperandi: Object,
    moment: Object,
    preMoment: Object,
    preUbicacion: Object,
    prosecutor: Object,
    residencia: Object,
    robados: Object,
    saveStatus: String,
    spinnerOrderTablePrintFrom: Boolean,
    sumarioEstado: String,
    sumarioEstadoID: String,
    textEditsCounter: Object,
    textEstaticos: Object,
    timer: Boolean,
    tiposumarioID: String,
    type: String,
    ubicacion: String
  }
};

export default actuationsModel;