<template>
  <div class="container">
    <DashboardBody />
  </div>
</template>

<script>
import DashboardBody from '../components/Dashboard/DashboardBody.vue';
export default {
  components: {
    DashboardBody
  },
  name: "Dashboard",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
