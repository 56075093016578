import generalFunctions from '../generalFunctions';
import views from './views'
import * as AltaDenuncia from '../../../data/sumario_denuncia_alta';
import functionsEditMode from './editModeModules/functionsEditMode';
import Vue from 'vue'

const state = () => ({
  actuationDataForEdit: {},
  formType: '',
  viewsForFormType: {},
  spinnerSaveButton: false,
  disableFinishButton: false,
})

const actions = {
  async prepareActuationForEdition({ commit, dispatch }, payload) {
    commit('setDisableFinishButton', true);
    commit('establishFormType', payload);
    commit('establishViewsForFormType', payload);
    let actuationBuilded = await dispatch('buildActuation', payload);
    commit('setActuationDataForEdit', actuationBuilded);
    await dispatch('sendActuationDataForEditToSumario');
    commit('setDisableFinishButton', false);
  },
  async sendActuationDataForEditToSumario({ dispatch, state }) {
    dispatch('sumario/setSumario', {siis_state: {...state.actuationDataForEdit}},  { root: true });
  },
  async buildActuation({ dispatch, rootState, commit }, payload) {
    // console.log("payload: ",payload);
    const affecteds = await dispatch('buildAffectds', payload);
    // console.log("affecteds: ",affecteds);
    const vinculateds = await dispatch('buildVinculateds', payload);
    const effects = await dispatch('buildEffects', payload);
    const interveningPersonnel = await dispatch('buildInterveningPersonnel', payload);
    await dispatch('siis/setSitio', null, { root: true });
    let actuationPreLoaded = createActuationPreLoaded(payload);
    commit('preloadEffects', {actuationPreLoaded, effects});
    actuationPreLoaded.denunciante = { emptyDoc: affecteds[affecteds.length -1].doc, list: affecteds };
    actuationPreLoaded.acusados = { emptyDoc: vinculateds[vinculateds.length -1].doc, list: vinculateds };
    actuationPreLoaded.Sitio = rootState.siis.sitio.find(sitio => sitio.Nombre ===  payload.Sitio);
    actuationPreLoaded.moment = {date: payload.moment.date, date2: payload.moment.date2};
    actuationPreLoaded.preMoment = {date: payload.moment.date, date2: payload.moment.date2};
    actuationPreLoaded.PersonalInterviniente = interveningPersonnel;
    actuationPreLoaded.fechaDenuncia = new Date();
    actuationPreLoaded.preUbicacion.departamento = AltaDenuncia.default.departamentos.find(deparment => deparment.nombre ===  actuationPreLoaded.preUbicacion.departamento);
    actuationPreLoaded.preUbicacion.orientacion =  { "id": actuationPreLoaded.preUbicacion.orientacion, "nombre": actuationPreLoaded.preUbicacion.orientacion };
    return actuationPreLoaded;
  },
  buildAffectds({state}, payload) {
    // console.log("payload.denunciante.list: ",payload.denunciante.list);
    let newAffectedsList = buildPersonList(payload.denunciante.list, state);
    newAffectedsList.push({
      arrId: generalFunctions.uuidv4(),
      doc: {
        "instruccion":{"id":"sabe_leer_sabe_firmar","nombre":"Sabe leer y sabe firmar"},
        "nacionalidad":{"id":1,"nombre":"ARGENTINA"},
        "tipoDocumento":{"id":"DNI","nombre":"DNI"},
        "condicion": generalFunctions.determineDefaultConditionFromAffected(state.formType)
      },
      emptyTitle: state.viewsForFormType.formPersona.whistleblower.emptyTitle,
      ordenPublico: false,
      type:"empty",
    })
    return newAffectedsList;
  },
  buildVinculateds({state}, payload) {
    let newVinculateds = buildPersonList(payload.acusados.list, state);
    let defaultValues = { identikit: [], identikitQuestion: 1, hasIdentikit: false, conocido: true, identikit: [], descriptionOffender: [] };
    // let defaultValues = { identikitQuestion: 1,  conocido: true, descriptionOffender: [] };
    let newVinculatedsWithDefaultValues = addDefaultValuesToPerson(newVinculateds, defaultValues);
    newVinculatedsWithDefaultValues.push({
      arrId: generalFunctions.uuidv4(),
      doc: {
        "instruccion":{"id":"sabe_leer_sabe_firmar","nombre":"Sabe leer y sabe firmar"},
        "nacionalidad":{"id":1,"nombre":"ARGENTINA"},
        "tipoDocumento":{"id":"DNI","nombre":"DNI"},
        "condicion": generalFunctions.determineDefaultConditionFromVinculated(state.formType) 
      },
      emptyTitle: state.viewsForFormType.formPersona.accused.emptyTitle, 
      type:"empty",
      identikit: [], identikitQuestion: 1, hasIdentikit: false, descriptionOffender: [],
      conocido: true
    })
    return newVinculatedsWithDefaultValues;
  },
  buildEffects({}, payload) {
    let newEffectsList = buildEffectsList(payload.robados.list);
    newEffectsList.push({
      arrId: generalFunctions.uuidv4(), 
      type: "empty",
      doc: {},
      hasIdentikit:false,
      name: "Nuevo efecto",
      conocido:true
    })
    return newEffectsList;
  },
  buildInterveningPersonnel({}, payload) {
    let newInterveningPersonnel = [];
    payload.PersonalInterviniente.map(intervener => {
      newInterveningPersonnel.push({
        dependencia: intervener.Dependencia,
        jerarquia: AltaDenuncia.default.jerarquia.find(hierarchy => hierarchy.nombre === intervener.Jerarquia),
        nombre: intervener.Nombreyapellido
      })  
    })
    return newInterveningPersonnel;
  },
  async saveEdition({state, commit, dispatch}, payload) {
    commit('setSpinnerSaveButton', true);
    let actuationFromBack = await functionsEditMode.getActuationFromBack(state);
    await dispatch('saveUbication', payload);
    await dispatch('saveCoelsa', payload.denunciante.list);
    await dispatch('savePlataformas', {affecteds: payload.denunciante.list, vinculateds: payload.acusados.list});
    await dispatch('saveEffects', { effectsForUpdate: payload.robados.list });
    await dispatch('savePersons', { personsForUpdate: payload.acusados.list, actuationFromBack, personBack: 'VINCULADO', personAPI: 'Vinculados'});
    await dispatch('savePersons', { personsForUpdate: payload.denunciante.list, actuationFromBack, personBack: 'AFECTADO', personAPI: 'Afectados'});
    commit('setSpinnerSaveButton', false);
  },
  async saveUbication({state}, payload) {
    let actuationUpdated =  payload.preUbicacion;
    let options = {
      url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/momento-ubicacion/${state.actuationDataForEdit.id}`,
      formData:{
        "MomentoUbicacion": {     
          "Calle": actuationUpdated.calle,    
          "Numero": actuationUpdated.numero,   
          "Departamento":   actuationUpdated.departamento?.nombre,
          "Casa":   actuationUpdated.casa,
          "Orientacion": actuationUpdated.orientacion?.nombre,
          "Sector": actuationUpdated.sector,
          "Piso":   actuationUpdated.piso,
          "Barrio": actuationUpdated.barrio, 
          "Zona":   actuationUpdated.zona,
          "Longitud":   actuationUpdated.coordinates.lng, "Latitud": actuationUpdated.coordinates.lat,
        }    
      }, ContentType: "text/plain"
    }
    await functionsEditMode.methodPut(options);
  }, 
  async savePersons({state}, {personsForUpdate, actuationFromBack, personBack, personAPI}) {
    let personsFromBack = actuationFromBack.ActuacionPersonaFisica[personBack];
    let params = {personsFromBack: personsFromBack, personsForUpdate};
    let personsForUpdateFormatted = formatPersonsForBackend(params, state);
    if(personAPI === 'Afectados') personsForUpdateFormatted.forEach(person => { delete person.Apodo; });
    let options = {
      url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-persona-fisica/${state.actuationDataForEdit.id}`,
      formData: {[personAPI]: personsForUpdateFormatted}, ContentType: "text/plain"
    }
    await functionsEditMode.methodPut(options);
  },
  async saveEffects({state}, {effectsForUpdate}) {
    let structureToUnnests = makeStructureToUnnestsForOldEffects();
    let effectsFormatted = formatEffects(state, {structureToUnnests, effectsForUpdate});
    let options = {
      url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-efecto/${state.actuationDataForEdit.id}`, formData: effectsFormatted, 
      ContentType: "text/plain"
    }
    await functionsEditMode.methodPut(options);
  },
  async saveCoelsa({}, affecteds) {
    let itemNames = { 
      newItemName: "codigosCoelsa", oldItemName: "oldCoelsa", itemAPIName: "persona-coelsa", 
      itemBackName: "CodigoCoelsa", itemToDeleteName: "PersonaFisicaCoelsaID"
    };
    affecteds.map( affected => {
      processAllItems(affected, itemNames);
    });
  },
  async savePlataformas({}, persons) {
    let itemNames = { 
      newItemName: "plataformas", oldItemName: "oldPlataformas", itemAPIName: "persona-plataforma", 
      itemBackName: "Url", itemToDeleteName: "PersonaFisicaPlataformaID"
    };
    persons.affecteds.map( affected => {
      processAllItems(affected, itemNames);
    });
    persons.vinculateds.map( vinculated => {
      processAllItems(vinculated, itemNames);
    });
  },
}



const mutations = {
  setActuationDataForEdit(state, payload) {
    state.actuationDataForEdit = payload;
  },
  establishFormType(state, payload) {
    const formType = generalFunctions.translateNameFromFormType(payload.formType);
    state.formType = formType;
  },
  establishViewsForFormType(state, payload) {
    const viewsForFormType = views.state()[generalFunctions.translateNameFromFormType(payload.formType)];
    state.viewsForFormType = viewsForFormType;
  },
  preloadEffects({}, {actuationPreLoaded, effects}) {
    actuationPreLoaded.robados.list = effects;
  },
  setSpinnerSaveButton(state, payload) { 
    state.spinnerSaveButton = payload; 
  },
  setDisableFinishButton(state, payload) { 
    state.disableFinishButton = payload; 
  }
}

function transformConditionToJSON(condition) {
  switch (condition) {
    case 'Victima':
      return { id: "victima", nombre: "Víctima" };
    case 'Peaton':
      return { id: "Peatón", nombre: "Peatón" };
    default: 
      return { id: condition, nombre: condition };
  }
}

function transformItemForFront(itemListFromBack, itemPropName) {
  let itemListForFront = [];
  itemListFromBack.map((item) => {
    itemListForFront.push({
      "PersonaFisicaID": item.PersonaFisicaID,
      [itemPropName]: item[itemPropName]
    })
  });
  return itemListForFront;
}

function createActuationPreLoaded(payload) {
  let actuationPreLoaded =  {};
  for (const key in payload) {
    actuationPreLoaded[key] = payload[key];
  }
  return actuationPreLoaded; 
}

function buildPersonList(personList, state) {
  // console.log("personList: ", personList);

  let newPersonList = [];
  personList.map(async person => {
    if(person?.Apellido === "Persona de Filiacion desconocida"){
      newPersonList.push(buildUnknownParentage(person));
      return;
    } 
    if(person?.Apellido === "Orden público"){
      let publicOrder = buildPublicOrder(state);
      newPersonList.push(publicOrder);
      return;
    }
    newPersonList.push(buildAPersonForFront(person));
  })
  return newPersonList;
}

function buildUnknownParentage(person) {
  let unknownParentage = {
    filiacionDesconocida:true,
    arrId: generalFunctions.uuidv4(),
    doc: {
      "instruccion":{"id":"sabe_leer_sabe_firmar","nombre":"Sabe leer y sabe firmar"},
      "nacionalidad":{"id":1,"nombre":"ARGENTINA"},
      "tipoDocumento":{"id":"DNI","nombre":"DNI"},
      "condicion": { id: "Acusado", nombre: "Acusado" },
      "ActuacionPersonaFisicaID": person.ActuacionPersonaFisicaID
    },
    emptyTitle: '', 
    type:"doc",
    identikit: person?.Identikit?.length > 0 ? person.Identikit : [], identikitQuestion: 1, 
    hasIdentikit: person?.Identikit?.length > 0 ? true : false, descriptionOffender: [], conocido: true, sipeSearch:false,
  }
  return unknownParentage;
}

function buildPublicOrder() {
  let publicOrder = {
    arrId: generalFunctions.uuidv4(),
    doc: {
      arrId: generalFunctions.uuidv4(),
      "instruccion":{"id":"sabe_leer_sabe_firmar","nombre":"Sabe leer y sabe firmar"},
      "nacionalidad":{"id":1,"nombre":"ARGENTINA"},
      "tipoDocumento":{"id":"DNI","nombre":"DNI"},
      "condicion": generalFunctions.determineDefaultConditionFromAffected(state.formType)
    },
    emptyTitle: state.viewsForFormType?.formPersona?.whistleblower?.emptyTitle,
    ordenPublico: true,
    type:"doc",
  }
  return publicOrder;
}

function addDefaultValuesToPerson(persons, defaultValues) {
  let personWithDefaultValues = [];
  let defaultValuesCopy;
  persons.map(async person => {
    defaultValuesCopy = {...defaultValues};
    Object.keys(person).forEach(key => key in defaultValuesCopy && delete defaultValuesCopy[key]);
    personWithDefaultValues.push({
      ...person,
      ...defaultValuesCopy
    })
  })
  return personWithDefaultValues;
}

function buildEffectsList(effects) {
  let newEffectsList = [];
  effects.map(effect => {
    if(effect.type = "doc"){
      newEffectsList.push({
        arrId: generalFunctions.uuidv4(), type:"doc", 
        tipoEfecto: AltaDenuncia.default.objetosAllTypes.find(objectType => objectType.nombre ===  effect.doc.tipoEfecto),
        doc: buildEffectsDoc(effect)
      })
    }
  })
  return newEffectsList;
}

function buildEffectsDoc(effect) {
  if(effect?.doc?.newFormatEffect === true){
    let newEffect = {...effect};
    newEffect.doc.tipoEfecto =  AltaDenuncia.default.objetosAllTypes.find(objectType => objectType.nombre ===  effect?.doc?.Tipoefecto);
    return newEffect.doc;
  } else{
    return {
      arrId: generalFunctions.uuidv4(), color: effect.doc.color,
      details: effect.doc.details,
      marca: effect.doc.marca, modelo: effect.doc.modelo,
      numId: effect.doc.numId, ActuacionEfectoID: effect.doc.ActuacionEfectoID,
      tipo: effect.doc.tipo, EfectoID: effect.doc.EfectoID,
      tieneSeguro: effect.doc.tieneSeguro === "1" ? true : false,
      tipoEfecto: AltaDenuncia.default.objetosAllTypes.find(objectType => objectType.nombre ===  effect.doc.tipoEfecto),
      newFormatEffect: false 
    }
  }
}

function accessNestedProperties(structureToUnnests, objectToUpdate) {
  let newElement = {};
  for (let prop in structureToUnnests) {
    const objectToUnnest = structureToUnnests[prop];
    let payload = objectToUpdate.doc;
    for (let i = 0; i <  objectToUnnest.length; i++) {
      payload = payload?.[objectToUnnest[i]] ;
    }
    newElement[prop] = payload !== undefined ? payload : "";
  }
  return {...newElement};
}
const emptyEffect={
  "EfectoDepositoID": null,
  "EstadoEfectoActuacionID": "Denunciado" 
}

function formatEffects(state, {structureToUnnests, effectsForUpdate}) {
  let effectsFormatted = [];
  for (let i = 0; i < effectsForUpdate.length; i++) {
    // console.log(effectsForUpdate[i])
    if(effectsForUpdate[i].type === "empty"){ 
    continue;
  }
    let effect = {};
    if(!effectsForUpdate[i].doc.newFormatEffect){
      effect = functionsEditMode.accessNestedProperties(structureToUnnests, effectsForUpdate[i]);
      effect.TieneSeguro = effect.TieneSeguro === true ? "1" : "";
      effect.ActuacionID = state.actuationDataForEdit.id;
    } else{
      effect = {...effectsForUpdate[i].doc};
      delete effect.arrId; delete effect.newFormatEffect;
      effect.EfectoDepositoID = effect?.EfectoDepositoID === undefined ? null : effect.EfectoDepositoID;
      effect.EstadoEfectoActuacionID = effect?.tipoEfecto?.id;
      if(effect?.ActuacionEfectoID !== undefined ) effect.ActuacionEfectoID
      if(effect?.Tipoefecto !== undefined ) delete effect.Tipoefecto
    }
    delete effect.tipoEfecto; 
    if(!isEqualExcept(effect,emptyEffect,["EstadoEfectoActuacionID"])){
    effectsFormatted.push(effect);
    }
  }
  return {"Efectos": effectsFormatted};
}

function processAllItems(person, itemNames) {
  let personaFisicaID = person.doc.PersonaFisicaID;
  let allItems = {
    currentItems: person?.doc?.[itemNames.newItemName] === undefined ? [] : person.doc[itemNames.newItemName],
    oldItems: person?.doc?.[itemNames.oldItemName] === undefined ? [] : person.doc[itemNames.oldItemName]
  };
  let itemsToCreate = findItemsToCreate(allItems);
  let itemsToDelete = findItemsToDelete(allItems, itemNames);
  if(itemsToCreate.length > 0) createAllNewItems(itemsToCreate, { ...itemNames, personaFisicaID });
  if(itemsToDelete.length > 0) deleteAllItems(itemsToDelete, itemNames);
}

function findItemsToCreate(allItems) {
  let itemsToCreate = [];
  allItems.currentItems.map( item => {
    let isItemToCreate = typeof item === "string";
    if(isItemToCreate) itemsToCreate.push(item);
  });
  return itemsToCreate;
}

  function findItemsToDelete(allItems, itemNames) {
  let currentItemsFormatted = formatCurrentItems(allItems.currentItems, itemNames);
  let itemsToDelete = [];
  allItems.oldItems.map( item => {
    if(!currentItemsFormatted.includes(item[itemNames.itemBackName])) itemsToDelete.push(item);
  });
  return itemsToDelete;
}

function formatCurrentItems(currentCoelsas, itemNames) {
  const result = currentCoelsas.map(item => {
    if (item && item[itemNames.itemBackName] !== undefined) {
      return item[itemNames.itemBackName];
    }
    return item;
  });
  return result;
}

async function createAllNewItems(itemsToCreate, itemsData) {
  let options
  itemsToCreate.map( async item => {
    options = {
      url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/${itemsData.itemAPIName}`,
      formData:{
        "PersonaFisicaID": itemsData.personaFisicaID,
        [itemsData.itemBackName]: item
      }, 
      ContentType: "text/plain"
    }
    await functionsEditMode.methodPost(options);
  });
}

async function deleteAllItems(itemsToDelete, itemNames) {
  let options;
  itemsToDelete.map( async item => {
    options = {
      url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/${itemNames.itemAPIName}/${item[itemNames.itemToDeleteName]}`,
      ContentType: "text/plain"
    }
    await functionsEditMode.methodDelete(options);
  });
}

//Los datos de Vinculados y Afectados son iguales, salvo por el campo "Apodo" que posteriormente es eliminado al guardar Afectados
function makeABlankPerson(actuacionID) {
  return {
    ActuacionID: actuacionID, ActuacionPersonaFisicaID: "", Apellido: "Sin Apellido Especificado", Apodo: undefined,
    Celular: undefined, Cuil: null, Cuit: undefined, Denominacion: undefined, Dni: "Sin Dni Especificado",
    DomicilioJuridico: undefined, DomicilioSipe: undefined, CorreoElectronico: undefined, EstadoCivil: undefined, FechaDeNacimiento: undefined,
    FechaDefuncion: null, FechaDesde: "", FechaHasta: null, Instruccion: "", LugarDeNacimiento: null, Nacionalidad: "",
    Nombre: "Sin Nombre Especificado", Orden: null, PersonaFisicaID: "", PersonaJuridica: undefined, Profesion: undefined,
    Rubro: undefined, Sexo: undefined, Telefono: undefined, TelefonoJuridico: undefined, TipoRolPersonaFisicaID: ""
  }
}

function makeStructureToUnnestsForPersons() {
  return {
    TipoRolPersonaFisicaID: ["condicion", "nombre"], Celular: ["telefono"], Nacionalidad: ["nacionalidad", "nombre"],
    Sexo: ["sexo", "nombre"], Telefono: ["telefono"], EstadoCivil: ["estadoCivil", "nombre"], CorreoElectronico: ["email"], Apodo: ["apodo"],
    FechaDeNacimiento: ["fechaNacimiento"], Instruccion: ["instruccion", "nombre"], Profesion: ["profesion"], Cuit: ["cuit"],
    DomicilioSipe: ["domicilioSIPE"], PersonaJuridica: ["personaJuridica"],  Denominacion: ["denominacion"], Rubro: ["rubro"],
    DomicilioJuridico: ["domicilioJuridico"], TelefonoJuridico: ["telefonoJuridico"], coelsa: ["codigosCoelsa"]
  }
}

function makeStructureToUnnestsForOldEffects() {
  return {
    Color: ["color"], 
    Detalles: ["details"],
    EstadoEfectoActuacionID: ["tipoEfecto", "id"],
    Marca: ["marca"],
    Modelo: ["modelo"],
    Tipo: ["tipo"],
    IdSeriePatente: ["numId"],
    TieneSeguro: ["tieneSeguro"],
    EfectoID: ["EfectoID"],
    ActuacionEfectoID: ["ActuacionEfectoID"],
  }
}

const emptyAcusado={
  "ActuacionID": "18bdef8e-ab1b-11ee-8df7-0e3a1e34687f",
  "ActuacionPersonaFisicaID": "",
  "Apellido": "Sin Apellido Especificado",
  "Apodo": "",
  "Celular": "",
  "Cuil": null,
  "Cuit": "",
  "Denominacion": "",
  "Dni": "Sin Dni Especificado",
  "DomicilioJuridico": "",
  "DomicilioSipe": null,
  "CorreoElectronico": "",
  "EstadoCivil": "",
  "FechaDeNacimiento": "",
  "FechaDefuncion": null,
  "FechaDesde": "",
  "FechaHasta": null,
  "Instruccion": "Sabe leer y sabe firmar",
  "LugarDeNacimiento": null,
  "Nacionalidad": "ARGENTINA",
  "Nombre": "Sin Nombre Especificado",
  "Orden": null,
  "PersonaFisicaID": "",
  "PersonaJuridica": "",
  "Profesion": "",
  "Rubro": "",
  "Sexo": "",
  "Telefono": "",
  "TelefonoJuridico": "",
  "TipoRolPersonaFisicaID": "Acusado"
}
function formatPersonsForBackend(params, state) {
  let newPersonsList = [];
  params.personsFromBack.map(person => {
    let index = params.personsForUpdate.findIndex(personsForUpdate => personsForUpdate.doc?.ActuacionPersonaFisicaID === person.ActuacionPersonaFisicaID);
    if(index !== -1){
      let newPerson = {...person, ...functionsEditMode.accessNestedProperties(makeStructureToUnnestsForPersons(), params.personsForUpdate[index])};
      newPerson.Apellido =  params.personsForUpdate[index]?.filiacionDesconocida === true ? 'Persona de Filiacion desconocida' : (params.personsForUpdate[index]?.doc?.apellido !== '' ? params.personsForUpdate[index]?.doc?.apellido : "Sin Apellido Especificado");
      newPerson.Dni =  params.personsForUpdate[index]?.doc?.documento !== '' ? params.personsForUpdate[index]?.doc?.documento : "Sin Dni Especificado";
      newPerson.Nombre = params.personsForUpdate[index]?.doc?.nombre !== '' ? params.personsForUpdate[index]?.doc?.nombre : "Sin Nombre Especificado";
      newPerson.Identikit = params.personsForUpdate[index]?.descriptionOffender?.length > 0 ? params.personsForUpdate[index]?.descriptionOffender : params.personsForUpdate[index]?.identikit;
      newPerson.DomicilioSipe = params.personsForUpdate[index]?.doc?.domicilio !== undefined ? params.personsForUpdate[index]?.doc?.domicilio : params.personsForUpdate[index]?.doc?.domicilioSIPE;
      newPerson.coelsa = params.personsForUpdate[index]?.doc?.codigosCoelsa !== undefined ? params.personsForUpdate[index].doc.codigosCoelsa.map(coelsa => (typeof coelsa === 'string' ? { CodigoCoelsa: coelsa } : coelsa)) : [];
      newPersonsList.push(newPerson); params.personsForUpdate[index].toDelete = true;
      newPerson.coelsa = params.personsForUpdate[index]?.doc?.codigosCoelsa !== undefined ? params.personsForUpdate[index].doc.codigosCoelsa.map(coelsa => (typeof coelsa === 'string' ? { CodigoCoelsa: coelsa } : coelsa)) : [];
      newPerson.plataformas = params.personsForUpdate[index]?.doc?.plataformas !== undefined ? params.personsForUpdate[index].doc.plataformas.map(plataform => (typeof plataform === 'string' ? { Url: plataform } : plataform)) : [];
    }
  })
  params.personsForCreate = [...params.personsForUpdate.filter(obj => !obj.toDelete)];
  newPersonsList = newPersonsList.concat(formatNewPersonsForBackend(
    params,
    {...makeABlankPerson(state.actuationDataForEdit.id)}
  ));
  return newPersonsList;
}

function formatNewPersonsForBackend(params, newABlankPerson) {
  let newPersonsForCreate = [];
  let publicOrder = false;
  params.personsForCreate.map(async Person => {  
    publicOrder = Person.ordenPublico ;
    if(Person.type !== 'empty'){
      let newPerson = {...newABlankPerson, ...accessNestedProperties(makeStructureToUnnestsForPersons(), Person)};
      if(publicOrder){
        newPerson.Apellido = "Orden Publico"; newPerson.Nombre = "";
      } else{
        newPerson.Apellido = Person.filiacionDesconocida === true ? 'Persona de Filiacion desconocida' : (Person?.doc?.apellido !== undefined ? Person?.doc?.apellido : newPerson.Apellido);
        newPerson.Nombre = Person?.doc?.nombre !== undefined ? Person?.doc?.nombre : newPerson.Nombre;
      }
      newPerson.Dni = Person?.doc?.documento !== undefined ? Person.doc.documento : newPerson.Dni;
      newPerson.Nombre = Person?.doc?.nombre !== undefined ? Person?.doc?.nombre : newPerson.Nombre;
      newPerson.DomicilioSipe = Person?.doc?.domicilio !== undefined ? Person?.doc?.domicilio : Person?.doc?.domicilioSIPE;
      newPerson = functionsEditMode.replaceUndefinedWithNull(newPerson);
      if(!isEqualExcept(newPerson,emptyAcusado,"ActuacionID")){
        newPersonsForCreate.push(newPerson);
      }
    }
  })
  return newPersonsForCreate;
}
export function isEqualExcept(obj1, obj2, excludeKeys = []) {
  if (obj1 === obj2) {
    return true;
  }
  let keys1 = Object.keys(obj1).filter(key => !excludeKeys.includes(key));
  let keys2 = Object.keys(obj2).filter(key => !excludeKeys.includes(key));
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!keys2.includes(key) || !isEqualExcept(obj1[key], obj2[key], excludeKeys)) {
      return false;
    }
  }
  return true;
}
function buildAPersonForFront (personToConvert) {
  // console.log("personToConvert: ", personToConvert);

  return {
    arrId: generalFunctions.uuidv4(), sipeSearch:false, type:"doc", emptyTitle:"Nueva víctima",
    doc:{
      apellido: personToConvert?.Apellido, arrId: generalFunctions.uuidv4(), ActuacionPersonaFisicaID: personToConvert?.ActuacionPersonaFisicaID,
      condicion: transformConditionToJSON(personToConvert?.TipoRolPersonaFisicaID), documento: personToConvert?.Dni,
      domicilioSIPE: personToConvert?.DomicilioSipe, domicilio: personToConvert?.domicilio, email: personToConvert?.email, apodo: personToConvert?.Apodo,
      estadoCivil: AltaDenuncia.default.estadocivil.find(civilState => civilState.nombre === personToConvert?.EstadoCivil),
      instruccion: AltaDenuncia.default.enum_instruccion.find(instruction => instruction.nombre === personToConvert?.Instruccion),
      nacionalidad: AltaDenuncia.default.nacionalidad.find(nacionality => nacionality.nombre === personToConvert?.Nacionalidad),
      nombre: personToConvert?.Nombre, profesion: personToConvert?.Profesion, sexo: {id: personToConvert?.Sexo?.charAt(0), nombre: personToConvert?.Sexo},
      telefono: personToConvert?.Telefono, tipoDocumento: {id: 'DNI', nombre: 'DNI'}, fechaNacimiento: personToConvert?.FechaDeNacimiento,
      email: personToConvert?.Email, codigosCoelsa: transformItemForFront(personToConvert?.coelsa, "CodigoCoelsa"), 
      oldCoelsa: personToConvert?.coelsa, plataformas: transformItemForFront(personToConvert?.plataformas, "Url"),
      oldPlataformas: personToConvert?.plataformas, PersonaFisicaID: personToConvert?.PersonaFisicaID,
    },
    hasIdentikit: personToConvert?.Identikit?.length > 0 ? true : false,
    identikit: personToConvert?.Identikit?.length > 0 ? personToConvert.Identikit : []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}