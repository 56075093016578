<template>

  <div  :class="{'grid': !minimal}">
    <div v-if="!minimal" class="col-12 md:col-4 lg:col-3 lateral">
      <Listbox v-model="arrId"   :options="robadosTodosSelect" dataKey="arrId" optionValue="arrId" optionLabel="name">
        <template #option="slotProps">
          <div class="flex justify-content-between" >
            <span class="mt-2">{{slotProps.option.name}}</span>
            <div class="flex justify-content-center align-items-center flex-nowrap">
              <Button
                v-if="slotProps.option.type !== 'empty'"
                icon="pi pi-trash"
                class="p-button-danger p-button-lg px-2 py-2 m-1"
                style="z-index: 3"
                @click.stop="$_TemplateParentForm_delete(slotProps.option.arrId)"
              />  
            </div>
          </div>
        </template>
      </Listbox>
      <div class="button_Section_process volver"  >
        <Button label="Volver" icon="pi pi-back" @click="$router.push('/actuaciones/new/' + actuacionType + '/initial')" test-id="buttonVolver"/>
      </div>
    </div>
    <div :class="{'col': !minimal}">
      <div :class="{'card': !minimal, 'hideForm' : formData.type === null}" >
        <FormObjetos
          ref="robado"
          @updateValue="$_TemplateParentForm_handleUpdateDocValue"
          :formType="formType"
          :listaDeEfectos="robadosTodosSelect"
          :arrId="this.arrId"
          :viewData="viewData"
        />
      </div>
      <div class="button_Section_process guardar mt-3" v-if="arrId" test-id="buttonGuardarEfectos">
        <Button label="Guardar" icon="pi pi-back" @click="validacion()"/>
      </div>
    </div>
  </div>
</template>

<script>
import Listbox from 'primevue/listbox';

import FormObjetos from '../forms/FormObjetos.vue'
/*
Este es un modelo para componente que seran re utilizables muchas veces
*/
import { mapGetters } from 'vuex'
export default {
  name: 'SumarioFormRobados',
  components: {
    FormObjetos, Listbox
  },
  props: {
    minimal: {
      type: String // vista minima del formulario si esta definido
    },
    predefined: {
      type: Object //string que se usara como base para llenar la declaracion de denuncia
    },
    aid: {
      type: String //string que se usara para volver a mostrar en Editor de denuncia al recargar
    },
    formType: String, //Defines the type of form,
    viewData: {
      type: Object //string que se usara como base para llenar la declaracion de denuncia
    }, 
    actuacionType: String, //Defines the
  },
  data() { 
    return {
      arrId: this.aid || null,
   
    }
  },
  mounted() {
  // console.log('this.formType sumarioformrobados', this.formType)
  if(typeof this.getRobadoByArrId(this.$router.currentRoute.query.id) != 'undefined')  {
      this.arrId = this.$router.currentRoute.query.id
    }
  },
  created(){
    if(this.$route.query.id == 'new')
    {
      this.arrId = this.robadosTodosSelect[(this.robadosTodosSelect.length)-1].arrId
    }


  },
  computed: {
    ...mapGetters('sumario/robados', [
      'robadosTodos',
      'robadosCount',
      'getRobadoByArrId',
      'getNewRobadoArrId',
      'robadosTodosSelect'
    ]),
      
    //manejo del dato de state
    formData: {
      get () { 
        if (this.predefined && !this.arrId){
          console.log("predefined", this.predefined)
          // return this.getRobadoByArrId(this.getNewRobadoArrId)
          if (this.predefined.new) {
            
            return {
              doc:{}, 
              type: 'empty',
              filiacionDesconocida: this.predefined.default.filiacionDesconocida,
              hasIdentikit: this.predefined.default.hasIdentikit
            }
          }
        }
        if (this.arrId) return this.getRobadoByArrId(this.arrId)
        return {doc:{},  type: null, filiacionDesconocida: false, hasIdentikit: false}
      }
    },
        
  },
  //comunicacion con hijos
  watch: {
    arrId: function (newValue) {
      if(newValue != null && this.$router.currentRoute.query.id != newValue)
        this.$router.push({path: `/actuaciones/new/${this.actuacionType}/initial/efectos?id=${newValue}`})
      // if(newValue != null && this.$router.currentRoute.query.id != newValue && this.formType === 'oficio')
        // this.$router.push({path: `/sumario/denuncia/formulario/oficio/objetos?id=${newValue}`}) 
      // if(newValue != null && this.$router.currentRoute.query.id != newValue && this.formType === 'contravencional-oficio')
        // this.$router.push({path: `/sumario/denuncia/formulario/contravencional/oficio/objetos?id=${newValue}`}) 
      // if(newValue != null && this.$router.currentRoute.query.id != newValue && this.formType === 'contravencional-denuncia')
        // this.$router.push({path: `/sumario/denuncia/formulario/contravencional/denuncia/objetos?id=${newValue}`}) 
    },
    formData: function (newValue) {
      if(typeof newValue != 'object') return
      // console.log("watch formData", newValue)
      this.$refs.robado.setData( newValue.doc )
    }
  },

  methods: {
    validacion(){
      let errores = this.$refs.robado.emitErrores()
      if(errores == true)
      {
        alert("Verifica los campos")
      }else
      {
        // console.log("URL: ",url)
        this.$router.push('/actuaciones/new/' + this.actuacionType + "/initial" )
      }

    },
    //se ejecuta al ser activado por editor para mostrar luego de estar oculto
    //revisar si es necesario que lo use en la primera carga
    
    activeComponent(){
      this.$refs.robado.setData( this.formData.doc )
    },

    // guarda cambios en stado
    async $_TemplateParentForm_updateValue( field, value ){

      if ( !this.formData.arrId ) {
        this.formData.arrId = await this.getNewRobadoArrId
      }
      await  this.$store.dispatch('sumario/robados/setRobado', { ...this.formData, 
        [field]: value
      })
     
      this.$_TemplateParentForm_setArrId()
      
    },


    // guarda cambios en doc 
    async $_TemplateParentForm_handleUpdateDocValue( field, value ){

      if ( !this.formData.arrId ) {
        this.formData.arrId = await this.getNewRobadoArrId
      }
      await  this.$store.dispatch('sumario/robados/setRobado', { ...this.formData, 
        doc: { ...this.formData.doc, [field]: value }
      })
     
      this.$_TemplateParentForm_setArrId()
      
    },

    //le pone array id, es para el caso de nuevos desde editor de denuncia
    $_TemplateParentForm_setArrId(){
      if (!this.arrId) {
        this.arrId = this.formData.arrId
        if ( this.predefined ) {
          this.$emit("setArrId", this.arrId)
        }
      }
    },

    // elimina del array
    $_TemplateParentForm_delete(arrId) {
      this.arrId = null
      this.$store.dispatch('sumario/robados/deleteRobadoByArrId', arrId)      
    },

  }
}
</script>
<style scoped>
.card {
  background: #ffffff;
  padding: 2rem;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}
.hideForm {
  display: none;
}
.button_Section_process {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button_Section_process:not(.volver){
  margin-right: 25px;
}

.guardar {
  margin-top: -80px;
}

.volver{
  margin-top: 10px;
}
.pad {
  padding: 1rem .7rem;
}
.lateral > button {
  margin-top: 20px;
}
.pad > div {
  margin-right: 30px;
}
</style>