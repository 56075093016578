<template>
  <div>
    <div class="custom-card" v-if="!showProgressSpinner" >
      <h3>ACTUACIONES SIN CONEXIÓN</h3>
      <DataTable :value="actuationsOffline" :style="{ fontSize: '13px' }">
        <Column header="Fecha">
          <template #body="slotProps">
            {{ generateDate(slotProps.data.siis_state.fechaDenuncia) }}
          </template>
        </Column>
        <Column field="siis_state.causaNumero" header="Nro. Actuación"></Column>
        <Column header="Actuación">
          <template #body="slotProps">
            {{ Object.keys($store.state.sumarioViews[slotProps.data.siis_state.formType]?.actuationName)[0] }}
          </template>
        </Column>
        <Column header="Causa/Carátula">
          <template #body="slotProps">
            {{ getCausaCaratula(slotProps.data) }}
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button 
              icon="pi pi-pencil"  
              @click="goToEditOfflineActuation(slotProps.data)"
              class="p-button-warning p-button-lg px-2 py-2 m-1" 
              v-tooltip.left="'Continuar con la carga'"
            />
            <Button 
              icon="pi pi-trash" 
              @click="deleteConfirmation($event, 'Actuación', slotProps.data)" 
              class="p-button-danger p-button-lg px-2 py-2 m-1" 
              v-tooltip.left="'Eliminar actuación'"
            />
          </template>
        </Column>
        <template #empty>
          No existen registros
        </template> 
      </DataTable> 
    </div>
    <ProgressSpinner
      style="width: 50px; height: 50px"
      strokeWidth="5"
      fill="#EEEEEE"
      animationDuration="1.8s"
      class="flex justify-content-center mt-5"
      v-if="showProgressSpinner"
    />
  </div>
</template>

<script>
import actuationsRepository from '../../../dixieDB/repositories/actuationsRepository';
import actuacionesRepository from "../../../dixieDB/app-offline/repositories/actuacionesRepository";
import ConfirmPopupMixin from "../../components/TForm/mix/ConfirmPopupMixin.js";
import dateMixin from '@/mixins/dateMixin';
import actuacionesFunctions from '@/mixins/actuaciones/actuacionesFunctions.js';

export default {
  name: "OfflineTable",
  mixins: [ConfirmPopupMixin, dateMixin, actuacionesFunctions],
  data() {
    return {      
      actuationsOffline: [],
      showProgressSpinner: true
    }
  },
  async created(){
    this.showProgressSpinner = true;
    try {
      const actuations = await actuationsRepository.getAllActuations(actuacionesRepository.getAll());
      this.actuationsOffline = actuations;
    } catch (error) {
      console.error('Error fetching actuations:', error);
    } finally {
      this.$nextTick(() => {
        this.showProgressSpinner = false;
      });
    }
  },
  methods: {
    getCausaCaratula(actuationData){
      if(actuationData?.siis_state?.causaCaratula?.Nombre !== undefined) return actuationData?.siis_state?.causaCaratula?.Nombre;
      let causes = actuationData.siis_state.listaArticulos.length > 0 ? actuationData.siis_state.listaArticulos 
      : actuationData.siis_state.listaCaratulas 
      let causesText = "";
      for (let i in causes) {
        if(causes[i]?.Numeroarticulo !== undefined) {
          causesText = causesText == "" ? causes[i].Numeroarticulo + ' - ' + causes[i].Codigo
          : causesText + ", " + causes[i].Numeroarticulo + ' - ' + causes[i].Codigo;
        } else {
          causesText = causesText == "" ? causes[i].Nombre : causesText + ", " + causes[i].Nombre;
        }
      }
      return causesText;
    },
    goToEditOfflineActuation(actuationData){
      let actuacionType = this.changeNameInStore(Object.keys(this.$store.state.sumarioViews[actuationData.siis_state.formType]?.actuationName)[0]);
      this.$store.dispatch('sumario/setSumario', {siis_state: {...actuationData.siis_state, idOffline: actuationData.id, type: actuationData.type}});
      this.$router.push("/actuaciones/new/" + actuacionType + "/initial");
    },
  }
}
</script>

<style>
.custom-card {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
