export default {
  data: ()=> ({}),
  props: {
    toListAfterSave: {
      type: Boolean,
      default: true,
    },
    backPath: {
      type: [String, Boolean]
    },
    
  },
  methods: {
    back() {
      return new Promise((resolve) => {
        if (this.backPath) {
          this.$router.push(this.backPath).then(resolve).catch((error) => {
            console.error('Error navigating to this.backPath:', error, ' trying window.location.href');
            this.$rest.toast_open({message: "La redirección de la página falló",position: "top-right",type: "warning", duration: 10000,});
            // const fullPath = `${window.location.protocol}//${window.location.host}` + this.backPath;
            // window.location.href = fullPath;
            resolve();
          });
        } else {
          try {
            this.$router.go(-1);
            resolve();
          } catch (error) {
            console.error('Error with $router.go(-1), trying window.history.back():', error);
            window.history.back();
            resolve();
          }
        }
      });
    },
    changeDropdown(field) {
      // console.log("changeDropdown", field, this.values[field], typeof this.values[field])
      if (!this.params[field].dropdown.changeDropdown) return;
      if (typeof this.values[field] == 'undefined') return;
      this.params[field].dropdown.changeDropdown(this.values[field],this.values);
    },
    hasMsg(field){
      if (!this.params[field].setMsg) return;
      if (typeof this.values[field] == 'undefined') return;
      this.params[field].msg = this.params[field].setMsg(this.values[field], this.values);
    }
    
    
  },
}