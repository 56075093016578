export default {
  data: () => ({
    errors: {},
  }),
  methods: {
    
    handleBlur(field){
      this.validateField(field)
    },
    
    isValid(nameField){
      // console.log('isValid', nameField, this.params[nameField], this.values[nameField] )
      return this.errors[nameField].error === false
    },
    async validate() {
      let valid = true;
      for (let fieldName in this.params) {
        
        let validField = await this.validateField(fieldName)
        if (!validField) valid = false
      }
      // console.log("validate result", valid )
      return Promise.resolve(valid);
    },
    validateField(fieldName){
      // console.log("validateField", fieldName, this.params[fieldName].validation)
      let valid = true;
      this.errors[fieldName].error = false;
      this.errors[fieldName].errMsg = '';

      
      //si no se establecieron reglas de validacion
      if (!this.params[fieldName].validation) return Promise.resolve(valid) 
      
      
      //si existe una funcion especifica de validacion
      if (typeof this.params[fieldName].validation == "function") {
        let data = this.params[fieldName].validation(this.values);
        // console.log("data dice: ",data)
        this.errors[fieldName].error = data.error;
        this.errors[fieldName].errMsg = data.mensaje;
        if (data.error) valid = false;
        return Promise.resolve(valid) 
      }
      
      //reglas
      let valor = this.params[fieldName].validation;
      if (this.params[fieldName].type == "table") {
        if (valor.includes("min_length")) {
          let min_length = parseInt(valor.split("min_length[")[1].split("]")[0]);
          if (this.params[fieldName].dataTable.list.length < min_length) {
            valid = false;
            this.errors[fieldName].error = true;
            // this.errors[fieldName].errMsg = `La vinculación del objeto es obligatoria`;
            this.$emit('openModal')
          }
        }
      }
      
      //validacion para FileUpload
      if (this.params[fieldName].type == "FileUpload") {
        let lengthGallery = 0
        //tiene en cuenta la galleria
        if (this.params[fieldName].upload.gallery){
          let gallery = this.params[this.params[fieldName].upload.gallery].gallery.list
          lengthGallery = gallery?.length || 0
          
        }
        if (
          valor.includes("required") &&
          ((!this.files[fieldName] || this.files[fieldName].length < 1) && lengthGallery <= 0)
        )
        {
          this.errors[fieldName].error = true;
          valid = false;
          this.errors[fieldName].errMsg = "Debe incluir al menos un archivo";
        }
        if (valor.includes("min_length")) {
          let length = this.files[fieldName]?.length || 0
          length = length + lengthGallery;
          

          let min_length = parseInt(valor.split("min_length[")[1].split("]")[0]);
          if (length < min_length || length === undefined) {
            console.error("length", length, min_length)
            this.errors[fieldName].error = true;
            valid = false;
            this.errors[fieldName].errMsg = `Debe incluir al menos ${min_length} archivo/s`;
          } 
        }
        return Promise.resolve(valid) 
      }

      //setea a string vacio si no tiene un valor
      if (!this.values[fieldName] && this.values[fieldName]!==0) {
        this.values[fieldName] = "";
      }

      // REQUIRED: verifica que no es un string vacio
      if (
        valor.includes("required") &&
        this.values[fieldName] === ""
      ) {
        this.errors[fieldName].error = true;
        valid = false;
        this.errors[fieldName].errMsg = "Este campo no puede quedar vacio";
      }

      // INTEGER: verifica que se trate de un numero entero sin decimales
      if (
        valor.includes("integer") &&
        (isNaN(this.values[fieldName]) ||
          this.values[fieldName].includes(".") ||
          this.values[fieldName].includes(","))
      ) {
        this.errors[fieldName].error = true;
        valid = false;
        this.errors[fieldName].errMsg =
          'Este valor debe ser un Numero Entero sin "." ni ","';
      }

      // DECIMAL: verifica que se trate de un numero decimal
      if (valor.includes("decimal") && isNaN(this.values[fieldName])) {
        this.errors[fieldName].error = true;
        valid = false;
        this.errors[fieldName].errMsg = "Este valor debe ser un Numero Decimal";
      }

      //MAX_LENGTH: verifica que el string no sea superior a lo permitido
      if (valor.includes("max_length")) {
        let max_length = valor.split("max_length[")[1].split("]")[0];
        if (this.values[fieldName].length > max_length) {
          this.errors[fieldName].error = true;
          valid = false;
          this.errors[fieldName].errMsg = `El texto no puede contener mas de ${max_length} caracteres`;
        }
      }

      //EMAIL: verifica que el e-mail tenga un arroba
      if (valor.includes("Email") && valid !== false) {
        if (!this.values[fieldName].includes("@")) {
          this.errors[fieldName].error = true;
          valid = false;
          this.errors[fieldName].errMsg = `Falta un "@" en el email`;
        }
      }

      // devuelve resultado de la validacion
      return Promise.resolve(valid) 
    }
  }
}