<template>
    <a target="_blank" :href="link">
        <i class="pi pi-book" />
    </a>
</template>

<script>
    export default {
        name: 'Icon',
        props: {
            link: {
                type: String,
                required: true
            },
        },
    }

</script>

<style scoped>
i{
    font-size: 1.8em;
    color:rgb(139, 144, 146);
    margin-left: 15px;
}
i:hover{
    cursor: pointer;
}
</style>
