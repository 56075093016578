import caratula from "@/documentos/expediente contravencional/caratula";

//TODO: refactor
export default {    
  data() {
    return {
      modalAttributes: {},
      confirmationMode: false,
      modalSpinner: false,
      allowEmptySave:true,
      blankArray: [],
    };
  },
  methods: {
    async saveModalData(){
      this.modalSpinner = true
      let respuesta;
      // console.log('this.modalAttributes.type', this.modalAttributes.type)
      // console.log('this.causaCaratula', this.causaCaratula)
      // let resp = await this.$rest.put({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/`},  {formData: this.sumario})
      //   console.log(resp)
      //   return;
      if(this.modalAttributes.type === 'Juzgado interviniente' && this.causaJuzgado !== ''){
        respuesta =  await this.$store.dispatch('sumario/updateJuzgado', {JuzgadoID: this.causaJuzgado.JuzgadoID, Nombre: this.causaJuzgado.Nombre, VueCli: this})
        if(respuesta != null){
          await this.updateSumario(respuesta)
          this.toastSuccess()
        }        
      }
      if((this.modalAttributes.type === 'Causa/Carátula') && this.causaCaratula !== ''){
        respuesta = await this.$store.dispatch('sumario/updateSumario', {causaCaratula: this.causaCaratula, VueCli: this});
        if(respuesta != null){
          await this.updateSumario(respuesta)
          this.toastSuccess()
        }
      } 
      if(this.modalAttributes.type === 'Delito'){
        if(this.crimes?.length == 0){
          this.$rest.toast_open({ message: "ERROR: Se debe definir al menos un delito", position: 'top-right', type: 'error', duration: 10000})
        }else{
          //Actualiza el store con los nuevos datos, antes de enviarlos
          await this.$store.dispatch('sumario/setValue', {field: 'listaCaratulas', value: this.crimes});  
          // console.log(this.sumario) ;
          try{
            let resp = await this.$rest.put({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${this.sumario.id}`, formData: this.sumario, params:{method: 'own', section: 'Delito'}} ) ;
          }
          catch(e){this.$rest.toast_open({ message: "ERROR: Al actualizar los delitos", position: 'top-right', type: 'error', duration: 10000})}
        }
      }      
      if(this.modalAttributes.type === 'Causas/Carátulas'){
        if(this.listaCaratulas?.length == 0){
          this.$rest.toast_open({ message: "ERROR: Se debe definir al menos una Causa", position: 'top-right', type: 'error', duration: 10000})
        }else{
          //Actualiza el store con los nuevos datos, antes de enviarlos
          await this.$store.dispatch('sumario/setValue', {field: 'listaCaratulas', value: this.listaCaratulas});  
          try{

            let resp = await this.$rest.put({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${this.sumario.id}`, formData: this.sumario, params:{method: 'own', section: 'listaCaratulas'}} ) ;
          }catch(e){this.$rest.toast_open({ message: "ERROR: Al actualizar las causas", position: 'top-right', type: 'error', duration: 10000})}
        }

      }
      if(this.modalAttributes.type === 'Carátulas del Expediente' && this.articulos.length !== 0){
        respuesta = await this.$store.dispatch('sumario/updateArticulos', {articulos: this.articulos, VueCli: this});  
        if(respuesta != null){
          await this.updateSumario(respuesta)
          this.toastSuccess()
        }
      }
      if(this.modalAttributes.type === 'N° de Actuación' ){
        if(this.nroActuacion === ''){
          this.$rest.toast_open({ message: "ERROR: N° de Actuación invalido", position: 'top-right', type: 'error', duration: 10000})
        }else{
          console.log("nroActuacion", this.nroActuacion)
          console.log("confirmationMode", this.confirmationMode)
          respuesta = await this.$store.dispatch('sumario/updateSumario', {Numero: this.nroActuacion, VueCli: this});
          if(respuesta != null){
            await this.updateSumario(respuesta)
            this.toastSuccess()
          }
        }
      }
      if(this.modalAttributes.type === 'Ufi Interviniente' && this.nroActuacion !== ''){
        console.log("Desde aca ufiInterviniente", this.ufiInterviniente)
        console.log("confirmationMode", this.confirmationMode)
        console.log('this.ufiInterviniente', this.ufiInterviniente)
        respuesta = await this.$store.dispatch('sumario/updateUfi', {tipoUfi: this.ufiInterviniente.TipoufiID, VueCli: this});
        if(respuesta != null){
          await this.updateSumario(respuesta)
          this.toastSuccess()
        }
      }      
      if(this.modalAttributes.type === "Ayudante Fiscal"){
        //Actualiza el store con los nuevos datos, antes de enviarlos
        await this.$store.dispatch('sumario/setValue', {field: 'assistant', value: this.assistants});

        let resp = await this.$rest.put({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${this.sumario.id}`, formData: this.sumario, params:{method: 'own',  section: 'ayudanteFiscal'}} ) ;
        console.log('resp: ',resp);
      }
      if(this.modalAttributes.type === "Fiscal"){
        //Actualiza el store con los nuevos datos, antes de enviarlos
        await this.$store.dispatch('sumario/setValue', {field: 'prosecutor', value: this.prosecutor});

        let resp = await this.$rest.put({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${this.sumario.id}`, formData: this.sumario, params:{method: 'own',  section: 'fiscal'}} ) ;
        console.log('resp: ',resp);
      }
      if(this.modalAttributes.type === "Interviniente"){
        // Actualiza el store con los nuevos datos, antes de enviarlos
        await this.$store.dispatch('sumario/setValue', {field: 'causaJuzgado', value: this.causaJuzgado});

        let resp = await this.$rest.put({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${this.sumario.id}`, formData: this.sumario, params:{method: 'own',  section: 'juzgado'}} ) ;
        console.log('resp: ',resp);
      }      
      this.causaJuzgado = ''
      this.causaCaratula = ''
      this.nroActuacion = this.sumario.causaNumero
      this.articulos = []
      this.displayModal = false;
      this.ufiInterviniente = ''
      this.modalSpinner = false
      this.confirmationMode = true
    },
    isSaveDisabled(){
        // console.log("this.selectedDropdownValue",this.selectedDropdownValue)
        // console.log("this.modalAttributes.allowEmptySave: ",this.modalAttributes.allowEmptySave)
        if (this.modalAttributes.allowEmptySave) {
          return false; // Not disabled if it's allowed to save empty
        }
      // Disable the button if the dropdown value is not selected.
      return !this.selectedDropdownValue;
    
    },
    openModal(type) {
      this.listaCaratulas = this.formatCausasCaratulas();
      this.crimes = this.sumario.listaCaratulas?.length >0 ? this.sumario.listaCaratulas : [ this.delitoList.find(e => e.Nombre =this.resumen.caratula)] ;
      this.loadModalAttributes(type)
      this.nroActuacion = this.sumario.causaNumero
      this.displayModal = true;
    },
    closeModal(){
      this.confirmationMode = true
      this.causaJuzgado = ''
      this.causaCaratula = ''
      this.nroActuacion = this.sumario.causaNumero
      this.articulos = []
      this.displayModal = false
    },   
    deleteModalItem(itemID) {
      let arrayDeItems = [...this[this.modalAttributes.arrayItems.arrayName]].filter(item => item[this.modalAttributes.arrayItems.id] !== itemID)
      this[this.modalAttributes.arrayItems.arrayName] = arrayDeItems
      if(this.$refs.modalUpdate.$el.getElementsByClassName('p-dialog-content')[0].offsetHeight <= 362)
        this.$refs.modalUpdate.$el.getElementsByClassName('p-dialog-content')[0].style.overflow = "visible"
    },
    loadModalAttributes(type){
      //check actuation type
      let simpleCausaCaratula = this.resumen.caratula && !this.evaluateIfFormTypeIsUFI() ? true : false ;
      let multipleCausaCaratula = this.resumen.listaCaratulas ? true : false ;
      let delito = this.evaluateIfFormTypeIsUFI();
      let expediente = this.resumen.listaArticulos ? true : false ;
      //it's multipleCausaCaratula discard option
      if(!simpleCausaCaratula && !delito &&  !expediente)
        multipleCausaCaratula = true;
      //generic attributes
      this.confirmationMode = false ;
      this.modalAttributes.form = "Dropdown" ;
      this.modalAttributes.divTestId = "b3uttonArt" ;
      this.modalAttributes.arrayItems = {
        //the default 'blankArray' prevents a warning
        arrayName: 'blankArray',
      } ;
      if(type === "Caratula"){
        if(simpleCausaCaratula){
          this.modalAttributes.type = "Causa/Carátula" ;
          this.modalAttributes.new = "nueva" ;
          this.modalAttributes.vModel = "causaCaratula" ;
          this.modalAttributes.optionsList = this.causasList ;
        }
        if(multipleCausaCaratula){
          this.modalAttributes.type = "Causas/Carátulas" ;
          this.modalAttributes.new = "nuevas" ;
          this.modalAttributes.vModel = "dataCaratulas" ;
          this.modalAttributes.optionsList = this.causasList ;
          this.modalAttributes.arrayItems = {
            arrayName: 'listaCaratulas', 
            id: 'TipocausacaratulaID',
            attribute1: {text: '', prop: 'Nombre'},
            attribute2: {text: '', prop: ''},
          } ;
        }
        if(delito){
          this.modalAttributes.type = "Delito" ;
          this.modalAttributes.new = "nuevos" ;
          this.modalAttributes.optionsList = this.delitoList;
          this.modalAttributes.vModel = "dataCrimes";
          this.modalAttributes.arrayItems = {
            arrayName: 'crimes', 
            id: 'TipocausacaratulaID',
            attribute1: {text: '', prop: 'Nombre'},
            attribute2: {text: '', prop: ''},
          };
        }
        //if it's Causa/Carátula, Causas/Carátulas or Delito
        this.modalAttributes.testId = "dropdownCausaCaratula" ;
        this.modalAttributes.optionLabel = "Nombre" ;
        this.modalAttributes.showMo = this.showMo() ;
        this.modalAttributes.dataKey = "Nombre" ;
        if(expediente){
          this.modalAttributes.type = "Carátulas del Expediente" ;
          this.modalAttributes.new = "nuevas" ;
          this.modalAttributes.optionsList = this.articulosList ;
          this.modalAttributes.showMo = !this.showMo()
          this.modalAttributes.dataKey = "Numeroarticulo" ;
          this.modalAttributes.optionLabel = "Numeroarticulo" ;
          this.modalAttributes.vModel = "dataArticulos" ;
          this.modalAttributes.testId = "dropdownArticulos" ;
          this.modalAttributes.arrayItems = {
            arrayName: 'articulos', 
            id: 'ArticulosID',
            attribute1: {text: 'Art   ', prop: 'Numeroarticulo'},
            attribute2: {text: ' - ', prop: 'Codigo'},
          } ;
        }
      } else {
        this.modalAttributes.type = type ;
        this.modalAttributes.showMo = true ;
        if(this.modalAttributes.type === "Juzgado interviniente" ||this.modalAttributes.type ===  "Interviniente"){
          this.modalAttributes.vModel = "causaJuzgado" ;
          this.modalAttributes.optionsList = this.juzgadosList;
          this.modalAttributes.optionLabel = "Nombre";
          this.modalAttributes.testId = "dropdownJuzgado" ;
          this.modalAttributes.divTestId = "ButtonInterviniente" ;
          this.modalAttributes.allowEmptySave=false;
        }       
        if(this.modalAttributes.type === "Ufi Interviniente"){
          this.modalAttributes.vModel = 'ufiInterviniente' ;
          this.modalAttributes.optionsList = this.numeroUfiList ;
          this.modalAttributes.optionLabel = "Numero";
          this.modalAttributes.testId = "dropdownNumeroUfi" ;
          this.modalAttributes.divTestId = "ButtonInterviniente" ;
          this.modalAttributes.allowEmptySave=false;
        }
        if(this.modalAttributes.type === "N° de Actuación"){
          this.confirmationMode = true ; 
          this.modalAttributes.form = "InputText" ;
          this.modalAttributes.vModel = 'nroActuacion' ;
          this.modalAttributes.divTestId = "ButtonInterviniente" ;
          this.modalAttributes.allowEmptySave=true;
        }
        if(this.modalAttributes.type === "Ayudante Fiscal"){
          this.modalAttributes.vModel = 'assistants' ;
          this.modalAttributes.optionsList = this.ayudanteFiscal ;
          this.modalAttributes.optionLabel = "Denominacion";
          this.modalAttributes.testId = "dropdownAyudanteFiscal" ;
          this.modalAttributes.divTestId = "ButtonAyudanteFiscal" ;
          this.modalAttributes.allowEmptySave=false;
        }
        if(this.modalAttributes.type === "Fiscal"){
          this.modalAttributes.vModel = 'prosecutor' ;
          this.modalAttributes.optionsList = this.fiscal ;
          this.modalAttributes.optionLabel = "Denominacion";
          this.modalAttributes.testId = "dropdownFiscal" ;
          this.modalAttributes.divTestId = "ButtonFiscal" ;
          this.modalAttributes.allowEmptySave=false;
        }        
      }
    },
    evaluateIfFormTypeIsUFI(){
      let isUFI = false;
      isUFI = this.resumen.formType.includes('Unidades Fiscales de Investigacion') || 
        this.resumen.formType.includes('Unidades Fiscales de Investigación') ? true : false ;
      return isUFI;
    },
    changeModal(params){
      let [ e, vModel, ref ] = params ;
      if( ref === 'ModalDropdown'){
        this.$refs[ref].value = e.value ;
        this[vModel] = e.value ;
      }
      if( ref === 'ModalInputText'){
        this[vModel] = e.target.value ;
      }
    },
    formatCausasCaratulas(){
      //console.log('this.sumario.listaCaratulas', this.sumario.listaCaratulas);
      //this is for get the same format to the other CausaCaratula list
      let newListaCaratulas = [] ;
      if(this.sumario?.listaCaratulas !== undefined){
        this.sumario.listaCaratulas.map((causaCaratula) =>{
          newListaCaratulas.push(this.causasList.filter(item => item.TipocausacaratulaID === causaCaratula.TipocausacaratulaID)[0]);
        });
        return newListaCaratulas;
      }
      return undefined;
    }
  }
};
