
  <template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">

      <div v-if="getMode() == 'new'" class="feature-intro">
        <h1>Nuevo {{ pageName }}</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
      <div v-else class="feature-intro">
        <h1>Editando {{ pageName }}</h1>
        <p>Modifique los datos que desea EDITAR</p>
      </div>
    </div>

    <div>
        <!-- :nombres="names"
        :errors="errors"
        :mensajes="mensajes" -->
      
      <div class="mb-4 text-xl">Tipo de Usuario Seleccionado</div>

      <TForm
        :params="params"
        :mode="getMode()"
        :designEdit="designEdit"
        :designNew="design"
        :url="url"
        :viewer="viewer"
        :viewerID="viewerID"
      >
        <!-- <template #JsonTipoUsuario="slotProps">
         <div style=" display: block;" v-if="!viewer">
            <vue-json-editor class="vue-json-editor" v-model="slotProps.value"
              :expandedOnStart="true" @json-change="(val) => slotProps.change(val)"
             ></vue-json-editor>
          </div>
       </template> -->
      </TForm>

      <div class="mt-4" v-if="!viewer">
        <h2 class="inline">Permisos</h2>
        <Button
          icon="pi pi-plus"
          class="p-button-rounded p-button-info ml-4"
          @click="$router.push(`/permiso-tipo-usuario/add/${id}`)"
        />
      </div>

      <div
        v-for="(item, index) in dataTiposPermisosUsuarios"
        :key="item"
        class="p-fluid formgrid grid mt-4 ml-1"
      >
        <TemplateEditTipoUsuario
          @probandoFuncion="probandoFuncion(item, index)"
          :data="dataPermisos"
          :nombres="namesPermisos"
          :errors="errorsPermisos"
          :mensajes="mensajesPermisos"
          :desing="designPermisos"
          :url="urlPermisos"
          :id="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import vueJsonEditor from 'vue-json-editor'
import TemplateEditTipoUsuario from "../../../components/nuevo-tipo-usuario/TemplateEditTipoUsuario.vue";

export default {
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    }
  },
  components: {
    TemplateEditTipoUsuario, 
    // vueJsonEditor
  },
  data() {
    return {
      json: {},
      params: {
        FechaEliminacion: {
          validation: "max_length[50]",
          type: "date"
        },
        FechaCreacion: {
          validation: "max_length[50]",
          type: "date"
        },
        Nombre: {
          validation: "required|max_length[50]",
          type: "input"
        },
        // JsonTipoUsuario: {
        //   validation: "",
        //   type: "template",
        //   formatGetDoc: (doc) => JSON.parse(doc.JsonTipoUsuario)
        // },
      },
      design: [
        ["Nombre"],
        // ["JsonTipoUsuario"]
      ],
      //["FechaEliminacion", "FechaCreacion"],
      designEdit: [
        ["Nombre"],
        // ["JsonTipoUsuario"]
      ],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-usuario",

      pageName: "Tipo Usuario",
      dataTiposPermisosUsuarios: "",
      // permisosVisibles: false,
      urlPermisos:
        process.env.VUE_APP_BACKEND_ENVIRONMENT + "/permiso-tipo-usuario",
      dataPermisos: {
        FechaCreacion: {
          validation: "required",
        },
        FechaEliminacion: {
          validation: "",
        },
        PermisousuarioID: {
          validation: "required|max_length[50]",
        },
        TipoUsuarioID: {
          validation: "required|max_length[50]",
        },
      },
      designPermisos: [["Nombre", "FechaCreacion"]], //en este caso Nombre es un campo de Permiso de Usuario, esta concatenado en EditTIpoUsuario.vue
      namesPermisos: {
        FechaCreacion: "",
        FechaEliminacion: "",
        PermisousuarioID: "",
        TipoUsuarioID: "",
      },
      errorsPermisos: {
        FechaCreacion: false,
        FechaEliminacion: false,
        PermisousuarioID: false,
        TipoUsuarioID: false,
      },
      mensajesPermisos: {
        FechaCreacion: "",
        FechaEliminacion: "",
        PermisousuarioID: "",
        TipoUsuarioID: "",
      },

      contTitle: 0,
    };
  },
  async created() {

    let { id } = this.$route.params;
    this.id = id;

    // console.log("El id de aca es: "+id)
    let resp = await axios.get(this.urlPermisos, { withCredentials: true });
    let dato = resp.data.data;
    // console.log("LOS DATOS SON: "+JSON.stringify(dato))
    let tiposPermisosUsuario = [];
    // console.log("el dato id principal es :"+id)

    for (let key in Object.keys(dato)) {
      // console.log("valor: "+JSON.stringify(dato[key].TipoUsuarioID))
      if (dato[key].TipoUsuarioID == id) {
        // console.log("coincide con: "+ dato[key])
        tiposPermisosUsuario.push(dato[key].PermisoTipoUsuarioID);
      }
    }
    if (tiposPermisosUsuario.length > 0) {
      // this.permisosVisibles = true
      this.dataTiposPermisosUsuarios = tiposPermisosUsuario;
    }
    // console.log("tiposPermisosUsuario= "+tiposPermisosUsuario)
  },
  methods: {
    jschange(v){
      console.log('jschange', v)

    },
    getMode(){
      return this.id== 'add' ? 'new' : 'edit';
    },
    probandoFuncion(item, index) {
      this.dataTiposPermisosUsuarios.splice(index, 1); //eliminamos el componente en el que se hizo click a Eliminar
    },
  },
};
</script>
<style >
.vue-json-editor, .vue-json-editor > .jsoneditor-vue {
  height: 300px;
}
</style>
