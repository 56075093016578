export const ufiAniviConfig = [
    {
        path: "/actuaciones/new/ufi-anivi/initial",
        pathName: "ufi-anivi",
        titulo: "UFI - ANIVI",
        icon: "pi-box",
        roles: [
          "User Tester",
          "Super User",
          "Jefe de Dependencia",
          "Oficial Sumariante",
          "Jefe de Sumario",
        ],
        type: "link",
        grupo: "UFI",
        props: { formType: "ufi-anivi", actuacionType: "ufi-anivi" },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-anivi/initial/denunciante",
        pathName: "ufi-anivi",
        titulo: "VÍCTIMA",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-anivi/initial/momento",
        pathName: "ufi-anivi",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/ufi-anivi/initial/acusado",
        pathName: "ufi-anivi",
        titulo: "APREHENDIDOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/ufi-anivi/initial/efectos",
        pathName: "ufi-anivi",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
      
]