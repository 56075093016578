export default  {
  mounted(){
    this.removeOrIncludeElementsFromParams();
  },
  methods: {
    removeOrIncludeElementsFromParams(){
      for (const element in this.params) {
        let removeIn = this.params[element]?.removeIn === undefined ? false : true;
        let onlyIncludeIn = this.params[element]?.onlyIncludeIn === undefined ? false : true;
        let action = removeIn ? 'removeIn' : 'onlyIncludeIn';
        if(!removeIn && !onlyIncludeIn) continue;
        let lengthOfAction = this.params[element][action].length;
        for (let index = 0; index < lengthOfAction; index++) {
          if(removeIn && !this.$route.path.includes(this?.params?.[element]?.removeIn?.[index])) continue;
          if(onlyIncludeIn && this.$route.path.includes(this?.params?.[element]?.onlyIncludeIn?.[index])) continue;
          this.params[element] = {};
          this.params[element].label = " ";
        }
      }
    },
  }
};