export default {
    props: {
        enableFontSize: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: "",
        },
        preview: {
            type: Boolean,
            default: false,
        },
        suggestions: {
            type: Array,
            default: () => []
        },
        insert: {
            type: Function
        }
    }
}