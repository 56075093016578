export const ufiEstablecerConfig = [
    {
        path: "/actuaciones/new/ufi-establecer-paradero/initial",
        pathName: "ufi-establecer-paradero",
        titulo: "UFI - ESTABLECER PARADERO",
        props: { formType: "ufi-establecer-paradero", actuacionType: "ufi-establecer-paradero"},
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-establecer-paradero/initial/denunciante",
        pathName: "ufi-establecer-paradero",
        titulo: "DENUNCIANTE",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-establecer-paradero/initial/momento",
        pathName: "ufi-establecer-paradero",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/ufi-establecer-paradero/initial/acusado",
        pathName: "ufi-establecer-paradero",
        titulo: "EXTRAVIADO",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/ufi-establecer-paradero/initial/efectos",
        pathName: "ufi-establecer-paradero",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]