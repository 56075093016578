
const auth = {

    data() {
        return {
            Errors: {
                NotAuthorized: "El usuario no esta autorizado",
            }
        }
    },
    methods: {
        /**
       * returns true only if the state "user.userdata.permisos" contains
       * an element with TipoUsuarioID that exists among "users" array parameter
       *
       * @param {string[]} users Array of authorized types of users.
       * @return {boolean} 
       * 
       * E.g.:
       * -logged as "admin" with user type = SuperUser- 
       * 
       * authorizedUsers("UserTester","SuperUser")==> True
       * 
        */

        authorizedUsers: function(...users) {

            const permisos = this.user?.userdata?.permisos;
           
            /*TODO manejo de errores si 
            * 1 No existe el usuario
            * 2 el usuario posee userdata
            * 3 no tiene ningun permiso
            * 4 no se paso ningun string como argumento
            * 5 etc
           */

            return !!permisos.find(element => {
                // console.log("el:", element.TipoUsuarioID)
                return users.includes(element.TipoUsuarioID)
            }
            )
                           
        },
    }

};
export default auth;