<template>
  <div class="filesSearch">
    <div v-if="type === 'home'">
      <div class="p-input-icon-left all-w">
        <i class="pi pi-search" style="z-index: 1" />
        <span class="p-fluid">
          <AutoComplete :disabled="!$offline.onlineMode" ref="filesAutocomplete" v-model="searchText" :suggestions="suggestionsFiles" @complete="searchFiles($event)" field="Nombre" placeholder="Buscar" >
            <template #item="slotProps">
              {{slotProps.item.Nombre}}
            </template>
          </AutoComplete>
        </span>
      </div>
    </div>
    <div v-if="type === 'topBar'">
      <div class="p-input-icon-right search-TopBar">
        <span class="p-fluid" v-if="showSearchTopBar" >
          <AutoComplete :disabled="!$offline.onlineMode" ref="filesAutocomplete" @blur="inputSeach" v-model="searchText" :suggestions="suggestionsFiles" @complete="searchFiles($event)" field="Nombre">
            <template #item="slotProps">
              {{slotProps.item.Nombre}}
            </template>
          </AutoComplete>
        </span>
        <i class="pi pi-search" :class="{'search-icon': !showSearchTopBar }"  @click="inputSeach"/>
      </div>
      <!-- this invisible div prevents a bug with search icon -->
      <div class="invisible-div" v-if="showSearchTopBar"  @click="inputSeach($event, 'invisibleDiv')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FilesSearch",
  data() {
    return {
      allFiles: [],
      suggestionsFiles: [],
      searchText: "",
      showSearchTopBar: false,
    };
  },
  props: {
    type: {
      type: String,
      default: "",
    }
  },
  methods: {
    searchFiles(event) {
      this.suggestionsFiles = []
      let preSuggestionsFiles
      //detect if it have a coma
      if((event.query.match(/,/g) || []).length === 1){
        let query = event.query.split(',')
        query[0] = query[0].trim() 
        query[1] = query[1].trim()
        //searching for matches
        preSuggestionsFiles = this.allFiles.filter((file) => {
          return file.Nombre.toLowerCase().trim().startsWith(query[0].toLowerCase())
        });
        preSuggestionsFiles.map((file)=>{
          if(file.Tipo.toLowerCase().trim().startsWith(query[1].toLowerCase()))
            this.suggestionsFiles.push({...file, Nombre: file.Nombre + ', ' + file.Tipo })
        })
      } else  {
        //searching for matches
        preSuggestionsFiles = this.allFiles.filter((file) => {
          return file.Nombre.toLowerCase().startsWith(event.query.toLowerCase())
        });
        preSuggestionsFiles.map((file)=>{
          this.suggestionsFiles.push({...file, Nombre: file.Nombre + ', ' + file.Tipo })
        })
      }
      if(this.suggestionsFiles.length === 0)
        this.$toast.open({ message: "Búsqueda en biblioteca: Archivo no encontrado", position: 'top-right', type: 'error', duration: 2000})
      setTimeout(() => {
        //creating a invisible '<a>' to can download and view each file
        function viewFile (file){
          let element = document.createElement('a');
          element.setAttribute('href', `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/archivopdf/${file.ArchivopdfID}?view=true`);
          element.setAttribute('target', '_blank');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
        let items = this.$refs.filesAutocomplete.$el.querySelectorAll('li')
        //adding to each '<li>' a link for download and view
        for (let index = 0; index < items.length; index++) {        
          items[index].addEventListener('click', () => viewFile(this.suggestionsFiles[index]));
        }
      }, 100);
    },
    inputSeach(e, type){
      if(type !== 'invisibleDiv' || (!this.$offline.onlineMode && type === 'invisibleDiv')){
        setTimeout(() => {
          this.showSearchTopBar = !this.showSearchTopBar
        }, 100);
      } 
      setTimeout(() => {
        if(this.showSearchTopBar == true){
          this.$refs.filesAutocomplete.$el.children[0].focus()
        }
      }, 100);
    }
  },
  async created(){
    if (this.$offline.onlineMode){
      let resp = await this.$rest.get({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/archivopdf/`})
      this.allFiles = resp.data.data
    }
  },
}
</script>
<style scoped>
.all-w {
  width: 100%;
}
.item {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 10px;
}
>>> .p-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
}
a{
  text-decoration: none;
  color: #4d535a;
}
.search-TopBar{
  cursor: pointer;
  width: 470px ;
}
.invisible-div{
  position: absolute;
  z-index: 2;
  height: 30px;
  width: 30px;
  margin-left: 440px;
  margin-top: -35px;
  cursor: pointer;
}
.search-icon{
  z-index: 1; 
  margin-top: -14px
}
.filesSearch{
  width: 100%;
}
</style>