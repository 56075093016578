var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toolbar flex-column"},[_c('div',[(_vm.$offline.onlineMode)?_c('TTableFilters',{ref:"Filters",attrs:{"apiversion":"v2","dropdown":_vm.filterDropdown,"filtersFields":_vm.filtersFields,"enableFilterMultiple":_vm.enableFilterMultiple},on:{"search":function($event){return _vm.getData(true)}}}):_vm._e()],1),_c('DataTable',{staticClass:"p-datatable-forms col-12",attrs:{"value":_vm.denunciations,"paginator":false,"dataKey":"id","filters":_vm.filters,"loading":_vm.loading,"test-id":"Denuncias"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" No hay denuncias para mostrar. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Descargando, espere por favor. ")]},proxy:true},(_vm.$offline.onlineMode)?{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-content-between"},[_c('div',{staticClass:"flex align-items-center"},[_c('span',{staticClass:"pagination"},[_vm._v(_vm._s(`Mostrando registros del ${_vm.pagination.offset} al ${ _vm.pagination.offset + _vm.pagination.limit > _vm.total ? _vm.total : _vm.pagination.offset + _vm.pagination.limit } de un total de ${_vm.total}`))])]),_c('div',{staticClass:"align-self-center",style:({ marginRight: '20%' })},[_c('ul',{staticClass:"flex flex-row"},[_c('Button',{staticClass:"p-button-text",attrs:{"icon":"pi pi-angle-double-left","disabled":_vm.pagination.actualPage <= 0},on:{"click":function($event){return _vm.anterior('x2')}}}),_c('Button',{staticClass:"p-button-text",attrs:{"icon":"pi pi-angle-left","disabled":_vm.pagination.actualPage <= 0},on:{"click":function($event){return _vm.anterior()}}}),_vm._l((_vm.pagination.indices),function(e,i){return _c('li',{key:i,style:({ textDecoration: 'none', listStyle: 'none' })},[_c('Button',{style:(_vm.pagination.actualPage === e - 1
                    ? { backgroundColor: '#2196F3' }
                    : {
                        backgroundColor: 'transparent',
                        color: '#2196F3',
                        border: 'none',
                      }),on:{"click":function($event){return _vm.goToPage(e - 1)}}},[_vm._v(_vm._s(e))])],1)}),_c('Button',{staticClass:"p-button-text",attrs:{"icon":"pi pi-angle-right","iconPos":"right","test-id":"nextButton","disabled":_vm.pagination.actualPage + 1 >=
                Math.ceil(_vm.total / _vm.pagination.limit)},on:{"click":function($event){return _vm.siguiente()}}}),_c('Button',{staticClass:"p-button-text",attrs:{"icon":"pi pi-angle-double-right","iconPos":"right","test-id":"nextButton","disabled":_vm.pagination.actualPage + 1 >=
                Math.ceil(_vm.total / _vm.pagination.limit)},on:{"click":function($event){return _vm.siguiente('x2')}}})],2)]),_c('div',[_c('button',{style:({
              backgroundColor: 'transparent',
              cursor: 'normal',
              border: 'none',
            }),attrs:{"disabled":""}})])])]},proxy:true}:null],null,true)},[_vm._l((_vm.keyShow),function(column){return (_vm.showColumnOrNot(column.title))?_c('Column',{key:column.title,attrs:{"field":column.title,"sortable":false,"sortField":column.fieldName},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex align-items-center"},[_vm._v(" "+_vm._s(column.title)+" "),(_vm.order[column.fieldName])?_c('Button',{staticClass:"p-button-rounded p-button-secondary p-button-text",attrs:{"icon":_vm.order[column.fieldName].icon || 'pi pi-sort-alt'},on:{"click":function($event){return _vm.setOrderBy(column.fieldName)}}}):_vm._e()],1)]},proxy:true},{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"flex align-items-center",attrs:{"test-id":'fila-nombre' + slotProps.index,"id":!_vm.isOfflineDoc(slotProps.data)
              ? slotProps.data.ActuacionUsuarioDependencia.find(
                  (e) =>
                    e.UsuarioID === 'Sin Usuario Asignado' &&
                    e.Fechahasta === null
                )
                ? 'sin-propietario'
                : ''
              : ''}},[_c('div',{staticClass:"flex mr-2"},[(_vm.booleans(slotProps, 'triangleButton', column.title))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(
                slotProps.data.Numero
                  ? 'No fue asignado ningún propietario'
                  : 'Falta numero de sumario'
              ),expression:"\n                slotProps.data.Numero\n                  ? 'No fue asignado ningún propietario'\n                  : 'Falta numero de sumario'\n              ",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-danger",attrs:{"icon":"pi pi-exclamation-triangle","test-id":'danger-' + slotProps.data.ActuacionID}}):_vm._e()],1),_c('div',{class:_vm.getClass(column, slotProps.data)},[_vm._v(" "+_vm._s(_vm.formatDoc(column.title, slotProps.data))+" ")])])]}}],null,true)}):_vm._e()}),_c('Column',{staticClass:"flex-row",scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"flex",staticStyle:{"text-align":"right"}},[(_vm.isOfflineDoc(slotProps.data) && _vm.$offline.onlineMode)?_c('div',{staticClass:"flex mr-2"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Subir'),expression:"'Subir'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-success p-button-outlined",attrs:{"icon":"pi pi-upload"},on:{"click":function($event){return _vm.subirActuacion(slotProps)}}})],1):_vm._e(),(_vm.booleans(slotProps, 'numeroExtracto'))?_c('div',[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Recientemente asignada'),expression:"'Recientemente asignada'",modifiers:{"left":true}}],staticClass:"p-button-danger p-button-sm",style:({
                height: '40px ',
                width: '200px',
                marginRight: '0.5rem ',
              }),attrs:{"label":"Extracto de Denuncia","icon":"pi pi-check","test-id":'Asignar-numero' + slotProps.data.ActuacionID},on:{"click":function($event){return _vm.OpenModalAddNsum(slotProps)}}})],1):(_vm.visibleButtons())?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex mr-2"},[_c('router-link',{staticClass:"decoration__links",attrs:{"to":`/actuaciones/view/${slotProps.data.ActuacionID}`}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Visualizar esta Actuacion'),expression:"'Visualizar esta Actuacion'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-outlined",attrs:{"icon":"pi pi-eye","test-id":'visualizar-act' + slotProps.data.ActuacionID}})],1)],1),_c('div',{staticClass:"flex"},[(_vm.authorizedUser() && !_vm.isOfflineDoc(slotProps.data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Eliminar esta Actuacion'),expression:"'Eliminar esta Actuacion'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-outlined p-button-danger mr-2",attrs:{"icon":"pi pi-trash","id":"boton-eliminar"},on:{"click":function($event){return _vm.deleteActuacion(slotProps)}}}):_vm._e()],1),(!_vm.isOfflineDoc(slotProps.data))?_c('div',{staticClass:"flex mr-2"},[(
                  !_vm.$rest.esTipoUsuario(
                    ['Oficial Sumariante', 'Deposito Judicial', 'Juez', 'Ayudante de Juez'].concat(_vm.prosecutorUserTypes),
                    _vm.user
                  ) && _vm.$offline.onlineMode
                )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Reasignar secretario'),expression:"'Reasignar secretario'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-outlined p-button-seccess mr-2",attrs:{"icon":"pi pi-user-plus","id":"reasignar-secretario","test-id":'reasignar-secretario' + slotProps.data.ActuacionID},on:{"click":(e) =>
                    _vm.assignSecretary(
                      e,
                      slotProps.data.ActuacionUsuarioDependencia.find(
                        (e) =>
                          (e.UsuarioID === 'Sin Usuario Asignado' &&
                            !e.Fechahasta) ||
                          !e.Fechahasta
                      ),
                      slotProps.data
                    )}}):_vm._e()],1):_vm._e()]):_c('div',{staticClass:"flex"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Vincular con esta Actuacion'),expression:"'Vincular con esta Actuacion'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-outlined p-button-help",attrs:{"icon":"pi pi-paperclip","test-id":'vincular-act' + slotProps.data.ActuacionID},on:{"click":function($event){return _vm.vincularActuacion(slotProps.data)}}})],1)])]}}])})],2),_c('div',[(_vm.$offline.onlineMode && _vm.splitItems.length > 0)?_c('SplitButton',{attrs:{"label":"Descargar excel","id":"bottonXLS","model":_vm.splitItems}}):_vm._e()],1),_c('Dialog',{style:({
      width: '30vw',
      height: '300px',
      backgroundClip: 'padding-box',
      backgroundColor: 'white',
    }),attrs:{"visible":_vm.displayBasic,"contentStyle":"padding:0px"},on:{"update:visible":function($event){_vm.displayBasic=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{style:({ marginTop: '0px', marginBottom: '0px' })},[_vm._v(" Agregar un secretario a cargo ")])]},proxy:true}])},[(_vm.displayBasic)?_c('div',[_c('form',{staticClass:"form-secretario"},[_c('div',[(
              _vm.$rest.esTipoUsuario(['Super User', 'User Tester'], _vm.user) ||
              _vm.user.userdata.usuarioNombre == 'SuperUserE3'
            )?_c('Dropdown',{style:({
              position: 'absolute',
              left: '10%',
              top: '30%',
              minWidth: '80%',
            }),attrs:{"id":"dropdownActuaciones","options":_vm.dependenciaList,"filter":"","optionValue":'id',"optionLabel":"dependencia","placeholder":"Filtrar por dependencia....","test-id":"dropdownNewSecretarioDependency"},model:{value:(_vm.dependenciaModal),callback:function ($$v) {_vm.dependenciaModal=$$v},expression:"dependenciaModal"}}):_vm._e(),_c('Dropdown',{style:({
              position: 'absolute',
              left: '10%',
              top: '50%',
              minWidth: '80%',
            }),attrs:{"id":"dropdownActuaciones","options":_vm.completeNullLabelsAndAddingID(_vm.getActuacionesUserByStationId(_vm.dependenciaModal), 'apellido_nombre'),"filter":"","optionLabel":"apellido_nombre","placeholder":"Nombre del Oficial...","dataKey":"id","test-id":"dropdownNewSecretario"},scopedSlots:_vm._u([{key:"item",fn:function(slotProps){return [_c('div',{staticClass:"p-dropdown-car-value"},[_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(slotProps.item.nombre))])])]}}],null,false,2039153638),model:{value:(_vm.secretario),callback:function ($$v) {_vm.secretario=$$v},expression:"secretario"}})],1),_c('div',{staticClass:"field col",style:({ paddingTop: '150px' })},[_c('Button',{staticClass:"m-b-1",attrs:{"disabled":_vm.disabledAsignarButton,"test-id":"buttonAsignar"},on:{"click":_vm.AsignarSecretario}},[_vm._v(" Asignar ")])],1)])]):_vm._e()]),_c('Dialog',{attrs:{"visible":_vm.dataExcel.showModal},on:{"update:visible":function($event){return _vm.$set(_vm.dataExcel, "showModal", $event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{style:({ marginTop: '0px', marginBottom: '0px' })},[_vm._v(" Descargar Excel ")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-content-center"},[_c('JsonExcel',{attrs:{"data":_vm.dataExcel.data,"fields":_vm.dataExcel.fields,"default-value":"----","name":_vm.dataExcel.name + '.xls',"disabled":_vm.dataExcel.loading || _vm.dataExcel.data.length == 0}},[_c('div',{class:_vm.dataExcel.loading || _vm.dataExcel.data.length == 0
                ? 'bg-blue-200 border-round p-3 select-none text-white'
                : 'bg-primary p-3 cursor-pointer border-round select-none'},[_vm._v(" Descargar "+_vm._s(_vm.dataExcel.name)+" ")])])],1)]},proxy:true}])},[_c('div',{staticClass:"flex flex-column align-items-center justify-content-center"},[(_vm.dataExcel.loading)?_c('ProgressSpinner'):_c('div',[(_vm.dataExcel.data.length > 0)?_c('Button',{staticClass:"p-button-rounded p-button-outlined p-button p-component p-button-icon-only",style:({
            color: 'green',
            borderRadius: '100%',
            border: 'solid 1px green',
          }),attrs:{"icon":"pi pi-check"}}):(!_vm.dataExcel.error)?_c('span',[_vm._v("No hay sumarios para exportar")]):_vm._e()],1),(_vm.dataExcel.loading && !_vm.dataExcel.error)?_c('span',[_vm._v("El boton se habilitara una vez que se termine de cargar el excel...")]):_vm._e(),(_vm.dataExcel.error)?_c('span',[_vm._v(_vm._s(_vm.dataExcel.error))]):_vm._e()],1)]),_c('ModalForm',{attrs:{"Show":_vm.showModalForm,"Title":"Ingrese un Nro° de Sumario  ","Inputs":_vm.Inputs,"OptionDisable":true},on:{"sendInfo":_vm.addNsumario}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }