<template>
  <div :class="{'page' : formType !== 'contravencional-oficio' && !(formType?.startsWith('preliminar')), 'no_page' : formType === 'contravencional-oficio' || formType?.startsWith('preliminar') }">
      <SuccessAndFail
            :msjEnable.sync="msjEnable"
            :mensajeDialog="mensaje"
            :buttomAceptar="buttomAceptar"
            :icon.sync="iconModal"
      />
    <div class="grid justify-content-between align-items-center vertical-container menu flex-row-reverse" v-if="this.formType !== 'contravencional-oficio'">
        <div :class="{'spinner':!timer, 'ml-auto':true, 'md:col-0':true, 'mt-1':true}" >
          <ProgressSpinner style="width:50px;height:50px" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s"/>
        </div>
      <div class="actions">
        <!--<Button  v-if="saveStatus != 'save'" label="Validar datos" @click="previewCertificado" class="p-button-warning" />-->
         <!-- v-if="validate && !isSaving && saveStatus == 'new'"  -->
         <Button v-if="!isSaving && saveStatus == 'save'" label="Ir a Sumario" icon="pi pi-next" @click="redirect()" test-id="ButtonIrSumario"/>
         <Button v-else v-if="!viewData.mixedFormDatosLegalesDenunciaFormDatos" label="Guardar" icon="pi pi-check" @click="$_SumarioDenunciaForm_save" test-id="ButtonGuardar" :disabled="SaveDisable"/>
      </div>
    </div>

    <div class="grid formOk">
      <div :class="{'md:col-4' : formType !== 'contravencional-oficio' && !(formType?.startsWith('preliminar'))}" class="col" v-if="this.formType !== 'contravencional-oficio'">
        <div class="p-fluid">
          <div class="field">
            <label for="causaNumero">{{ formType?.startsWith('preliminar') ? 'ACT. Preliminares N° / N° de extracto' : 'N° de sumario / N° de extracto' }}</label>
            <div class="flex">
              <InputNumber
                :useGrouping="false"
                :min="0"
                class="titleSelect"
                placeholder="N° sumario/extracto"
                v-model="causaNumero"
                test-id="inputNumeroExp"
              />
              <div class="flex ml-1 titleSelect" style="">
                <p class="mr-1">/</p>
                <Dropdown style="" v-model="NumActPart2" :options="anios" optionLabel="anio" optionValue="code" placeholder="2023" />
              </div>
            </div>            
            <label :class="{visible: error}"> {{error}}</label>
          </div>
          <div class="field">
            <div class="titleSelect">
              <label for="TipoSitioID">SITIO</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('site')" :style="[UpdateSite ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateMo ? {'display': 'none'}: '']"></i>
            </div>
            <Dropdown dataKey='TipoSitioID'
              v-model="dataSitio"
              placeholder="SIN SITIO"
              :options="sitio"
              optionLabel="Nombre"
              test-id="dropdownModusOperandi" :filter="true"
            />
          </div>
          <div class="field">
            <div class="titleSelect">
              <label for="modusoperandi">Modus operandi</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('modus operandi')" :style="[UpdateMo ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateMo ? {'display': 'none'}: '']"></i>
            </div>
            <Dropdown dataKey='Nombre'
                v-model="dataModusOperandi"
                placeholder="Modus operandi"
                :options="modusoperandiList"
                optionLabel="Nombre"
                test-id="dropdownModusOperandi" :filter="true"
            />
          </div>
          <div class="field">
            <div class="titleSelect">
              <label for="causaCaratula">Causa / Carátula</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('causa-caratula')" :style="[UpdateCausa ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateCausa ? {'display': 'none'}: '']"></i>
            </div>
            <div @keydown.enter="pressedEnterForAddCausaCaratula($event)">
            <Dropdown 
                dataKey='TipocausacaratulaID'
                v-model="dataCausaCaratula"
                placeholder="Causa / Carátula"
                :options="causasList"
                optionLabel="Nombre"
                test-id="dropdownCausaCaratula" :filter="true"
                @change="addCausaCaratula($event)"
              />
            </div>
          </div>
          <div>
            <div class="field flex flex-column border-round-md border-solid border-400 border-1" v-if="listaCausaCaratula.length > 0">
              <div class="pb-1 pt-1">
                <div class="flex flex-row flex-nowrap justify-content-between align-items-center pl-2" v-for="item in listaCausaCaratula" :key="item.TipocausacaratulaID">
                  <div class="text-xs">
                    <p>{{ item.Nombre }}</p>
                  </div>
                  <div class="w-5rem">
                    <Button icon="pi pi-trash" class="md:col-4 p-button-text"  @click="deleteCaratula(item.TipocausacaratulaID)" style="color:white; width:30px; height:30px;background-color:#dc3545; margin:3px"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="titleSelect">
              <label for="juzgado">{{ formType?.startsWith('preliminar') ? 'Interviniente' : 'Juzgado interviniente' }}</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('juzgado')" :style="[UpdateJuzgado ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateJuzgado ? {'display': 'none'}: '']"></i>
            </div>
            <Dropdown dataKey='Nombre'
              v-model="dataJuzgado"
              :placeholder="formType?.startsWith('preliminar') ? 'Interviniente' : 'Juzgado interviniente'"
              :options="juzgadosList"
              optionLabel="Nombre"
              test-id="ButtonInterviniente" 
              :filter="true"
              ref="DropdownJuzgado"
            />
          </div>
        </div>
        <!-- <label for="upload-acta" v-if="this.formType === 'oficio'" class="p-button p-component label-file" >{{this.fileName ? this.fileName :  "Examinar..."}}</label>
        <input type="file" @change="fileInput($event)" v-if="this.formType === 'oficio'" id="upload-acta">
         -->
        <div v-if="!viewData.mixedFormDatosLegalesDenunciaFormDatos">
          <Button label="Volver" class="back-button" icon="pi pi-back" @click="validacion(viewData.mainForm.path)"/>
          <!-- <Button label="Volver" v-if="this.formType === 'oficio'" class="back-button" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario/oficio')"/> -->
        </div>
      </div>
      <div v-if="!viewData.mixedFormDatosLegalesDenunciaFormDatos" :class="{'previewCausa md:col-5': formType !== 'contravencional-oficio'}" test-id="BoxErrores">
        <!-- {{saveStatus}} -->
        <!-- <h4 v-if="resumen.errorValidationWarning.length > 0">Errores: {{resumen.errorValidationWarning.length}}</h4> -->
        <h4 v-if="resumen.errorValidationWarning.length > 0 && this.formType !== 'contravencional-oficio'">Errores: {{resumen.errorValidationWarning.length}}</h4>

        <!-- <p v-for="(item, i) in resumen.errorValidationErr" v-bind:key="i+20" class="p-error2">
          {{item}}
        </p> -->

        <p v-for="(item, i) in resumen.errorValidationErr" v-bind:key="i+20" :class="{'p-error': formType !== 'contravencional-oficio','p-error-contravencional': formType === 'contravencional-oficio'}">
          {{item}}
        </p>

        <!-- <p v-for="(item, i) in resumen.errorValidationWarning" v-bind:key="i" class="p-error">
          {{item}}
        </p> -->

        <p v-for="(item, i) in resumen.errorValidationWarning" v-bind:key="i" :class="{'p-error2': formType !== 'contravencional-oficio','p-error2-contravencional': formType === 'contravencional-oficio'}">
          {{item}}
        </p>



        <!-- <p v-if="causaNumero"><span>Nro Causa:</span> {{causaNumero}}</p>
        <p v-if="txt_caratula"><span>causa:</span> {{txt_caratula}}</p>
        <p v-if="txt_juzgado"><span>juzgado:</span> {{txt_juzgado}}</p> -->
        <p v-if="resumen.fechaDeLaDenuncia"><span>Fecha de la denuncia:</span> {{resumen.fechaDeLaDenuncia}}</p>
        <p v-if="resumen.dniDenunciante"><span>Denunciante:</span> {{resumen.dniDenunciante}}</p>
        <p ><span v-if="resumen.apellidoDenunciante">{{resumen.apellidoDenunciante}},</span> <span v-if="resumen.nombreDenunciante">{{resumen.nombreDenunciante}}</span></p>
        <p v-if="resumen.nacionalidadDenunciante"><span>Nacionalidad:</span> {{resumen.nacionalidadDenunciante}}</p>
        <p v-if="resumen.estadoCivilDenunciante"><span>Estado civil:</span> {{resumen.estadoCivilDenunciante}}</p>
        <p v-if="resumen.edadDenunciante"><span>Edad:</span> {{resumen.edadDenunciante}}</p>
        <p v-if="resumen.lugarDeRecidenciaDenunciante"><span>Residencia:</span> {{resumen.lugarDeRecidenciaDenunciante}}</p>
        <p v-if="resumen.fechaDelHecho"><span>Fecha del hecho:</span> {{resumen.fechaDelHecho1}}</p>
        <p v-if="resumen.efectosDenunciados && formType !== 'contravencional-oficio'"><span>Objetos sustraídos:</span> {{resumen.efectosDenunciados}}</p>
        <p v-if="resumen.efectosDenunciados && formType === 'contravencional-oficio'"><span>Efecto secuestrado:</span> {{resumen.efectosDenunciados}}</p>
        <p v-if="resumen.fechaDeExtencionCertificado"><span>Fecha de extensión del certificado:</span> {{resumen.fechaDeExtencionCertificado}}</p>

        <!-- {{certificadoText}} -->
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    minimal: {
      type: String
    },
    // formType: String //Defines the type of form
  },
  name: 'DenunciaFormDatos',

  data() {
    return {
      //---------Datos para el Modal SuccessAndFail------
      buttomAceptar:{buttom:true,action:false},
      iconModal:{},
      mensaje:'',
      msjEnable:false,
      //-----------Fin datos para Modal SuccessAndFail-----      
      anios: [
        {anio: new Date().getFullYear(), code: JSON.stringify(new Date().getFullYear()).substr(-2)},
        {anio: new Date().getFullYear()-1, code: JSON.stringify(new Date().getFullYear()-1).substr(-2)},
      ],
      NumActPart2: JSON.stringify(new Date().getFullYear()).substr(-2),
      causaNumero: null,      
      // causaNumero: null,
      validate: false,
      modusOperandi: null,
      // causaCaratula: null,
      causaJuzgado: null,
      fields: [
        'name', 'b'
      ],
      id: null,
      UpdateMo: false,
      UpdateCausa: false,
      UpdateJuzgado: false,
      UpdateSite: false,
      error: null,
      // timer: false,
      SaveDisable:false,
      dataCausaCaratula: null
    }
  },
  async created() {
    this.causaNumero = this.causaNumeroStore ? Number(this.causaNumeroStore.substring(0, this.causaNumeroStore.length - 3)): null
    this.NumActPart2 = this.causaNumeroStore ? this.causaNumeroStore.substr(-2) : JSON.stringify(new Date().getFullYear()).substr(-2)    
    this.$store.dispatch('siis/setJuzgados');
    this.$store.dispatch('siis/setModusoperandi');
    this.$store.dispatch('siis/setCausaCaratula');
  },
  mounted(){
    if(this.formType?.startsWith('preliminar')){
      this.dataJuzgado = {
        Domicilio: null,
        Fechafinvig: null,
        Fechainivig: null,
        JuzgadoID: this.dependencia,
        Nombre: this.dependencia,
        Telefono: null
      }
    }
  },
  computed: {
    ...mapState({
      modusoperandiList: state => state.siis.modusoperandi,
      causasList: state => state.siis.causaCaratula,
      juzgadosListFromDB: state => state.siis.juzgados,
      sumario: state => state.sumario,
      causaCaratula: state => state.sumario.causaCaratula,
      listaCausaCaratula: state => state.sumario.listaCaratulas || [],
      isSaving: state => state.sumario.isSaving,
      saveStatus: state => state.sumario.saveStatus,
      causaNumeroStore: state => state.sumario.causaNumero,
      idsumario: state => state.sumario.id,
      timer: state => state.sumario.timer,
      dependencia: state => state.user.dependencia,
      formType: (state) => state.sumarioViews.actuacion,
      sitio: state => state.siis.sitio,
    }),
    ...mapGetters({
      resumen: 'sumario/dataResumen',
      viewData: "sumarioViews/getViewData"
    }),

    dataJuzgado: {
      get () {
        return this.sumario.causaJuzgado
      },
      set (value) {
        if (typeof value == 'string') value = {JuzgadoID: 'new', Nombre: value};
        if (value.Nombre == this.dependencia){
          this.$refs.DropdownJuzgado.$el.children[1].innerHTML = this.dependencia;
          value = {};
        } else{
          this.$refs.DropdownJuzgado.$el.children[1].innerHTML = value.Nombre;
        }
        this.$store.dispatch('sumario/setValue', { field: 'causaJuzgado', value: value } );
      }
    },
    dataModusOperandi: {
      get () { return this.sumario.modusOperandi },
      set (value) {
        if (typeof value == 'string') value = {id: 'new', Nombre: value}
        this.$store.dispatch('sumario/setValue', { field: 'modusOperandi', value: value } );
      }
    },
    dataSitio: {
      get () { return this.sumario.Sitio },
      set (value) { 
        typeof value !== 'object' ? value = null : value = {TipoSitioID: value.TipoSitioID};
        this.$store.dispatch('sumario/setValue', { field: 'Sitio', value: value } );
      }
    },
    // dataCausaCaratula: {
    //   get () { return this.causaCaratula },
    //   set (event, value) {
    //     console.log('dataCausaCaratula', value);
    //     let repetedTipocausacaratulaID = this.listaCausaCaratula.some(causaCaratula => causaCaratula.TipocausacaratulaID === value.TipocausacaratulaID);
    //     if(repetedTipocausacaratulaID) return;
    //     if (typeof value == 'string') value = {id: 'new', Nombre: value};
    //     var lista = [...this.listaCausaCaratula, value];
    //     this.$store.dispatch('sumario/setValue', { field: 'listaCaratulas', value: lista } );
    //     // this.causaCaratula = ''
    //   }
    // },
    juzgadosList(){
      if(this.formType?.startsWith('preliminar')){
        return [{
            Nombre: this.dependencia,
          }, 
          ...this.juzgadosListFromDB
        ];
      } else{
        return this.juzgadosListFromDB;
      }
    },
  },
  watch:{
    causaNumero(v)
    {
      if(v != null)
      {
        this.$store.dispatch('sumario/setValue', { field: 'causaNumero', value:  v+'/'+this.NumActPart2 } );
        if(JSON.stringify(v).length > 15){
          this.error = 'Este campo no puede contener mas de 15 Caracteres'
          this.passValidation = false
        }else{
          this.error = null
          this.passValidation = true
        }
      }else
      {

        this.$store.dispatch('sumario/setValue', { field: 'causaNumero', value:  null } );
      }
    },   
    NumActPart2(v)
    {
      if(this.causaNumero)
      {
        this.$store.dispatch('sumario/setValue', { field: 'causaNumero', value:  this.causaNumero+'/'+v } );
      }
    }    
  },
  methods: {
     deleteCaratula(CaratulaID) {
      let arrayDeCaratulas = [...this.listaCausaCaratula].filter(item => item.TipocausacaratulaID !== CaratulaID)
      this.$store.dispatch('sumario/setValue', { field: 'listaCaratulas', value: arrayDeCaratulas } );
    },
    async toUpdateSelect(tipo){
      switch(tipo)
      {
        case "modus operandi":
            this.UpdateMo = true;
            await this.$store.dispatch('siis/setModusoperandi', true);
            this.UpdateMo = false;
        break;
        case "juzgado":
            this.UpdateJuzgado = true;
            await this.$store.dispatch('siis/setJuzgados', true);
            this.UpdateJuzgado = false;
        break;
        case "causa-caratula":

            this.UpdateCausa = true;
            await this.$store.dispatch('siis/setCausaCaratula', true);
            this.UpdateCausa = false;
        break;
        case "site":
            this.UpdateSite = true;
            await this.$store.dispatch('siis/setSitio');
            this.UpdateSite = false;
        break;    
      }
    },

    async $_SumarioDenunciaForm_save(){
      let errores = this.emitErrores()
      if(errores == true) {
        alert("Verifica los campos")
        return
      }

      await this.$store.dispatch('sumario/setDependencia', this.dependencia);
      if ( this.resumen.errorValidationErr.length > 0 ) {
        this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }             
        this.mensaje = 'Falta algun Dato para Generar el Sumario'
        this.msjEnable = true        
        // alert('Falta algun Dato para Generar el Sumario');
      } else {
        this.SaveDisable = true;

        try {

          // console.log("saveActuacion saveDenuncia")
          await this.$store.dispatch('sumario/saveDenuncia');
          // await this.$store.dispatch('sumario/saveUsuarioSumario')
          // console.log("saveActuacion saveDenuncia finalizo")
          // this.$store.dispatch('sumarioList/get', {accion: "inicio"});
        }
        catch(e){
          console.error("saveActuacion error ", e)
        }
      }
    },
    setData(data){
      for (let i in data) {
        this[i] = data[i]
      }
    },
    $_TemplateForm_update( field, value ){
      this.$emit("updateValue", field, value)
    },
    redirect()
    {
      // console.log("en redirect el id es: ", JSON.stringify(this.resumen.formType))
      this.$router.push({path: `/sumario/denuncia/detalle/${this.idsumario}`})
    },
    emitErrores(){
      if(this.error != null)
      {
        return true;
      }
      return false;
    },
    validacion(route){
      let errores = this.emitErrores()
      if(errores == true)
      {
        alert("Verifica los campos")
      }else
      {
        this.$router.push(route)
      }
    },
    addCausaCaratula(event) {
      if(event.originalEvent.key === 'ArrowDown' || event.originalEvent.key === 'ArrowUp') return;
      let repetedTipocausacaratulaID = this.listaCausaCaratula.some(causaCaratula => causaCaratula.TipocausacaratulaID === event.value.TipocausacaratulaID);
      if(repetedTipocausacaratulaID) return;
      if (typeof event.value == 'string') event.value = {id: 'new', Nombre: event.value};
      var lista = [...this.listaCausaCaratula, event.value];
      this.$store.dispatch('sumario/setValue', { field: 'listaCaratulas', value: lista } );
    },
    pressedEnterForAddCausaCaratula(){
      let event = { originalEvent: { keydown: 'Enter' }, value: this.dataCausaCaratula };
      this.addCausaCaratula(event);
    },
  },
}
</script>

<style scoped>
  .no_page{
    margin:15px 0 0 10px;
  }
  .p_sin_margen{
    margin: 0px;
  }
  .p-error2{
    color:#ffc107;
  }
  .p-error-contravencional{
    color:#dc3545;
    margin:4px;
  }
  .p-error2-contravencional{
    color:#ffc107;
    margin:4px;
  }
  .preview span {
    font-weight: bold;
  }
  .formulario  {
    max-width: 60rem;
    padding: 0 1rem;
    margin: 1rem auto 0;
  }
  .previewCausa {
    margin-left: 40px;
  }
  .previewCausa p {
    line-height: 1.5em;
    padding: 0;
    margin: 10px 0 0px;
  }
  .MWDropdown  {
    max-width: 400px;
  }

  .MWButton  {
    max-width: 300px;
  }

  .summaryStart   {
    margin-right: 25px;
  }

  .flex-end   {
    display: flex ;
    justify-content: flex-end;
  }

  .summaryStart   {
    margin-right: 25px;
  }
  .escritoTextarea {
    text-align: justify;
    text-justify: inter-word;
    white-space: pre-line;
  }
  .actions button {
    margin-left: 10px;
  }
  #upload-acta{
    opacity: 0;
    position: absolute;
    z-index: -1;
    overflow: visible;
  }
  .label-file:hover{
    color: #ffffff;
    background: #0d89ec;
    border: 1px solid #2196F3;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 3px;
    border-color: #0d89ec;
    cursor: pointer;
  }
  .back-button{
    margin-top: 30px
  }
  .spinner{
      display: none;
  }
  .titleSelect{
    height:2em;
    display:flex;
    align-items: center;
  }
  .vertical-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
  }
</style>
