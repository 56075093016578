import interveningPersonnel from './dataResumenModules/interveningPersonnel';
import effects from './dataResumenModules/effects';
import viculateds from './dataResumenModules/viculateds';
import affecteds from './dataResumenModules/affecteds';
import datesAndLocations from './dataResumenModules/datesAndLocations';
import generalData from './dataResumenModules/generalData';
import legalData from './dataResumenModules/legalData';

const resumen = (state, getters) => {
  let data = {
    modusOperandi: 'no se lleno',
    formulario:state.formType,
    validate: false,
    numeroSumario: state.causaNumero,
    departamento: '',
    acusado: '',
    listaArticulos:'',
    listaCaratulas:'',
    acusadosDetalles: '',
    damnificado: '',
    denunciantes:'',
    denunciantesCaratula: '',
    denunciantesNyA:'',
    denunciantesDni:'',
    nacionalidadDenunciante:'',
    efectosDenunciados : '',
    lugarDelHecho:'',
    instruccion:'',
    profesion:'',
    secretarioOriginal: '',
    secretarioActual: '',
    dependenciaNumero: 25,
    comisaria: 25,
    fechaCertificadoDenuncia: '',
    año : '2022',
    dependencia: '',//state.dependenciaPolicial,
    ActuacionID: state.id, //NO BORRAR
    personalInterviniente: '',
    personalIntervinienteDelitosEspeciales: '',
    juzgado: '',
    SumarioUfi: '',
    deposition: '',
  }

  data.err = 0;
  data.warning = 0;
  data.errorValidationErr = [];
  data.errorValidationWarning = [];
  data.failFields = [];
  data.formType = state.formType;

  interveningPersonnel.interveningPersonnel(state, data);
  effects.effects(data, getters);
  viculateds.viculateds(state, data);
  affecteds.affecteds(state, data);
  datesAndLocations.datesAndLocations(state, data);
  generalData.generalData(state, data);
  legalData.legalData(state, data);

  return data;
}

export default {
  resumen
}
