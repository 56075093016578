<template>
  <div>
    <Button type="button" label="Insertar"  iconPos="right"  icon="pi pi-angle-down" class="p-button-outlined p-button-secondary" @click="toggle" />
    <OverlayPanel ref="op" >
      <div class="p-dropdown-header">
          <div class="p-dropdown-filter-container">
            
              <InputText  v-model="filterValue"  placeholder="Filtrar" />
              <!-- <span class="p-dropdown-filter-icon pi pi-search"></span> -->
          </div>
      </div>
      <div class="max-h-15rem overflow-auto">
        <div v-for="(option, index) in menuSugerenciasFilter" :key="index" >
            <Button
              class="p-button-text w-full text-left"
              v-tooltip.top="`${option.value}`"
              type="button"
              @click="(e) => test2(e, option)"
              >{{ option.label }}</Button>
          </div>
        <div v-if="menuSugerenciasFilter.length < 1">
          No se encontraron resultados
        </div>
      </div>
    </OverlayPanel>

    <!-- <Dropdown
      :options="menuSugerencias"
      optionLabel="label"
      filter
      placeholder="Insertar"
      emptyFilterMessage="No se encontraron resultados"
      @click.prevent
    >
      <template #option="slotprops" @click="test(1)" >
        
      </template>
    </Dropdown> -->
  </div>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel';
export default {
  name: "DropdownForInserts",
  components: {
    OverlayPanel
  },
  data() {
    return {
      dropValue: "",
      filterValue: ''
    };
  },
  mounted(){
    
  },
  props: {
    menuSugerenciasProp:{
      type: Array,
    },
    data:{
      type: [Object, Array],
    },
    notInclude:{
      type: Array,
      default: () => []
    },
  },
  computed: {
    menuSugerenciasFilter: {
      get(){
        if (this.filterValue == '') return this.menuSugerencias
        return this.menuSugerencias.filter(i =>i.label.toLowerCase().search(this.filterValue.toLowerCase()) >= 0)
      }
    },
    menuSugerencias: {
      get() {     
        let menu = [];
          for (let i in this.data) {
            if (this.data[i] == "") continue;
            if (!this.data[i]) continue;
            if (typeof this.data[i] == "string" && this.data[i].search("</") >= 0)
              continue;
            if (this?.notInclude.indexOf(i) >= 0) continue;
            let option = {
              label: this.capitalizeFirstLetter(this.decamelize(i, " ")),
            };
  
            if (typeof this.data[i] != "object") {
              option.command = (event) => {
                event.stopPropagation();
                this.$emit('insert', this.data[i]);
              };
              option.value = this.data[i];
              menu.push(option);
            } else {
              if (Array.isArray(this.data[i])) {
                for (let x in this.data[i]) {
                  if (this.data[i][x] == "") continue;
                  if (!this.data[i][x]) continue;
                  if (
                    typeof this.data[i][x] == "string" &&
                    this.data[i][x].search("</") >= 0
                  )
                    continue;
                  let item = {
                    label: this.capitalizeFirstLetter(
                      this.decamelize(i + " " + x, " ")
                    ),
                    value: this.data[i][x],
                    command: (e) => {
                      e.stopPropagation();
                      this.$emit('insert', this.data[i][x]);
                    },
                  };
                  menu.push(item);
                }
              } else {
                for (let x in this.data[i]) {
                  if (this.data[i][x] == "") continue;
                  if (!this.data[i][x]) continue;
                  if (
                    typeof this.data[i][x] == "string" &&
                    this.data[i][x].search("</") >= 0
                  )
                    continue;
  
                  let item = {
                    label: this.capitalizeFirstLetter(this.decamelize(x, " ")),
                    value: this.data[i][x],
                    command: (e) => {
                      e.stopPropagation();
                      this.$emit('insert', this.data[i][x]);
                    },
                  };
                  menu.push(item);
                }
              }
              option.command = (e) => {
                e.stopPropagation();
                this.$emit('insert', this.data[i]);
              };
            }
          }
        return menu;
      },
    },
  },
  methods: {
    test2(e, option){
      return option.command(e);
    },
    toggle(event) {
        this.$refs.op.toggle(event);
    },
    
    decamelize(str, separator = "_") {
      return str
        .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
        .toLowerCase();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style scoped>

</style>
