<template>
  <div>    
    <h4 class="flex align-content-start justify-content-center mb-8 mt-4 text-base"  v-if="title ? true : false">{{title}}</h4>
    <div class="flex align-items-center justify-content-center">
      <div
        class="flex flex-wrap justify-content-center white-container column-gap-6 row-gap-8"
        style="max-width: 1200px"
      >
        <Button
          class="border-round border-200 bg-white text-black-alpha-70 p-1"
          v-for="option in options"
          :key="option.name"
          style="width: 300px"
          @click="goToRoute(option.url)"
          :test-id="option.name"
          
        >
          <div class="align-items-center justify-content-center">
            <i class="pi pi-fw pi-check-square layout-menuitem-icon" style="margin:10px;"/>
          </div>

          <div class="block text-left m-1">
            <h4 class="text-xs m-1 ">{{ option.name }}</h4>
            <p class="font-light text-xs m-1">{{ option.title }}</p>
          </div>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "primevue/button";
import Card from 'primevue/card';
export default {
  name: "MenuButtons",
  components: {
    Button,
    Card
  },
  props: ["options", "title"],
  data() {
    return {};
  },
  methods: {
    goToRoute(route) {
      // this.visibleLeft = false;
      this.$router.push(route);
      // location.reload()//cada que vamos a los sumarios, actualizamos para evitar cruzamiento de datos, entre un usuario y otro
      ////Cuando vamos a  una Actuacion seteamos el store
      this.$store.dispatch("sumario/resetState");
    },
  },
};
</script>
<style></style>
