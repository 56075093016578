import Vue from 'vue'
import VueRouter from 'vue-router'


// Pages
import Dashboard from '@/pages/Dashboard.vue';
import AccesoDenegado from '@/pages/AccesoDenegado.vue';
import PaginaNoEncontrada from '@/pages/PaginaNoEncontrada.vue';
import CerrandoSesion from '@/pages/CerrarSesion.vue';
// Modulos de rutas
import depositoRoutes from '@/router/depositoRoutes';
import actuacionRoutes from '@/router/actuacionesRoutes';
import parametrizacionRoutes from '@/router/parametrizacionRoutes'
import isUserRolGuard from "@/guards/isUserRolGuard";


Vue.use(VueRouter)


// Pages
const routes = [
    {
      path: '/',
      name: 'escritorio',
      component: Dashboard,
      props: { pageTitle: "ESCRITORIO" }
    },
    {
      path: '/deposito',
      name: 'deposito-efectos',
      ...depositoRoutes
    },
    {
      path:'/actuaciones',
      beforeEnter: isUserRolGuard,
      ...actuacionRoutes
    }, 
    {
      path:'/parametrizacion',
      // beforeEnter: isUserRolGuard,
      redirect:'/parametros',
    },
    {
      path:'/parametros',
      name: 'parametros',
      // beforeEnter: isUserRolGuard,
      ...parametrizacionRoutes
    }, 
    { 
      path: '/editar-contrasena',        
      name: 'cambiar-contraseña',
      component: () => import('@/pages/Contrasena.vue')      
    },
    { path: '/acceso-denegado', AccesoDenegado  , component: AccesoDenegado},
    { path: '/logout', name: 'cerrando-sesion'    , component: CerrandoSesion},
    { path: '/:pathMatch(.*)*', name: 'not-found', component: PaginaNoEncontrada },

   


  ]


const router = new VueRouter({
  routes,
  mode: 'history'
})


export default router

