const emptyDoc =  {
  instruccion: {id:'sabe_leer_sabe_firmar', nombre:'Sabe leer y sabe firmar'},
  nacionalidad: {id:1 , nombre: "ARGENTINA"},
  tipoDocumento:{'id':'DNI','nombre':'DNI'},
  exhibeDocumento:{'id': 1,'nombre':'Sí'},
  tieneEstudios:{'id': 1,'nombre':'Sí'}
}
const emptyObject = {
  list: [
    {
      arrId: uuidv4(),
      doc: { ...emptyDoc },
      identikit: [],
      descriptionOffender: [],
      identikitQuestion: 1,
      type: 'empty',
      conocido: true,
      hasIdentikit: false,
      sipeSearch: false,
      emptyTitle: ''
    },
  ],
  emptyDoc: { ...emptyDoc }
}
const state = () => ({
  ...emptyObject
})

// actions
const actions = {

  async setAcusado ({ commit, state }, params) {
    // console.log(params, state)
    let empty = params.type === 'empty'
    await commit('setAcusado', params)
    if (empty) commit('addAcusado')
  },
  async deleteAcusadoByArrId ({ commit, getters }, arrId) {
    let index = await getters.getIndexAcusadoByArrId(arrId) 
    commit('deleteAcusado', index)
  },
  async setSipeSearch ({ commit, state }, value) {
    await commit('setSipeSearch', value) 
  },
  async setEmptyTitle ({ commit, state }, value) {
    await commit('setEmptyTitle', value) 
  }
}

const getters = {
  acusadosTodos: state => {
    return state.list
  },
  getEmptyDocAcusado: state => () => {
    return state.emptyDoc;
  },
  acusadosTodosSelect: state => {
    return state.list.map(item => {
      if (item.type == 'empty') return { arrId: item.arrId, name: item.emptyTitle, type: item.type }
      if (item.filiacionDesconocida === true ) return  { arrId: item.arrId, type: item.type, name: 'Filiación Desconocida'  }
      if (!item.doc.nombre && !item.doc.apellido) return  { arrId: item.arrId,  name: 'Datos incompletos' , type: item.type , apodo:item.doc.apodo }
      else 
      if(item.doc.nombre == undefined)
        {
          return { arrId: item.arrId,  name: item.doc.apellido , type: item.type , apodo:item.doc.apodo}
          
        }else if(item.doc.apellido == undefined)
        {
          return { arrId: item.arrId,  name: item.doc.nombre , type: item.type , apodo:item.doc.apodo }
        }else
      return { arrId: item.arrId,  name: item.doc.apellido + ', ' + item.doc.nombre , type: item.type , apodo:item.doc.apodo }
    })
  },
  acusadosTodosResumen: (state, getters) => {
    return getters.acusadosTodos.filter(todo => todo.type === 'doc')
  },
  acusadosCount: (state, getters ) => {
    return getters.acusadosTodos.length - 1
  },
  getNewAcusadoArrId: (state ) => {
    return state.list[ state.list.length - 1].arrId
  },
  getAcusadoByArrId: (state) => (arrId) => {
    return state.list.find(todo => todo.arrId === arrId)
  },
  getIndexAcusadoByArrId: (state) => (arrId) => {
    return state.list.findIndex(todo => todo.arrId === arrId)
  },
  
}

// mutations
const mutations = {

  setAcusado (state, params) {
    state.list = state.list.map(n => {
      if ( n.arrId === params.arrId ) {
        params.doc.arrId = params.arrId
        params.descriptionOffender = params.descriptionOffender
        params.type = 'doc'
        return params
      }
      return n;
    })
  },
  addAcusado (state) {
    state.list = [...state.list, {
      arrId: uuidv4(),
      doc: {...emptyDoc },
      identikit: [],
      descriptionOffender: [],
      identikitQuestion: 1,
      type: 'empty',
      conocido: true,
      hasIdentikit: false,
      emptyTitle: ''
    }]
  },
  deleteAcusado (state, payload) {
    state.list = [
      ...state.list.slice(0, payload),
      ...state.list.slice(payload + 1)
    ]
  },
  setSipeSearch(state, payload){
    let acusado = state.list.find(todo => todo.arrId === payload.arrId) 
    acusado.sipeSearch = payload.sipeSearch
  },
  setEmptyTitle(state, payload){
    // console.log('state', state);
    // console.log('payload', payload);
    let acusado = state.list.find(todo => todo.type === 'empty') 
    let index = state.list.indexOf(acusado) 
    // console.log('index', index);
    if(state.list[index]?.emptyTitle != undefined ){//Este if es solo para que no surja un Warning la primera vez que se llama (ya que emptytitle aun no existe)
      state.list[index].emptyTitle  = payload
    }
  },
}


export default {
  emptyObject,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

function uuidv4(){
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}