import { DiligenciaConfig as config } from '@/pages/parametros/moduleConfig';
import NewDiligencia from '@/pages/NewDiligencia.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    {
        path: 'tipo-diligencia',
        name: 'tipo-diligencia',
        beforeEnter: isUserRolGuard,
        //component:() => import ('@/pages/parametros/tipo-diligencia.vue' ),
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    {
      path:'tipo-diligencia/edit/:id',
      component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
      beforeEnter: isUserRolGuard,
      props: { config: config.form },

    },
    {
      path:'tipo-diligencia/preview/:id',
      component:() => import ('@/pages/NewDiligencia.vue'),
      beforeEnter: isUserRolGuard,
      props: { config: config.form },
    },
    {
      path:'tipo-diligencia/new',
      name: 'tipo-diligencia-new',
      component:() => import ('@/pages/NewDiligencia.vue'),
      // component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
      beforeEnter: isUserRolGuard,
      props: { config: config.form },
    },
]
