export default [
  {
    name: "INCENDIO EN VIVIENDA",
    title: "Actuaciones Preliminares",
    url: "/actuaciones/new/preliminares-incendio-vivienda/initial"
  },
  {
    name: "ESTABLECER PROCEDENCIA",
    title: "Actuaciones Preliminares",
    url: "/actuaciones/new/preliminares-establecer-procedencia/initial"
  },
  {
    name: "FUGA DE MENOR",
    title: "Actuaciones Preliminares",
    url: "/actuaciones/new/preliminares-fuga-menor/initial"
  },
  {
    name: "SINIESTRO VIAL",
    title: "Actuaciones Preliminares",
    url: "/actuaciones/new/preliminares-siniestro-vial/initial"
  },
  {
    name: "CAÍDA CASUAL",
    title: "Actuaciones Preliminares",
    url: "/actuaciones/new/preliminares-caida-casual/initial"
  },
  {
    name: "INCENDIO AUTOMOTOR",
    title: "Actuaciones Preliminares",
    url: "/actuaciones/new/preliminares-incendio-automotor/initial"
  },
  {
    name: "TENTATIVA DE SUICIDIO",
    title: "Actuaciones Preliminares",
    url: "/actuaciones/new/preliminares-suicidio/initial"
  },
  {
    name: "OTROS",
    title: "Actuaciones Preliminares",
    url: "/actuaciones/new/preliminares-otros/initial"
  }
];
