const state = () => ({
    showReloadToast: false,
  })
  
const mutations = {
    setShowReloadToast(state, payload) {
      console.log("setShowReloadToast", payload)
        state.showReloadToast = payload;
      },
  }
  
export default {
    namespaced: true,
    state,
    // getters,
    // actions,
    mutations
  }