<template>
  <div>
    <GestorDiligencias
      :data="DataDiligences"
      :userid="useridProp"
      :DepositoArticuloID="DepositoArticuloID"
      :diligencesTemplates="diligencesTemplates"
      @add="newDiligences"
      :viewer="props.viewer"
      :rest="rest"
      :showFileUpload="showFileUpload"
      :url="props.url"
      @edit="editDiligences"
      idDiligenceName="DiligenciaID"
      idFileName="File_ArticuloDepositoID"
      apiDiligence="/diligenciadeposito/"
      apiFilesDiligences="/files/deposito-diligencias/"
      :apiFilesDiligencesFilters="apiFilesDiligencesFilters"
      :getURLForViewFile="getURLForViewFile"
    />
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import GestorDiligencias from "@/components/gestorDiligencias/GestorDiligencias.vue";
import { useRouter } from "vue-router/composables";

const props = defineProps({
  data: Object,
  useridProp: String,
  DepositoArticuloID: String,
  diligencesTemplates: Array,
  efectoData: Object,
  viewer: Boolean,
  rest: Object,
  showFileUpload: Boolean,
  url: String,
});
const DataDiligences = ref(null);
onMounted(() => {
  getDiligences();
});


const apiFilesDiligencesFilters = ref(
  `?search={"ArticuloID": [{"operator": "=", "value": "${props.DepositoArticuloID}"}]}`
);

const router = useRouter();

const newDiligences = () => {
  let newDiligencesTemplates = props.diligencesTemplates.filter(function (obj) {
    return obj.TiposumarioID === "Depósito Judicial";
  });
  router.push({
    name: "DiligenciaDeposito",
    params: {
      userid: props.useridProp,
      DepositoArticuloID: props.DepositoArticuloID,
      newDiligencesTemplates: newDiligencesTemplates,
      efectoData: props.efectoData,
    },
  });
};

const getDiligences = async () => {
  let optionsget = {
    url: "/diligenciadeposito/",
  };
  await props.rest
    .get(optionsget)
    .then((response) => {
      DataDiligences.value = response.data.data.filter(
        (diligence) => diligence.DepositoArticuloID === props.DepositoArticuloID
      );
    })
    .catch((e) => {
      console.log(e);
    });
};

const editDiligences = async (id) => {
  router.push({
    name: "DiligenciaDepositoEdit",
    params: {
      efectoData: props.efectoData,
      DepositoArticuloID: props.DepositoArticuloID,
      DiligenciaId: id,
    },
  });
};

const getURLForViewFile = (file) => {
  return `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/deposito-diligencias/${file.File_ArticuloDepositoID}${file.Extension}?viewAWS=true`;
};
</script>
