export const parametrosConfig = [    
    {
        path: '/parametros/articulos',
        title: 'ARTÍCULOS',
    },
    {
        path: '/parametros/caratula',
        title: 'CARÁTULA',
    },
    {
        path: '/parametros/categorias',
        title: 'CATEGORÍAS',
    },
    {
        path: '/parametros/departamental',
        title: 'DEPARTAMENTAL',
    },
    {
        path: '/parametros/dependencia',
        title: 'DEPENDENCIA',
    },
    {
        path: '/parametros/deposito-dependencia',
        title: 'DEPÓSITO DEPENDENCIA',
    },
    {
        path: '/parametros/efecto-denuncia',
        title: 'EFECTO DENUNCIA',
    },
    {
        path: '/parametros/estado-civil',
        title: 'ESTADO CIVIL',
    },
    {
        path: '/parametros/genero',
        title: 'GÉNERO',
    },
    {
        path: '/parametros/juzgado',
        title: 'JUZGADO',
    },
    {
        path: '/parametros/marcas',
        title: 'MARCAS',
    },
    {
        path: '/parametros/modelos',
        title: 'MODELOS',
    },
    {
        path: '/parametros/modus-operandi',
        title: 'MODUS OPERANDI',
    },
    {
        path: '/parametros/pais',
        title: 'PAÍS',
    },
    {
        path: '/parametros/persona-fisica',
        title: 'PERSONA FÍSICA',
    },
    {
        path: '/parametros/personal-fiscal',
        title: 'PERSONAL FISCAL',
    },
    {
        path: '/parametros/provincia',
        title: 'PROVINCIA',
    },
    {
        path: '/parametros/sitio',
        title: 'SITIO',
    },
    {
        path: '/parametros/sub-categorias',
        title: 'SUB-CATEGORÍAS',
    },
    {
        path: '/parametros/tipo-diligencia',
        title: 'TIPO DILIGENCIA',
    },
    {
        path: '/parametros/tipo-efecto',
        title: 'TIPO EFECTO',
    },
    {
        path: '/parametros/usuario',
        title: 'USUARIO',
    },

]