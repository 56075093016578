// COMPONENTS
import Galleria from 'primevue/galleria';
import Textarea from 'primevue/textarea';
import SelectButton from 'primevue/selectbutton';
import CustomFileUpload from '@/components/custom-fileupload';
import DinamicSelector from "@/components/TemplateComponent/DinamicSelector.vue";
import DinamicViewer from "@/components/TemplateComponent/DinamicViewer.vue";

//mixin
import formRest from "./mix/formRest.js";
import deprecatedTF from "./mix/deprecatedTF.js";
import dinamicSiis from "./mix/dinamicSiis.js";
import designForm from "./mix/designForm.js";
import eventsForm from "./mix/eventsForm.js";
import fileUploadMixin from "./mix/fileUploadMixin.js";
import imageForm from "./mix/imageForm.js";
import slotMixin from "./mix/slotMixin.js";
import validateMixin from "./mix/validateMixin.js";
import ConfirmPopupMixin from "./mix/ConfirmPopupMixin.js";
import removeOrIncludeElementsFromParams from "./mix/removeOrIncludeElementsFromParams.js";

export default {
  mixins: [
    formRest,
    deprecatedTF,
    dinamicSiis,
    designForm,
    eventsForm,
    fileUploadMixin,
    imageForm,
    slotMixin,
    validateMixin,
    ConfirmPopupMixin,
    removeOrIncludeElementsFromParams
  ],
  components: { DinamicSelector, DinamicViewer, Textarea, Galleria, CustomFileUpload, SelectButton },
}
