// composable store use toast with pinia
import { defineStore } from 'pinia';
// import { useToast } from 'vue-toast-notification';
import Vue from 'vue'
import { ref } from 'vue';

export const useSiisToast = defineStore('toast', () => {
    const toast = Vue.prototype.$toast;
    const toastQueue = ref([])

    // Si esta en la cola no lo muestra.
    // los mensajes se eliminan de la cola despues de 9 segundos
    function containsInQueue(msg) {
        if (toastQueue.value.indexOf(msg) >= 0) return false
        toastQueue.value.push(msg)
        setTimeout(() => {
            toastQueue.value.splice(toastQueue.value.indexOf(msg), 1)
        }, 9000)
        return true
    }

    function open(options){
        toast.open(options);
    }
    function verifyAndOpen(options){
        if (containsInQueue(options.message)) toast.open(options);
    }

    return {
        open,
        containsInQueue,
        verifyAndOpen
    }

})
