const emptyObject = {
  list: [
    {
      arrId: uuidv4(),
      doc: {
        newFormatEffect: true,
      },
      type: 'empty',
    },
  ],
}
const state = () => ({
  ...emptyObject
})


// actions
const actions = {
  async setRobado ({ commit }, params) {
    let empty = params.type === 'empty'
    await commit('setRobado', params)
    if (empty) commit('addRobado')
  },
  async deleteRobadoByArrId ({ commit, getters }, arrId) {
    let index = await getters.getIndexRobadoByArrId(arrId) 
    commit('deleteRobado', index)
  }
}

const getters = {
  robadosTodos: state => {
    return state.list
  },
  robadosTodosSelect: (state, getters, rootState )=> {
    let formType = rootState.sumario.formType
    return state.list.map(item => {
        if (item.type == 'empty') {
          return { arrId: item.arrId, type:item.type, name: 'Nuevo efecto', doc:{newFormatEffect: true}
          }
        }
      let name = rootState?.siis?.subcategoriasDeposito?.filter(elemento => elemento.SubcategoriaID === item.doc.SubcategoriaID)?.[0]?.Nombre;
      name = item?.doc?.tipo !== undefined ? item?.doc?.tipo : name ;
      if (name === undefined) {
        return  { arrId: item.arrId, type:item.type,  name: 'Datos Incompletos'  , tipoEfecto: item.doc.tipoEfecto, newFormatEffect: item.doc.newFormatEffect } 
      }
      return { arrId: item.arrId, type:item.type,  name: name, tipoEfecto: item.doc.tipoEfecto, newFormatEffect: item.doc.newFormatEffect }
    })
  },
  
  robadosTodosResumen: (state, getters) => {
    return getters.robadosTodosSelect.filter(todo => todo.type === 'doc')
  },
  robadosList: (state, getters) => {
    return getters.robadosTodos.filter(todo => todo.type === 'doc')
  },
  robadosCount: (state, getters ) => {
    return getters.robadosTodos.length - 1
  },
  getNewRobadoArrId: (state ) => {
    return state.list[ state.list.length - 1].arrId
  },
  getRobadoByArrId: (state) => (arrId) => {
    return state.list.find(todo => todo.arrId === arrId)
  },
  getIndexRobadoByArrId: (state) => (arrId) => {
    return state.list.findIndex(todo => todo.arrId === arrId)
  },
  
}

// mutations
const mutations = {

  setRobado (state, params) {
    state.list = state.list.map(n => {
      if ( n.arrId === params.arrId ) {
        params.doc.arrId = params.arrId
        if(params.doc.newFormatEffect === undefined){
          params.doc.newFormatEffect = true;
        }
        params.type = 'doc'
        return params
      }
      return n;
    })
  },
  addRobado (state) {
    state.list = [...state.list, {
      arrId: uuidv4(),
      doc: {newFormatEffect: true,},
      type: 'empty',
      conocido: true,
      hasIdentikit: false
    }]
  },
  deleteRobado (state, payload) {
    state.list = [
      ...state.list.slice(0, payload),
      ...state.list.slice(payload + 1)
    ]
  }
}


export default {
  emptyObject,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

function uuidv4(){
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}