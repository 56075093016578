<template>
  <div class="toolbar">
    <br>
    <div class="feature-intro title">
      <h1>Tipo Usuario</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="Tipo Usuario" 
        reference="tipo-usuario"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        :modal="modal"
        apiversion="v2"
        :keyShow="keyShow"
        :filtersFields="filtersFields"
        :disableButtonEdit="false"
        :multiSelect="true"
        :enableSelectAllCheckbox="true"
        :enableEditButtons="true"
        :enableGlobalSearch="false"
        :enableAddButton="{ enabled: true, route: '/tipo-usuario/add'}" 
      />
    </div>
  </div>  
  <!-- <div class="ml-5">
    <Button label="Seleccionar" @click="submit"/>
  </div> -->
  <!-- POR AHORA,  enableGlobalSearch REQUIERE QUE enableEditButtons SEA TRUE!!-->
  <!-- <button>submit</button> -->
</template>

<script>

  // import axios from 'axios'
  export default {
    name: 'TipousuarioList',
    props: {
      modal: {
        type: Boolean
      }
    },
    data() { 
      return {
        filteredLocation: [],
        customName: [],
        data: null,
        customColumn: [],
        idTable: 'TipoUsuarioID',
        loading:true,
        filtersFields: [
          { name: "Nombre", field: "Nombre" },
          { name: "FechaCreacion", field: "FechaCreacion" },
        ],
        keyShow: ['Nombre', 'FechaCreacion']
      }
    },
    inject: ['UserTypeTitleGrandson'],
    computed: {
      dataUserTypeTitleGrandson() {
        return this.UserTypeTitleGrandson();
      },
      url() {
        let UserTypeTitle = {
          'Usuario Judicial': 'Juzgado',
          'Usuario Policial': 'Dependencia',
          'Usuario Fiscal': 'Fiscal',
        }
        let url = process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-usuario?TipoUsuarioPadre=" + UserTypeTitle[this.dataUserTypeTitleGrandson];
        return url;
      }
    },
    methods: {
      submit(){
        let userTableData = this.$refs.tableData.getTableData()
        this.$setCookie('userTipousuario', userTableData.Nombre)
        this.$setCookie('userTipoUsuarioID', userTableData.UsuarioID)
        this.$router.push({path: '/gestionUsers'})
      },
    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .title  {
    margin-left: 30px;
  }
</style>
  
