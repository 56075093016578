<template>
  <div class="flex align-items-center justify-content-center card-container h-screen pl-2" style="background-color: #f8f9fa">
    <Card style="width: 70%" class="flex align-content-center justify-content-center vertical-align-middle h-30rem">
      <template #header>
        <MenuButtons :options="computedOptions" :title="title"/>
      </template>
    </Card>
  </div>
</template>

<script>
import MenuButtons from "./MenuButtons.vue";
import Card from "primevue/card";
import ufiOptions from "./ufiOptions.js"; // Importa las opciones para 'ufi'
import preliminaresOptions from "./preliminaresOptions.js"; // Importa las opciones para 'preliminares'

export default {
  name: "PreliminarMenu",
  props: {
    formType: String, // Defines the type of form ('ufi-delitos-especiales' or 'actuaciones-preliminares')
  },
  components: {
    MenuButtons,
    Card,
  },
  data() {
    return {
      title: "SELECCIONE UNA OPCIÓN",
    };
  },
  computed: {
    computedOptions() {
      if (this.formType === 'ufi-delitos-especiales') {
        return ufiOptions;
      } else if (this.formType === 'actuaciones-preliminares') {
        return preliminaresOptions;
      } else {
        return []; // Puedes manejar otros valores de 'formType' según tus necesidades
      }
    },
  },
};
</script>
