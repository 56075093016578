export default {
  data: () => ({
    activeDesign: [],
  }),
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    },
    mode: {
      type: String,
    },
    cards: {
      type: Boolean,
      default: true,
    },
    design: {
      type: Array, //bool que se usará para habilitar los controles de edicion

      default: () => [],
    },
    designNew: {
      type: Array, //bool que se usará para habilitar los controles de edicion

      default: () => [],
    },
    designEdit: {
      type: Array, //bool que se usará para habilitar los controles de edicion

      default: () => [],
    },
    designViewer: {
      type: Array, //bool que se usará para habilitar los controles de edicion

      default: () => [],
    },
    
    formatDoc: {
      type: Function,
    },
    
    cancelButton: {
      type: Boolean,
      default: true
    },

    enableAddFields: {
      type: Array,
      default: () => [],
    },
    enableEditFields: {
      type: Array,
      default: () => [],
    },

  },
  methods: {
    isDisableField(nameField){
      if (this.mode == 'new') {
        if (this.enableAddFields.length<1) return false;
        return this.enableAddFields.indexOf(nameField) < 0;
      }
      if (this.enableEditFields.length<1) return false;
      return this.enableEditFields.indexOf(nameField) < 0;
    },
    emptyValuesAndErrors(){
      let values = {}
      let errors = {}
      for (let i in this.params){
        // console.log("set value", i)
        //asigna un valor vacio a cada campo del objeto que va a leer el fomponente para rellenar el formulario
        values[i] = this.params[i].hasOwnProperty('default') ? this.params[i].default : null;
        errors[i] = {
          errMsg: '',
          error: false
        }
      }
      this.values = values;
      this.errors = errors;
      
      return Promise.resolve()
    },
    async setDesign(mode) {
      let designEdit = this.designEdit.length > 0 ?  this.designEdit : this.design;
      let designNew = this.designNew.length > 0 ?  this.designNew : this.design || designEdit;
      let designViewer = this.designViewer.length > 0 ?  this.designViewer : designEdit;

      mode = mode || this.mode

      let design = this.viewer ? designViewer : mode == "new" ? designNew : designEdit;
      
      design = await this.formatOldDesign(design)
      
      this.activeDesign = design;
      
      return Promise.resolve()
    },
    isVisibleComponent(nameField, typeFromView=false){
      let type = typeFromView ? typeFromView : this.params[nameField].type
      if (this.params[nameField].type != type) return false
      switch (type){
        case 'FileUpload':
          // console.log("isVisibleComponent FileUpload", nameField, this.id, this.params[nameField].upload  )
          if (
              ((!this.id || this.id == "add") && this.params[nameField].upload.enableWhenNew )
              || (this.id && this.id !== "add" && this.params[nameField].upload.enabledToUpdate !== false )
            )
          {
            return true
          } else {
            return false
          }
        case 'template':
          if (!typeFromView) return false
          return true
        default:
            return true
      }

    },
    showUploadButton(nameField){
      let uploadAndSave = this.params[nameField].upload.hasOwnProperty('uploadAndSave');
      if (uploadAndSave) return !this.params[nameField].upload.uploadAndSave
      
      return true
    },
    getLabel(fieldName) {
      return this.params[fieldName].label || fieldName;
    },
    getPlaceholder(fieldName) {
      return this.params[fieldName].placeholder || fieldName;
    },
    changeType(field){
      this.values[field] = typeof parseInt(this.values[field]) !== "number" ? 0 : parseInt(this.values[field]);
    },
    formatData(field, doc) {
      // console.log("formatData>>>>>>>>>>>>>>>>", field, doc);
      let v
      try {
        v = this.formatDoc(field, doc)
      }
      catch(e){
        // console.error("fallo formatdoc"),
        v = doc[field]
      }
      return v
    },
  }
}