export default {
    computed: {
        PreformContravencionalOficio:{
            get (){
                let dependency = 
                    this.userState.dependencia !== null && this.userState.dependencia !== undefined
                    ?
                        ' ' + this.userState.dependencia + ', '
                    :
                        ''
                let dateDenuncia = {
                    withStyles:   `<p style="color: #55b5ea ; display: inline">${this.dateDenuncia}</p>,`,
                    noStyles: this.dateDenuncia + ','
                }
                let constance = {
                    withStyles:   ` el funcionario de Policia que suscribe, a los fines legales <b style="text-decoration: underline">HACE CONSTAR:</b> `,
                    noStyles: ` el funcionario de Policia que suscribe, a los fines legales HACE CONSTAR: `,
                }
                let constanceText = {
                    withStyles:   `Que siendo la fecha y hora antes indicada, se presenta el Personal Interviniente `,
                    noStyles: `Que siendo la fecha y hora antes indicada, se presenta el Personal Interviniente `,
                }
                let factHour = {
                    withStyles: this.dataResumen.horaDelHecho1 !== undefined ? ` informando que siendo las <p style="color: #55b5ea ; display: inline">${this.dataResumen.horaDelHecho1} horas,</p>` : '', 
                    noStyles: this.dataResumen.horaDelHecho1 !== undefined ? ` informando que siendo las ${this.dataResumen.horaDelHecho1} horas,` : '',
                }
                let factScene = {
                    withStyles: this.dataResumen.lugarDelHecho !== '' ? ` se encontraban de recorridas por <p style="color: #55b5ea ; display: inline">${this.dataResumen.lugarDelHecho}</p> lugar donde observaban` : '', 
                    noStyles: this.dataResumen.lugarDelHecho !== '' ? ` se encontraban de recorridas por ${this.dataResumen.lugarDelHecho} lugar donde observaban` : '',
                }
                if(this.textEditsCounter.Preform == 0)
                {
                    let Preform = {
                        withStyles: this.Preforma.withStyles + this.department.withStyles + dependency + dateDenuncia.withStyles + constance.withStyles + constanceText.withStyles + this.interveningPersonnel.withStyles + factHour.withStyles + factScene.withStyles,
                        noStylesFirtPart : this.Preforma.noStyles + this.department.noStyles + dependency + dateDenuncia.noStyles + constance.noStyles,
                        noStylesSecondPart : constanceText.noStyles + this.interveningPersonnel.noStyles + factHour.noStyles + factScene.noStyles,
                        noStyles : this.Preforma.noStyles + this.department.noStyles + dependency + dateDenuncia.noStyles + constance.noStyles + constanceText.noStyles +this.interveningPersonnel.noStyles + factHour.noStyles + factScene.noStyles
                    };
                    return Preform
                }   else
                {   
                    let Preform = {
                        withStyles : "vacio por que no se muestra, es solo para que no de error por undefined",
                        noStyles : this.textEstaticos.Preform,
                        noStylesFirtPart : this.Preforma.noStyles + this.department.noStyles + dependency + dateDenuncia.noStyles + constance.noStyles,
                        noStylesSecondPart : this.AuxTextSecond
                    }
                    return Preform
                }
            }
        },
        Contravener:{
            get (){
                if(this.textEditsCounter.Contravener == 0)
                {
                    let Contravener = {
                        withStyles:   `Al consultarle por los datos personales manifestando llamarse `,
                        noStyles:   `Al consultarle por los datos personales manifestando llamarse `
                    }
                    let contravenerContraventional = {
                        withStyles:  this.dataResumen.acusados !== '' && this.dataResumen.acusados !== undefined ?  `<p style="color: #55b5ea ; display: inline">${this.dataResumen.acusados}</p>, procediendo al traslado a esta dependencia,` : '',
                        noStyles:    this.dataResumen.acusados !== ''  && this.dataResumen.acusados !== undefined ?  `${this.dataResumen.acusados}, procediendo al traslado a esta dependencia,` : ''
                    }
                    let hijackedItems = {
                        withStyles:  this.dataResumen.efectosDenunciados !== '' && this.dataResumen.efectosDenunciados !== undefined  ?  ` como así también del secuestro de <p style="color: #55b5ea ; display: inline">${this.dataResumen.efectosDenunciados}</p>` : '',
                        noStyles:    this.dataResumen.efectosDenunciados !== '' && this.dataResumen.efectosDenunciados !== undefined ?  ` como así también del secuestro de ${this.dataResumen.efectosDenunciados}` : ''
                    }
                    Contravener.withStyles = Contravener.withStyles + contravenerContraventional.withStyles + hijackedItems.withStyles
                    Contravener.noStyles = Contravener.noStyles + contravenerContraventional.noStyles + hijackedItems.noStyles
                    return Contravener
                }   else
                {   
                    let Contravener = {
                        noStyles : this.textEstaticos.Contravener
                    }
                    return Contravener
                }
            },
        },     
        LegalData:{
            get (){
                if(this.textEditsCounter.LegalData == 0){
                    let LegalData = {
                        withStyles:   `<b style="text-decoration: underline">DISPONE:</b> Iniciar en base a la presente Acta las Actuaciones Contravencionales de rigor. Designar Secretario al Oficial que al pie firma en notificación del cargo conferido. Recibir a tenor el `,
                        noStyles:   `DISPONE: Iniciar en base a la presente Acta las Actuaciones Contravencionales de rigor. Designar Secretario al Oficial que al pie firma en notificación del cargo conferido. Recibir a tenor el `
                    }
                    let article = {
                        withStyles:  this.dataResumen.listaArticulos !== '' ? `<div style="display: inline ; color: red">${this.dataResumen.listaArticulos}</div>` : '',
                        noStyles:   this.dataResumen.listaArticulos !== '' ? this.dataResumen.listaArticulos : ''
                    }
                    let contraventional = {
                        withStyles:  ', el acta Contravencional labrada in situ, así también al contraventor antes mencionado. Dar inmediata intervención al Señor Juez del ',
                        noStyles:   ', el acta Contravencional labrada in situ, así también al contraventor antes mencionado. Dar inmediata intervención al Señor Juez del '
                    }
                    let court = {
                        withStyles: this.dataResumen.juzgado !== '' && this.dataResumen.juzgado !== undefined  ? `<div style="display: inline ; color: red">${this.dataResumen.juzgado}</div>` : '',
                        noStyles:   this.dataResumen.juzgado !== '' && this.dataResumen.juzgado !== undefined ? this.dataResumen.juzgado : ''
                    }
                    let certify = {
                        withStyles: `, Sr. Jefe de Policia (Dpto. Jud.D-5) mediante remisión de copias nota de Estilo. Llevar adelante toda otra medida que se estime de interés para la sustanciación del presente expediente. CERTIFICO.`,
                        noStyles:   `, Sr. Jefe de Policia (Dpto. Jud.D-5) mediante remisión de copias nota de Estilo. Llevar adelante toda otra medida que se estime de interés para la sustanciación del presente expediente. CERTIFICO.`
                    }
                    LegalData.withStyles = LegalData.withStyles + article.withStyles + contraventional.withStyles + court.withStyles + certify.withStyles
                    LegalData.noStyles = LegalData.noStyles + article.noStyles + contraventional.noStyles + court.noStyles + certify.noStyles
                    return LegalData
                }   else{
                    let LegalData = {
                        noStyles:  this.textEstaticos.LegalData
                    }
                    return LegalData
                }
            },
        },   
        actOfStartContravencionalOficio:{
            get (){
                let actOfStart = this.PreformContravencionalOficio.noStyles + ' ' + this.deposition + ' ' + this.Contravener.noStyles + ' ' + this.LegalData.noStyles
                return actOfStart
            }
        }
    }    
};