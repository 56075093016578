<template>
  <div>
    <ConfirmPopup />
  </div>
</template>

<script>
import ConfirmPopup from 'primevue/confirmpopup';

export default {
  name: 'ConfirmPopupSeted',
  components: {
    ConfirmPopup,
  },
  // data() { 
  //   return {
  //   }
  // },
  props: {
    event: {
      type: HTMLButtonElement,
    },
    message: {
      type: String
    },
    idToDelete: {
      type: String,
      default: ''
    }
  },
  // mounted() {
  // },
  methods: {
    deleteConfirmation() {
      this.$confirm.require({
        target: this.event,
        group: "demo",
        message: this.message,
        icon: "pi pi-question-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Sí",
        rejectLabel: "No",
        accept: () => {
          this.$emit('deleteConfirmed', this.idToDelete);
        },
        reject: () => {
        },
      });
    },
  }
}
</script>
<style scoped>
  
</style>