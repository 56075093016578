import { GeneroConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/genero'
        path: 'genero',    
        name: 'genero',   
        component:  ()=> import('@/pages/parametros/TemplateView.vue'),
        beforeEnter:isUserRolGuard, 
        props: { config: config.templateTable },
    },
    { //'parametros/genero/new'
        path: 'genero/new',
        name: 'genero-new',
        component:  ()=> import('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter:isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/genero/edit/:id'
        path: 'genero/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter:isUserRolGuard,
        props: { config: config.form },
    },
]
     
