export const ufiSuicidioConfig = [
    {
        path: "/actuaciones/new/ufi-suicidio/initial",
        pathName: "ufi-suicidio",
        titulo: "UFI - AHORCAMIENTO O SUICIDIO",
        props: { formType: "ufi-ahorcamiento-suicidio", actuacionType: "ufi-suicidio" },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-suicidio/initial/denunciante",
        pathName: "ufi-suicidio",
        titulo: "DAMNIFICADO / FALLECIDO",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-suicidio/initial/momento",
        pathName: "ufi-suicidio",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },      
      {
        path: "/actuaciones/new/ufi-suicidio/initial/efectos",
        pathName: "ufi-suicidio",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },

]