export default {
  data: function (){
    return {
      order: {
        DenunciaFechacreacion: {
          field: 'exp_pre_Denuncia.Fechacreacion'
        },
        Numero: {
          field: 'exp_pre_Actuacion.Numero'
        }
      },
      orderColumns: [

      ]
    }
  },
  methods: {
    setOrderBy(field){
      console.log("setOrderBy", field)
      let order = {...this.order}
      if (!order[field].mode) {
        order[field] = {
          mode: false,
          icon: "pi pi-sort-alt"
        }
      }

      switch (order[field].mode) {
        case false:
          order[field].mode = "ASC"
          order[field].icon = "pi pi-sort-alpha-up"
        break;
        case "ASC":
          order[field].mode = "DESC"
          order[field].icon = "pi pi-sort-alpha-down-alt"
        break;
        case "DESC":
          order[field].mode = false
          order[field].icon = "pi pi-sort-alt"
        break;
      }
        
      this.setOrder(order)
      console.log("this.order[field]", order[field])
    },
    async setOrder(data){
      this.order = data
      let orderArr = []
      
      for (let k in data){
        console.log("k", k, data[k])
        if (!data[k].mode) continue;
        let field = data[k].field || k
        orderArr.push(`${field} ${data[field].mode}`)
      }

      let order = orderArr.length > 0 ? orderArr.join(",") : null;
      console.log("orderArr", order, orderArr)
      await this.$store.dispatch("sumarioList/setOrder", order);
      this.getData()

    }

  },

}