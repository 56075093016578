<template>
  <div v-if="totalRows"  class="flex justify-content-between pagination">
    <!-- <Button
        v-if="page != 1"
        icon="pi pi-angle-double-left"
        label="Anterior"
        class="p-button-text"
        @click="$emit('setPage', 'back')"
      /> -->
      <p>Registros {{offset}} a {{lastRow}} de {{totalRows}}</p>
      <div class="flex align-items-center">

        <p class="ml-2">Paginas: </p>
        <ul  class="pl-2">
          <li v-for="i in pages" :key="i" @click="$emit('setPage', i)" :class="{ active: page == i }">{{i}}</li>
        </ul>
      </div>

        

      <!-- <Button
        label="Siguiente"
        icon="pi pi-angle-double-right"
        iconPos="right"
        class="p-button-text"
        @click="$emit('setPage', 'next')"
      /> -->
  </div>
</template>
<script>
export default {
  props: {
    page: {
      type: Number
    },
    totalRows: {
      type: Number
    },
    offset: {
      type: Number
    },
    limit: {
      type: Number
    },
  },
  computed: {
    lastRow: {
      get(){
        let last = parseInt(this.limit) + parseInt(this.offset)
        return last > this.totalRows ? this.totalRows : last
      }
    },
    pages: {
      get(){
        return [...Array(Math.ceil(parseInt(this.totalRows) / parseInt(this.limit))).keys()].map(i => i+ 1)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.pagination {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  align-items: center;
}
ul {
  list-style: none;
  display: flex;
  align-items: center;
}
li {
  margin-right: 10px;
  cursor: pointer;
}
.active {
  font-size: 1.5em;
}
</style>
