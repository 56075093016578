
export default {

    
    methods: {
        dateFromString(date){
            let dateTimeParts = date.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
            dateTimeParts[1]--;
            return new Date(...dateTimeParts)
        },
        date2Es(date, time){
            if (typeof date == 'string') date = this.dateFromString(date)
            if (time) return date.toLocaleString('es-AR')
            return date.toLocaleDateString('es-AR');

        },
        esCalendar2Sql(date){
            let sqlDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            return `${sqlDate} ${date.getHours()}:${date.getMinutes()}`
        },
        //El siguiente formato se establecio para la Fecha Hasta del filtro Fecha de Creacion, ya que si no tiene hora y minuto 23:59, solo busca hasta las 00:00
        esCalendar2SqlFechaHasta(date){
            let sqlDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            return `${sqlDate} 23:59`;
        },
        /**
         * fecha para asignarle a la tabla usuario sumario
         */
        actualDate(){
            let fechaYhoraActual = new Date();
            let fecha = fechaYhoraActual.toISOString().slice(0, 10).replace("T", " ");
            //separamos la hora y le sumamos +3 horas que se restan por la zona horaria
            let dia = new Date(fechaYhoraActual);
            let hora =
              dia.getHours() + ":" + dia.getMinutes() + ":" + dia.getSeconds();
            //unimos hora y fecha
            fechaYhoraActual = fecha + " " + hora;
            return fechaYhoraActual;
        },
        generateDate(data) {
            if (typeof data == 'string') data = new Date(data);
            if(data === null ) return '';
            var fecha = data.toISOString().slice(0, 10).replace('T', ' ');
            var dia = new Date(data);
            var hora = (dia.getHours()) + ':' + dia.getMinutes() + ':' + dia.getSeconds();
            var fechaYhora = fecha + ' ' + hora;;
            return fechaYhora;
        },
    }

};