<template>
    <SimpleCard id="card" class="boxes">
      <template v-slot:content class="">

         <div v-for="(rows , index) in desing" :key="index" class="p-fluid formgrid grid" >
          <div v-for="(nameField , indexField) in rows" :key="indexField" class="field col" :class="{'md:col-4':true}" >

            <!-- <label for="firstname">{{nameField}}</label> -->
            <!-- <InputText v-if="enableField(nameField)" :class="{'input': true , 'p-invalid': errors[nameField]}" type="text" min="0" :placeholder="nameField" v-model="names[nameField]" /> -->

            <h4>{{names[nameField]}}</h4>

            <!-- <Calendar v-if="!enableField(nameField)" :class="{'input': true , 'p-invalid': errors[nameField]}"  placeholder="Hasta fecha" dateFormat="yy-mm-dd" v-model="names[nameField]" /> -->
            <!-- <label  for="name" :class="{'visible': errors[nameField], 'disable': !errors[nameField]}">{{mensajes[nameField]}}</label> -->
            
          </div>  
          
          <div class="actions mt-3" v-if="timer" >
                <Button label="Eliminar" icon="pi pi-trash" class="md:col-4 p-button-text"  @click="deleteItem(index)" style="color:Red"/>
          </div> 
           <div :class="{'spinner':timer, 'ml-auto ':true, 'md:col-2':true}" >
                  <ProgressSpinner style="width:50px;height:50px" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s"/>        
          </div> 
        </div>

      </template>        
    </SimpleCard>
</template>

<script>

import axios from 'axios'

export default {
  name: 'TempleteEditComponent',
  data() { 
        return {
            names:'',
            timer:true,
            idPermiso:'',
        }
  },

  props: {
        data:{
        type: Object
        },
        nombres: {
        type: Object //string que se usará como título de la tabla
        },
        errors: {
        type: Object //string que se usará como título de la tabla
        },
        mensajes: {
        type: Object //string que se usará para el http request
        },
        desing: {
        type: Array //bool que se usará para habilitar los controles de edicion
        },
        url: {
        type: String //string que se usará para habilitar la barra de busqueda global
        },
        id: {
        type: String 
        },
    },

async created()
{
    this.timer = false
    let resp = await axios.get(this.url+'/'+this.id , { withCredentials: true }); //recuperamos el dato de permiso tipo usuario
    // console.log("LA RESPUESTA ES: "+JSON.stringify(resp.data.data))
    this.idPermiso= resp.data.data[0].PermisousuarioID // guardamos su id
    // console.log("this.id", this.idPermiso)
    const { Fechainivig } = resp.data.data
    
    let resp2 = await axios.get(process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/permiso-usuario/"+this.idPermiso , { withCredentials: true }) //recuperamos la informacion de ese permiso
    // console.log("SEGYBDA RESPUESTA: "+ JSON.stringify(resp2));
    this.names = resp2.data.data[0]
    this.names.Fechainivig = Fechainivig  
    this.timer = true
    // console.log(JSON.stringify(this.names))
},

   methods:
   {
    
        async deleteItem(index){
            this.timer = false

            let resp = await axios.delete(this.url+'/'+this.id, { withCredentials: true })
            // console.log("respuesta al eliminar: "+JSON.stringify(resp))
            if(resp.data.error == '')
            {
                // this.actualizarPermisos()
                // this.names.splice( index, 1 );
                this.$emit(`probandoFuncion`);
            }else
            {
                alert("Hubo un problema al eliminar el permiso")
                this.timer = true

            }
            
        },

   }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .boxes{
        width: 90%;
        /* margin-left: 5%; */
    }
    .title  {
        margin-left: 30px;
    }

    .flex-inline {
        width: 90%;
        /* margin-left: 5%; */
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* margin: 20px 0 20px 8px; */
    }
    .formulario{
        margin: 20px 0 0 10px;
        width: 20rem;
    }
    .disable{
        display: none;
    }
    .visible{
        color: #dc3545;
        text-align: center;
    }
    .spinner{
        display: none;
    }
</style>
