import { ArticulosConfig as config } from '@/pages/parametros/moduleConfig';
import TemplateView from '@/pages/parametros/TemplateView.vue'
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/articulos'
        path: 'articulos', 
        name: 'articulos',  
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.templateTable },
    },
    { //'parametros/articulos/new'
        path: 'articulos/new',
        name: 'articulos-new',
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/articulos/edit/:id'
        path: 'articulos/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },

    },
]

