<template class="flex justify-content-center align-items-center bglogin" >
  <div class="flex justify-content-center align-items-center bglogin">
    <Card class="cardLogin">
      <template #header>
        <div class="flex justify-content-center">
          <div class="flex" test-id="ingreso">
            <div v-if="MailAvailable" class="ingt">Ingreso</div>
            <div v-if="!MailAvailable" class="ingt">Validación de Identidad</div>
            <img
              class="logologin"
              alt="Logo Policia"
              src="../assets/logo-policia-de-san-juan.png"
            />
          </div>
        </div>
      </template>

      <template #content>
        <div class="p-fluid formgrid grid" v-if="!loginSuccess">
          <div class="field col-12 md:col-12" v-if="MailAvailable">
            <label class="label">Usuario</label>
            <InputText
              id="inputuser"
              test-id="campo-nombre-de-usuario"
              v-model="dataUsername"
              @keyup="cleaner"
              @keyup.enter="validations"
              :class="{ 'p-invalid': userError }"
            />
          </div>
          <div class="field col-12 md:col-12" v-if="!MailAvailable">
            <label class="label">Ingrese su DNI</label>
            <InputText
              type="number"
              id="inputDNI"
              test-id="campo-dni"
              v-model="dni"
              @keyup="cleaner"
              @keyup.enter="validations"
              :class="{ 'p-invalid': dniError }"
            />
          </div>
          <div class="field col-12 md:col-12" >
  
  <label class="label">Contraseña</label>
  <div style="display: flex; align-items: center;">
    <Password :feedback="false" 
     toggleMask
    id="inputpass"
    v-model="contrasena"
    test-id="input_password"
    @keyup="cleaner"
    @keyup.enter="validations"
    :class="{ 'p-invalid': passError }"
    />
  
</div>
</div>
        </div>
        <div v-else class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label class="label">Codigo de autentificación</label>
            <InputText
              id="inputAuthCode"
              ref="verificationCodeInput"
              test-id="campo-de-codigo-autentificacion"
              v-model="verificationCode"
              :disabled="!reSendCode"
              @keyup="cleaner"
              @keyup.enter="validations"
              :class="{ 'p-invalid': codeError }"
            />
          </div>
        </div>
        <p test-id="mensaje-error" style="color: #dc3545" v-if="error">
          {{ error }}
        </p>
        <p style="color: #dc3545" v-if="err && cleanError">{{ errorMsg }}</p>
        <p style="color: #dc3545" v-if="!online">
          El sistema se encuentra actualmente funcionando sin conexion a
          internet. Es posible que al restablecerse el servicio de internet le
          vuelva a pedir la contraseña.
        </p>
      </template>
      <template #footer>
        <Button
          id="botonlogin"
          test-id="button_login"
          icon="pi pi-check"
          label="Iniciar sesión"
          @click="validations"
          v-if="!loginSuccess && MailAvailable"
        />
        <div class="flex flex-column w-9rem"  v-if="loginSuccess && MailAvailable">
          <div class="flex flex-row w-14rem">
            <Button 
              id="botontoken"
              test-id="button_token"
              label="Reenviar codigo"
              :disabled="reSendCode"
              class="p-button-info p-button-text pl-0 "
              @click="sendCode"
            />
            <Timer :intensity="300" :duration="timeOut" v-if="reSendCode">
              <!-- <template #before-time>
                <p>Espera: </p>
              </template> -->
            </Timer>
          </div>
          <div class="flex flex-row  w-14rem">
            <Button
              id="botonsendtoken"
              test-id="button_send_token"
              label="Continuar"
              class="w-8rem mt-3"
              @click="validations('auth')"         
            />
            <ProgressSpinner
              :class="{ spinner: timer, 'ml-6': true, 'mt-auto': true }"
              style="width: 40px; height: 40px"
              strokeWidth="5"
              fill="#EEEEEE"
              animationDuration="1.5s"
            />
          </div>
        </div>
        <div class="flex flex-column w-9rem"  v-if="!loginSuccess && !MailAvailable">
      
          <div class="flex flex-row  w-14rem">
            <div class="field col-12 md:col-12" style="display: flex; align-items: center;">
 
            <Button
              id="botonsDNI"
              test-id="button_dni"
              label="Validar Identidad"
              class="w-8rem mt-3"
              icon="pi pi-shield"
              @click="validations('DNI')"         
            />
          </div>
            <div>{{ `Intentos restantes: ${MaxAttemptsDNI-countWrongDni}` }}</div>    
            <ProgressSpinner
              :class="{ spinner: timer, 'ml-6': true, 'mt-auto': true }"
              style="width: 40px; height: 40px"
              strokeWidth="5"
              fill="#EEEEEE"
              animationDuration="1.5s"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Timer from "../components/Timer.vue"

import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Password from 'primevue/password';

export default {
  name: "LoginPage",
  data() {
    return {
      // tiempo de espera para volver a pedir mail con token de seguridad
      timeOut : 2,
      username: "",
      password: "",
      dni:"",
      code:"",
      MaxAttemptsDNI: 2,
      error: "",
      userError: false,
      passError: false,
      codeError: false,
      errorMsg: "No existe usuario con esa contraseña",
      cleanError: false,
      reSendCode:true
    };
  },
  components: {
    Card,
    InputText,
    Button,
    Password,
    Timer
  },
  props: {
    online: Boolean,
  },
  computed: {
    ...mapGetters({
      loginSuccess : "user/loginSuccess",
      errorLogin : "user/errorLogin",
      errorDni : "user/errorDni",
      countWrongDni : "user/countWrongDni",
      MailAvailable : "user/MailAvailable",
    }),
    ...mapState({
      err: (state) => state.user.err,
      tipoUsuario: (state) =>state.user.userdata.tipoUsuario
    }),
    dataUsername: {
      get() {
        return this.username;
      },
      set(value) {
        // console.log(value);
        this.username = value;
      },
    },
    contrasena:{
      get(){
        return this.password;
      },
      set(value){
        this.password = value.trim()
      }
    },
    timer: {
      get() {
        return this.$store.state.user.timer;
      },
    },
    verificationCode: {
      get() {
        return this.code;
      },
      set(value) {
        //NaN and .length > 4 validations
        if(isNaN(parseInt(value[value.length - 1])) || value.length > 4){
          value = value.slice(0, -1);
          this.$refs.verificationCodeInput.$el.value = value;
        }
        this.code = value;
        return value;
      },
    },

  },
  created() {
    this.$store.dispatch("user/checkLogin",  {VueCli: this});
    if(this.$store.state.user.logged){      
      this.$router.push("/");
    }
  },
  watch: {
    username(nv){
      // console.log('search nv', nv)
      // setOfflineMode
      if (nv == 'test offline true'){
        this.$offline.setOfflineMode(true)
      } else if (nv == 'test offline false'){
        this.$offline.setOfflineMode(false)
      }
    }
  },
  methods: {
    async login() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      this.$store.dispatch("user/clearErrorLogin");
      this.$store.dispatch("user/clearErrorDni");
      try {
      if(!this.MailAvailable){
        await this.$store.dispatch("user/login", {
          username: this.dataUsername,
          password: this.password,
          dni: this.dni
        });
      }else{
        await this.$store.dispatch("user/login", {
          username: this.username,
          password: this.password,
          code: this.code
        });
        
        if(this.codeError){
          this.errorType(
            "errorBD",
            "El codigo no es correcto"
          );
        }
      }
      if (this.errorDni ) {
        console.log("errorDni",this.errorDni);
          this.errorType(
            "error",
            "El dni o la contraseña no son correctos"
          );
        }
       else if (this.errorLogin) {
          this.errorType(
            "error",
            this.errorLogin
          );
        } else 
        if (this.errorLogin) {
          this.errorType(
            "error",
            this.errorLogin
          );
        } else 
        if (this.errorLogin) {
          this.errorType(
            "error",
            this.errorLogin
          );
        } else {
          if (params.has("closeTab")) {
            window.close();
          }else
          {
            if (this.$route.path !== "/") this.$router.push("/");
          }
        }
        this.reSendCode = true;
        setTimeout(()=>{
          this.reSendCode = false;
          if(this.loginSuccess === true) 
            this.error = 'Código vencido';
          this.code = "";
        },this.timeOut*60000)
        // },this.timeOut*6000)
      } catch (e) {
        console.error("login error", e);
      }
    },
    
    async validations(type) {
      this.error = "";
      if (this.countWrongDni>=this.MaxAttemptsDNI){
      this.$store.dispatch("user/toastWrongDni");
      this.$store.dispatch("user/clearUserState");
      return
      }
      if(type == "auth"){
        if (this.code.length!=4){
          this.errorType(
            "codeError",
            "El codigo debe contenter 4 digitos"
          );
        }else{
          this.login();
        }
        return
      }
      if(type == "DNI"){
        console.log("DNI",this.dni);
        if (this.dni.length < 3) {
        this.errorType(
          "userError",
          "El dni debe tener al menos 3 caracteres"
        );
      }
      else if (this.dni.length > 50) {
        this.errorType(
          "userError",
          "El dni no debe tener más de 50 caracteres"
        );
      }else{
          this.login();
        }
        return
      }
      if (this.username.length < 3) {
        this.errorType(
          "userError",
          "El nombre de usuario debe tener al menos 3 caracteres"
        );
      }
      if (this.username.length > 50) {
        this.errorType(
          "userError",
          "El nombre de usuario no debe tener más de 50 caracteres"
        );
      }
      if (!this.error) {
        await this.login();
      }
     
    },
   async sendCode(){ 
      this.code="";
    await  this.login();
      this.timeOut=0
      setTimeout(()=>{
        this.timeOut=2

      },1000)      
    },
    errorType(type, msg) {
      this[type] = true;
      this.error = msg;
    },
    cleaner() {
      this.error = "";
      this.cleanError = false;
      this.userError = false;
      this.passError = false;
      this.dniError = false;
      this.codeError = false;
    },
    validatePassword(){
      if (this.password.length < 3) {
        this.errorType(
          "passError",
          "La contraseña debe tener al menos 3 caracteres"
        );
      }
      if (this.password.length > 50) {
        this.errorType(
          "passError",
          "La contraseña no debe tener más de 50 caracteres"
        );
      }
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logologin {
  max-width: 200px;
}
.cardLogin {
  padding-top: 30px;
  max-width: 400px;
  /* height: 300px; */
}
.ingt {
  padding-top: 4px;
  font-size: 25px;
  font-weight: bold;
}
.bglogin {
  background-color: #f8f9fa;
  min-height: calc(100vh - 70px);
}
.spinner {
  display: none;
}
</style>
