import { GestorArchivosConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import addEditArchivo from '@/pages/parametros/Biblioteca/add-edit-archivo.vue';
import gestorDeArchivos from '@/pages/parametros/Biblioteca/gestor-de-archivos.vue';
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/categorias'
        path: 'gestor-archivos',
        name: 'gestor-archivos',
        beforeEnter: isUserRolGuard,
        // component:   () => import ('@/pages/parametros/pais.vue'),
        component:   () => import ('@/pages/parametros/Biblioteca/gestor-de-archivos.vue'),
        // props: { config: config.templateTable },
    },
    { //'parametros/categorias/new'
        path: 'gestor-archivos/new',
        name: 'gestor-archivos-new',
        component:  () => import ('@/pages/parametros/Biblioteca/add-edit-archivo.vue'),
        beforeEnter: isUserRolGuard,
        // props: { config: config.form },
    },
    { //'parametros/categorias/edit/:id'
        path: 'gestor-archivos/edit/:id',
        component:  ()=> import('@/pages/parametros/Biblioteca/add-edit-archivo.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]
     
