<template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">
      <div class="feature-intro">
        <h1>Nuevo {{ localConfig.pageName }}</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
    </div>
    <ProgressSpinner
      v-if="!showTForm"
      style="width: 50px; height: 50px"
      strokeWidth="5"
      fill="#EEEEEE"
      animationDuration="1.5s"
    />
    <TForm
      v-if="localConfig && showTForm"
      :config="config"
      :data="localConfig.data"
      :designNew="localConfig.design"
      :dropdown="localConfig.dropdown || {}"
      :errors="localConfig.errors"
      :labels="config.data.labels || {}"
      :mensajes="localConfig.mensajes"
      mode="new"
      :nombres="localConfig.names"
      :placeholder="localConfig.placeholder || {}"
      :url="localConfig.url"
      :viewer="viewer"
      :viewerID="viewerID"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    viewer: { type: Boolean, default: false },
    viewerID: { type: String, default: "" },
    config: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showTForm: false,
    //   localConfig: this.config
    //     ? JSON.parse(JSON.stringify(this.config.data))
    //     : {
    //         data: {},
    //         names: {},
    //         labels: {},
    //         placeholder: {},
    //         errors: {},
    //         mensajes: {},
    //         design: [],
    //         url: "",
    //         dropdown: {},
    //       },
    };
  },
  async created() {
    await this.$store.dispatch("siis/setMarca");
    await this.$store.dispatch("siis/setSubCategoriasDeposito");
    await this.$store.dispatch("siis/setCategoriasDeposito");
    await this.$store.dispatch("siis/setTipoDeposito");
    await this.$store.dispatch("siis/setDepartamentales");
  },
  async mounted() {
    if (this.localConfig.dropdown) {
      if (this.localConfig.dropdown.id_categoria) {
        this.localConfig.dropdown.id_categoria.changeDropdown = this.handleCategoriaChange;
      }
      if (this.localConfig.dropdown.id_subcategoria) {
        this.localConfig.dropdown.id_subcategoria.changeDropdown = this.handleSubcategoriaChange;
      }
      if (this.localConfig.dropdown.id_marca) {
        this.localConfig.dropdown.id_marca.refreshDropdown = this.refreshMarcaDropdown;
      }
      if (this.localConfig.dropdown.DepartamentalID) {
        this.localConfig.dropdown.DepartamentalID.refreshDropdown = await this.$store.dispatch("siis/setDepartamentales", true);
        this.localConfig.dropdown.DepartamentalID.options = this.$store.state.siis.departamentales
      }
    }
    this.showTForm = true;
  },
  computed: {
    ...mapState({
      marca: (state) => state.siis.marca,
      subcategorias: (state) => state.siis.subcategoriasDeposito,
      categorias: (state) => state.siis.categoriasDeposito,
      tipo: (state) => state.siis.tipoDeposito,
      departamentales: (state) => state.siis.departamentales,
    }),
    localConfig(){
      if(this.config){
        return this.config.data;
      } else{
        return {
          data: {},
          names: {},
          labels: {},
          placeholder: {},
          errors: {},
          mensajes: {},
          design: [],
          url: "",
          dropdown: {},
        };
      }
    }
  },

  watch: {
    subcategorias(updatedValue) {
      if (updatedValue && this.localConfig.dropdown && this.localConfig.dropdown.id_subcategoria) {
        this.localConfig.dropdown.id_subcategoria.options = updatedValue;
      }
    },
    categorias(updatedValue) {
      if (updatedValue && this.localConfig.dropdown && this.localConfig.dropdown.id_categoria) {
        this.$set(this.localConfig.dropdown.id_categoria, "options", updatedValue);
      }
    },
    marca(newValue) {
      if (
        newValue &&
        this.localConfig &&
        this.localConfig.dropdown &&
        this.localConfig.dropdown.id_marca
      ) {
        this.localConfig.dropdown.id_marca.options = newValue;
      }
    },
  },
  methods: {
    handleCategoriaChange(value) {
      this.localConfig.dropdown.id_subcategoria.options = this.subcategorias.filter(
        (item) => item.id_categoria === value
      );
    },
    handleSubcategoriaChange(value) {
      this.localConfig.dropdown.id_tipo.options = this.tipo.filter(
        (item) => item.id_subcategoria === value
      );
    },
    refreshMarcaDropdown() {
      this.$store.dispatch("siis/setMarca", true);
    },
  },
};
</script>
