<template>
  <div class="page">
    <div>
      <FormAcusados
        @onSave="onSave"
        :formType="this.formType"
        :viewData="viewData"
        :actuacionType="this.actuacionType"
      />
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'

import FormAcusados from '../../components/sumario/FormAcusados.vue';
import { mapState, mapGetters } from "vuex";
import Icon from "../../components/Icon";
import { defineAsyncComponent } from "vue";

export default {
  name: "SumarioDenunciaFormAcusado",
  components: {
    Icon,
    FormAcusados: defineAsyncComponent({
      loader: () => import("../../components/sumario/FormAcusados.vue"),
      // loadingComponent: LoadingComponent,
      // errorComponent: ErrorComponent,
      delay: 200, // Tiempo antes de mostrar el componente de carga
      timeout: 30000, // Tiempo de espera antes de considerar la carga fallida
    }),
  },
  props: {
    actuacionType: String, //Defines the type of form
  },
  computed: {
    ...mapState({
      formType: (state) => state.sumarioViews.actuacion,
    }),
    ...mapGetters({
      viewData: "sumarioViews/getViewData",
    }),
  },
  data() {
    return {
      filteredLocation: [],
    };
  },
  methods: {
    onSave() {
      if (this.formType === "denuncia") {
        // console.log("push denuncia")
        this.$router.push("actuaciones/sumario-denuncia/initial-data");
      }
      if (this.formType === "oficio") {
        // console.log("push oficio")
        this.$router.push("/sumario/denuncia/formulario/oficio");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feature-intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 20px;
}
.feature-intro > h1 {
  width: 150%;
  font-size: 1.5rem;
  padding-bottom: 0px;
  margin: 0px;
}
.feature-intro > p {
  font-size: 1.5rem;
  padding-top: 0px;
}
.feature-intro > i {
  align-items: flex-start;
  height: 100%;
}
</style>
