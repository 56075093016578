export default [
  {
    name: "ESTABLECER PARADERO",
    title: "UFI - Actuaciones por personas extraviadas",
    url: "/actuaciones/new/ufi-establecer-paradero/initial",
  },
  {
    name: "EMERGENCIAS MÉDICAS",
    title: "UFI - Actuaciones por fallecimiento",
    url: "/actuaciones/new/ufi-emergencias-medicas/initial",
  },
  {
    name: "SINIESTRO VIAL",
    title: "UFI - Actuaciones por fallecimiento",
    url: "/actuaciones/new/ufi-siniestro-vial/initial",
  },
  {
    name: "AHORCAMIENTO O SUICIDIO",
    title: "UFI - Actuaciones por fallecimiento",
    url: "/actuaciones/new/ufi-suicidio/initial",
  },
  {
    name: "INCENDIO EN VIVIENDA",
    title: "UFI - Actuaciones por fallecimiento",
    url: "/actuaciones/new/ufi-incendio-vivienda/initial",
  },
  {
    name: "INTOXICACIÓN",
    title: "UFI - Actuaciones por fallecimiento",
    url: "/actuaciones/new/ufi-intoxicacion/initial",
  },
];
