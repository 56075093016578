export default {
  data: () => ({
    isDisable: true,
    displayBasic: false,
    modalField: false,
  }),
  methods: {
    
    openBasic(modalField) {
      this.modalField = modalField;
      // console.log("el modalField es: ", this.modalField)
      this.displayBasic = true;
    },
    async closeBasic() {
      this.displayBasic = false;

      //En el caso particular que se trate de 'Sumario', refrescamos la vista
      // console.log("el modalField en closeBasic es: ", this.modalField)
      if(this.modalField == 'sumario'){
        await this.params.sumario.dataTable.refresh()
      }


    },
    
    selectItem(itemId) {
      // console.log("selecciono aca >>>", itemId)
      this.values[this.modalField] = itemId;
      this.isDisable = false;
      setTimeout(() => {
        this.isDisable = true;
      }, 50);
      // console.log("sele i22222333333->>>", this.modalField, this.values[this.modalField])
      this.closeBasic();
    },
  },
}