import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Avatar from 'primevue/avatar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Card from 'primevue/card';
import AutoComplete from 'primevue/autocomplete';
// import CustomAutoComplete from './components/custom-autocomplete/CustomAutoComplete.vue';
import Icon from './components/Icon.vue';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
// import Editor from 'primevue/editor';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import SelectButton from 'primevue/selectbutton';
import TreeTable from 'primevue/treetable';
import ProgressSpinner from 'primevue/progressspinner'
import Message from 'primevue/message';
import ProgressBar from 'primevue/progressbar';
import Slider from 'primevue/slider';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from 'primevue/confirmpopup';
// import FormDatosLegales from './components/forms/FormDatosLegales.vue';
// import DashboardTopMenu from './components/Dashboard/DashboardTopMenu.vue';
// import DashboardBody from './components/Dashboard/DashboardBody.vue';
import Toolbar from './components/Dashboard/DashboardTopMenu.vue';
// import Peoples from './components/Peoples.vue';
import SimpleCard from './components/SimpleCard.vue';
// import MyMap from './components/MyMap.vue';
// import TextareaNestor from './components/TextareaNestor.vue';
// import InputNestor from './components/InputNestor.vue';
// import EditorNestor from './components/EditorNestor.vue';
// // import TextareaNestorDiv from './components/TextareaNestorDiv.vue';
import Tag from 'primevue/tag';
// import FormUbicacion from './components/forms/FormUbicacion.vue';
// import ResumenCard from './components/ResumenCard.vue';
// import FormMomento from './components/forms/FormMomento.vue';
// import FormLugarMomento from './components/forms/FormLugarMomento.vue';
// import FormPersonalInterviniente from './components/forms/FormPersonalInterviniente.vue';
// import FormObjetos from './components/forms/FormObjetos.vue';
// import FormAcusados from './components/sumario/FormAcusados.vue';
// import FormDenunciantes from './components/sumario/FormDenunciantes.vue';
// import SumarioFormRobados from './components/sumario/SumarioFormRobados.vue';
import OverlayPanel from 'primevue/overlaypanel';
import Menu from 'primevue/menu';
import TabMenu from 'primevue/tabmenu';
import Tooltip from 'primevue/tooltip';
import Paginator from 'primevue/paginator';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Chart from 'primevue/chart';

// import BlockUI from 'primevue/blockui';
// import DinamicSelector from './components/TemplateComponent/DinamicSelector.vue';
// import FormIdentikit from './components/forms/FormIdentikit.vue';
// import FormPersona from './components/forms/FormPersona.vue';
// import FormUser from './components/forms/FormUser.vue';
// import DatosGeneralesTable from './components/DatosGeneralesTable.vue';


// import ScrollPanel from 'primevue/scrollpanel';
import TemplateTable from './components/TTable/TemplateTable.vue';
// import TemplateEdit from './components/TemplateComponent/TemplateEdit.vue';
// import TemplateAdd from './components/TemplateComponent/TemplateAdd.vue';

import TForm from './components/TForm/TForm.vue';
// import TemplateNewComponent from './components/TemplateComponent/TemplateNewComponent';
import SuccessAndFail from './components/modal/SuccessAndFail.vue'
import ModalForm from './components/modal/ModalForm.vue'
import FilesSearch from './components/FilesSearch.vue'
import CustomCalendar from './components/custom-calendar/CustomCalendar.vue'

export default  {
  load: (Vue) => {
    Vue.component('Tag', Tag);
    Vue.component('InputSwitch', InputSwitch);
    Vue.component('Button', Button);
    Vue.component('Badge', Badge);
    Vue.component('Dialog', Dialog);
    Vue.component('InputText', InputText);
    Vue.component('InputNumber', InputNumber);
    Vue.component('Avatar', Avatar);
    Vue.component('DataTable', DataTable);
    Vue.component('Column', Column);
    Vue.component('Card', Card);
    Vue.component('AutoComplete', AutoComplete);
    // // Vue.component('CustomAutoComplete', CustomAutoComplete);
    Vue.component('Icon', Icon);
    Vue.component('Dropdown', Dropdown);
    Vue.component('Calendar', Calendar);
    // Vue.component('Editor', Editor);
    Vue.component('Checkbox', Checkbox);
    Vue.component('Textarea', Textarea);
    Vue.component('SelectButton', SelectButton);

    Vue.component('TreeTable', TreeTable);
    Vue.component('ProgressSpinner', ProgressSpinner);
    Vue.component('Message', Message)
    Vue.component('ProgressBar', ProgressBar)
    Vue.component('Slider', Slider)

    Vue.use(ConfirmationService);
    Vue.component('ConfirmPopup', ConfirmPopup)


    // PROYECT COMPONENTS
    // // Vue.component('FormDatosLegales', FormDatosLegales);
    Vue.component('Toolbar', Toolbar);
    // // Vue.component('Peoples', Peoples);
    // // Vue.component('DashboardTopMenu', DashboardTopMenu);
    // // Vue.component('DashboardBody', DashboardBody);
    Vue.component('SimpleCard', SimpleCard);
    // // Vue.component('MyMap', MyMap);
    // // Vue.component('TextareaNestor', TextareaNestor);
    // // Vue.component('InputNestor', InputNestor);
    // // Vue.component('EditorNestor', EditorNestor);
    // // Vue.component('TextareaNestorDiv', TextareaNestorDiv);
    // Vue.component('FormUbicacion', FormUbicacion);
    // // Vue.component('ResumenCard', ResumenCard);
    // Vue.component('FormMomento', FormMomento);
    // // Vue.component('FormLugarMomento', FormLugarMomento);
    // Vue.component('FormPersonalInterviniente', FormPersonalInterviniente);
    // // Vue.component('FormObjetos', FormObjetos);
    // // Vue.component('FormAcusados', FormAcusados);
    // // Vue.component('FormDenunciantes', FormDenunciantes);
    // // Vue.component('SumarioFormRobados', SumarioFormRobados);
    Vue.component('OverlayPanel', OverlayPanel);
    Vue.component('Menu', Menu);
    Vue.component('TabMenu', TabMenu);
    Vue.component('Paginator', Paginator);
    Vue.component('TabView', TabView);
    Vue.component('TabPanel', TabPanel);
    Vue.directive('tooltip', Tooltip);
    Vue.directive('Chart', Chart);
    // // Vue.directive('blockui', BlockUI);
    // Vue.component('DinamicSelector', DinamicSelector);
    // // Vue.component('FormIdentikit', FormIdentikit);
    // Vue.component('FormPersona', FormPersona);
    // // Vue.component('FormUser', FormUser);
    // // Vue.component('DatosGeneralesTable', DatosGeneralesTable);
    
    // // Vue.component('ScrollPanel', ScrollPanel);
    Vue.component('TemplateTable', TemplateTable);
    // Vue.component('TemplateEdit', TemplateEdit);
    // // Vue.component('TemplateAdd', TemplateAdd);
    // Vue.component('TestGetter', TestGetter);
    Vue.component('TForm', TForm);
    // // Vue.component('TemplateNewComponent', TemplateNewComponent);
    Vue.component('SuccessAndFail', SuccessAndFail);
    Vue.component('ModalForm', ModalForm);
    Vue.component('FilesSearch', FilesSearch);
    // Vue.component('CustomCalendar', CustomCalendar);
  }
}