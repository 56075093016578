<template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">
      <div v-if="getMode() == 'new'" class="feature-intro">
        <h1>Nuevo {{ pageName }}</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
      <div v-else class="feature-intro">
        <h1>Editando {{ pageName }}</h1>
        <p>Modifique los datos que desea EDITAR</p>
      </div>
    </div>
    
    <div class="mb-4 text-xl">Persona Física Seleccionada</div>
    
    <TForm
      :data="data"
      :nombres="names"
      :errors="errors"
      :mensajes="mensajes"
      :mode="getMode()"
      getLimit="40"
      :designEdit="designEdit"
      :designNew="design"
      :url="url"
      :viewer="viewer"
      :viewerID="viewerID"
    />
  </div>
</template>

<script>
export default {
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    }
  },
  data() {
    return {
      //La FechaDefuncion se comento por que actualmente no hay registro de que se este Usando y el Calendar mostraba una fecha erronea
      data: {"Apellido":{"value":"canada","value2":"valdez","validation":"required|max_length[50]",type: "input"},
      "Celular":{"value":"26455555","value2":"2645064969","validation":"max_length[50]",type: "input"},
      "Cuil":{"value":"264655656","value2":"23493478323","validation":"max_length[50]",type: "input"},
      "Dni":{"value":"375068659","value2":"49347832","validation":"required|max_length[50]",type: "input"},
      // "FechaDefuncion":{"value":"2021-07-29","value2":"2021-07-30","validation":"",type: "date"},
      "LugarDeNacimiento":{"value":"otrolugar","value2":"unlugar","validation":"alpha|max_length[50]",type: "input"},
      "Nacionalidad":{"value":"aleman","value2":"argentino","validation":"alpha|max_length[50]",type: "input"},
      "Nombre":{"value":"leo","value2":"gonzalo","validation":"required|max_length[50]",type: "input"},
      "Sexo":{"value":"probando","value2":"otrosexo","validation":"alpha|max_length[50]",type: "input"},
      "Telefono":{"value":"2648548","value2":"4256858","validation":"alpha_num|max_length[50]",type: "input"}},
      // "TipodocumentoID":{"value":"123abc","value2":"abc123","validation":"required|max_length[50]",type: "input"}},
      names: {"Apellido":"","Celular":"","Cuil":"","Dni":""/*,"FechaDefuncion":""*/,"LugarDeNacimiento":"","Nacionalidad":"","Nombre":"","Sexo":"","Telefono":""},
      errors:  {"Apellido":false,"Celular":false,"Cuil":false,"Dni":false,"FechaDefuncion":false,"LugarDeNacimiento":false,"Nacionalidad":false,"Nombre":false,"Sexo":false,"Telefono":false},
      mensajes: {"Apellido":"","Celular":"","Cuil":"","Dni":""/*,"FechaDefuncion":""*/,"LugarDeNacimiento":"","Nacionalidad":"","Nombre":"","Sexo":"","Telefono":""},
      design: [["Apellido","Nombre"],["Dni","Cuil"],["Sexo"],["Nacionalidad","LugarDeNacimiento"],["Telefono","Celular"]],
      designEdit: [["Apellido","Celular"],["Cuil","Dni"],[/*"FechaDefuncion",*/"LugarDeNacimiento"],["Nacionalidad","Nombre"],["Sexo","Telefono"]],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + '/persona-fisica',
      pageName:'Persona Física'
    }
  },
  created(){
    let {id} = this.$route.params
    this.id=id
  },
  methods:{
    getMode(){
      return this.id== 'add' ? 'new' : 'edit';
    },
  },
}
</script>
  