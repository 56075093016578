import store from '../index.js'

function uuidv4(){
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function translateNameFromFormType(formType){
  const sumarioViewsArray =  Object.values(store.state.sumarioViews);
  let index = sumarioViewsArray.findIndex(item => item?.actuationName?.[formType] !== undefined);
  let formTypeValue  =  Object.keys(store.state.sumarioViews).find(key => store.state.sumarioViews[key] === sumarioViewsArray[index]);
  return formTypeValue !== undefined ? formTypeValue : formType;
}

function determineDefaultConditionFromAffected(formType){
  return store.state.sumarioViews[formType]?.personConditionDefault?.affected;
}
function determineDefaultConditionFromVinculated(formType){
  return store.state.sumarioViews[formType]?.personConditionDefault?.vinculated;
}

export default {
  uuidv4,
  translateNameFromFormType,
  determineDefaultConditionFromAffected,
  determineDefaultConditionFromVinculated
}