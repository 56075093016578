<template>
  <div class="toolbar">
    <br />
    <div class="feature-intro title">
      <h1>Gestor de Archivos</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="Gestor de Archivos"
        reference="parametros/gestor-archivos/edit"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        :modal="modal"
        apiversion="2"
        :keyShow="keyShow"
        :showLink="showLink"
        :filtersFields="filtersFields"
        :disableButtonEdit="false"
        :multiSelect="true"
        :enableSelectAllCheckbox="true"
        :enableEditButtons="true"
        :enableGlobalSearch="false"
        :enableAddButton="{ enabled: true, name: 'gestor-archivos-new' ,  params: { id: 'add' }}"
      >
        <template #LinksBody="slotProps">
          <div style="text-align: center">
              <a class="file__link" :href="`${url}/${slotProps.data['ArchivopdfID']}?download=true`" target="_blank">
                <Button 
                  class="p-button-success" 
                  icon="pi pi-search" 
                  lab 
                  :disabled="!slotProps.data['ExistingFile']" 
                  v-tooltip.left="!slotProps.data['ExistingFile'] ? 'Archivo no encontrado' : 'Ver archivo'"
                >
                </Button>
              </a>
          </div>
        </template>
      </TemplateTable>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'

export default {
  name: "Gestordearchivos",
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/files/archivopdf",
      customName: [],
      data: null,
      customColumn: [],
      idTable: "ArchivopdfID",
      loading: true,
      filtersFields: [{ name: "Nombre", field: "Nombre" }],
      keyShow: ["Nombre", "Tipo", "Detalle", "Links"],
      showLink: true,
    };
  },
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  margin-left: 30px;
}
.file__link {
  text-decoration: none;
}
</style>
