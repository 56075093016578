//UFIs: 'ufi-emergencias-medicas', 'ufi-siniestro-vial', 'ufi-ahorcamiento-suicidio', 'ufi-incendio-vivienda' and 'ufi-intoxicacion'      
export default {
    computed: {
        PreformActuacionesPreliminares:{
            get (){
                let dateDenuncia = {
                    withStyles:   `<p style="color: #1356cc ; display: inline">${this.dateDenuncia}</p>,`,
                    noStyles: this.dateDenuncia + ','
                }
                
                let dependency = 
                    this.userState.dependencia !== null && this.userState.dependencia !== undefined 
                    ?
                        {
                            withStyles: ` sede de <p style="color: #1356cc ; display: inline">${this.userState.dependencia}</p>, `, 
                            noStyles: ` sede de ${this.userState.dependencia}, `,
                        }
                    :
                        {
                            withStyles: '', 
                            noStyles: '',
                        }
                //changing the last character for a ';'
                dateDenuncia.withStyles =  dateDenuncia.withStyles.slice(0, -1)
                dateDenuncia.withStyles =  dateDenuncia.withStyles + ";"
                dateDenuncia.noStyles =  dateDenuncia.noStyles.slice(0, -1)
                dateDenuncia.noStyles =  dateDenuncia.noStyles + ";"
                
                let constance = {
                    withStyles:   ` el funcionario de Policía que suscribe, Jefe Titular de esta Dependencia, con intervención del Secretario de Actuaciones que Refrenda, a los fines legales <b><p style="text-decoration: underline ; display: inline"> HACE CONSTAR:</p></b> `,
                    noStyles: ` el funcionario de Policía que suscribe, Jefe Titular de esta Dependencia, con intervención del Secretario de Actuaciones que Refrenda, a los fines legales HACE CONSTAR: `,
                }
                
                let constanceText = {
                    withStyles:   `Que en la fecha y hora indicada, se hizo presente personal de `,
                    noStyles: `Que en la fecha y hora indicada, se hizo presente personal de `,
                }
                
                constanceText.noStyles = constanceText.withStyles  
                
                let interveningPersonnel =  { 
                    withStyles:   `<p style="color: #1356cc ; display: inline">${this.dataResumen.personalIntervinienteDelitosEspeciales}</p>`,
                    noStyles: this.dataResumen.personalIntervinienteDelitosEspeciales !== undefined ? this.dataResumen.personalIntervinienteDelitosEspeciales : '',
                }
                
                let reporting ={ 
                    withStyles:   `,  informando que `,
                    noStyles: `,  informando que `,
                }
                
                if(this.textEditsCounter.Preform == 0)
                {
                    let Preform = {
                        withStyles: this.Preforma.noStyles + this.department.noStyles + dependency.withStyles + dateDenuncia.withStyles + constance.withStyles + constanceText.withStyles +  interveningPersonnel.withStyles + reporting.withStyles ,
                        noStyles: this.Preforma.noStyles + this.department.noStyles + dependency.noStyles + dateDenuncia.noStyles + constance.noStyles + constanceText.noStyles + interveningPersonnel.noStyles + reporting.noStyles
                    };
                    return Preform
                }   else
                {   
                    let Preform = {
                        withStyles : "vacio por que no se muestra, es solo para que no de error por undefined",
                        noStyles : this.textEstaticos.Preform,
                    }
                    return Preform
                }
            }
        },
        instructionDecreeActuacionesPreliminares:{
            get (){
                if(this.textEditsCounter.instructionDecree == 0){ 
                    let firstPart =   {
                        withStyles: `En virtud de ello el suscripto `,
                        noStyles: `En virtud de ello el suscripto `,
                    }
                    
                    let secondPart =   {
                        withStyles: "<b><u>DISPONE:</b></u> Iniciar las actuaciones sumariales correspondientes. Agregar a continuación Declaración testimonial del funcionario policial y acta labrada en el lugar, en calidad de una foja útil. Designar como Secretario de actuaciones al Oficial que firma al pie, como prueba de su conformidad y aceptación al cargo conferido. Llevar adelante toda medida legal de interés, en los presentes actuados.",
                    }
                    
                    secondPart.noStyles =  secondPart.withStyles.replace('<b><u>DISPONE:</b></u>', 'DISPONE')
                                        
                    let thirdPart =  {
                        withStyles: ` <b style='text-decoration: underline'>CERTIFICO.-</b> `,
                        noStyles: ` CERTIFICO.-`,
                    }
                    
                    let instructionDecree = {
                        withStyles: firstPart.withStyles + secondPart.withStyles + thirdPart.withStyles,
                        noStyles: firstPart.noStyles + secondPart.noStyles + thirdPart.noStyles,
                    }
                    return instructionDecree 
                }   else{
                    console.log('instructionDecree.noStyles', instructionDecree.noStyles);
                    let instructionDecree = {
                        noStyles:  this.textEstaticos.instructionDecree
                    }
                    
                    return instructionDecree                
                }
                                
            },
        },   
        actOfStartActuacionesPreliminares:{
            get (){
                let actOfStart = this.Preform.noStyles + ' ' + this.deposition + '. ' + this.instructionDecree.noStyles
                return actOfStart 
            }
        } 
    }    
};