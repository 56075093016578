export default {
    computed: {
        PreformSumarioOficio:{
            get (){
                let dependency = {
                    withStyles:   `<p style="color: #55b5ea ; display: inline">${this.userState.userdata.dependenciaDepartamento !== null ? this.userState.userdata.dependenciaDepartamento + ', ': ''  }</p> local de <p style="color: #55b5ea ; display: inline">${this.userState.dependencia}</p>`,
                    noStyles: `${this.userState.userdata.dependenciaDepartamento !== null ? this.userState.userdata.dependenciaDepartamento + ', ': ''  } local de ${this.userState.dependencia}`,
                }
                let dateAndTimeDenuncia = {
                    withStyles:   ` <p style="color: #55b5ea ; display: inline">${this.dateDenuncia}</p>,`,
                    noStyles: this.dateDenuncia,
                }
                let introduction = {
                    withStyles:   ` el Funcionario de Policía que suscribe, Jefe Titular de esta Dependencia, con intervención del Secretario de Actuaciones que Refrenda, a los fines legales <b style="text-decoration: underline"> HACE CONSTAR:</b> `,
                    noStyles: `el Funcionario de Policía que suscribe, Jefe Titular de esta Dependencia, con intervención del Secretario de Actuaciones que Refrenda, a los fines legales HACE CONSTAR: `,
                }
                let constanceText = {
                    withStyles:   `Que en la fecha y hora indicada, se hace presente `,
                    noStyles: `Que en la fecha y hora indicada, se hace presente `,
                }  
                let report = ', informando que'
               
                if(this.textEditsCounter.Preform == 0)
                {
                    let Preform = {
                        withStyles: this.Preforma.withStyles  + dependency.withStyles + dateAndTimeDenuncia.withStyles + introduction.withStyles + constanceText.withStyles + this.interveningPersonnel.withStyles + report  ,
                        noStylesFirtPart : this.Preforma.noStyles  + dependency.noStyles + dateAndTimeDenuncia.noStyles + introduction.noStyles,
                        noStylesSecondPart : constanceText.noStyles + this.interveningPersonnel.noStyles + report ,
                        noStyles : this.Preforma.noStyles + dependency.noStyles + dateAndTimeDenuncia.noStyles + introduction.noStyles + constanceText.noStyles + this.interveningPersonnel.noStyles + report 
                    };
                    return Preform
                }else
                {   
                    let Preform = {
                        withStyles : "vacio por que no se muestra, es solo para que no de error por undefined",
                        noStyles : this.textEstaticos.Preform,
                        noStylesFirtPart : this.Preforma.noStyles + dependency + dateAndTimeDenuncia.noStyles + introduction.noStyles,
                        noStylesSecondPart : this.AuxTextSecond

                    }
                    return Preform
                }
            }
        },
        postformSumarioOficio:{
            get (){
                if(this.textEditsCounter.postformSumarioOficio == 0){
                    let postformSumarioOficio = {
                        withStyles:   `En virtud de ello el suscripto <b style="text-decoration: underline">DISPONE:</b> Iniciar las actuaciones sumariales correspondientes. Agregar a continuación Declaración testimonial del funcionario policial y acta labrada en el lugar, en calidad de una foja útil. Designar como Secretario de actuaciones al Oficial que firma al pie, como prueba de su conformidad y aceptación al cargo conferido. Llevar adelante toda medida legal de interés, en los presentes actuados. <b style="text-decoration: underline">CERTIFICO</b>.- `,
                        noStyles:   `En virtud de ello el suscripto DISPONE: Iniciar las actuaciones sumariales correspondientes. Agregar a continuación Declaración testimonial del funcionario policial y acta labrada en el lugar, en calidad de una foja útil. Designar como Secretario de actuaciones al Oficial que firma al pie, como prueba de su conformidad y aceptación al cargo conferido. Llevar adelante toda medida legal de interés, en los presentes actuados. CERTIFICO.- `
                    }
                    postformSumarioOficio.withStyles = postformSumarioOficio.withStyles;
                    postformSumarioOficio.noStyles = postformSumarioOficio.noStyles;
                    return postformSumarioOficio
                }   else{
                    let postformSumarioOficio = {
                        noStyles:  this.textEstaticos.postformSumarioOficio
                    }
                    return postformSumarioOficio
                }
            },
        },      
        actOfStartSumarioOficio:{
            get (){
                let actOfStart = this.PreformSumarioOficio.noStyles + ' ' + this.deposition + '. ' + this.postformSumarioOficio.noStyles
                return actOfStart   
            }
        } 
    } 
};