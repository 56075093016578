export const preliminarFugaConfig = [
       
    {
        path: "/actuaciones/new/preliminares-fuga-menor/initial",
        pathName: "preliminares-fuga-menor",
        titulo: "ACTUACIONES PRELIMINARES - FUGA DE MENOR",
        props: { formType: "preliminar-fuga-menor",actuacionType: "preliminares-fuga-menor" },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/preliminares-fuga-menor/initial/denunciante",
        pathName: "preliminares-fuga-menor",
        titulo: "DENUNCIANTE Y/O DAMNIFICADO",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/preliminares-fuga-menor/initial/momento",
        pathName: "preliminares-fuga-menor",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/preliminares-fuga-menor/initial/acusado",
        pathName: "preliminares-fuga-menor",
        titulo: "CAUSANTE",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/preliminares-fuga-menor/initial/efectos",
        pathName: "preliminares-fuga-menor",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]