export default function (){
    
    return {
        html: `    
        <p>__________________ .</p>`,

        getCode: (html, comisaria, sangria) => `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <style>
        @font-face {
            font-family: 'Times New Roman';
            font-style: normal;
            font-weight: 400;
            src: url('file:///usr/share/fonts/truetype/msttcorefonts/Times_New_Roman.ttf');
        } 
        * {
            font-family: 'Times New Roman', Times, serif;
        }
        body {
            margin: 0;
            padding:0;  
        }
        .page {
            margin: 0;
            padding:0;
            font-size:25px;
        }
        .logo{
            height:5em;
        }
        .comisaria{
            top:-3em;
            position:relative;
            width:100%;
            font-size:0.6em;
        }
        .sangria {
            text-indent: ${sangria}px !important;
        }
        .regla {
            width: 100px;
            height: 100px;
        }
        .regla2 {
            width: 10px;
            height: 100px;
            border-left: 1px solid red;
        }
    </style>
   
</head>
<body>
    <div class="page" style="">
        <div  style="margin-bottom: 1px;" class="logo">
            <div>
                <img style="float:left" src="https://siis.policiadesanjuan.gob.ar/assets/logo-policia-de-san-juan.png" />
                <img style="float:right; width: 200px;height: 100px;" src="https://siis.policiadesanjuan.gob.ar/assets/policia-b.jpeg" />
            </div>
            <div>
                <p style="float:right;" class="comisaria">${comisaria}</p>
            </div>
        </div>
        <div id="content">
            ${html}
        </div>
        
    </div>
</body>
</html>`
}
}