import Button from "primevue/button";
import DataTable from "../../datatable";
import TTableFilters from "../TTableFilters.vue";
import TTablePagination from "../TTablePagination.vue";
// import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext/InputText";
import Checkbox from "primevue/checkbox";
import CustomCheckbox from "../../TemplateComponent/custom-checkbox";
import FileUpload from "primevue/fileupload";


export default {
    components: {
        Button,
        DataTable,
        Column,
        InputText,
        Checkbox,
        CustomCheckbox,
        FileUpload,
        TTableFilters,
        TTablePagination,
    },
}