var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[(
      _vm.$rest.esTipoUsuario(
        [
          'Super User',
          'User Tester',
          'Super Usuario de E3',
          'Dpto. Asuntos Judiciales D-5',
          'Dpto.Criminalistica',
          'Lab. Informatico Forense',
        ].concat(_vm.prosecutorUserTypes),
        _vm.usuario
      )
    )?_c('div',[_c('Dropdown',{attrs:{"options":_vm.dependenciaList,"filter":"","optionLabel":"dependencia","optionValue":"id","placeholder":"Seleccione una dependencia...","showClear":""},model:{value:(_vm.dependenciaDrop),callback:function ($$v) {_vm.dependenciaDrop=$$v},expression:"dependenciaDrop"}})],1):_vm._e(),_c('div',{staticClass:"upContainer"},[(!_vm.$rest.esTipoUsuario(['Deposito Judicial'], _vm.usuario) && _vm.$offline.onlineMode)?_c('DashboardTopInfo',{attrs:{"dependencia":_vm.dependenciaDrop}}):_vm._e()],1),_c('div',{staticClass:"downContainer",class:{'centered': _vm.isSpecialUserType}},[(!_vm.isSpecialUserType)?_c('div',{staticClass:"left"},[_c('BibliotecaVirtual')],1):_vm._e(),(!_vm.isSpecialUserType)?_c('div',{staticClass:"center"},[_c('DashboardChart',{attrs:{"dependencia":_vm.dependenciaDrop}})],1):_vm._e(),(!_vm.isSpecialUserType)?_c('div',{staticClass:"right"},[_c('OfflineTable')],1):_vm._e(),(_vm.isSpecialUserType)?_c('div',{staticClass:"centered-biblioteca"},[_c('BibliotecaVirtual')],1):_vm._e()]),_c('br'),(!_vm.$rest.esTipoUsuario(['Deposito Judicial'], _vm.usuario) && _vm.$offline.onlineMode)?_c('div',[_c('DashboardBottomInfo',{attrs:{"dependencia":_vm.dependenciaDrop,"user":_vm.usuario}})],1):_vm._e(),_c('ConfirmPopup',{ref:"ConfirmPopup",staticStyle:{"z-index":"999"},attrs:{"group":"demo"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }