import store from '../../../index.js'

const effects = (data, getters) => {
   ///////////////////// Mensaje Advertencia carga Efectos Denunciados///////////////
  let robadosList = getters['robados/robadosList']
  if(robadosList.length<=0){    
    data.errorValidationWarning.push('No especificó ningún efecto denunciado')
  }

  //////////////Lista de Objetos Robados///////////////////////
  try {
    var tipo='';
    var color='';
    var subcategoria = '';
    var marca= '';
    var modelo= '';
    var numId='';
    var ListaObjetos='';
    var detalles='';
    var tipoEfecto= '';

    let robadosList = getters['robados/robadosList']
    var objeto='';
    for (let item of robadosList){
      if(item.type != 'empty')
      {
        if(item.doc.SubcategoriaID != undefined && store.state.siis.subcategoriasDeposito.length > 0)
        {
          subcategoria = store.state.siis.subcategoriasDeposito.find(subcategory => subcategory?.SubcategoriaID === item.doc.SubcategoriaID);
          subcategoria = subcategoria?.Nombre !== undefined ? subcategoria.Nombre : 'objeto desconocido';
          objeto = `un/una o varios/as ${subcategoria}`;
        }
        if(item.doc.MarcaID != undefined && store.state.siis.marca.length > 0)
        {
          marca = store.state.siis.marca.find(brand => brand?.MarcaID === item.doc.MarcaID);
          marca = marca?.nombre !== undefined ? marca.nombre : 'desconocida';
          objeto= objeto+' Marca '+marca;
        }
        if(item.doc.ModeloID != undefined && store.state.siis.modelo.length > 0)
        {
          modelo = store.state.siis.modelo.find(model => model?.ModeloID === item.doc.ModeloID);
          modelo = modelo?.Nombre !== undefined ? modelo.Nombre : 'desconocido';
          objeto=objeto+' Modelo ' + modelo;
        }
        // if(item.doc.color != undefined)
        // {
        //   color= item.doc.color;
        //   objeto= objeto+', Color: '+color;
        // }

        // if(item.doc.numId != undefined)
        // {
        //   numId = item.doc.numId;
        //   objeto=objeto+', ID/SERIE/PATENTE: '+numId;
        // }    
        // if(item.doc.details != undefined)
        // {
        //   detalles = item.doc.details;
        //   objeto=objeto+', Detalles: '+detalles;
        // } 
        // if(item.doc.tieneSeguro == true)
        // {
        //   objeto=objeto+', Tiene seguro';
        // }


        if(ListaObjetos == '')
        {
          ListaObjetos = objeto;
        }else{
          ListaObjetos= ListaObjetos+ ';\n'+objeto
        }
      }
    }
    data.efectosDenunciados= ListaObjetos;
  }
  catch(e){
    console.log('tiro un problema al cargar los Objetos robados: ', e);    
    data.errorValidationWarning.push("Verificar efectos sustraidos")
  }
}
export default {
  effects
}