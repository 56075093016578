<template>
      <div class="custom-card">
                <div class="header">
                  <h3 class="title">BIBLIOTECA VIRTUAL</h3>
                  <div
                    class="menu"
                    v-if="
                      !$rest.esTipoUsuario(['Deposito Judicial'], this.usuario) &&
                      this.$rest.haveDependencia(usuario)
                    "
                  >
                    <router-link to="/actuaciones/newForm" class="decoration__links">
                      <Button
                        icon="pi pi-plus"
                        class="p-button-rounded p-button-info p-button-outlined"
                        test-id="nuevo_formulario"
                      />
                    </router-link>
                  </div>
                </div>
                <div class="content">
                  <FilesSearch type="home" />
                </div>
            </div>
</template>
<script>
import FilesSearch from "@/components/FilesSearch";
export default {
  components: {   
    FilesSearch,   
  },
  name: "DashboardBody",
  data() {
    return {
        usuario: this.$store.state.user,
    }
    },
    async created(){
        if (
        this.$rest.esTipoUsuario(
          [
            "Super User",
            "User Tester",
            "Super Usuario de E3",
            "Dpto. Asuntos Judiciales D-5",
            "Dpto.Criminalistica",
            "Lab. Informatico Forense",
            "Dpto.Criminalistica",
            "Lab. Informatico Forense",
          ],
          this.usuario
        )
      ) {
        this.$store.dispatch("siis/setDependencias");
      }
      if (this.$rest.esTipoUsuario(["Jefe de Dependencia", "Jefe de Sumario"], this.usuario)) {
        this.dependenciaDrop = this.usuario.userdata.dependenciaID;
      }
    }
}
</script>
<style>
.custom-card {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-card .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-card .content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.custom-card .content .files-search {
  width: 100%;
}

.custom-card .content .files-search input {
  width: 100%;
}
</style>