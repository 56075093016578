<template>
  <div>
    <Sidebar
      :visible.sync="localIsVisible"
      :showCloseIcon="false"
      class="sidebar-container"
      style="min-height: 95vh; overflow-y: auto; width: 255px"
      @hide="onSidebarHide"
      unstyled="false"
    >
      <div class="logoSidebar">
        <img alt="Logo Policia" src="../../assets/logo-policia-de-san-juan.png" />
      </div>
      <div class="menuSidebar">
        <div v-for="group in groupedLinks" :key="group.name" class="linkGroups">
          <div v-if="group.name !== 'principal'" class="group-title">
            <hr class="short-line" />
            <span class="group-title-text" style="color: rgb(184, 196, 235)">{{
              group.name
            }}</span>
            <hr class="long-line" />
          </div>
          <div v-for="link in group.links" :key="link.pathName">
            <div v-if="link.type === 'button'">
              <Button
                @click="handleLinkClick(link)"
                class="buttons"
                style="width: 100%; margin-bottom: 15px"
              >
                <i :class="['pi', link.icon]"></i>{{ link.titulo }}
              </Button>
            </div>
            <div
              v-else-if="link.type === 'link'"
              @click="handleLinkClick(link)"
              class="routerLinksTitles"
              :style="{ 'margin-bottom': dynamicMargin }"
            >
              <div class="routerLinks">
                <i :class="['pi', link.icon]"></i>
                {{ link.titulo }}
              </div>
            </div>
            <div v-else-if="link.type === 'spacer'">
              <div style="height: 20px"></div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import Sidebar from "primevue/sidebar";
import { sidebarLinks } from "./sidebarLinks";
import { mapState } from 'vuex';

export default {
  components: {
    Sidebar, 
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    userType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localIsVisible: this.isVisible,
    };
  },  
  computed: {
    filteredLinks() {
      return sidebarLinks.filter((link) => {
        if (link.roles.length === 0) return true;
        return link.roles.includes(this.userType);
      });
    },
    uniqueGroups() {
      return [...new Set(this.filteredLinks.map((link) => link.grupo))];
    },
    groupedLinks() {
      return this.uniqueGroups.map((group) => ({
        name: group,
        links: this.filteredLinks.filter((link) => link.grupo === group),
      }));
    },
    dynamicMargin() {    
      const minLength = 10; 
      const baseMargin = '10px'; 
      for (const group of this.groupedLinks) {        
        for (const link of group.links) {          
          if (link.titulo.length > minLength) {
            return '20px'; 
          }
        }
      }
      return baseMargin;
    },
    ...mapState({
        // Mapea el estado que deseas observar del store
        formType: (state) => state.sumarioViews?.actuacion,
      }),
  },
  watch: {
    isVisible(newVal) {
      this.localIsVisible = newVal;
    },
    formType(newVal, oldVal) {
    // Acciones a realizar cuando someState cambia
      // console.log('El estado someState cambió de', oldVal, 'a', newVal);
      // Aquí puedes agregar cualquier lógica adicional que necesites
    },

  },
  methods: {
    onSidebarHide() {
      this.localIsVisible = false;
      this.$emit("update:isVisible", false);
    },
    // async handleLinkClick(link) {
    //   await this.$store.dispatch("sumario/resetState");
    //   if (link.action && link.action === "logout") {
    //     this.$emit("logout");
    //     return;
    //   }else{
    //     this.$router.push({
    //       path: link.path,
    //   //     // params: { pathName: link.pathName }
    //     });
    //   }      
    //   this.localIsVisible = false;
    // },
    async handleLinkClick(link) {
      await this.$store.dispatch("sumario/resetState");
      if (link.action && link.action === "logout") {
        this.$emit("logout");
        return;
      }      
      const currentRoute = this.$router.currentRoute.path;      
      if (link.path !== currentRoute) {   
        this.$router.push({
          path: link.path,
          // params: { pathName: link.pathName }
        });
      }

      this.localIsVisible = false;
    }

  },
  
};
</script>
<style>
.logoSidebar {
  width: 105%;
  height: 57px;
  margin: -2px -5px 0 0;
  background-color: #fff;
  overflow: hidden;
}

.menuSidebar {
  max-height: calc(95vh - 57px);
  overflow-y: auto;
  cursor: pointer;
  padding: 7px 15px;
  background-color: #343a40;
  margin-top: 10px;
}
.sidebar-container {
  background-color: #343a40;
}
.routerLinks {
  color: #fff;
  text-decoration: none;
  display: flex; /* Uso de flexbox para alineación */
  align-items: center; /* Alineación vertical en el centro */
  justify-content: flex-start; /* Alineación horizontal a la izquierda */
  border-radius: 5px; /* Bordes redondeados */
  box-sizing: border-box; /* Incluye padding y border en el cálculo de la altura */
  padding: 12px 14px; /* Ajusta el padding para la altura total deseada */
  height: 35px; /* Altura total, incluyendo padding */
}

.routerLinks:hover {
  background-color: #4b545c; /* Color de fondo al pasar el ratón */
  color: rgba(255, 255, 255, 0.8);
  /* No es necesario cambiar la altura o el padding para el hover */
  
}

.routerLinksTitles {
  margin-top: 10px;
}
.routerLinksTitles:hover {
  margin: 10px 0; /* Esto agregará margen arriba y abajo al hacer hover */
}

.menuSidebar i.pi {
  font-size: 20px;
  margin-right: 10px;
}
.buttons {
  list-style: none;
  font-weight: 700;
  word-spacing: 0.4rem;
  background-color: #4b545c !important; /* Se aplica !important para garantizar que este estilo tenga prioridad */
  color: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid transparent !important; /* Se mantiene el borde transparente para evitar desplazamientos */
  transition: background-color 0.3s, border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%; /* Se asume que este estilo no necesita !important porque no está siendo sobrescrito */
  margin-bottom: 15px;
}

.buttons:enabled:hover {
  background-color: #343a40 !important; /* Se asegura que el color de fondo al hacer hover tenga prioridad */
  border-color: rgba(
    255,
    255,
    255,
    0.2
  ) !important; /* Se cambia el color del borde al hacer hover */  
}

.buttons:focus {
  outline: none;
  box-shadow: none;
  /* border: none; */
}

/* .p-button:enabled:hover{
  background-color: #343a40;
  border-color: rgba(255,255,255,.2);
} */

.p-button {
  outline: none;
  box-shadow: none;
}

.p-button:focus {
  border: none;
}

.group-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 10px;
}

.short-line {
  border: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  width: 10px;
  margin-right: 5px;
}

.long-line {
  flex-grow: 1;
  border: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin-left: 5px;
}

.group-title-text {
  position: relative;
}

.linkGroups {
  margin-bottom: 20px;
}
</style>
