import Dexie from 'dexie';
import processedSchemas from './tableSchemas';

export const db = new Dexie('SIISDatabase');

db.version(1).stores({
  ...processedSchemas
});

export default db;
