import generalFunctions from './generalFunctions';
import Vue from 'vue'
import axios from 'axios'
import acusados from './sumario/acusados'
import getter from './sumario/dataResumen'
import robados from './sumario/robados'
import denunciante from './sumario/denunciante'
import diligencia from './sumario/diligencia'
import example from './sumario/example'
import afectacionPatrimonial  from './sumario/afectacionPatrimonial'
import actuationsRepository from '../../../dixieDB/repositories/actuationsRepository';
import actuacionesRepository from '../../../dixieDB/app-offline/repositories/actuacionesRepository';

import { prepareDoc } from './sumario/formatPost'
// import VueCli from '../../siis_utils'

// import dependencia from './user'


const emptyObject = {
  denunciantesBD: [],
  ubicacion: {},
  preUbicacion: {},
  moment: [],
  preMoment: {},
  estadoCivil: {},
  residencia: {},
  denuncia: [
    { name: "InputNestor", value: "" }
  ],
  //La fecha de la denuncia, es la fecha en que debio ser generada la Denuncia (Esto se debe a que a veces los oficiales, toman la denuncia pero no la cargan en el momento)
  fechaDenuncia: new Date(),
  //La fecha de extencion del certificado, es la fecha y hora cuando este fue cargado
  fechaCertificadoDenuncia: null,
  denunciaText: '',
  causaNumero: null,
  modusOperandi: null,
  causaCaratula: null,
  listaCaratulas: [],
  listaArticulos: [],
  causaJuzgado: null,
  SumarioUfi: null,
  prosecutor: null,
  assistant: null,
  id: null,
  saveStatus: 'new',
  timer: false,
  isSaving: false,
  type: null,
  deposition: '',
  formType: '',
  dependencia: '',
  Sitio: null,
  effectsType: 'empty',
  PersonalInterviniente: [],
  textEditsCounter: {
    Preform: 0,
    Contravener: 0,
    Postform: 0,
    LegalData: 0,
    instructionDecree: 0,
    postformSumarioOficio: 0
  },
  textEstaticos: {
    Preform: "",
    Contravener: "",
    Postform: "",
    LegalData: "",
    instructionDecree: "",
    postformSumarioOficio: ""
  },
  spinnerOrderTablePrintFrom: false,
  actuationEditModeActive: false,
  // denunciantes:[]
}

const emptyState = () => {
  let newState = Object.assign({}, emptyObject)
  // console.log("newState", newState, denunciante.emptyObject())

  newState = {
    ...newState,
    denunciante: denunciante.emptyObject(),
    acusados: { ...acusados.emptyObject },
    robados: { ...robados.emptyObject },
    afectacionPatrimonial: { ...afectacionPatrimonial.emptyObject },
    diligencia:  diligencia.emptyObject(),
    dependencia: null,
    idDependencia: null,
    idUser: null,
    id: null,
    ubicacion: {},
    preUbicacion: {},
  }
  // console.log("newState2", newState)
  return newState
}

const state = () => (Object.assign({}, emptyObject))


// actions
const actions = {
  setExample({commit, state}){// este metodo se usa para que las actuaciones se carguen de forma automatica con el sistema de los 10 click en la card"Fecha, Hora y Ubicacion"
    commit('setSumario', {siis_state: example[state.formType]})  
  },
  async updateArticulos({ commit, state , rootState}, payload)
  {
    // console.log(payload)
    //Guardamos los articulos que luego tendremos que borrar del store
    // Cargamos lo nuevos Articulos
    try{
        let listaArticulosCopy = [...state.listaArticulos]

        for(let index = 0 ; index < payload.articulos.length; index++)
        {
          let exist = listaArticulosCopy.find( element => element.ArticulosID == payload.articulos[index].ArticulosID)
          if(!exist)
          {
            let dataSumarioArticulo = {
              ActuacionID : state.id,
              ArticulosID : payload.articulos[index].ArticulosID
            }
            //creamos el nuevo registro en la Bd
            let res = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-articulo/`,dataSumarioArticulo, { withCredentials: true});
            // console.log("respuesta Update: ",JSON.stringify(res.data))
            // if(res.data.error == "")
            // {
            //   //actizamos en el store ese nuevo registro
            //   commit('updateArticulos', payload.articulos[index], res.ActuacionArticuloID)
            // }
          }else
          {
            let i =  listaArticulosCopy.indexOf(exist)
            listaArticulosCopy.splice(i, 1)
          }
        }
        // console.log("el tamaño antes del for es: ", listaArticulosCopy.length)

        for(let i = 0; i < listaArticulosCopy.length; i++)
        {
          let res = await axios.delete(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-articulo/${listaArticulosCopy[i].ActuacionArticuloID}`, { withCredentials: true});
          // console.log("respuesta Delete: ",JSON.stringify(res.data))
          // if(res.data.error == "")
          // {
          //   commit('deleteArticulos',i)
          // }
        }
        return state.id;
    }
    catch(e){
      // alert("No se pudieron Actualizar algunos de los Articulos.\nError: "+e)
      console.log("No se pudieron actualizar algunos de los Articulos.\nError: "+e)
      payload.VueCli.$toast.open({message: "No se pudieron actualizar algunos de los Artículos", position: 'top-right', type: 'error', duration: 3000,});
      return null;
    }
  },   
  async updateSumario({ commit, state , rootState}, payload)
  {
    let CausaCaratula
    let Numero
    if(payload.hasOwnProperty("causaCaratula")){
      CausaCaratula = payload.causaCaratula.Nombre
    } else{
      CausaCaratula = state.CausaCaratula
    }
    if(payload.hasOwnProperty("Numero")){
      Numero = payload.Numero
    } else{
      Numero = state.causaNumero
    }
    let dataRolJuzgadoSumario = {
      // Fechafin:"", 
      Fechainicio:state.fechaDenuncia, 
      Numero: Numero,
      DenunciaID:state.denunciaID,
      TiposumarioID:state.tiposumarioID, 
      DependenciaID:state.dependenciaID, 
      // FechaEliminacion:"", 
      CausaCaratula: CausaCaratula,
    }
    console.log("dataRolJuzgadoSumario", dataRolJuzgadoSumario)
    console.log("state.id", state.id)
    try{
        let resp = await axios.put(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${state.id}`,dataRolJuzgadoSumario, { withCredentials: true ,params:{method: "methodCommon"}});
        console.log(resp)
        return state.id;
    }
    catch(e){
      payload.VueCli.$toast.open({message: "No se pudo actualizar la Causa/Caratula", position: 'top-right', type: 'error', duration: 3000,});
      // alert("No se pudo Actualizar la Causa/Caratula.\nError: "+e)
      console.log("No se pudo Actualizar la Causa/Caratula.\nError: "+e)
      return null;
    }
  },
  async updateJuzgado({ commit, state , rootState}, payload)
  {
    let dataRolJuzgadoSumario = {
      Descripcion : `Modificado el dia ${fechaYhora(new Date())} por el usuario ${rootState.user.userdata.usuarioNombre} de la dependencia ${rootState.user.userdata.dependenciaNombre}`,
      Nombre: payload.Nombre,
      JuzgadoID: payload.JuzgadoID,
      SumarioID: state.id
    }
    if(state.causaJuzgado?.RoljuzgadosumarioID != undefined)
    {
      try{
        let resp = await axios.put(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/rol-juzgado-sumario/${state.causaJuzgado.RoljuzgadosumarioID }`, dataRolJuzgadoSumario, { ContentType: "text/plain", withCredentials: true });
        // console.log("resp: ", resp)
        return state.id;
      }
      catch(e){
        // alert("No se pudo Actualizar el Juzgado.\nError: "+e)
        console.log("No se pudo Actualizar el Juzgado.\nError: "+e)
        payload.VueCli.$toast.open({message: "No se pudo actualizar el Juzgado", position: 'top-right', type: 'error', duration: 3000,});
        return null;
      }
    }else
    {
      try{
        let resp = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/rol-juzgado-sumario/`, dataRolJuzgadoSumario, { ContentType: "text/plain", withCredentials: true });
        // console.log("resp: ",resp)
        return state.id;
      }
      catch(e){
        // alert("No se pudo Actualizar el Juzgado.\nError: "+e)
        console.log("No se pudo Actualizar el Juzgado.\nError: "+e)
        payload.VueCli.$toast.open({message: "No se pudo actualizar el Juzgado", position: 'top-right', type: 'error', duration: 3000,});
        return null;
      }
    }
  },
  async updateUfi({ commit, state , rootState}, payload)
  {
    let dataNumeroUfi = {
      TipoufiID: payload.tipoUfi,
      SumarioID: state.id
    }
    console.log('dataNumeroUfi', dataNumeroUfi)
    if(state.SumarioUfi?.TipoufiID != undefined)
    {
      try{
        let resp = await axios.put(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumarioufi/${state.SumarioUfi.SumarioufiID }`, dataNumeroUfi, { ContentType: "text/plain", withCredentials: true });
        return state.id;
      }
      catch(e){
        payload.VueCli.$toast.open({message: "No se pudo actualizar el N° de Ufi", position: 'top-right', type: 'error', duration: 3000,});
        return null;
      }
    }
  },  
  TextEstaticos({ commit }, payload) {
    commit('TextEstaticos', payload)
  },
  textResetEditsCounter({ commit }, payload) {
    commit('textResetEditsCounter', payload)
  },
  textEditsCounter({ commit }, payload) {
    commit('textEditsCounter', payload)
  },
  TextActaInicio({ commit }, text) {
    commit('TextActaInicio', text)
  },
  DeletePersonalInterviniente({ commit }, ind) {
    commit('DeletePersonalInterviniente', ind)
  },
  setPersonalInterviniente({ commit }, personalInt) {
    commit('setPersonalInterviniente', personalInt)
  },
  deleteDenuncianteDefault({ commit }) {
    commit('deleteDenuncianteDefault')
  },
  setDenuncianteDefault({ commit }) {
    commit('setDenuncianteDefault')
  },
  setDependencia({ commit, state }, dependencia) {
    commit('setDependencia', dependencia)
  },
  async resetState({ commit, rootState }) {
    // console.log("resetState")
    let empty = {
      ...emptyState(),
      dependencia: rootState.user.userdata.dependenciaLetraNombre,//policeStation.dependenciaLetra,
      idDependencia: rootState.user.userdata.dependenciaID,//policeStation.id,
      idUser: rootState.user.userdata.userId//uid
    }
    commit('resetTodo', empty);

  },

  async saveDenuncia({ commit, state, dispatch }) {
    try {
      // console.log("saveDenuncia", state.isSaving)
      if (state.isSaving) return
      commit('isSaving', true);
      commit('timerLoading', true)
      // console.log("saveDenuncia state.saveStatus", state.saveStatus, "state?.ActuacionID", state?.ActuacionID)
      if (state.saveStatus != 'new' && state?.ActuacionID?.search("offline-") !== 0) {
        // console.log("este usuario ya estaa creado")
        return;
      }
      
      var request
      let newStatus;
      let newId;
      console.log('por preparar');
      let objSumarioPost = await prepareDoc(state);
                
      if (Vue.prototype.$offline.onlineMode){
        let postData = {
          url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario`,
          formData: objSumarioPost
        }
        request = await Vue.prototype.$rest.post(postData);
        newId = request.data.id
        
        // guarda diligencias de la actuacion
        if (state?.id?.search("offline-") == 0) {
          let offline_id = state.id
          commit('saveId', newId)
          for (let i in state.diligencia) {
            console.log("dil", {...state.diligencia[i], siis_no_commit: true})
            await dispatch('addDiligencia', {...state.diligencia[i], siis_no_commit: true} )
          }

          //elimina la actuacion de offline
          let deleteParams = {
            update: {
              ActuacionID: offline_id
            },
            delete: true,
            data: false
          }
          await Vue.prototype.$offline.setItem("actuaciones", deleteParams);

        }
      }

      if (!Vue.prototype.$offline.onlineMode){ 
        console.log("saveDenuncia OFFLINE", Vue.prototype.$offline.offlineServer, state, state.id, typeof state.id)
        if (Vue.prototype.$offline.offlineServer && (!state.id || state.id == "new") ) {
          
          newId = "offline-" + Date.now()
          objSumarioPost.ActuacionID = newId;
          objSumarioPost.siis_state = state;
          request = await Vue.prototype.$offline.setItem(`actuaciones`, objSumarioPost)
        } else {
          throw 0;
        }
      }
      newStatus = 'save';
      commit('timerLoading', false)
      
      commit('saveId', newId)
      commit('saveDenuncia', newStatus);
      commit('isSaving', false);
      commit('saveEstadoActuacion', 'En Curso')//al crear la actuacion el estado estara por defecto "En Curso"
      if(objSumarioPost?.State?.idOffline !== undefined){
        const actuationsType = {
          actuationsRepository,
          actuacionesRepository
        }
        await actuationsType[objSumarioPost.State.type].delete(objSumarioPost.State.idOffline);
      }
      return Promise.resolve()
    }
    catch (e) {
      commit('timerLoading', false)
      let newId = "actualion-" + Date.now()
      localStorage.setItem(newId, JSON.stringify(state))
      if (e == 'Error: Request failed with status code 409') {
        alert("Se produjo un error al intentar guardar la Actuacion, corrobore los datos ingresado, si el problema continua póngase en contacto con el administrador del sistema")
        // console.log("dice: ",JSON.stringify(request));
      }
      if(e == 'Error: Request failed with status code 500')
      {
        alert("Se produjo un error al intentar guardar la Actuacion, corrobore los datos ingresado, si el problema continua póngase en contacto con el administrador del sistema\nERROR:"+e)
      }
      console.log("e", e);
      commit('isSaving', false);
      if(state?.idOffline === undefined){
        await actuationsRepository.addActuation({siis_state: state});
        Vue.prototype.$toast.open({
          message: "Creación de la actuacion exitosa",
          position: "top-right",
          type: "success",
          duration: 10000,
        });
        window.location.href = "/";//Comentar esta linea para que no redirecione luego de crear una Actuacion
      }
      return false
    }

  },
  async addCounterPrint({ commit }, payload) {
    try {
      if (Vue.prototype.$offline.onlineMode){

        let request = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/print/count`, payload, { withCredentials: true })
        console.log("Response addCounterPrint: ", request)
      }
    }
    catch (e) {
      console.log("error en la ruta del addCounterPrint", e)
    }
  },
  async addDiligencia({ commit, state }, payload) {
    return new Promise (async (resolve, reject) => {

      try {
        const htmlEntities = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&apos;"
        };
        let modify = "false"
        for (const property in state.textEditsCounter) {
          if (state.textEditsCounter[property] > 0) { modify = "true"; break; }
        }
        let {TamanoSangria, TipodiligenciaID} = payload;
        // console.log("modify?: ", modify)
        var diligencia = {
          "SumarioID": state.id,
          "Modificada": modify,
          TamanoSangria,
          TipodiligenciaID,
          "UsuarioID": payload.userId
        }

        if (payload.Contenido){
          diligencia.Contenido = payload.Contenido
          diligencia.Nombre = payload.Nombre
        } else {
          diligencia.Contenido = payload.documento.replace(/([&<>\"'])/g, match => htmlEntities[match])
          diligencia.Nombre = payload.tipo
        }

        if (Vue.prototype.$offline.onlineMode ){
          let optionsPost = {
            url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/diligencia/`,
            formData: diligencia,
            ContentType: "text/plain"
          }
          // console.log("Sumario.js--->Hace el Post", payload, payload.DiligenciaID)
          let request = await Vue.prototype.$rest.post(optionsPost);
          
          console.log("Sumario.js--->Vuelve del post")


          // cuando la diligencia ya tiene un id no hacer commit ( esto es)
          if (!payload.siis_no_commit){
            diligencia["Fechainivig"] = "Hace un Minuto";
            diligencia["DiligenciaID"] = request.data.id;
            commit('sumario/addDiligencia', diligencia);
          } else {

            //viene de saveDenuncia y es offline
            if (payload.DiligenciaID && payload.DiligenciaID.search("offline-") == 0){

              let deleteParams = {
                update: {
                  DiligenciaID: payload.DiligenciaID
                },
                delete: true,
                data: false
              }
              await Vue.prototype.$offline.setItem("diligencias", deleteParams);
            }
          }
        } else {
          if (!payload.siis_no_commit){
            let date = new Date();
            diligencia["Fechainivig"] = date.toLocaleString();
            diligencia["DiligenciaID"] = "offline-" + Date.now();
            let request = await Vue.prototype.$offline.setItem("diligencias", diligencia);
            commit('sumario/addDiligencia', diligencia);
          }
        }
        return resolve()
      }
      catch (e) {

        console.error("e", e);
        return reject()
      }
    })
  },

  async addCounterPrint({ commit }, payload) {
    try {
      if (Vue.prototype.$offline.onlineMode){

        let request = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/print/count`, payload, { withCredentials: true })
        console.log("Response addCounterPrint: ", request)
      }
    }
    catch (e) {
      console.log("error en la ruta del addCounterPrint", e)
    }
  },
  async addDiligencia({ commit, state }, payload) {
    return new Promise (async (resolve, reject) => {

      try {
        const htmlEntities = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&apos;"
        };
        let modify = "false"
        for (const property in state.textEditsCounter) {
          if (state.textEditsCounter[property] > 0) { modify = "true"; break; }
        }
        let {TamanoSangria, TipodiligenciaID} = payload;
        // console.log("modify?: ", modify)
        var diligencia = {
          "SumarioID": state.id,
          "Modificada": modify,
          TamanoSangria,
          TipodiligenciaID,
          "UsuarioID": payload.userId,
          // assigns the actual date in the format YYYY-MM-DD HH:MM:SS with gmt-3
          "Fechainivig":  new Date().toLocaleString('es-AR', {
            timeZone: 'America/Argentina/Buenos_Aires',
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }).replace(/\//g, "-").replace(",", "")
        }

        if (payload.Contenido){
          diligencia.Contenido = payload.Contenido
          diligencia.Nombre = payload.Nombre
        } else {
          diligencia.Contenido = payload.documento.replace(/([&<>\"'])/g, match => htmlEntities[match])
          diligencia.Nombre = payload.tipo
        }

        if (Vue.prototype.$offline.onlineMode ){
          let optionsPost = {
            url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/diligencia/`,
            formData: diligencia,
            ContentType: "text/plain"
          }
          // console.log("Sumario.js--->Hace el Post", payload, payload.DiligenciaID)
          let request = await Vue.prototype.$rest.post(optionsPost);
          
          console.log("Sumario.js--->Vuelve del post")


          // cuando la diligencia ya tiene un id no hacer commit ( esto es)
          if (!payload.siis_no_commit){
            diligencia["Fechainivig"] = "Hace un Minuto";
            diligencia["DiligenciaID"] = request.data.id;
            commit('sumario/addDiligencia', diligencia);
          } else {

            //viene de saveDenuncia y es offline
            if (payload.DiligenciaID && payload.DiligenciaID.search("offline-") == 0){

              let deleteParams = {
                update: {
                  DiligenciaID: payload.DiligenciaID
                },
                delete: true,
                data: false
              }
              await Vue.prototype.$offline.setItem("diligencias", deleteParams);
            }
          }
        } else {
          if (!payload.siis_no_commit){
            let date = new Date();
            diligencia["Fechainivig"] = date.toLocaleString();
            diligencia["DiligenciaID"] = "offline-" + Date.now();
            let request = await Vue.prototype.$offline.setItem("diligencias", diligencia);
            commit('sumario/addDiligencia', diligencia);
          }
        }
        return resolve()
      }
      catch (e) {

        console.error("e", e);
        return reject()
      }
    })
  },
  async deleteDiligencia({ commit, dispatch, state }, arrId) {

    try {
      let resp = await Vue.prototype.$rest.delete({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/diligencia/${arrId}`});
      // console.log("resp completo: ",JSON.stringify(resp));
      // console.log("error: ", resp.data.error)
      if(resp.data.error == "")
      {
        commit('deleteDiligencia', arrId)
        return true;
      }else
      {
        return false;
      }
    }
    catch (e) {
      console.error("e", e);
    }
  },
  async editDiligencia({ dispatch, state }, payload) {
    try {
      const htmlEntities = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&apos;"
      };
      let modify = "true"
      let {TamanoSangria} = payload;

      var diligencia = {
        "Contenido": payload.documento.replace(/([&<>\"'])/g, match => htmlEntities[match]),
        "Nombre": payload.tipo,
        "SumarioID": state.id,
        "Modificada": modify,
        "DiligenciaID": payload.DiligenciaID || payload.diligenciaID,
        "TamanoSangria": TamanoSangria
      }
      if (Vue.prototype.$offline.onlineMode && payload.DiligenciaID.search("offline-") < 0){
        let optionsPut = {
          url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/diligencia/${diligencia.DiligenciaID}`,
          formData: diligencia,
          ContentType: "text/plain"
        }
        await Vue.prototype.$rest.put(optionsPut);
        // let request = await axios.put(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/diligencia/${payload.diligenciaID}`, diligencia, { ContentType: "text/plain", withCredentials: true });
        // // Agregamos estos 2 atributos a diligencia y la cargamos en el store para no volver a consultar la BD
        // diligencia["Fechainivig"] = "Hace un Minuto";
        // diligencia["DiligenciaID"] = request.data.id;
      } else {
        diligencia.Fechainivig = payload.Fechainivig
        let updateData = {
          update: {
            DiligenciaID: payload.DiligenciaID
          },
          data: diligencia
        }
        await Vue.prototype.$offline.setItem("diligencias", updateData)
      }

    }
    catch (e) {
      console.error("e", e);
    }
  },


  async editOrderTablePrintFromDiligencia({ commit, state, dispatch }, payload) {
    try {
      dispatch('spinnerOrderTablePrintFrom', true)
      // console.log("payload.orderTablePrint", payload.OrderTablePrint)
      var diligencia = {
        // "orderTablePrint": payload.orderTablePrint,
        "OrderTablePrint": payload.OrderTablePrint,
        "SumarioID": state.id,
      }
      let optionsPut = {
        url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/diligencia/${payload.diligenciaID}`,
        formData: diligencia,
        ContentType: "text/plain"
      }
      console.log("payload", diligencia)
      let request = await  Vue.prototype.$rest.put(optionsPut);
      // if(request.data.includes('"affected_rows\":0')){
      if(request.data.affected_rows === 0){
        console.error("Falló la modificación de la base de datos con ", payload.name, "intentando asignarle el número ", payload.OrderTablePrint, request);
        setTimeout(()=>{
          dispatch('spinnerOrderTablePrintFrom', false)
        }, 2000)
      } else{
        // console.log("La modificación en la base de datos tuvo éxito con ", payload.name, "intentando asignarle el número ", payload.OrderTablePrint, request);
        setTimeout(()=>{
          dispatch('spinnerOrderTablePrintFrom', false)
        }, 2000)
      }
      // commit("editOrderTablePrintFromDiligencia", {diligenciaID: payload.diligenciaID, OrderTablePrint: payload.OrderTablePrint})
    }
    catch (e) {
      console.error("e", e);
    }
  },
  async editOrderTableForFiles({ commit, state, dispatch }, payload) {
    try {
      dispatch('spinnerOrderTablePrintFrom', true)
      // console.log("payload.orderTablePrint", payload.OrderTablePrint)
      // console.log("payload", payload)
      var archivo = {
        // "orderTablePrint": payload.orderTablePrint,
        "Filename": payload.name,
        "OrderTable": payload.OrderTablePrint,
        "ActuacionID": state.id,
        // "FechaCreacion": payload.FechaCreacion,
        "Extension": payload.Extension,
      }


      let optionsPut = {
        url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/sumario/${payload.ActuacionArchivoID}`,
        formData: archivo,
        ContentType: "text/plain"
      }
      let request = await  Vue.prototype.$rest.put(optionsPut);
      // if(request.data.includes('"affected_rows\":0')){
      if(request.data.affected_rows === 0){
        console.error("Falló la modificación de la base de datos con ", payload.name, "intentando asignarle el número ", payload.OrderTablePrint, request);
        setTimeout(()=>{
          dispatch('spinnerOrderTablePrintFrom', false)
        }, 2000)
      } else{
        console.log("La modificación en la base de datos tuvo éxito con ", payload.name, "intentando asignarle el número ", payload.OrderTablePrint, request);
        setTimeout(()=>{
          dispatch('spinnerOrderTablePrintFrom', false)
        }, 2000)
      }
    }
    catch (e) {
      console.error("e", e);
    }
  },
  spinnerOrderTablePrintFrom({ commit, state }, payload){
    commit("spinnerOrderTablePrintFrom", payload)
  },

  async getDependencyEmail({ commit }, payload) {
    try {
      console.log('payload', payload);
      let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/dependencia/${payload}`
      const r = await Vue.prototype.$rest.get({url})
      console.log('r', r.data.data[0].CorreoElectronico);
      commit("setDependencyEmail", r.data.data[0].CorreoElectronico)
    }
    catch (e) {
      console.error("e", e);
    }
  },

  async setSumario({ commit }, payload) {
    if (typeof payload === 'string') {
      if (Vue.prototype.$offline.onlineMode && payload.search("offline-") != 0){
        let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${payload}`;
        const r = await Vue.prototype.$rest.get({url})
        payload = r.data.data[0]
        payload.effectsOld = "EfectosOld" in  payload ? payload["EfectosOld"].map(item => mapEffectItem(item, false)) : [];

        payload.effectsNew = "Efectos" in payload ? payload["Efectos"].map(item => mapEffectItem(item, true)) : [];
        payload.effects = [...payload.effectsOld, ...payload.effectsNew];


      } else {
        const r = await Vue.prototype.$offline.getItem("actuaciones", { ActuacionID: payload })
        const rdil = await Vue.prototype.$offline.getItem("diligencias", { SumarioID: payload })

        payload = r.data.data[0]
        payload.siis_state.diligencia = rdil.data.data;
        payload.siis_state.id = payload.ActuacionID
        payload.siis_state.ActuacionID = payload.ActuacionID
        console.log("desde offline", payload)
      }

    }

    payload.saveStatus = 'save';
    payload.isSaving = false;
    await commit('setSumario', payload)
    return Promise.resolve()
  },
  async deleteActuacion({ commit}, payload)
  {
    try {

      let resp = await Vue.prototype.$rest.delete({url:`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${payload}`});
      if(resp.data.error == "")
      {
        return true;
      }else
      {
        return false;
      }
    }
    catch (e) {
      console.error("e", e);
    }
  },
  setDenuncia({ commit }, data) {
    commit('setDenuncia', data)
  },
  async setValue({ commit }, payload) {
    return new Promise (async (resolve, reject) => {
      try {
        await commit('setValue', payload)
        return resolve()
      }
      catch (e) {
        console.error("e", e);
        return reject()
      }
    })
  },
  refreshDate({ commit }, data) {
    commit('refreshDate', data)
  },
  modifyDate({ commit }, data) {
    commit('modifyDate', data)
  },
  addEditorElement({ commit }, data) {
    commit('addEditorElement', data)
  },
  addEditorInput({ commit }, data) {
    commit('addEditorInput', data)
  },
  addEditorElementValue({ commit }, data) {
    commit('addEditorElementValue', data)
  },
  addEditorElementArrId({ commit }, data) {
    commit('addEditorElementArrId', data)
  },
  setUbicacion({ commit }, ubicacion) {
    commit('setUbicacion', ubicacion)
  },
  setPreUbicacion({ commit }, ubicacion) {
    commit('setPreUbicacion', ubicacion)
  },
  setMoment({ commit }, data) {
    commit('setMoment', data)
  },
  setPreMoment({ commit }, data) {
    // console.log("setPreMoment", data)
    commit('setPreMoment', data)
  },
  setType({ commit }, data) {
    // console.log("setType", data)
    commit('setType', data)
  },


  setformType({ commit }, data) {
    commit('setformType', data)
  },
  setDeposition({ commit }, data) {
    commit('setDeposition', data)
  },
  setEditMode({ commit }, data) {
    commit('setEditMode', data)
  },
}

const getters = {
  dateDenuncia: state => {
    let fecha = state.fechaDenuncia
    if (typeof fecha == 'string') fecha = new Date(fecha)

    return state.fechaDenuncia !== null ? fecha.toLocaleString('es-AR') : null
  },
  getIndexDiligenciaByArrId: (state) => (arrId) => {
    return state.list.findIndex(todo => todo.arrId === arrId)
  },
  dataResumen: getter.resumen

}
// mutations
const mutations = {

  TextEstaticos(state, payload) {
    state.textEstaticos[payload.field] = payload.value
  },
  textResetEditsCounter(state, payload) {
    state.textEditsCounter[payload]=0;
  },
  textEditsCounter(state, payload) {
    state.textEditsCounter[payload.field]++
  },
  TextActaInicio(state, text) {
    state.actaInicio = text
  },
  DeletePersonalInterviniente(state, ind) {
    state.PersonalInterviniente.splice(ind, 1);
  },
  setPersonalInterviniente(state, payload) {
    state.PersonalInterviniente.push(payload)
  },
  deleteDenuncianteDefault(state) {
    state.denunciante.list.pop()
  },
  setDenuncianteDefault(state) {
    //con unshift insertamos al principio del arreglo denunciante
    state.denunciante.list.unshift(({
      "arrId": "DenunciantePorDefectoParaContravencional",
      "doc":
      {
        "instruccion": {},
        "nacionalidad": {},
        "documento": '',
        "apellido": '',
        "nombre": '',
        "sexo": {},
        "arrId": "DenunciantePorDefectoParaContravencional"
      },
      "type": "doc", "ordenPublico": true
    }))
  },
  setDependencia(state, payload) {
    state.dependencia = payload
  },
  resetTodo(state, payload) {
    for (let i in payload) {
      state[i] = payload[i]
    }
    //el state.moment y el PersonalInterviniente lo reseteo desde aqui por que no se estaban borrando sus datos
    state.moment = []
    state.PersonalInterviniente = []
    state.ubicacion = ''
    state.preUbicacion = ''
    state.textEditsCounter.Contravener = 0
    state.textEditsCounter.Preform = 0
    state.textEditsCounter.Postform = 0
    state.textEditsCounter.LegalData = 0
    state.textEditsCounter.instructionDecree = 0    
    state.textEditsCounter.postformSumarioOficio = 0

    state.textEstaticos.Contravener = ""
    state.textEstaticos.Preform = ""
    state.textEditsCounter.Postform = ""
    state.textEditsCounter.LegalData = ""
    state.textEditsCounter.instructionDecree = ""    
    state.textEditsCounter.postformSumarioOficio = ""
    state.id = "new"

  },
  refreshDate(state, data) {
    state.fechaDenuncia = data
  },
  modifyDate(state, data) {
    state.fechaDenuncia = data
  },
  saveDenuncia(state, newStatus) {
    state.saveStatus = newStatus
  },
  saveId(state, newId) {
    state.id = newId
  },
  isSaving(state, newStatus) {
    state.isSaving = newStatus
  },
  timerLoading(state, newTimer) {
    state.timer = newTimer
  },
  setDenuncia(state, data) {
    state.denuncia = data
  },
  setSumario(state, payload) {
    let effectsType = state.effectsType;
    let empty = emptyState()
    Object.keys(state).forEach(key => {
      state[key] = empty[key]; // or = initialState[key]
    });
    state.effectsType = effectsType;
    if (payload.siis_state){
      payload.siis_state.isSaving = false
      Object.keys(payload.siis_state).forEach(key => {
        state[key] = payload.siis_state[key]; // or = initialState[key]
      });
      return Promise.resolve()
    }
    state.DependenciaID= payload.DependenciaID
    state.id = payload.ActuacionID

    // Datos del secretario Original y Actual
    // console.log(payload.ActuacionUsuarioDependencia[0])
    if(payload.ActuacionUsuarioDependencia[0] != undefined )//verificamos que la Api nos retorne almenos un UsuarioSumario
    {
      let lastElement = (payload.ActuacionUsuarioDependencia.findIndex(e => e.Fechahasta == null));
      // console.log("lastElement: ",lastElement)
      state.ActuacionUsuarioDependencia = payload.ActuacionUsuarioDependencia
      // console.log("payload.ActuacionUsuarioDependencia: ", payload.ActuacionUsuarioDependencia)
      state.actuacionUsuarioDependenciaID = lastElement != -1 ? payload.ActuacionUsuarioDependencia[lastElement].ActuacionUsuarioDependenciaID : ''
      // console.log("actuacionUsuarioDependenciaID: ", state.actuacionUsuarioDependenciaID )

    }else// en caso de que no retorne ninguno, informamos el error
    {
      state.ActuacionUsuarioDependencia  = [{info :"Usuario eliminado", Fechahasta:"fecha de simulacro"}]//Este caso implica que a ese sumario, se le borro la persona fisica asociada a este usuario
    }

    // state.dependenciaPolicial = payload.DependenciaDependenciaLetra
    state.dependenciaPolicial = payload.DependenciaLetra

    state.dependenciaDepartamento = payload.DependenciaDepartamento
    state.dependenciaDomicilio = payload.DependenciaDomicilio
    // state.dependenciaID = payload.SumarioDependenciaID
    state.dependenciaID = payload.DependenciaID
  

    state.PersonalInterviniente = payload.PersonalInterviniente || []

    state.modusOperandi = payload.Modusoperandi != undefined ? payload.Modusoperandi.Nombre : ''
    // state.causaCaratula = payload.SumarioCausaCaratula
    state.causaCaratula = payload.CausaCaratula

    state.causaJuzgado = payload.Roljuzgadosumario

    //la fechaCertificadoDenuncia y la fecha de la denuncia son diferente, pero actualmente ambas se alimentan de .SumarioFechaCreacion para no romper el esquema que se
    state.fechaCertificadoDenuncia = payload.FechaCreacion
    state.fechaDenuncia = payload.FechaCreacion

    state.causaNumero = payload.Numero

    state.formType = payload.TiposumarioID


    state.preUbicacion.calle = payload.HechoubicacionCalle
    state.preUbicacion.numero = payload.HechoubicacionNumero
    state.preUbicacion.departamento = payload.HechoubicacionDepartamento
    state.preUbicacion.casa = payload.HechoubicacionCasa
    state.preUbicacion.orientacion = payload.HechoubicacionOrientacion
    state.preUbicacion.barrio = payload.HechoubicacionBarrio
    state.preUbicacion.piso = payload.HechoubicacionPiso
    state.preUbicacion.zona = payload.HechoubicacionZona
    state.preUbicacion.sector = payload.HechoubicacionSector
    state.preUbicacion.coordinates = {lng : payload.HechoubicacionLongitud,
      lat : payload.HechoubicacionLatitud}
    state.denunciaText = payload.DenunciaRelato

    state.moment.date = payload.HechoubicacionFechahoradesde
    state.moment.date2 = payload.HechoubicacionFechahorahasta

    //cargamos en el store los denunciantes , acusados y las diligencias para poder mostrarlos
    state.diligencia = payload["Diligencia"] || []
    loadingDenunciantesAndAcusados(state, payload)
    // state.robados.list = payload[state.effectsType] !== undefined ? payload[state.effectsType] : []
    // console.log("en el state payload: ",payload.effects);
    state.robados.list = payload.effects || []
    state.listaArticulos = payload.ActuacionArticulo

    //datos nescesarios para actualizar sumario
    state.denunciaID= payload.DenunciaID
    state.tiposumarioID= payload.TiposumarioID
    state.listaCaratulas = payload.Sumariocausacaratula
    state.Sitio = payload.Sitio
    // console.log('state', state)
    // state.listaCaratulas = payload.causasList

    // state.sumarioEstado = payload.Sumarioestado[0].EstadosumarioID || "sin estado asignado"
    // state.sumarioEstadoID = payload.Sumarioestado[0].SumarioestadoID || "sin estado asignado"
    state.sumarioEstado = payload.Estado|| "sin estado asignado"
    state.sumarioEstadoID = payload.EstadoActuacionID || "sin estado asignado"

    //Datos para Ufi
    if (payload.Sumarioufi) {
      state.SumarioUfi= payload.Sumarioufi[0]
      if (payload.Sumariopersonalfiscal) {
        // state.prosecutor = payload.Sumariopersonalfiscal[0]
        // state.assistant = payload.Sumariopersonalfiscal[1]
        state.prosecutor = payload.Sumariopersonalfiscal.find(item => item.Jerarquia == 'Fiscal')
        state.assistant = payload.Sumariopersonalfiscal.find(item => item.Jerarquia == 'Ayudante')
        // console.log("PROSECUTPR: ",state.prosecutor, "\nassistant: ",state.assistant)
      }
    }
    return Promise.resolve()
  },
  saveEstadoActuacion(state, payload)
  {
    state.sumarioEstado = payload
  },
  async deleteActuacion(id){

  },
  setValue(state, payload) {
    return new Promise (async (resolve, reject) => {
      try {
        state[payload.field] = payload.value
        return resolve()
      }
      catch (e) {
        console.error("e", e);
        return reject()
      }
    })
  },
  addEditorElement(state, data) {
    state.denuncia.splice(data[0], 0, data[1], { name: "InputNestor", value: "" })
  },

  addEditorInput(state, data) {
    // console.log("addEditorInput", data)
    state.denuncia = [
      ...state.denuncia.slice(0, data[0]),
      { name: "InputNestor", value: data[1] },
      ...state.denuncia.slice(data[0])
    ]
    // .splice(data[0], 0, {name: "InputNestor", value:data[1]})
  },
  addEditorElementValue(state, data) {
    state.denuncia[data[0]].value = data[1]
  },
  addEditorElementArrId(state, data) {
    state.denuncia[data[0]].arrId = data[1]
  },
  setUbicacion(state, ubicacion) {
    state.ubicacion = ubicacion
  },
  setPreUbicacion(state, ubicacion) {
    state.preUbicacion = ubicacion
  },
  setMoment(state, data) {
    state.moment = data
  },
  setPreMoment(state, data) {
    state.preMoment = data
  },
  setType(state, data) {
    state.type = data
  },
  setformType(state, data) {
    // console.log("------------- mutation ahora estamos en el setformTyPE---------- data: "+JSON.stringify (data))
    state.formType = data
  },
  setDeposition(state, data) {
    // console.log("------------- mutation ahora estamos en el setformTyPE---------- data: "+JSON.stringify (data))
    state.deposition = data
  },
  editOrderTablePrintFromDiligencia(state, data){
    console.log( state.diligencia)
    let diligenceToEdit = state.diligencia.find(diligence => diligence.DiligenciaID === data.diligenciaID)
    let diligenceToEditIndex = state.diligencia.indexOf( diligenceToEdit )
    state.diligencia[diligenceToEditIndex].OrderTablePrint = data.OrderTablePrint
    // if(data.OrderTablePrint === state.diligencia.length)
    //   spinnerOrderTablePrintFrom(false)
  },
  spinnerOrderTablePrintFrom(state, data){
    state.spinnerOrderTablePrintFrom = data
  },
  deleteDiligencia(state, data){
    let indice = state.diligencia.findIndex( element => element.DiligenciaID == data)
    state.diligencia.splice(indice, 1)
  },
  setEditMode(state, data){
    state.actuationEditModeActive = data;
  },
  setDependencyEmail(state, data){
    state.dependencyEmail = data;
  },
}

function mapEffectItem(item, isNewType) {
  const commonFields = {
    Color: item.Color,
    newFormatEffect: isNewType 
  };

  if (isNewType) {
    return {
      type: "doc",
      doc: {
        ...commonFields,
        Abonado: item.Abonado,
        Anio: item.Anio,
        Chasis: item.Chasis,
        Color: item.Color,
        Dominio: item.Dominio,
        CategoriaID: item.CategoriaID,
        MarcaID: item.MarcaID,
        ModeloID: item.ModeloID,
        SubcategoriaID: item.SubcategoriaID,
        TipoID: item.TipoID,
        Imei: item.Imei,
        NumeroMotor: item.NumeroMotor,
        NumeroSerie: item.NumeroSerie,
        Tipoefecto: item.Tipoefecto,
        EfectoDepositoID: item.EfectoDepositoID,
        EstadoEfectoActuacionID: item.EstadoEfectoActuacionID,
        ActuacionEfectoID: item.ActuacionEfectoID,
        newFormatEffect: true
      }
    };
  } else {
    return {
      type: "doc",
      doc: {
        ...commonFields,
        color: item.ColorOld,
        details: item.Detalles,
        marca: item.Marca,
        modelo: item.Modelo,
        numId: item.IdSeriePatente,
        tipo: item.Tipo,
        tipoEfecto: item.EstadoEfectoActuacionID,
        tieneSeguro: item.TieneSeguro,
        EfectoID: item.EfectoID,
        ActuacionEfectoID: item.ActuacionEfectoID,
        newFormatEffect: false 
      }
    };
  }
}
function loadingDenunciantesAndAcusados(state, payload){
      state.denunciante.list = payload.ActuacionPersonaFisica["AFECTADO"] || [];
      state.acusados.list = payload.ActuacionPersonaFisica["VINCULADO"]  || [];
      return ;
}

function fechaYhora(data) {
  //convertimos la hora a un formato entendible
  var fecha = data.toISOString().slice(0, 10).replace('T', ' ')

  //separamos la hora y le sumamos +3 horas que se restan por la zona horaria
  var dia = new Date(data);
  var hora = (dia.getHours()) + ':' + dia.getMinutes() + ':' + dia.getSeconds();
  //unimos hora y fecha
  var fechaYhora = fecha + ' ' + hora;

  return fechaYhora
}

function transformConditionToJSON(condition) {
  switch (condition) {
    case 'Victima':
      return { id: "victima", nombre: "Víctima" };
    case 'Peaton':
      return { id: "Peatón", nombre: "Peatón" };
    default: 
      return { id: condition, nombre: condition };
  }
}

export default {
  emptyObject,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    acusados,
    robados,
    denunciante,
    diligencia,
    afectacionPatrimonial
    
  }
}
