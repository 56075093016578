

<template>
   <div class="content borderShadow"  tabindex="0">
        <Card 
          :class="{
            'passed': calculatePassedCard(),
            'warning':calculateWarningCard(),
            'error': calculateErrorCard(),
          }" 
          test-id="Resumen"
        >
          <template #title>
            <div class="title-toolbar">
              <div class="title" test-id="ResumenTitle">
                {{title}}
              </div> 
              <Button icon="pi pi-plus" v-if="showPlusButton" ref="cardFocus" class="p-button-rounded p-button-info p-button-outlined"  @click="$router.push({path: editPath, query: {id: 'new'}})"  test-id="ResumenPlusIcon"/>
            </div>
          </template>
          <template #content>
              <slot>
              </slot>
          </template>
        </Card>
    </div>
</template>

<script>

export default {
  props: {
    showPlusButton: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    editPath: {
      type: String,
      default: null
    },
    warning: {
      type: [String, Array]
    },
    errors: {
      type: [String, Array]
    },
    formType: {
      type: String,
      default: null
    },
    showValidation: {
      type: Boolean,
      default: false
    },
    viewData: {
      type: Object,
    },
  },
  name: 'ResumenCard',
  data() { 
    return {
    }
  },
  // mounted(){
    // console.log('alertas()', this.alertas(), this.title)
  // },
  methods: {
    alertas()
    {
      // console.log("this.title : ",this.title);
      // console.log('this.errors', this.errors);
      if(this.title == "Acusado" && !this.warning.includes("No especificó un acusado")){
        return "ok" 
      }
      if(this.title == "Contraventor/a" && !this.warning.includes("No especificó un acusado")){
        return "ok" 
      }else{
        if(this.title == "Aprehendido y/o acusado" && !this.errors.includes("Falta especificar un acusado")){
          return "ok" 
        }else{
          if(this.title == "Autolesionado" && !this.errors.includes("Debe especificar al menos un Autolesionado"))
          {
            console.log("retorna ok")
            return "ok" 
          }
        }
      }
      if((this.title == "Efectos secuestrados" || this.title == "Efectos") && !this.warning.includes("No especificó ningún efecto denunciado"))
      {
        return "ok" 
      }
      if(this.title == "Causante" && !this.errors.includes("Falta especificar un causante"))
      {
        return "ok" 
      }
      return "false"
    }, 
    calculatePassedCard(){
      return this.viewData.resumenCardPassed && this.alertas() == 'ok' && this.showValidation == true;
    },
    calculateWarningCard(){
      return this.alertas() != 'ok' && this.showValidation == true &&
      (
        (
          this.viewData.resumenCardWarning === true
        ) ||
        (
          this.formType.startsWith('ufi') && 
          (this.title == 'Efectos secuestrados' || this.title == 'Efectos')
        ) 
      );
    },
    calculateErrorCard(){
      return (
        this.alertas() != 'ok' && 
        this.showValidation == true &&
        (
          this.formType.startsWith('ufi')||
          ( 
            (this.formType === 'preliminar-tentativa-suicidio' && this.title == 'Autolesionado')) || 
            (this.formType === 'preliminar-fuga-menor' && this.title == 'Causante')
          ) 
      ) && 
      (this.title != 'Efectos secuestrados' || this.title != 'Efectos');
    }
  }
}
</script>
<style scoped>
  .content {
    width: 100%;
  }
  div.card {
    width: 100%;
  }
  .borderShadow:focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
    outline: none;
  }
  .title {
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 1rem;
  }
  .title-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .passed{
    border-bottom: solid 2px #28a745 ;
    /* background-color: #28a745; */ 
  }
  .error{
     border-bottom: solid 2px #dc3545;
  }
  .warning{
    border-bottom: solid 2px #ffc107
  }  
  .alert
  {
    color: #dc3545;
  }
</style>
