export const sumarioDenunciaConfig = [
    {
        path: "/actuaciones/new/sumario-denuncia/initial",
        pathName: "sumario-denuncia", 
        titulo: "PREVENCIONAL DENUNCIA",
        icon: "pi-briefcase",
        roles: [
          "User Tester",
          "Super User",
          "Jefe de Dependencia",
          "Oficial Sumariante",
          "Jefe de Sumario",
        ],
        type: "link",
        grupo: "SUMARIOS",
        props: {
          formType: "denuncia",
          actuacionType: "sumario-denuncia",      
        },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/sumario-denuncia/initial/denunciante",
        pathName: "sumario-denuncia",
        titulo: "DENUNCIANTE",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/sumario-denuncia/initial/momento",
        pathName: "sumario-denuncia",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/sumario-denuncia/initial/acusado",
        pathName: "sumario-denuncia",
        titulo: "ACUSADO",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/sumario-denuncia/initial/efectos",
        pathName: "sumario-denuncia",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]