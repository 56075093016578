import actuationsRepository from '@/../dixieDB/repositories/actuationsRepository';
import actuacionesRepository from "@/../dixieDB/app-offline/repositories/actuacionesRepository";

export default  {
  data() {
    return {
      currentTarget: null,
      deleteConfirmationInProgress: false,
      loadingDeleteButton: ''
    };
  },
  methods: {
    deleteConfirmation(event, type, arrId) {
      this.currentTarget = event.currentTarget;
      this.deleteConfirmationInProgress = true;
      let message = "¿Desea eliminar ";
      if (type === "momento") {
        message = message + "esta ubicación y este ";
      } else if(type === "víctima o damnificado" || type === "imagen" || type === "Actuación") {
        message = message + "esta ";
      } else{
        message = message + "este ";
      }
      message = message + type;
      message = message + "?";
      this.$confirm.require({
        target: this.currentTarget,
        group: "demo",
        message: message,
        icon: "pi pi-question-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Sí",
        rejectLabel: "No",
        accept: async () => {
          if (type === "contraventor" || type === "aprehendido" || type === "acusado") {
            this.$store.dispatch("sumario/acusados/deleteAcusadoByArrId", arrId);
            this.deleteConfirmationInProgress = false;

            //reinicia preform o postform
            let actualPath = this.$router.history.current.path.split("/");
            if (actualPath[actualPath.length - 1] !== "denuncia"){
              if(actualPath[actualPath.length - 1] == "ufi-flagrancia")  this.$store.dispatch("sumario/textResetEditsCounter", "instructionDecree") 
              else this.$store.dispatch("sumario/textResetEditsCounter", "Contravener") 
            }
            else this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
          }
          if (type === "efecto secuestrado") {
            this.$store.dispatch("sumario/robados/deleteRobadoByArrId", arrId);
            this.deleteConfirmationInProgress = false;

            //reinicia preform o postform
            let actualPath = this.$router.history.current.path.split("/");
            if (actualPath[actualPath.length - 1] !== "denuncia")
              if(actualPath[actualPath.length - 1] == "ufi-flagrancia")  this.$store.dispatch("sumario/textResetEditsCounter", "instructionDecree") 
              else this.$store.dispatch("sumario/textResetEditsCounter", "Contravener");
          }
          if (type === "afectacion patrimonial") {
            this.$store.dispatch('sumario/afectacionPatrimonial/deleteAfectacionByArrId', arrId);
            this.deleteConfirmationInProgress = false;

            //reinicia preform o postform
            let actualPath = this.$router.history.current.path.split("/");
            if (actualPath[actualPath.length - 1] !== "denuncia")
              if(actualPath[actualPath.length - 1] == "ufi-flagrancia")  this.$store.dispatch("sumario/textResetEditsCounter", "instructionDecree") 
              else this.$store.dispatch("sumario/textResetEditsCounter", "Contravener");
          }
          if (type === "denunciante o damnificado" || type === "víctima" || type === "denunciante" ||type === "víctima o damnificado") {
            this.$store.dispatch("sumario/denunciante/deleteDenuncianteByArrId", arrId);
            this.deleteConfirmationInProgress = false;
            
            //reinicia preform o postform
            let actualPath = this.$router.history.current.path.split("/");
            if (actualPath[actualPath.length - 1] == "denuncia")
              this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
          }
          if (type === "momento") {
            this.$refs.Moment.date = undefined;
            this.$refs.Moment.date2 = undefined;
            this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
            this.$store.dispatch("sumario/setPreUbicacion", {});
            this.$store.dispatch("sumario/setUbicacion", {});

            //reinicia preform o postform
            let actualPath = this.$router.history.current.path.split("/");
            if (actualPath[actualPath.length - 1] !== "denuncia")
              this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
            this.deleteConfirmationInProgress = false;
          }
          if (type === "personal interviniente") {
            this.$store.dispatch("sumario/DeletePersonalInterviniente", arrId);
            this.deleteConfirmationInProgress = false;

            //reinicia preform o postform
            this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
          }
          if (type === "imagen") {
            //adding spinner into delete button with his specific 'arrId'
            this.loadingDeleteButton = arrId;
            let response = this.$store.dispatch("siis/deleteImageFromGallery", arrId);
            response.then((result) => {
              // console.log('result', result);
              this.loadingDeleteButton = '';
              this.params.images.gallery.list = this.params.images.gallery.list.filter(image => image.File_ArticuloDepositoID != arrId);
              this.deleteConfirmationInProgress = false;
            });
          }
          if (type === "Actuación") {
            const actuationsType = {
              actuationsRepository,
              actuacionesRepository
            }
            actuationsType[arrId.type].delete(arrId.id).then(() => {
              return actuationsRepository.getAllActuations(actuacionesRepository.getAll());
            }).then((actuationsUpdated) => {
              this.actuationsOffline = actuationsUpdated;
            }).catch((error) => {
              console.error('Error:', error);
            });
          }
          this.currentTarget = null;
        },
        reject: () => {
          this.deleteConfirmationInProgress = false;
          this.currentTarget = null;
        },
      });
    },
    modalVisible() {
      if (this.$refs.ConfirmPopup === undefined) {
        return false;
      } else {
        return this.$refs.ConfirmPopup.visible;
      }
    },
    closeConfirmpopupWithDelay(){
      //delay with setTimeout for prevent the close of modal before touch the buttons "Sí" or "No" 
      setTimeout(() => {
        if(this.loadingDeleteButton === '')
          this.deleteConfirmationInProgress = false;
        this.$refs.ConfirmPopup.visible = false;
      }, 1000);
    },
  }
};