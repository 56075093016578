import Vue from 'vue'

export default {
  data: () => ({
    prosecutorUserTypes: {},
  }),
 async mounted() {
    let url = process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-usuario?TipoUsuarioPadre=Fiscal";
    const result = await Vue.prototype.$rest.get({url})
    this.prosecutorUserTypes = result.data.data;
    this.cleanData();
  },
  methods: {
    cleanData() {
      let prosecutorUserTypesNames = [];
      this.prosecutorUserTypes.map(userType => {
        prosecutorUserTypesNames.push(userType.Nombre);
      });
      this.prosecutorUserTypes = prosecutorUserTypesNames;
    },
  },
}