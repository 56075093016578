// composable siis_utils.js version
import { useSiisToast } from './useSiisToast';
import axios from 'axios';
import Vue from 'vue'

export function useRest() {
    const toast = useSiisToast();
    
    function get(options) {
        return new Promise(async(resolve, reject) => {
            try {
                if ( typeof options === 'string' ) options = { url: options }
                if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
                let params = options.params || {}
                let response = await axios.get(options.url, { withCredentials: true, params }, {
                    headers: {...options.headers }
                })
                // console.log("response get", response)
                let result = await processResponse(response, options)
                return resolve(result);

            } catch (e) {
                console.log("response get error", e)            
                processResponse(e.response, options)                
                return reject(e)
 
            }
        })
    }

    function post(options) {
        return new Promise(async(resolve, reject) => {
            try {
                if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
                let params = options.params || {}
                let response = await axios.post(options.url, options.formData, { withCredentials: true, params }, {
                    headers: {...options.headers }
                })
                let result = await processResponse(response, options)
                return resolve(result);

            } catch (e) {
                // console.log("sale x aqui", e.response, e)
               
                return processResponse(e.response, options)
                

            }
        })
    }

    function put(options) {
        return new Promise(async(resolve, reject) => {
            try {
                if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
                let params = options.params || {}
                let response = await axios.put(options.url, options.formData, { withCredentials: true, params }, {
                    headers: {...options.headers }
                })

                let result = await processResponse(response, options)
                return resolve(result);

            } catch (e) {
                // console.log("sale x aqui", e.response, e)
                return processResponse(e.response, options)

            }
        })
    }

    function del(options) {
        return new Promise(async(resolve, reject) => {
            try {
                if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`

                let response = await axios.delete(options.url, { withCredentials: true }, {
                    headers: {...options.headers }
                })
                let result = await processResponse(response, options)
                return resolve(result);

            } catch (e) {
                // console.log("sale x aqui", e.response, e)
                return processResponse(e.response, options)

            }
        })
    }

    function processResponse(response, options) {
        return new Promise(async(resolve, reject) => {
            // console.log("processResponse", response)
            if (!Vue.prototype.$offline.onlineMode){
              toast.open({ message: "OFFLINE SIIS: Accion no disponible.", position: 'top-right', type: 'error', duration: 3000 });
              return reject({})
            }
            let successMsg = options.success || "La carga se realizo con exito."
    
            let errAlert = () => {
                let msg = typeof response.data == 'object' ? JSON.stringify(response.data) : response.data;
                alert(msg);
            }
            // console.log("response: ", response)
            
            if (!response) console.error("response: ", response, options )

            if (!response || (response && response.status == '401')) {
                try {
                    console.log("err 401>", response.data)
                    if (response.data.error == 'Unauthorized') {
                        let msg = "La sesion ha caducado o no tiene permiso para la accion que desea realizar. Abriremos una nueva pestaña para que vuelva a loguearse. Luego de loguearse vuelva a intentarlo."
                        if (toast.containsInQueue(msg)) {
                            toast.open({ message: msg, position: 'top-right', type: 'error', duration: 5000, onClick: errAlert });
                            setTimeout(() => {
                                window.open('/login?closeTab', '_blank')
                            }, 3000)
                        }
                        return reject(response);
                    }
                    
                } catch (e) {
                    console.error("Error: ", e, response)
                    toast.open({ message: "Ups! Algo fallo, te pedimos disculpas y te ayudaremos a resolverlo. Por favor, haz click en este cartel rojo, te aparecera un nuevo mensaje, envia una captura de pantalla con esa información a la mesa de ayuda tecnica.", position: 'top-right', type: 'error', duration: 0, onClick: () => alert(e) });
                    return reject(response);

                }
                if (response) {
                    toast.open({ message: "Ups! Algo fallo, te pedimos disculpas y te ayudaremos a resolverlo. Por favor, haz click en este cartel rojo, te aparecera un nuevo mensaje, envia una captura de pantalla con esa información a la mesa de ayuda tecnica.", position: 'top-right', type: 'error', duration: 0, onClick: errAlert });
                    return reject(response);
                }
    
    
            } else if (response && (response.status != '200') || (response.data && response.data.error != '')) {
                toast.open({ message: "Ocurrio un error al cargar.", position: 'top-right', type: 'error', duration: 3000, onClick: errAlert });
                return reject(response)
            }
            // console.log("toast.containsInQueue(successMsg)", toastQueue, toast.containsInQueue(successMsg), successMsg)
            toast.verifyAndOpen({ message: successMsg, position: 'top-right', type: 'success', duration: 3000 });
            return resolve(response)
        })
    }
    
    return {
        get,
        post,
        put,
        del,
    }

}
