var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.viewer ? '' : 'page'},[(!_vm.viewer)?_c('div',[_c('div',{staticClass:"feature-intro"},[_c('h1',[_vm._v("Nuevo "+_vm._s(_vm.UserTypeTitle))]),_c('p',[_vm._v("Ingrese los datos del formulario")])])]):_vm._e(),_c('ConfirmPopup',{ref:"ConfirmPopup",attrs:{"group":"demo"}}),_c('TForm',{ref:"TForm",attrs:{"data":_vm.data,"nombres":_vm.names,"errors":_vm.errors,"mensajes":_vm.mensajes,"mode":_vm.mode,"designEdit":_vm.designEdit,"designNew":_vm.design,"url":_vm.url,"viewer":_vm.viewer,"viewerID":_vm.viewerID,"valuesForDefault":_vm.valuesForDefault,"enableEditFields":[
      'Email',
      'NombreUsuario',
      'PersonaFisicaID',
      'TipoUsuarioID',
      'DependenciaID',
      'JuzgadoID',
      'NumeroCredencial',
      'CategoriaUfiID',
    ]},on:{"errorRepeatedUser":function($event){return _vm.errorRepeatedUser()}}}),_c('ModalTemplate',{ref:"ModalRefInform",attrs:{"title":"Nombre de Usuario Inválido","type":"informativo","message":"El nombre de usuario ya está en uso. Por favor, elige otro nombre de usuario","showIcon":true,"severity":"danger"},on:{"confirm":function($event){return _vm.$refs.ModalRefInform.close()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }