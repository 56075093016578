//UFIs: 'ufi-establecer-paradero'      
export default {
    computed: {
        PreformUFIGenericDenuncia:{
            get (){
                let dateDenuncia = {
                    withStyles:   `<p style="color: #55b5ea ; display: inline"> ${this.dateDenuncia}</p>,`,
                    noStyles: ' ' + this.dateDenuncia + ','
                }
               
                //changing the last character for a ','
                dateDenuncia.withStyles =  dateDenuncia.withStyles.slice(0, -1)
                dateDenuncia.withStyles =  dateDenuncia.withStyles + ","
                dateDenuncia.noStyles =  dateDenuncia.noStyles.slice(0, -1)
                dateDenuncia.noStyles =  dateDenuncia.noStyles + ","
                
                let constance = {
                    withStyles:   ` el Funcionario de Policía que suscribe, Jefe de esta sede legal y Secretario de Actuaciones que refrenda, a los fines legales <p style="text-decoration: underline ; display: inline ; font-weight: bold"> HACE CONSTAR:</p> `,
                    noStyles: ` el Funcionario de Policía que suscribe, Jefe de esta sede legal y Secretario de Actuaciones que refrenda, a los fines legales HACE CONSTAR: `,
                }
                
                let constanceText = {
                    withStyles:   `Que en la fecha y hora precitada, comparece ante esta Instrucción la persona mencionada precedentemente a fin de poner en conocimiento la supuesta Comisión de un hecho de carácter delictivo, que da lugar a la intervención de esta Autoridad Policial, acto seguido y luego de ser impuesto del contenido del Art. 245° del Código Penal (falsa denuncia), a continuación, se le pregunta por sus nombres apellidos y demás circunstancias personales manifestó llamarse: `,
                    noStyles: `Que en la fecha y hora precitada, comparece ante esta Instrucción la persona mencionada precedentemente a fin de poner en conocimiento la supuesta Comisión de un hecho de carácter delictivo, que da lugar a la intervención de esta Autoridad Policial, acto seguido y luego de ser impuesto del contenido del Art. 245° del Código Penal (falsa denuncia), a continuación, se le pregunta por sus nombres apellidos y demás circunstancias personales manifestó llamarse: `,
                }
                
                let people = {
                    withStyles:   this.dataResumen.denunciantes !== undefined ? `<p style="color: #1356cc ; display: inline">${this.dataResumen.denunciantes}</p>. ` : '',
                    noStyles: this.dataResumen.denunciantes !== undefined ? ` ${this.dataResumen.denunciantes}. ` : '',
                }

                constanceText.noStyles = constanceText.withStyles  
                
                let dateAndTime ={ 
                    withStyles:   `Con relación al hecho que viene a denunciar <p style="text-decoration: underline ; display: inline; font-weight: bold">DECLARA:</p> Que se presenta ante esta autoridad policial a fin de manifestar que`,
                    noStyles: `Con relación al hecho que viene a denunciar DECLARA: Que se presenta ante esta autoridad policial a fin de manifestar que`,
                }
             
                if(this.textEditsCounter.Preform == 0)
                {
                    let Preform = {
                        withStyles: this.Preforma.noStyles + this.userState.dependencia + dateDenuncia.noStyles +  constance.withStyles + constanceText.withStyles + people.withStyles + dateAndTime.withStyles,
                        noStyles: this.Preforma.noStyles + this.userState.dependencia + dateDenuncia.noStyles + constance.noStyles + constanceText.noStyles + people.noStyles + dateAndTime.noStyles
                    };
                    return Preform
                }   else
                {   
                    let Preform = {
                        withStyles : "vacio por que no se muestra, es solo para que no de error por undefined",
                        noStyles : this.textEstaticos.Preform,
                    }
                    return Preform
                }
            }
        },
        instructionDecreeUFIGenericDenuncia:{
            get (){
                if(this.textEditsCounter.instructionDecree == 0){
                    let instructionDecree ={ 
                        withStyles:   `Lo que solicita es que se tomen las medidas legales de rigor. No siendo para más la presente acta la que leída y ratificada en todo su contenido se firma al pie por ante mí que lo <p style="text-decoration: underline ; display: inline; font-weight: bold">CERTIFICA.</p><b> --------</b>`,
                        noStyles: `Lo que solicita es que se tomen las medidas legales de rigor. No siendo para más la presente acta la que leída y ratificada en todo su contenido se firma al pie por ante mí que lo CERTIFICA. --------`,
                    }
                  
                    return instructionDecree   
                }   else{       
                    let instructionDecree = {
                        noStyles:  this.textEstaticos.instructionDecree
                    }
                    return instructionDecree                
                }             
            },
        },   
        actOfStartUFIGenericDenuncia:{
            get (){
                let actOfStart = this.Preform.noStyles + ' ' + this.deposition + '. ' + this.instructionDecree.noStyles
                return actOfStart 
            }
        } 
    }    
};