<template>
    <Dialog 
      test-id="modalSuccessAndFail"
      v-if="this.msjEnable"
      :visible="true" 
      :style="{width:'500px'}"
      :contentStyle="{}" 
      :modal="true" 
      :closable="false"
      ref="modalUpdate"
    >
      <template #header>
          <div v-if=" icon != {}" class="text-center" style="width: 100%">
            <i  :class="icon.type" :style="icon.style"></i>
            <!-- <i v-else class="pi pi-exclamation-circle" style="font-size: 3em; color: red;"></i> -->
          </div>
      </template>
      <!-- Content -->
      <div class="text-center">
          <h4 test-id="textoModal" class="ml-3">{{mensajeDialog}}</h4>
      </div>
      <template #footer>
        <div class="text-center">
          <Button test-id="buttomAceptarModal" v-if="buttomAceptar.buttom" :label="aceptar" icon="pi pi-check" @click="aceptarDialog('Aceptar')" autofocus />
          <Button v-if="buttomCancelar.buttom" :label="cancelar" icon="pi pi-times" @click="aceptarDialog('Cancelar')" autofocus />
        </div>
      </template>
    </Dialog>
</template>

<script>

// import SimpleCard from '../../components/SimpleCard.vue';

export default {
    name: 'SuccessAndFail',
		// components: {
		// 	SimpleCard,
		// },
    data() 
	{
      return {
       modalEnable:false

    	};
	},
	props: {
    aceptar:{
      type:String,
      default:'Aceptar'
    },
    cancelar:{
      type:String,
      default:'Cancelar'
    },
      msjEnable:{
        type:Boolean,
        default:false
      },
      icon:{
        type:Object,
        default: '',
      },
      buttomAceptar:{
        type:Object,
        default: () => ({buttom:true,action:false}),

      },
      buttomCancelar:{
        type:Object,
        default: () => ({buttom:false,action:false}),
      },      
      mensajeDialog:{
          type: String,
          default: '',
      },   
      routerPush:{
          type: String,
          default: ''
      },        
	},
    methods: {
        aceptarDialog(select)
        {
          switch(select)
          {
            case 'Aceptar':{
              if(this.buttomAceptar.routerPush)
              {
                if(this.buttomAceptar.routerPush== this.$route.fullPath){
                  this.$router.go()
                }else
                this.$router.push(this.buttomAceptar.routerPush)
              }
              if(this.buttomAceptar.action == false)
              {
                this.$emit('update:msjEnable', false)//ponemos msjEnable como false
                this.$emit('update:icon', {})
              }else
              {
                this.$emit('update:msjEnable', false)//ponemos msjEnable como false
                this.$emit("Aceptar",select);
              }
              break;
            }
            case 'Cancelar':{
              this.$emit('update:msjEnable', false)//ponemos msjEnable como false
              // this.$emit("Cancelar",select);
              break;
            }
          }
        },
    },
		
}
</script>

<style scoped>
</style>