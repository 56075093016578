import { actuacionesConfig } from '@/router/RoutesConfig/actuacionesConfig/actuacionesConfig'

export default {
  data() { 
    return {
      actuacionType: "",
    };
  },
  methods: {
    changeNameInStore(formType) {
      const viewConfig = actuacionesConfig.find((config) => config.path === "/actuaciones/view/");
      if (viewConfig && viewConfig.actuaciones) {
        const foundActuacion = viewConfig.actuaciones.find(
          (actuacion) => actuacion.nameInStore === formType
        );
        if (foundActuacion) {
          this.actuacionType = foundActuacion.pathName;
          return foundActuacion.pathName;
        } else {
          console.log(
            "No se encontró ninguna coincidencia para el formType proporcionado en /actuaciones/view/."
          );
          this.actuacionType =  null;
          return null;
        }
      } else {
        console.log("No se encontró la configuración para /actuaciones/view/.");
      }
    },
  }
}