import { TipoMonedaConfig as config } from '@/pages/parametros/moduleConfig';
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { 
        path: 'tipo-moneda',
        name: 'tipo-moneda',
        beforeEnter: isUserRolGuard,
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { 
        path: 'tipo-moneda/new',
        name: 'tipo-moneda-new',
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { 
        path: 'tipo-moneda/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]

