<template>
  <div class="page">
    <div>
      <FormDenunciantes
        @onSave="onSave"
        :formType="this.formType"
        :actuacionType="this.actuacionType"
      />
    </div>
  </div>
</template>

<script>
// import FormDenunciantes from "../../components/sumario/FormDenunciantes.vue";
import { mapState } from "vuex";
import Icon from "../../components/Icon.vue";
import { defineAsyncComponent } from "vue";

export default {
  name: "SumarioDenunciaFormDenunciante",
  props: {
    actuacionType: String, //Defines the type of form
  },
  data() {
    return {
      filteredLocation: [],
      // actuacionType: "",
    };
  },
  computed: {
    ...mapState({
      formType: (state) => state.sumarioViews.actuacion,
    }),
  },
  // created() {
  //   this.changeActuacionesTypeName()
  // },
  components: {
    Icon,
    FormDenunciantes: defineAsyncComponent({
      loader: () => import("../../components/sumario/FormDenunciantes.vue"),
      // loadingComponent: LoadingComponent,
      // errorComponent: ErrorComponent,
      delay: 200, // Tiempo antes de mostrar el componente de carga
      timeout: 30000, // Tiempo de espera antes de considerar la carga fallida
    }),
    //   FormDenunciantes
  },
  methods: {
    onSave() {
      this.$router.push("/sumario/denuncia/formulario");
    },
    //   changeActuacionesTypeName(){
    //   if( this.formType === "denuncia"){
    //     this.actuacionType ="sumario-denuncia"
    //   }
    //  }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  padding: 10px 30px;
  background-color: #f8f9fa;
  min-height: calc(95vh - 92px);
}
.feature-intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 20px;
}
.feature-intro > h1 {
  width: 150%;
  font-size: 1.5rem;
  padding-bottom: 0px;
  margin: 0px;
}

.feature-intro > p {
  font-size: 1.5rem;
  padding-top: 0px;
}
.feature-intro > i {
  align-items: flex-start;
  height: 100%;
}
</style>
