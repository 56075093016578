import { UsuarioConfig as config } from '@/pages/parametros/moduleConfig';
import NewUsuario from '@/pages/parametros/add-edit/NewUsuario.vue'
import EditUsuario from '@/pages/parametros/add-edit/EditUsuario.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/usuario'
        path: 'usuario',
        name: 'Usuario',
        beforeEnter: isUserRolGuard,
        // component:   () => import ('@/pages/parametros/usuario.vue'),
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { 
        //'parametros/usuario/new'
        // path: 'usuario/add',
        path: 'usuario/new',
        name: 'usuario-new',
        // component:  () => import ('@/pages/parametros/add-edit/usuario.vue'),
        // component:   () => import ('@/pages/parametros/tipo-usuario.vue'),
        // component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        component:  () => import ('@/pages/parametros/add-edit/NewUsuario.vue'),
        beforeEnter: isUserRolGuard,
        // props: { config: config.form },
        // props: { viewer: true , mode: "new"},
    },
    { //'parametros/usuario/edit/:id'
        path: 'usuario/edit/:id',
        // component:  ()=> import('@/pages/parametros/add-edit/usuario.vue'),
        // component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        component:  ()=> import('@/pages/parametros/add-edit/EditUsuario.vue'),
        beforeEnter: isUserRolGuard,
        // props: { config: config.form },
    },
]

