import { Extension } from '@tiptap/core'

export const lineHeight = Extension.create({
    addGlobalAttributes() {
      return [
        {
          // Extend the following extensions
          types: [
            'heading',
            'paragraph',
          ],
          // … with those attributes
          attributes: {
            lineHeight: {
              default: '1.4em',
              renderHTML: attributes  => {
                // console.log("attributes.lineHeight", attributes.lineHeightlineHeight)
                return {
                  style: `line-height: ${attributes.lineHeight.lineHeight}`,
                }
              },
              parseHTML: element => ({
                lineHeight: element.style.lineHeight || '1.4em',
              }),
            },
          },
        },
      ]
    },
  })

  // export const FontSize = createSubTextStyle({
    //   name: 'fontSize',
    //   cssName: 'font-size',
    //   renderValue: renderNumberToPx,
    // });
    
    //buscar textStyle
    //const { name, styleName = name, cssName, renderValue = (v) => v } = o;
    
    // const renderNumberToPx = (v: string) => (/^\d+$/.test(String(v)) ? `${v}px` : v);
  const renderNumberToPx = (v) => (/^\d+$/.test(v) ? `${v}px` : v);
  export const FontSize = Extension.create({
    name: 'fontSize',
    addOptions() {
      return {
        types: ['textStyle'],
        defaultUnit: '',
      };
    },

    addGlobalAttributes() {
      return [
        {
          // Extend the following extensions
          types: this.options.types,
          // … with those attributes
          attributes: {
            fontSize: {
              default: null,
              // renderHTML: attributes  => ({
              //   style: `font-size: ${attributes.fontSize.fontSize}`,
              // }),
              renderHTML: (attributes) => {
                let attr = attributes['fontSize'];
                if (!attr) {
                  return {};
                }
                
                return {
                  style: `font-size: ${renderNumberToPx(attr)}`,
                };
              },
              parseHTML: (element) => element.style['fontSize']?.replace(/['"]+/g, ''),
              // parseHTML: element => ({
              //   fontSize: element.style.fontSize || '1em',
              // }),
            },
          },
        },
      ]
    },

    addCommands() {
      return {
        setFontSize:
          (value) =>
          ({ chain }) => {
            console.log("setFontSize", value, chain)
            return chain()
              .setMark('textStyle', { fontSize: value })
              .run();
          },
        toggleFontSize:
          (value) =>
          ({ chain, editor }) => {
            if (editor.isActive('textStyle', { fontSize: value })) {
              return chain()
                .setMark('textStyle', { fontSize: value })
                .run();
            }
            return chain()
              .setMark('textStyle', { fontSize: null })
              .removeEmptyTextStyle()
              .run();
          },
        unsetFontSize:
          () =>
          ({ chain }) => {
            return chain()
              .setMark('textStyle', { fontSize: null })
              .removeEmptyTextStyle()
              .run();
          },
      };
    },
  })
  
  
  export const masterClass = Extension.create({
    addGlobalAttributes() {
      return [
        {
          // Extend the following extensions
          types: [
            'heading',
            'paragraph',
          ],
          // … with those attributes
          attributes: {
            masterClass: {
              default: '',
              renderHTML: attributes  => {
                // console.log("renderHTML", attributes.masterClass.masterClass)
                // if (attributes.masterClass.masterClass == '') return ''
                // style: 'color: blue'
                let response = {
                  class: `${attributes.masterClass.masterClass || ''}`,
                }
                return response;
              },
              parseHTML: element => {
                // console.log("masterClass parseHTML", element, element.getAttribute('class'))
                let clase = element.getAttribute('class') || ''
                if (clase == '') return ({});
                return ({
                  masterClass: clase,
                })
              },
            },
          },
        },
      ]
    },
    addCommands() {
      return {
        setClass: (className ,att) => ({ commands }) => {
          // console.log("setClass", className, att)
          let masterClass = att.masterClass.masterClass == className ? '' : className;
          return commands.setNode('paragraph', {...att, masterClass: {masterClass: masterClass}});
        },
      }
    },
  })
  
  
  
  export const masterID = Extension.create({
    name: 'masterID',
    addGlobalAttributes() {
      return [
        {
          types: [
            'heading',
            'paragraph',
          ],
          attributes: {
            masterID: {
              default: '',
              renderHTML: attributes  => {
                // if (attributes.masterID == '')return
                let response = {
                  id: `${attributes.masterID.masterID || ''}`,
                }
                
                // if (attributes.masterID.masterID == 'master-sangria'){
                //   response.style = 'color: blue'
                // }
                // if (attributes.masterID.masterID == 'master-sangria'){
                //   response.style = 'text-align: blue'
                // }
                return response;
              },
              parseHTML: element => {
                let clase = element.getAttribute('id') || ''
                if (clase == '')  return({})
                return ({
                  masterID: clase,
                })
              },
            },
          },
        },
      ]
    },
    addCommands() {
        return {
          setID: (id ,att) => ({ commands }) => {
            // console.log(">>>>>>>>>>>>>>>>>>>att",att.masterID)
            let masterID = att.masterID.masterID == id ? '' : id;
            return commands.setNode('paragraph', {...att, masterID: {masterID: masterID} });
          },
        }
      }, 
  })
  
  
  // class: 'master-sangria'
  
  
  export const textIndent = Extension.create({
    addGlobalAttributes() {
      return [
        {
          // Extend the following extensions
          types: [
            'heading',
            'paragraph',
          ],
          // … with those attributes
          attributes: {
            textIndent: {
              default: '0em',
              renderHTML: attributes  => ({
                style: `text-indent:  ${attributes.textIndent.textIndent}`,
              }),
              parseHTML: element => ({
                textIndent: element.style.textIndent || '0em',
              }),
            },
          },
        },
      ]
    },
  })
  
  // export const height = Extension.create({
  //   addGlobalAttributes() {
  //     return [
  //       {
  //         types: [
  //           'heading',
  //           'paragraph',
  //         ],
  //         attributes: {
  //           height: {
  //             default: 'auto',
  //             renderHTML: attributes  => ({
  //               style: `height:  ${attributes.height.height}`,
  //             }),
  //             parseHTML: element => ({
  //               height: element.style.height || 'auto',
  //             }),
  //           },
  //         },
  //       },
  //     ]
  //   },
  // })
  
  // export const border = Extension.create({
  //   addGlobalAttributes() {
  //     return [
  //       {
  //         types: [
  //           'heading',
  //           'paragraph',
  //         ],
  //         attributes: {
  //           border: {
  //             default: '0px solid',
  //             renderHTML: attributes  => ({style: `border:  ${attributes.border.border}`,}),
  //             parseHTML: element => ({border: element.style.border || '0px solid',}),
  //           },
  //         },
  //       },
  //     ]
  //   },
  // })
  
  // export const width = Extension.create({
  //   addGlobalAttributes() {
  //     return [
  //       {
  //         // Extend the following extensions
  //         types: [
  //           'heading',
  //           'paragraph',
  //         ],
  //         // … with those attributes
  //         attributes: {
  //           width: {
  //             default: 'auto',
  //             renderHTML: attributes  => ({
  //               style: `width:  ${attributes.width.width}`,
  //             }),
  //             parseHTML: element => ({
  //               width: element.style.width || 'auto',
  //             }),
  //           },
  //         },
  //       },
  //     ]
  //   },
  // })
  
  // export const display = Extension.create({
  //   addGlobalAttributes() {
  //     return [
  //       {
  //         // Extend the following extensions
  //         types: [
  //           'heading',
  //           'paragraph',
  //         ],
  //         // … with those attributes
  //         attributes: {
  //           display: {
  //             default: 'inline',
  //             renderHTML: attributes  => ({
  //               style: `display:  ${attributes.display.display}`,
  //             }),
  //             parseHTML: element => ({
  //               display: element.style.display || 'block',
  //             }),
  //           },
  //         },
  //       },
  //     ]
  //   },
  // })
  
  // export const padding = Extension.create({
  //   addGlobalAttributes() {
  //     return [
  //       {
  //         // Extend the following extensions
  //         types: [
  //           'heading',
  //           'paragraph',
  //         ],
  //         // … with those attributes
  //         attributes: {
  //           padding: {
  //             default: 'auto auto auto auto',
  //             renderHTML: attributes  => {
  //               // console.log('attributes.padding', attributes.padding)
  //               return {
  //                 style: `padding:  ${attributes.padding.padding}`,
  //               }
  //             },
  //             parseHTML: element => ({
  //               padding: element.style.padding || 'auto auto auto auto',
  //             }),
  //           },
  //         },
  //       },
  //     ]
  //   },
  // })
  
  // export const fontFamily = Extension.create({
  //   addGlobalAttributes() {
  //     return [
  //       {
  //         // Extend the following extensions
  //         types: [
  //           'heading',
  //           'paragraph',
  //         ],
  //         // … with those attributes
  //         attributes: {
  //           fontFamily: {
  //             default: "Times New Roman",
  //             renderHTML: attributes  => ({
  //               style: `font-family: ${attributes.fontFamily.fontFamily}`,
  //             }),
  //             parseHTML: element => ({
  //               fontFamily: element.style.fontFamily || "Times New Roman",
  //             }),
  //           },
  //         },
  //       },
  //     ]
  //   },
  // })
  
  export const color = Extension.create({
    addGlobalAttributes() {
      return [
        {
          // Extend the following extensions
          types: [
            'heading',
            'paragraph',
          ],
          // … with those attributes
          attributes: {
            color: {
              default: 'black',
              renderHTML: attributes  => ({
                style: `color:  ${attributes.color.color}`,
              }),
              parseHTML: element => ({
                color: element.style.color || 'black',
              }),
            },
          },
        },
      ]
    },
  })
  
  // export const margin = Extension.create({
  //   addGlobalAttributes() {
  //     return [
  //       {
  //         // Extend the following extensions
  //         types: [
  //           'heading',
  //           'paragraph',
  //         ],
  //         // … with those attributes
  //         attributes: {
  //           margin: {
  //             default: '1em auto auto auto;',
  //             renderHTML: attributes  => ({
  //               style: `margin:  ${attributes.margin.margin}`,
  //             }),
  //             parseHTML: element => ({
  //               margin: element.style.margin || '1em auto auto auto;',
  //             }),
  //           },
  //         },
  //       },
  //     ]
  //   },
  // })
  
  // export const bordercollapse = Extension.create({
  //   addGlobalAttributes() {
  //     return [
  //       {
  //         // Extend the following extensions
  //         types: [
  //           'heading',
  //           'paragraph',
  //           'table'
  //         ],
  //         // … with those attributes
  //         attributes: {
  //           bordercollapse: {
  //             default: 'none;',
  //             renderHTML: attributes  => ({
  //               style: `border-collapse: ${attributes.bordercollapse}`,
  //             }),
  //             parseHTML: element => ({
  //               bordercollapse: element.style.bordercollapse || 'none;',
  //             }),
  //           },
  //         },
  //       },
  //     ]
  //   },
  // })