import functionsDataResumen from './functionsDataResumen';

const generalData = (state, data) => {
  ////////////////Dependencia//////////////////
  try {
    data.dependencia = state.dependenciaPolicial != undefined ? state.dependenciaPolicial: state.dependencia
    data.departamento = state.dependenciaDepartamento != undefined ? state.dependenciaDepartamento : state.departamento
    data.dependenciaDomicilio = state.dependenciaDomicilio != undefined ? state.dependenciaDomicilio : ''
  }
  catch (e){
    console.log('error', e);
    data.dependencia = state.dependencia
    data.departamento = state.departamento
    data.dependenciaDomicilio = state.dependenciaDomicilio != undefined ? state.dependenciaDomicilio : ''
  }

  ////////////////Guardamos formType en caso de Refresh////////////
  if(!data.formType)//si formType llega vacio, recuperamos el ultimo usado en sessionStorage
  {
    data.formType= sessionStorage.formType
  }else
  {
    sessionStorage.setItem('formType', data.formType)//guardamos este dato por si la pag se actualiza
  }

  try {
    if (state.deposition == ''){
      if(data.formType == 'denuncia')
      {        
        data.errorValidationErr.push("Falta relato de la denuncia")
      }
    }
    data.relatoDenuncia = state.denunciaText;
  }
  catch(e){
    console.log('error', e);
    if(data.formType == 'denuncia')
    {      
      data.errorValidationErr.push("Falta relato de la denuncia")
    }
  }

  try {
    if (state.deposition == ''){
      if(data.formType == 'oficio')
      {        
        data.errorValidationErr.push("Falta relato de la denuncia")
      }
    }
    data.relatoDenuncia = state.denunciaText;
  }
  catch(e){
    console.log('error', e);
    if(data.formType == 'oficio')
    {      
      data.errorValidationErr.push("Falta relato de la denuncia")
    }
  }

  //Warning en caso de que falte el email del afectado y que sea una denuncia o contravencional-denuncia o ufi-flagrancia
  try {
    let formTypesForValidateAndInclude = {'denuncia': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true },
      'ufi-generica': { contains: true },  'ufi-generica-oficio': { contains: true },
      'ufi-delitos-contra-la-propiedad': { contains: true },'ufi-delitos-contra-la-propiedad-oficio': { contains: true },
      'ufi-estafas-y-delitos-informaticos': { contains: true }, 'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
    };
    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) {
      let affectedsWithEmptyMail = [];
      state.denunciante.list.map(function (affected) {
        if(functionsDataResumen.validateEmailWarning(affected) && !functionsDataResumen.validatePublicOrder(affected)) {
          affectedsWithEmptyMail.push(affected);
        };
      });
      if (affectedsWithEmptyMail.length > 0){
        data.errorValidationWarning.push("Falta Email del afectado")
      }
    }
  }
  catch(e){    
    console.log('e', e);
  }

  ///////////////////Sitio////////////
  try {
    if (state.Sitio === null){
      data.errorValidationErr.push("Falta sitio")
    }
    data.Sitio = state.Sitio;
  }
  catch(e){
    console.log('error', e);
  }
}
export default {
  generalData
}