import functionsDataResumen from './functionsDataResumen';

const interveningPersonnel = (state, data) => {
  //////////////////////////////Personal Interviniente para Contravencional /////////////////////////////////
  try{
    let formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true },'ufi': { startsWith: true },'preliminar': { startsWith: true },};
    let formTypesForValidateAndExclude = {'ufi-generica': { excluded: true }, 'ufi-estafas-y-delitos-informaticos': { excluded: true }, 'ufi-delitos-contra-la-propiedad': { excluded: true }, 'ufi-establecer-paradero': { excluded: true },'preliminar-tentativa-suicidio': { excluded: true },'preliminar-otros': { excluded: true },};
    let names = ''
    if(state.PersonalInterviniente.length == 0)
    {
      if(
        functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude) && 
        functionsDataResumen.validateFormType(data, formTypesForValidateAndExclude, true)
      ) {         
        data.errorValidationErr.push("No especificó al Personal Interviniente")
      }
    }else
    {
      let interveningPersonnelHierarchy;
      let interveningPersonnelName;
      let interveningPersonnelPoliceStation;
      for(let i = 0; i < state.PersonalInterviniente.length; i++ )
      {
        let j=i+1;
        interveningPersonnelHierarchy = state.PersonalInterviniente[i]?.jerarquia?.nombre ?? state.PersonalInterviniente[i]?.Jerarquia;
        interveningPersonnelName = state.PersonalInterviniente[i]?.nombre ?? state.PersonalInterviniente[i]?.Nombreyapellido;
        interveningPersonnelPoliceStation = state.PersonalInterviniente[i]?.dependencia ?? state.PersonalInterviniente[i]?.Dependencia;
        //Preguntamos si existe el elemento siguiente para poner un "y" o una "," segun corresponda
        if((j) < state.PersonalInterviniente.length )
        {
          if(state.PersonalInterviniente[i].dependencia == state.PersonalInterviniente[j].dependencia)
          {
            names = names === '' ? `${interveningPersonnelHierarchy} ${interveningPersonnelName}`: `${names}, ${interveningPersonnelHierarchy} ${interveningPersonnelName}`
          }else
          {
            names = names === '' ? `${interveningPersonnelHierarchy} ${interveningPersonnelName} adscripto/s al numerario de ${interveningPersonnelPoliceStation}`: `${names}, ${interveningPersonnelHierarchy} ${interveningPersonnelName} adscripto/s al numerario de ${interveningPersonnelPoliceStation}`
          }
        }else
        {
          names = names === '' ? ` ${interveningPersonnelHierarchy} ${interveningPersonnelName} adscripto/s al numerario de ${interveningPersonnelPoliceStation}`: `${names} y ${interveningPersonnelHierarchy} ${interveningPersonnelName} adscripto/s al numerario de ${interveningPersonnelPoliceStation}`
        }
      }
      data.personalInterviniente = names
    }
  }
  catch(e)
  {
    console.log("FALLO Personal Interviniente: ", e)
  }

  //////////////////////////////Personal Interviniente para Delitos Especiales /////////////////////////////////
  try{
    let names = ''
    let formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true },'ufi': { startsWith: true },};
    let formTypesForValidateAndExclude = {'ufi-establecer-paradero': { excluded: true }, 'ufi-generica': { excluded: true }, 'ufi-estafas-y-delitos-informaticos': { excluded: true }, 'ufi-delitos-contra-la-propiedad': { excluded: true },};
    if(state.PersonalInterviniente.length == 0)
    {        
      if(
        functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude) && 
        functionsDataResumen.validateFormType(data, formTypesForValidateAndExclude, true)
      ){
        data.errorValidationErr.push("No especificó al Personal Interviniente")
      }
    }else
    {
      for(let i = 0; i < state.PersonalInterviniente.length; i++ )
      {
        if(state.PersonalInterviniente[i]?.jerarquia?.nombre === undefined) continue;
        if(i === 0){
          names =  `${state.PersonalInterviniente[i].dependencia}, a cargo de el/la ${state.PersonalInterviniente[i].jerarquia.nombre} ${state.PersonalInterviniente[i].nombre}`
        }   else if(i === 1){
          names = names + ` en compañía de el/la ${state.PersonalInterviniente[i].jerarquia.nombre} ${state.PersonalInterviniente[i].nombre}`
        } else {
          names = names + ` y el/la ${state.PersonalInterviniente[i].jerarquia.nombre} ${state.PersonalInterviniente[i].nombre}`
        }
      }
      data.personalIntervinienteDelitosEspeciales = names
    }
  }
  catch(e)
  {
    console.log("FALLÓ Personal Interviniente Delitos Especiales: ", e)
  }
}
export default {
  interveningPersonnel
}