import { DepartamentalConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/departamental'
        path: 'departamental',
        name: 'departamental',
        beforeEnter: isUserRolGuard,
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { //'parametros/departamental/new'
        path: 'departamental/new',
        name: 'departamental-new',
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/departamental/edit/:id'
        path: 'departamental/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]
     
