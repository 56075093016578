export const ufiSiniestroConfig = [
    {
        path: "/actuaciones/new/ufi-siniestro-vial/initial",
        pathName: "ufi-siniestro-vial",
        titulo: "UFI - SINIESTRO VIAL",
        props: { formType: "ufi-siniestro-vial", actuacionType: "ufi-siniestro-vial"},
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-siniestro-vial/initial/denunciante",
        pathName: "ufi-siniestro-vial",
        titulo: "DAMNIFICADO / FALLECIDO",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-siniestro-vial/initial/momento",
        pathName: "ufi-siniestro-vial",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/ufi-siniestro-vial/initial/acusado",
        pathName: "ufi-siniestro-vial",
        titulo: "APREHENDIDO O ACUSADO",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/ufi-siniestro-vial/initial/efectos",
        pathName: "ufi-siniestro-vial",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]