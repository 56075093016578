const calculateAge = (birthday) => {// birthday is a date
  birthday = birthday.replaceAll('-', '/')
  let dateWithReverseMounth  = birthday.split('/')
  dateWithReverseMounth  = dateWithReverseMounth[1] + '/' + dateWithReverseMounth[0] + '/' + dateWithReverseMounth[2]
  birthday = dateWithReverseMounth
  birthday = new Date(birthday)
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const calculateAgeWithSeveralFormats = (dateOfBirth) => {
  const today = new Date();
  let birthDate;
  if (/^\d{4}[-/]\d{2}[-/]\d{2}$/.test(dateOfBirth)) {
    birthDate = new Date(dateOfBirth.replace(/-/g, '/')); // Format: "1981-10-27" or "1981/10/27"
  } else if (/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(dateOfBirth)) {
    const [day, month, year] = dateOfBirth.split(/[-/]/);
    birthDate = new Date(`${year}/${month}/${day}`); // Format: "27-10-1981" or "27/10/1981"
  } else if (/^\d{2}[-/]\d{2}[-/]\d{2}$/.test(dateOfBirth)) {
    const [day, month, year] = dateOfBirth.split(/[-/]/);
    const fullYear = parseInt(year) > 50 ? `19${year}` : `20${year}`; // Assuming years above 50 belong to the 1900s
    birthDate = new Date(`${fullYear}/${month}/${day}`); // Format: "27-10-81" or "27/10/81"
  } else {
    return ""; // Invalid date format
  }
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const sumarDias = (fecha, dias) => {
  fecha.setDate(fecha.getDate() + dias);
  return fecha;
}

const fechaLetras = (date) => {
  // console.log(date)
  if (!date) date = new Date()
  var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
  var diasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");
  var f = typeof date == 'string' ? new Date(date): date;
  return [
    diasSemana[f.getDay()] + ", " + f.getDate() + " de " + meses[f.getMonth()] + " de " + f.getFullYear(),
    f.getDate() + " dias del mes de " + meses[f.getMonth()] + " de " + f.getFullYear()
  ]
}

function validateFormType(data, formTypesToEvaluate, mustPassAllValidations = false) {
  let validations = [];
  for (let key in formTypesToEvaluate) {
    const { startsWith = false, contains = false, excluded = false } = formTypesToEvaluate[key];
    if (excluded && data?.formType !== key) { validations.push(true); continue} 
    if (startsWith && data?.formType?.startsWith(key)) { validations.push(true); continue} 
    if (contains && data?.formType === key) { validations.push(true); continue}
    validations.push(false);
  }
  return mustPassAllValidations ? validations.every(val => val === true) : validations.some(val => val === true);
}

function validatePublicOrder(affected) {
  return affected?.ordenPublico === true ? true : false;
}

function validateEmailWarning(affected) {
  if(affected.type !== "doc") return false;
  if(affected.doc?.email === '' || affected.doc?.email === undefined || affected.doc?.email === null) return true;
  return false;
}

export default {
  calculateAge,
  sumarDias,
  fechaLetras,
  validateFormType,
  validatePublicOrder,
  validateEmailWarning,
  calculateAgeWithSeveralFormats
}