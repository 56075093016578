
  <template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">

      <div v-if="getMode() == 'new'" class="feature-intro">
        <h1>Nuevo {{ pageName }}</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
      <div v-else class="feature-intro">
        <h1>Editando {{ pageName }}</h1>
        <p>Modifique los datos que desea EDITAR</p>
      </div>
    </div>

    <TForm 
      :data="data"
      :nombres="names"
      :errors="errors"
      :mensajes="mensajes"
      :mode="getMode()"
      :formatDoc="formatDoc"
      :designEdit="designEdit"
      :designNew="design"
      :url="url"
      :viewer="viewer"
      :viewerID="viewerID"
    />
  </div>
</template>

<script>
export default {
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    }
  },
  data() {
    return {   
      data: {
        
        
      },

      names: {},
      errors:  {},
      mensajes: {},
      designEdit: [
        ["Denunciante", "Fecha del hecho"],
        ["Caratula/Causa", "Actuaciones"],
        ["Juzgado", "N° de Actuacion"]
      ],
      design: [],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + '/sumario',
      pageName:'Actuacion'
    }
  },
  created(){
    let {id} = this.$route.params
    this.id=id
  },
  methods:{
    getMode(){
      return this.id=='add'?'new':'edit';
    },
    formatDoc: (col, data) => {
        // console.log("data es: "+ JSON.stringify(data))
        
      // console.log("formatDoc>>>>>>>>>>>>>>>>", col, data);
        switch (col) {
          case "Denunciante":
            var name = "DENUNCIANTE ANONIMO";
            // si existe el denunciante entonces
            if (data.Denunciantes.length > 0) {
              // si tenemos el apellido del denunciante
              if (data.Denunciantes[0].Apellido != null) {
                name = data.Denunciantes[0].Apellido;
              }
              // si tenemos el nombre del denunciante
              if (data.Denunciantes[0].Nombre != null) {
                name = name + ", " + data.Denunciantes[0].Nombre;
              }
            }
            return name;
          case "Fecha del hecho":
            let fechaDelHecho = "";
            //si tenemos fecha desde y fecha hasta.. incluimos ambas
            if (
              data.HechoubicacionFechahoradesde != null &&
              data.HechoubicacionFechahorahasta != null
            ) {
              fechaDelHecho =
                data.HechoubicacionFechahoradesde.slice(0, -8) +
                " / " +
                data.HechoubicacionFechahorahasta.slice(0, -8);
            }
            //si fechaDelHecho aun no se cargo, vemos cual de las 2 fecha se cargaron
            else {
              if (data.HechoubicacionFechahoradesde != null) {
                fechaDelHecho = data.HechoubicacionFechahoradesde.slice(0, -8);
              } else if (data.HechoubicacionFechahorahasta != null) {
                fechaDelHecho = data.HechoubicacionFechahorahasta.slice(0, -8);
              }
            }
            return fechaDelHecho;
          case "Actuaciones":
            return data.TiposumarioNombre;
          case "Caratula/Causa":
            //en el caso de que no tenga caratula, es por que se trata de un expediente, el cual cuenta con articulos

            if (data.SumarioCausaCaratula == null) {
              // console.log(JSON.stringify(data.ActuacionArticulo))
              //como aun dataResumen no fue cargado, tenemos que generar los articulos a mostrar
              let Articulos = "";
              for (let item of data.ActuacionArticulo) {
                Articulos =
                  Articulos == ""
                    ? item.Numeroarticulo + "°"
                    : Articulos + ", " + item.Numeroarticulo + "°";
              }
              return Articulos;
            } else return data.SumarioCausaCaratula;
          case "Juzgado":
            return data.Roljuzgadosumario[0].Nombre;
          case "N° de Actuacion":
            return data.Numero;
        }
      },
  },
};
</script>
  