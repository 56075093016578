

const emptyObject = {
    list: [
      {
        arrId: uuidv4(),
        doc: {
          fecha:'',
          tipo: '',
          monto: '',
          tipoMoneda: '',
          nombreCompleto:'',
          dni:'',
          identificadorOrigen:'',
          identificadorDestino:'',
          entidadBancaria:'',
          afectadoId: '',
          vinculadoId: '',
          esPropietario:'',
          observaciones:'',
          bienEntregado: '',
          NroTarjeta:'',
          newFormatEffect: true, 
        },
        type: 'empty',
      },
    ],
  }
  
  const state = () => {
  const initialState = { ...emptyObject };
  return initialState;
}
  
  const mutations = {
    setAfectacion(state, params) {
      state.list = state.list.map(n => {
        if (n.arrId === params.arrId) {
          const updatedDoc = { ...params.doc, arrId: params.arrId };
          params.doc = updatedDoc;
          params.type = 'doc';
          return params;
        }
        return n;
      });
    },
    addAfectacion(state) {
      state.list = [...state.list, {
        arrId: uuidv4(),
        doc: {
          fecha:'',
          tipo: '',
          monto: '',
          tipoMoneda: '',
          nombreCompleto:'',
          dni:'',
          identificadorOrigen:'',
          identificadorDestino:'',
          entidadBancaria:'',
          afectadoId: '',
          vinculadoId: '',
          esPropietario:'',
          observaciones:'',
          bienEntregado: '',
          NroTarjeta:'',
          newFormatEffect: true, 
        },
        type: 'empty'
      }];
    },
    deleteAfectacion(state, index) {
      state.list = [
        ...state.list.slice(0, index),
        ...state.list.slice(index + 1)
      ];
    }
  }
  
  const actions = {
    async setAfectacion({ commit, state }, params) {
      await commit('setAfectacion', params);
      const hayEmpty = state.list.some(item => item.type === 'empty');
      if (!hayEmpty) {
        commit('addAfectacion');
      }
      // if (params.type === 'empty') commit('addAfectacion');
    },
    async addAfectacion({ commit }) { 
      commit('addAfectacion');
    },
    async deleteAfectacionByArrId({ commit, getters }, arrId) {
      let index = await getters.getIndexAfectacionByArrId(arrId);
      commit('deleteAfectacion', index);
    }
  }
  
  const getters = {
    afectacionesTodas: state => state.list,
    getAfectacionByArrId: (state) => (arrId) => state.list.find(item => item.arrId === arrId),
    getIndexAfectacionByArrId: (state) => (arrId) => state.list.findIndex(item => item.arrId === arrId),
    afectacionesSelect: (state, getters, rootState) => {
      return state.list.map(item => {
        if (item.type === 'empty') {
          return {
            arrId: item.arrId,
            type: item.type,
            name: 'Nueva afectación',
            doc: { newFormatEffect: true }
          };
        }
        let tipo =  item?.doc?.tipo  
        if (tipo === undefined) {
          return {
            arrId: item.arrId,
            type: item.type,
            monto:item.monto,
            tipo: item.doc.tipo || '',
            monto:item.doc.monto || '',
            fecha:item.doc.fecha || '',
            tipoMoneda: item.doc.tipoMoneda || '' ,
            nombreCompleto:item.doc.nombreCompleto || '',
            dni:item.doc.dni || '',
            identificadorOrigen:item.doc.identificadorOrigen || '',
            identificadorDestino:item.doc.identificadorDestino || '',
            entidadBancaria:item.doc.entidadBancaria || '',
            afectadoId: item.doc.afectadoId || '',
            vinculadoId: item.doc.vinculadoId || '',
            esPropietario:item.doc.esPropietario || '',
            observaciones:item.doc.observaciones || '',
            bienEntregado: item.doc.bienEntregado || '',
            NroTarjeta:item.doc.NroTarjeta||'',
            name: 'Datos Incompletos',
            newFormatEffect: item.doc.newFormatEffect
          };
        }
        return {
          arrId: item.arrId,
          type: item.type,
          tipo: tipo,
          monto:item.doc.monto || '',
          fecha:item.doc.fecha || '',
          tipoMoneda: item.doc.tipoMoneda || '' ,
          nombreCompleto:item.doc.nombreCompleto || '',
          dni:item.doc.dni || '',
          identificadorOrigen:item.doc.identificadorOrigen || '',
          identificadorDestino:item.doc.identificadorDestino || '',
          entidadBancaria:item.doc.entidadBancaria || '',
          afectadoId: item.doc.afectadoId || '',
          vinculadoId: item.doc.vinculadoId || '',
          esPropietario: item.doc.esPropietario || '',
          observaciones: item.doc.observaciones || '',
          bienEntregado: item.doc.bienEntregado || '',
          NroTarjeta:item.doc.NroTarjeta||'',
          newFormatEffect: item.doc.newFormatEffect
        };
      });
    },
    afectacionTodosResumen: (state, getters) => {
      return getters.afectacionesSelect.filter(todo => todo.type === 'doc')
    },
  }
  
  export default {
    namespaced: true,
    emptyObject,
    state,
    getters,
    actions,
    mutations,
  }
  
  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  