export default {
  data: () => ({
    activeIndex: 0,
    displayCustom: false,
  }),
  methods: {
    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
    imgError(e, i, nameField, type){
      if (!this.params[nameField].gallery.imgError) return
      this.params[nameField].gallery.imgError(e, i, type)
    },
  }, 
}