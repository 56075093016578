<template>
  <div class="flex  flex flex-column align-items-center justify-content-center card-container h-screen pl-2" style="background-color:  #0f5390" >
    <h1 class="titulo text-8xl text-white font-Impact">{{title}}</h1>
    <h6 class="subtitulo text-xl text-white">{{subtitulo}}</h6>
    <Button test-id="buttomAceptarModal" v-if="true" label="Ir al escritorio" @click="$router.push('/')" autofocus />
  </div>
</template>
<script>

export default {
  name: "PreliminarMenu",
  components: {
 },
  props: {
  },
  components: {
  },
  data() {
    return {
      title:"ACCESO DENEGADO",
      subtitulo:"Usted no posee los permisos para acceder a este recurso"
    };
  },
};
</script>

<style scoped>
  .titulo {
        font-family: 'sans-serif', sans-serif; 
  }
</style>

 