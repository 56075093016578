import { ProvinciaConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/provincia'
        path: 'provincia',    
        name: 'provincia',  
        beforeEnter: isUserRolGuard, 
        // component:   () => import ('@/pages/parametros/pais.vue'),
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { //'parametros/provincia/new'
        path: 'provincia/new',
        name: 'provincia-new',
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/provincia/edit/:id'
        path: 'provincia/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]
     
