<template>
  <div class="container">
    <l-map
      :zoom="zoom"
      :center="center"
      ref="map"
      @update:center="centerUpdated"
      @mouseup="addMarker"
      @mousedown="() => (this.isMoving = true)"
      @mousemove="move"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-control position="topright" style="display:flex; flex-direction:column;align-items:flex-end">
        <button class="edit_button" v-if="!editing" @click="editToggle">
          Editar
        </button>
        <button class="end_edition_button" v-if="editing" @click="editToggle">
          Terminé
        </button>
        <div class="latlong_description">
          <p>Lat: <b>{{ markers[0].position.lat }}</b></p>
          <p>Long:{{ markers[0].position.lng }}</p>
        </div>
      </l-control>
      <l-marker
        v-for="(marker, index) in markers"
        :key="index"
        ref="markersRef"
        :lat-lng="marker.position"
      >
        <l-tooltip :content="marker.name" />

        <!-- l-popup :content="marker.name"/ -->
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip, LControl } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.4/dist/images/";

export default {
  name: "MyMap",
  components: {
    "l-map": LMap,
    "l-tile-layer": LTileLayer,
    "l-marker": LMarker,
    "l-tooltip": LTooltip,
    "l-control": LControl
  },
  data() {
    return {
      zoom: 17,
      center: { lat: -31.5417287, lng: -68.53757 },
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: "",
      markers: [
        {
          Id: 1,
          name: "Centro Civico",
          position: { lat: -31.5417287, lng: -68.53757 }
        }
      ],
      isMoving: false,
      editing: false,
      markerObjects: null
    };
  },
  mounted: function() {
    L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.4/dist/images/";
    this.$nextTick(() => {
      this.markerObjects = this.$refs.markersRef.map(ref => ref.mapObject);
    });
  },

  methods: {
    addMarker() {
      this.isMoving = false;
    },
    editToggle() {
      this.editing = !this.editing;
    },
    move() {
      if (this.editing && this.isMoving === true) {
        this.$refs.map.moveEndHandler();
      }
    },
    displayTooltip(selectedIndex) {
      for (let markerObject of this.markerObjects) {
        markerObject.closeTooltip();
      }
      this.markerObjects[selectedIndex].toggleTooltip();
    },

    test() {
      this.setPosition({ lat: -31.5417287, lng: -68.53757 });
    },
    moveUpdated(latlng) {
      this.markers[0].position = latlng;
    },
    setPosition(latlng) {
      this.markers[0].position = latlng;
      this.center = latlng;
      this.editing = true;
    },
    centerUpdated(center) {
      if (this.editing && this.isMoving === true) {
        this.$_MyMap_update(center);
        this.markers[0].position = center;
      }
    },
    $_MyMap_update(coordinates) {
      this.$emit("updateCoordinates", coordinates);
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100%;
  width: 100%;
}
li {
  cursor: pointer;
}
.latlong_description {
  background-color: rgba(248, 247, 247, 0.972);
  box-shadow: 1px 1px 1px rgb(126, 125, 125);
  padding: 9px 16px;
  line-height: 0.5;
  font-size: 10px;
  z-index: 1;
}
.topright{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.edit_button{
  background-color: #0d89ec;
  width: 100%;
  border: none;
  color: white;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.end_edition_button{
  background-color: #dacd1a;
  width: 100%;
  border: solid 0 0 1px 0  #e41b09df;
  color: rgb(5, 5, 5);
  padding: 8px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
</style>
