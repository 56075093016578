<template>
<div class="flex justify-content-start">
  
  <Bandeja :user="user" :dependencia="dependencia" v-if="(dependencia && $rest.esTipoUsuario(['Super User','Super Usuario de E3' ,'Jefe de Dependencia','Jefe de Sumario','User Tester'],user))||$rest.esTipoUsuario(['Oficial Sumariante'],user)"></Bandeja>
</div>
</template>

<script>
import Bandeja from '@/components/Dashboard/Bandeja'
export default {
  name: "DashboardBottomInfo",
  props:{
    user:{
      type:Object
    },
    dependencia:{
      type:String,
      default:null
    }
  },
  components:{
    Bandeja
  },
  data() {
    return {     
    };
  },
  created(){
  },
  methods: {

  },
};
</script>

<style scoped>
</style>