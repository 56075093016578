
export default {
    data () {
        return {
            Preforma: {
                withStyles:   `--- En la provincia de San Juan, `,
                noStyles:   `--- En la provincia de San Juan, `
            },
        }
    },
    computed: {
        department: {
            get(){
                return(
                    { 
                        withStyles:   
                            this.userState.userdata.dependenciaDepartamento !== null && 
                            this.userState.userdata.dependenciaDepartamento !== undefined
                            ? 
                                `<p style="color: #55b5ea ; display: inline">Departamento ${this.userState.userdata.dependenciaDepartamento}</p>,` 
                            :
                                '',
                        noStyles:   
                            this.userState.userdata.dependenciaDepartamento !== null &&
                            this.userState.userdata.dependenciaDepartamento !== undefined
                            ? 
                                `Departamento ${this.userState.userdata.dependenciaDepartamento},` 
                            : 
                                '' 
                    }
                )
            }
        },
        dateAndTimeDenuncia: {
            get(){
                return(
                    { 
                        withStyles:   ` <p style="color: #55b5ea ; display: inline">${this.dateDenuncia}</p>,`,
                        noStyles: this.dateDenuncia,
                    }
                )
            }
        },
        interveningPersonnel: {
            get(){
                return(
                    { 
                        withStyles:   `<p style="color: #55b5ea ; display: inline">${this.dataResumen.personalInterviniente}</p>`,
                        noStyles: this.dataResumen.personalInterviniente !== undefined ? this.dataResumen.personalInterviniente : '',
                    }
                )
            }
        },
        datosLegales () {
            const actuacionType = this.$router.app.$route.params.pathName
            let answer = this.$router.history.current.path === '/actuaciones/new/' +actuacionType + '/final' ? true : false;
            return answer;
        },
        dateDenuncia: {
            get() {
              function addZero(x) {
                return x < 10 ? `0${String(x)}` : String(x);
              }
          
              let phrase;
              let d = new Date();
          
              if (this.sumario.fechaDenuncia) {
                // Asegúrate de que fechaDenuncia sea un objeto Date
                d = new Date(this.sumario.fechaDenuncia);
              }
          
              var dia = addZero(d.getDate());
              var hora = `${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
              var mes = d.getMonth() + 1;
              var anio = addZero(d.getFullYear().toString().slice(2, 4));
          
              phrase = dia === "1" ? "a 1 día" : "a los " + dia + " días ";
              let letterYear = this.yearNumberToLetter(anio);
              let letterMounth = this.mounthNumberToLetter(mes.toString());
          
              phrase += "del mes de " + letterMounth + " ";
              phrase += "del año Dos Mil " + letterYear + ", ";
              phrase += "siendo las " + hora + " horas";
          
              return phrase;
            }
          },
          
        dataDeposition:{
            get (){
                return this.deposition
            },
                set(value){
                this.$store.dispatch('sumario/setValue', { field: 'deposition', value: value })
            }
        },
    },
    methods: {
        yearNumberToLetter(year){
            let letterYear 
            switch (year) {
                case '22':
                    letterYear = 'Veintidos'
                    break;
                case '23':
                    letterYear = 'Veintitrés'
                    break;
                case '24':
                    letterYear = 'Veinticuatro'
                    break;
                case '25':
                    letterYear = 'Veinticinco'
                    break;
                case '26':
                    letterYear = 'Veintiseis'
                    break;
                case '27':
                    letterYear = 'Veintisiete'
                    break;
                case '28':
                    letterYear = 'Veintiocho'
                    break;
                case '29':
                    letterYear = 'Veintinueve'
                    break;
                case '30':
                    letterYear = 'Treinta'
                    break;
                case '31':
                    letterYear = 'Treinta y uno'
                    break;
                case '32':
                    letterYear = 'Treinta y dos'
                    break;
                case '33':
                    letterYear = 'Treinta y tres'
                    break;
                case '34':
                    letterYear = 'Treinta y cuatro'
                    break;
                case '35':
                    letterYear = 'Treinta y cinco'
                    break;
                case '36':
                    letterYear = 'Treinta y seis'
                    break;
                case '37':
                    letterYear = 'Treinta y siete'
                    break;
                case '38':
                    letterYear = 'Treinta y ocho'
                    break;
                case '39':
                    letterYear = 'Treinta y nuevo'
                    break;
                case '40':
                    letterYear = 'Cuarenta'
                    break;
                case '41':
                    letterYear = 'Cuarenta y uno'
                    break;
                case '42':
                    letterYear = 'Cuarenta y dos'
                    break;
                case '43':
                    letterYear = 'Cuarenta y tres'
                    break;
                case '44':
                    letterYear = 'Cuarenta y cuatro'
                    break;
                case '45':
                    letterYear = 'Cuarenta y cinco'
                    break;
                case '46':
                    letterYear = 'Cuarenta y seis'
                    break;
                case '47':
                    letterYear = 'Cuarenta y siete'
                    break;
                case '48':
                    letterYear = 'Cuarenta y ocho'
                    break;
                case '49':
                    letterYear = 'Cuarenta y nueve'
                    break;
                case '50':
                    letterYear = 'Cincuenta'
                    break;
                case '51':
                    letterYear = 'Cincuenta y uno'
                    break;
                case '52':
                    letterYear = 'Cincuenta y dos'
                    break;
                case '53':
                    letterYear = 'Cincuenta y tres'
                    break;
                case '54':
                    letterYear = 'Cincuenta y cuatro'
                    break;
                case '55':
                    letterYear = 'Cincuenta y cinco'
                    break;
                case '56':
                    letterYear = 'Cincuenta y seis'
                    break;
                case '57':
                    letterYear = 'Cincuenta y siete'
                    break;
                case '58':
                    letterYear = 'Cincuenta y ocho'
                    break;
                case '59':
                    letterYear = 'Cincuenta y nueve'
                    break;
                case '60':
                    letterYear = 'Sesenta'
                    break;
                case '61':
                    letterYear = 'Sesenta y uno'
                    break;
                case '62':
                    letterYear = 'Sesenta y dos'
                    break;
                case '63':
                    letterYear = 'Sesenta y tres'
                    break;
                case '64':
                    letterYear = 'Sesenta y cuatro'
                    break;
                default:
                    letterYear = year
            }
            return letterYear
        },
        mounthNumberToLetter(mounth){
            let letterMounth
            switch(mounth){

              case '1':
        
                return letterMounth = "Enero"
              
              case '2':
        
                return letterMounth = "Febrero"
            
              case '3':
        
                return letterMounth = "Marzo"
              
              case '4':
        
                return letterMounth = "Abril"
              
              case '5':
        
                return letterMounth = "Mayo"
            
              case '6':
        
                return letterMounth = "Junio"
              
              case '7':
      
                return letterMounth = "Julio"
              
              case '8':
    
                return letterMounth = "Agosto"
              
              case '9':
    
                return letterMounth = "Septiembre"

              case '10':
    
                return letterMounth = "Octubre"
              
              case '11':
  
                return letterMounth = "Noviembre"
              
              case '12':
  
                return letterMounth = "Diciembre"
                          
            }
            return letterMounth
        }
    }    
};