export const preliminarOtrosConfig = [
      
   
    {
        path: "/actuaciones/new/preliminares-otros/initial",
        pathName: "preliminares-otros",
        titulo: "ACTUACIONES PRELIMINARES - OTROS",
        props: { formType: "preliminar-otros", actuacionType: "preliminares-otros"},
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/preliminares-otros/initial/denunciante",
        pathName: "preliminares-otros",
        titulo: "DENUNCIANTE Y/O DAMNIFICADO",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/preliminares-otros/initial/momento",
        pathName: "preliminares-otros",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/preliminares-otros/initial/acusado",
        pathName: "preliminares-otros",
        titulo: "ACUSADO",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/preliminares-otros/initial/efectos",
        pathName: "preliminares-otros",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]