import { ModusOperandiConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/modus-operandi'
        path: 'modus-operandi',
        name: 'modus-operandi',
        beforeEnter: isUserRolGuard,
        // component:   () => import ('@/pages/parametros/pais.vue'),
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { //'parametros/modus-operandi/new'
        path: 'modus-operandi/new',
        name: 'modus-operandi-new',
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/modus-operandi/edit/:id'
        path: 'modus-operandi/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]
     
