<template>
  <div class="card-container">
    <div
      :class="       
        `border-round-sm
        bg-white
        shadow-3
        flex flex-wrap
        h-9rem
        border-left-3
        `
      "
      :style="{ 'border-color': `var(--${color})`, }"
    >
      <div class="flex flex-row h-2rem pt-3 pl-3 h-3rem " >
        <span class="overview-icon flex align-items-end">
          <i :class="`pi pi-pencil flex align-items-center p-2 border-round`" :style="{ 'background-color': `var(--${color})` }"></i>
        </span>
        <span class="vertical-align-middle text-2xl font-semibold ml-2  flex align-items-center">
          {{ cardName }}
        </span>
      </div>
      <div class="flex flex-row min-w-full justify-content-center pb-3">
        <div class="flex flex-wrap">
          <div class="w-6">
            <div class="text-2xl font-semibold flex justify-content-center">
              {{ pendings }}
            </div>
            <div class="font-semibold mt-1 text-600">En curso</div>
          </div>
          <div class="w-6 border-left-1 border-300 pl-3">
            <div class="text-2xl font-semibold flex justify-content-center">
              {{ expireds }}
            </div>
            <div class="font-semibold mt-1 text-600">Vencidos</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    cardName: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
    pendings: {
      type: Number,
      default: 0,
    },
    expireds: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style scoped>
.card-container {
  margin-bottom: 20px; /* Espacio inferior entre las tarjetas */
}
@media (min-width: 1024px) {
  .card-container {
    margin-right: 20px; /* Espacio derecho entre las tarjetas en pantallas grandes */
    margin-bottom: 0; /* Eliminar el margen inferior en pantallas grandes */
  }
  .card-container:nth-child(4n) {
    margin-right: 0; /* Eliminar el margen derecho para el último elemento de cada fila de 4 tarjetas */
  }
}
</style>
