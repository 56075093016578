import Vue from 'vue'
import functionsDataResumen from './functionsDataResumen';

const datesAndLocations = (state, data) => {
  ////////////Fecha de la Denuncia/////////////
  try {
    //si la fecha de la denuncia viene del store, esta sera de formato objet y habra que convertirla 
    if(typeof(state.fechaDenuncia) == "object")
    {
      data.fechaCertificadoDenuncia = state.fechaDenuncia.toLocaleDateString('es-AR'); ;
    }else//caso contrario, si viene de la Bd vendra en formato string
    {
      data.fechaCertificadoDenuncia = state.fechaDenuncia
    }

    if(state.fechaDenuncia != null)
    {
      let formattedDate = state.fechaDenuncia
      let datosDeExtCertificado = new Date(formattedDate)
      data.año = datosDeExtCertificado.getFullYear();
      data.horaDeExtCertificado = datosDeExtCertificado.getHours() + ':' + datosDeExtCertificado.getMinutes() + ':' + datosDeExtCertificado.getSeconds()

    }else
    {
      let datosDeExtCertificado = new Date()
      data.año = datosDeExtCertificado.getFullYear();
      data.horaDeExtCertificado = datosDeExtCertificado.getHours() + ':' + datosDeExtCertificado.getMinutes() + ':' + datosDeExtCertificado.getSeconds()
    }
  }
  catch(e){
    console.log("Error al cargar la fecha de la Denuncia. EROOR: "+e);
  }

  ///////////////////////////Fecha y hora del Hecho//////////////////////
  try {
    let date = state.moment.date
    let date2 = state.moment.date2
    var dateActual = new Date();
    ////////cargo la fecha y hora 1-Desde/////// 
    if ((typeof  date == 'string' || typeof  date == 'object') && date != null && date != undefined) 
    {
      date = new Date(date)
      data.fechaDelHecho1 = date.toLocaleDateString('es-AR');//esto ya la trae en formato slash
      data.fechaDelHechoText1 =  functionsDataResumen.fechaLetras(date);
      data.horaDelHecho1 =date.toLocaleTimeString('es-AR');
    }else
    {
      if (date2 == null || date2 == undefined) //si ninguna de la 2 fechas esta definida emitimos un error
      { 
        let formTypesForValidateAndInclude = {'ufi': { startsWith: true },'preliminar': { startsWith: true },};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) {
          formTypesForValidateAndInclude = {'ufi-flagrancia': { contains: true },'ufi-cavig': { contains: true }, 'ufi-anivi': { contains: true },
          'ufi-generica': { contains: true }, 'ufi-generica-oficio': { contains: true },'ufi-delitos-contra-la-propiedad': { contains: true },
          'ufi-estafas-y-delitos-informaticos': { contains: true }, 'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
          'ufi-delitos-contra-la-propiedad-oficio': { contains: true },
        };
          let formTypesForValidateAndIncludeOrExclude = {'preliminar': { startsWith: true }, 'preliminar-incendio-vivienda': { excluded: true }, 'preliminar-establecer-procedencias': { excluded: true }, 'preliminar-otros': { excluded: true },};
          if(
            functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude) || 
            functionsDataResumen.validateFormType(data, formTypesForValidateAndIncludeOrExclude, true)
          ){
            data.errorValidationErr.push("Falta fecha del hecho");
          } else{
            data.errorValidationWarning.push("No especificó fecha del hecho");
          }    
        }  else{
          data.errorValidationErr.push("Falta fecha del hecho");
        }       
      }
    }
    
    ///////////cargo fecha y hora 2-Hasta////////////
    if ((typeof  date2 == 'string' || typeof  date2 == 'object') && date2 != null) 
    {
      date2 = new Date(date2)
      date = new Date(date)
      data.fechaDelHecho2 = date2.toLocaleDateString('es-AR');//esto ya la trae en formato slash
      data.fechaDelHechoText2 =  functionsDataResumen.fechaLetras(date2);
      data.horaDelHecho2 =date2.toLocaleTimeString('es-AR')
    }
    if(date != null && date2 != null && date > date2)
    {      
      data.errorValidationErr.push("Verificar el orden de las fechas del hecho");
    }
     if(dateActual < date || dateActual < date2){      
      data.errorValidationErr.push("La fecha del hecho no puede ser posterior a la fecha actual");
    }
     if(date == null && date2 != null)//si uno esta indefinido, tomamos el valor del otro
    {
      data.fechaDelHecho= data.fechaDelHecho2;
      data.horaDelHecho = data.horaDelHecho2;
      data.fechaDelHechoText = data.fechaDelHechoText2;
    }
     if(date != null && date2 == null ){//si uno esta indefinido, tomamos el valor del otro
      data.fechaDelHecho= data.fechaDelHecho1;
      data.horaDelHecho = data.horaDelHecho1;
      data.fechaDelHechoText = data.fechaDelHechoText1;
    }
     if(date != null && date === date2)// si ambos son iguales
    {
      data.fechaDelHecho= data.fechaDelHecho1;//cualquiera de las 2 es lo mismo seleccionamos cualquiera de ellos
      data.horaDelHecho = data.horaDelHecho1 < data.horaDelHecho2  ? `${data.horaDelHecho1} A ${data.horaDelHecho2}`: data.horaDelHecho1 
      data.fechaDelHechoText = data.fechaDelHechoText1;
    }
    if(date != null && date2 !=null){//si ambos son distintos armamos un string con ambos
      data.fechaDelHecho= data.fechaDelHecho1+ ' A '+ data.fechaDelHecho2;
      data.horaDelHecho = data.horaDelHecho1 < data.horaDelHecho2  ? `${data.horaDelHecho1} A ${data.horaDelHecho2}`: data.horaDelHecho1 
      data.fechaDelHechoText = [`${data.fechaDelHechoText1[0]} A ${data.fechaDelHechoText2[0]}`,`${data.fechaDelHechoText1[1]} A ${data.fechaDelHechoText2[1]}`]
    }
  }
  catch(e){
    console.log('error', e);
    data.errorValidationErr.push("Verificar fecha del hecho")
  }

  try {
    let inputs = 0;
    let lugarHecho='';
    let calleString = '';//'sin calle';
    if (state.preUbicacion.calle){
      calleString = state.preUbicacion.calle;
      lugarHecho = `Calle ${calleString}`;
      inputs++
    } 
    
    let numeroString = '';//'sin numero';
    if (state.preUbicacion.numero){
      numeroString = state.preUbicacion.numero;
      lugarHecho = lugarHecho+' Número: '+ numeroString;
      inputs++
    }
    
    // let dto = typeof state.preUbicacion.departamento == 'object' ? state.preUbicacion.departamento.nombre : false;
    let dto = (typeof(state.preUbicacion.departamento) === 'undefined'||
    state.preUbicacion.departamento === null )? false :state.preUbicacion.departamento.nombre
    
    let localidadString ='';// 'sin localidad';
    if (dto){
      localidadString = dto == "CAPITAL" ? "SAN JUAN" : dto ;
      lugarHecho = inputs >0 ? lugarHecho + ', '+ localidadString :lugarHecho + localidadString
      // lugarHecho = lugarHecho + ', '+ localidadString;
      inputs++
    } 

    let casaString = '';//'sin casa';
    if (state.preUbicacion.casa){
      casaString = state.preUbicacion.casa;
      lugarHecho = lugarHecho + ' Casa: ' + casaString;
      inputs++
    } 
    // let orientacion = typeof state.preUbicacion.orientacion == 'object' ? state.preUbicacion.orientacion.nombre : false;
    let orientacion = (typeof(state.preUbicacion.orientacion ) === 'undefined'||
    state.preUbicacion.orientacion  === null )? false :state.preUbicacion.orientacion.nombre
    let orientacionString ='';// 'sin orientacion';

    if (orientacion){
      orientacionString = orientacion 
      lugarHecho = lugarHecho + ', '+ orientacionString
      inputs++
    } 

    let barrioString = '';//'sin barrio';
    if (state.preUbicacion.barrio){
      barrioString = state.preUbicacion.barrio;
      lugarHecho = inputs > 0 ? lugarHecho + '. Barrio: ' + barrioString : lugarHecho + 'Barrio: ' + barrioString 
      // lugarHecho = lugarHecho + '. Barrio: ' + barrioString;
      inputs++
    }
    let sectorString = '';//'sin sector';
    if (state.preUbicacion.sector){
      sectorString = state.preUbicacion.sector;
      lugarHecho = lugarHecho + ' Sector: ' + sectorString;
      inputs++
    }

    
    let pisoString = '';//'sin piso';
    if (state.preUbicacion.piso){
      pisoString = state.preUbicacion.piso;
      lugarHecho = lugarHecho + ' Piso: ' + pisoString;
      inputs++
    }

    let zonaString = '';//'sin zona';
    if (state.preUbicacion.zona){
      zonaString = state.preUbicacion.zona;
      lugarHecho = lugarHecho + ', Zona: ' + zonaString;
      inputs++
    }
    if (state.preUbicacion?.coordinates == undefined && !Vue.prototype.$offline.offlineServer){  
      let formTypesForValidateAndInclude = {'ufi': { startsWith: true },'preliminar': { startsWith: true },};
      if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) {
        formTypesForValidateAndInclude = {'ufi-flagrancia': { contains: true },'ufi-cavig': { contains: true },
        'ufi-anivi': { contains: true },'ufi-generica': { contains: true },'ufi-generica-oficio': { contains: true },
        'ufi-delitos-contra-la-propiedad': { contains: true }, 'ufi-delitos-contra-la-propiedad-oficio': { contains: true },
        'ufi-estafas-y-delitos-informaticos': { contains: true }, 'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
      };
        let formTypesForValidateAndIncludeOrExclude = {'preliminar': { startsWith: true }, 'preliminar-incendio-vivienda': { excluded: true }, 'preliminar-establecer-procedencias': { excluded: true }, 'preliminar-otros': { excluded: true },};
        if(
          functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude) || 
          functionsDataResumen.validateFormType(data, formTypesForValidateAndIncludeOrExclude, true)
        ) {
          data.errorValidationErr.push("Falta geolocalización")
        } else{
          data.errorValidationWarning.push("No especificó geolocalización");
        }    
      }  else{
        data.errorValidationErr.push("Falta geolocalización")
      }   
    }

    data.lugarHecho = {
      ...state.preUbicacion,
      text: lugarHecho 
    }
    data.lugarDelHecho = lugarHecho;   
    switch (data.formType) {
      case 'denuncia':
        if (inputs < 1){             
          data.errorValidationErr.push("Falta lugar del hecho")  
        }
      break;
      case 'oficio':
        if (inputs < 1){            
          data.errorValidationErr.push("Falta lugar del hecho")  
        }
      break;
      case 'contravencional-oficio':
        if (inputs < 1){            
          data.errorValidationErr.push("Falta ubicación del hecho")
        }
      break;        
      case 'contravencional-denuncia':
        if (inputs < 1){            
          data.errorValidationErr.push("Falta ubicación del hecho")
        }
      break;   
    }
    if(data.formType?.startsWith("ufi")) {
      if (inputs < 1){        
        if(data.formType === 'ufi-flagrancia' || data.formType === 'ufi-cavig' || data.formType === 'ufi-anivi' 
          || data.formType === 'ufi-generica'|| data.formType === 'ufi-generica-oficio'
          || data.formType === 'ufi-delitos-contra-la-propiedad' || data.formType === 'ufi-delitos-contra-la-propiedad-oficio'
          || data.formType === 'ufi-estafas-y-delitos-informaticos' || data.formType === 'ufi-estafas-y-delitos-informaticos-oficio'
        ){
          data.errorValidationErr.push("Falta ubicación del hecho")
        } else{
          data.errorValidationWarning.push("No especificó ubicacion del hecho");
        }   
      }
    }
  }
  catch(e){   
    console.log('error', e);
    data.errorValidationErr.push("Falta lugar del hecho")
  }

  ///////////////////////////Fecha de extencion del certificado///////////////
  let date = !state.fechaCertificadoDenuncia ? new Date() :state.fechaCertificadoDenuncia;

  if (typeof  date == 'string') 
  { 
    date = new Date(date)//Lo convertimos a tipo Date para luego porder aplicarle la funcion toLocaleDateString
  }

  ///////////////////////////////////Fecha de extencion del Certificado///////////////////
  data.fechaDeExtencionCertificado = date.toLocaleDateString('es-AR');
  
  data.fechaDeExtencionCertificadoText =  functionsDataResumen.fechaLetras(date);

  //////////////////////////////////Fecha de extencion del certifcado +5 dias/////////////////////////////////////
  var fechaDeExtencionCertificadoMas5dias = functionsDataResumen.sumarDias(date, 5);
  data.fechaDeExtencionCertificadoMas5diasText = functionsDataResumen.fechaLetras(fechaDeExtencionCertificadoMas5dias);

  ///////////////////////////////Hora de extencion del certificado
  var hora = date.getHours() + ' Horas y ' + date.getMinutes() + ' minutos'; 
  data.horaDeExtencionCertificado = hora;

  if (data.err < 1) data.validate = true

  data.fechaAhoraText =  functionsDataResumen.fechaLetras()
}
export default {
  datesAndLocations
}