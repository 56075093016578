var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'grid': !_vm.minimal }},[(!_vm.minimal)?_c('div',{staticClass:"col-12 md:col-4 lg:col-3 lateral"},[_c('Listbox',{ref:"listBox",attrs:{"options":_vm.denuncianteTodosSelect,"dataKey":"arrId","optionValue":"arrId","optionLabel":"name","test-id":"listDenunciantes"},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('div',{staticClass:"flex justify-content-between"},[_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(slotProps.option.name))]),_c('div',{staticClass:"flex justify-content-center align-items-center flex-nowrap"},[(slotProps.option.type !== 'empty')?_c('Button',{staticClass:"p-button-danger p-button-lg px-2 py-2 m-1",staticStyle:{"z-index":"3"},attrs:{"icon":"pi pi-trash"},on:{"click":function($event){$event.stopPropagation();return _vm.$_TemplateParentForm_delete(slotProps.option.arrId)}}}):_vm._e()],1)])]}}],null,false,1759381828),model:{value:(_vm.dataArrId),callback:function ($$v) {_vm.dataArrId=$$v},expression:"dataArrId"}}),_c('div',{staticClass:"button_Section_process volver"},[_c('Button',{attrs:{"label":"Volver","icon":"pi pi-back","test-id":"buttonVolver"},on:{"click":function($event){return _vm.backToTheMainForm()}}})],1)],1):_vm._e(),_c('div',{class:{ 'col': !_vm.minimal }},[(
        _vm.arrId &&
        _vm.viewData[_vm.viewData.actuacion].checkboxPublicOrder
      )?_c('div',{staticClass:"field-checkbox ml-2",class:{ notVisible: _vm.checkOrdenPublico() }},[_c('Checkbox',{attrs:{"id":"binary","binary":true},model:{value:(_vm.ordenPublico),callback:function ($$v) {_vm.ordenPublico=$$v},expression:"ordenPublico"}}),_c('label',{attrs:{"for":"binary"}},[_vm._v("Orden público")])],1):_vm._e(),_c('div',{class:{
        card: !_vm.minimal,
        hideForm: _vm.formData.type === null || _vm.ordenPublico,
      }},[_c('FormPersona',{ref:"denunciante",attrs:{"arrId":_vm.arrId,"getPersonByArrId":_vm.getDenuncianteByArrId,"ordenPublico":_vm.ordenPublico,"formType":_vm.formType,"typeOfPerson":"denunciante","hidden":_vm.viewData[_vm.viewData.actuacion].formPersona.hidden,"Involucrados":_vm.denuncianteTodosSelect},on:{"updateValue":_vm.$_TemplateParentForm_handleUpdateDocValue}})],1),(_vm.arrId)?_c('div',{staticClass:"button_Section_process guardar"},[_c('Button',{attrs:{"label":"Guardar","icon":"pi pi-back","test-id":"buttonSaveDenuciante"},on:{"click":function($event){return _vm.validacion('/actuaciones/new/' + _vm.actuacionType + '/initial')}}})],1):_vm._e()]),_c('ModalTemplate',{ref:"ModalRefInform",attrs:{"title":"Información","type":"informativo","message":"Se requiere un email válido o dejar el campo vacío si no tienes uno disponible.","showIcon":true,"severity":"danger","closable":false},on:{"confirm":function($event){return _vm.closeModal()}}}),_c('ModalTemplate',{ref:"ModalRefInformWarning",attrs:{"title":"Información","type":"informativo","message":"Recuerde ingresar un correo válido para notificaciones y comunicación.","showIcon":true,"severity":"warning","closable":false},on:{"confirm":function($event){return _vm.closeModalWarning()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }