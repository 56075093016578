<template>
  <div v-if="minutes || seconds != '00'" class="flex flex-row align-center">
    <slot name="before-time"></slot>
    <p :class="`text-${color}-${intensity}`">{{ minutes }}:{{ seconds }}</p>
    <slot name="after-time"></slot>
  </div>
</template>

<script>
export default {
  name: "Timer",
  data() {
    return {
      minutes: 0,
      seconds: "00",
    };
  },
  props: {
    //Minutos que va a tener el contador
    duration: {
      type: Number,
      default: 0,
    },
    color:{
      type: String,
      default:'blue'
    },
    intensity:{
      type: Number,
      default: 500
    }
  },
  created() {
    this.minutes = this.duration - 1;
    this.seconds = 59;
  },
  watch: {
    seconds: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.seconds = this.addZeros(this.seconds - 1);
          }, 1000);
        } else if (value == 0 && this.minutes > 0) {
          setTimeout(() => {
            this.minutes--;
            this.seconds = 59;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    addZeros(value) {
      if (value < 10) {
        return (value = "0" + value);
      } else {
        return value;
      }
    },
  },
};
</script>