<template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">
      <div v-if="getMode() == 'new'" class="feature-intro">
        <h1>Nuevo {{ pageName }}</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
      <div v-else class="feature-intro">
        <h1>Editando {{ pageName }}</h1>
        <p>Modifique los datos que desea EDITAR</p>
      </div>
    </div>
    
    <div class="mb-4 text-xl">Juzgado Seleccionado</div>
    
    <TForm
      :data="data"
      :nombres="names"
      :errors="errors"
      :mensajes="mensajes"
      :mode="getMode()"
      :designEdit="designEdit"
      :designNew="design"
      :url="url"
      :viewer="viewer"
      :viewerID="viewerID"
    />
  </div>
</template>

<script>
export default {
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    },
  },
  data() {
    return {
      data: {
        // Fechainivig: {
        //   value: "2021-07-29",
        //   value2: "2021-07-30",
        //   validation: "required",
        //   type: "hidden"

        // },
        // Fechafinvig: { value: "2021-07-29", value2: "2021-07-30", validation: "", type: "hidden" },
        Nombre: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[50]",
          type: "input"
        },
        Telefono: {
          value: "2645064965",
          value2: "2645064969",
          validation: "max_length[50]",
          type: "input"
        },
        Domicilio: {
          value: "abc123",
          value2: "123abc",
          validation: "max_length[255]",
          type: "input"

        },
      },
      names: {
        // Fechainivig: "",
        // Fechafinvig: "",
        Nombre: "",
        Telefono: "",
        Domicilio: "",
      },
      errors: {
        // Fechainivig: false,
        // Fechafinvig: false,
        Nombre: false,
        Telefono: false,
        Domicilio: false,
      },
      mensajes: {
        // Fechainivig: "",
        // Fechafinvig: "",
        Nombre: "",
        Telefono: "",
        Domicilio: "",
      },
      design: [["Nombre", "Telefono"], ["Domicilio"]],
      designEdit: [
        ["Nombre"],
        ["Telefono", "Domicilio"],
      ],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/juzgado",
      pageName: "Juzgado",
    };
  },
  created() {
    let { id } = this.$route.params;
    this.id = id;
  },
  methods: {
    getMode() {
      return this.id == "add" ? "new" : "edit";
    },
  },
};
</script>
