export const ufiPropiedadDenunciaConfig = [
    {
        path: "/actuaciones/new/ufi-propiedad-denuncia/initial",
        pathName: "ufi-propiedad-denuncia",
        titulo: "UFI - DELITOS CONTRA LA PROPIEDAD POR DENUNCIA",
        icon: "pi-building",
        roles: [
          "User Tester",
          "Super User",
          "Jefe de Dependencia",
          "Oficial Sumariante",
          "Jefe de Sumario",
        ],
        type: "link",
        grupo: "UFI",
        props: { formType: "ufi-delitos-contra-la-propiedad", actuacionType: "ufi-propiedad-denuncia" },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-propiedad-denuncia/initial/denunciante",
        pathName: "ufi-propiedad-denuncia",
        titulo: "VÍCTIMA",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-propiedad-denuncia/initial/momento",
        pathName: "ufi-propiedad-denuncia",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/ufi-propiedad-denuncia/initial/acusado",
        pathName: "ufi-propiedad-denuncia",
        titulo: "APREHENDIDOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/ufi-propiedad-denuncia/initial/efectos",
        pathName: "ufi-propiedad-denuncia",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]