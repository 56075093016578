export const preliminarIncendioAutoConfig = [
    {
        path: "/actuaciones/new/preliminares-incendio-automotor/initial",
        pathName: "preliminares-incendio-automotor",
        titulo: "ACTUACIONES PRELIMINARES - INCENDIO DE AUTOMOTOR",
        props: { formType: "preliminar-incendio-automotor", actuacionType: "preliminares-incendio-automotor"},
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/preliminares-incendio-automotor/initial/denunciante",
        pathName: "preliminares-incendio-automotor",
        titulo: "PROTAGONISTAS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/preliminares-incendio-automotor/initial/momento",
        pathName: "preliminares-incendio-automotor",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },      
      {
        path: "/actuaciones/new/preliminares-incendio-automotor/initial/efectos",
        pathName: "preliminares-incendio-automotor",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]