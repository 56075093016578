import axios from "axios";

export const sumariosToExcel = {
  data() {
    return {
      dataExcel: { name: "", data: [], fields: {}, showModal: false, loading: false, error: "" },
    };
  },
  methods: {
    /**
     * @param {String} tipo 'planeamiento' || 'InspeccionControl' || 'direccion'
     */
    async downloadXls(tipo) {
      console.log("downloadXls");
      this.dataExcel.error=""
      this.dataExcel.name = tipo
      let url = this.lastUrl.replace('limit=8&offset=0', 'limit=20&offset=0');
      this.dataExcel.loading = true;
      this.dataExcel.showModal = true;
      let info = [];
      let format = [];
      let fields = {}
      this.dataExcel.name = tipo
      //pido los datos si son mas de 200 divido las cosultas
      try{
        if (this.total > 20) {
          for (let i = 0; i < Math.ceil(this.total / 20); i++) {
            // for (let i = 0; i < 1; i++) {

            // 0 http://dev.electro3.com.ar/siis/api/v1/sumario?limit=20&offset=0&getTotal=true&search={"createdAt":[{"operator":"TODAY","value":""}]}
              url = this.lastUrl.replace('limit=8&offset=0', `limit=20&offset=${20 * i}`).replace('&getTotal=true', '')
              // url = url.replace(`limit=20&offset=0`, `limit=20&offset=${20 * i}`)
              console.log('url: ', i, url)
            let res = (await axios.get(`${url + '&getXLSpersonalizado=' + tipo}`, { withCredentials: true, params: { method: "own" } })).data.data
            info = [...info, ...res]
          }
        } else {
          info = (await axios.get(`${url + '&getXLSpersonalizado=' + tipo}`, { withCredentials: true, params: { method: "own" } })).data.data
        }
      }catch(e){
        console.error('error: ', e)
        this.dataExcel.error="Ocurrio un error en la carga de los registros"
        this.dataExcel.loading = false;
      }
      // seteo el formato del excel
      switch (tipo) {
        case 'planeamiento':
          format = [{ name: 'Dependencia', nameField: 'DependenciaLetra', type: '', nameExcel: 'Dependencia' },
          { name: 'FechaInicio', nameField: 'DenunciaFechaCreacion', type: 'fecha', nameExcel: 'Fecha de inicio' },
          { name: 'FechaHecho', nameField: 'HechoubicacionFechahoradesde', type: 'fecha', nameExcel: 'Fecha del hecho' },
          { name: 'FechaDeResumen', type: 'diligencia', nameExcel: 'Fecha de resumen' },
          { name: 'NroSumario', nameField: 'Numero', type: '', nameExcel: 'N° de sumario' },
          { name: 'Causa', nameField: 'CausaCaratula', type: 'causa', nameExcel: 'Causa' },
          { name: 'Operandi', nameField: '', type: 'ModusoperandiNombre', nameExcel: 'Operandi' },
          { name: 'Status', nameField: '', type: 'status', nameExcel: 'Estado' },
          { name: 'Hora', nameField: 'DenunciaFechaCreacion', type: 'hora', nameExcel: 'Hora' },
          { name: 'Domicilio', type: 'Domicilio', nameExcel: 'Domicilio exacto de donde Ocurrio el Hecho' },
          { name: 'Departamento', nameField: 'HechoubicacionDepartamento', type: '', nameExcel: 'Departamento' },
          { name: 'Coordenadas', type: 'Coordenadas', nameExcel: 'Coordenada' },
          { name: 'Damnificado', nameField: 'Damnificados', type: 'persona', nameExcel: 'Damnificado' },
          // { name: 'Victima', nameField: 'Victima', type: 'persona', nameExcel: 'Damnificado' },
          // { name: 'Denunciante', nameField: 'Denunciantes', type: 'persona', nameExcel: 'Damnificado' },
          { name: 'Victima/Denunciante', nameField: 'Victima', type: 'persona', nameExcel: 'Damnificado' },
          { name: 'Victima/Denunciante', nameField: 'Denunciantes', type: 'persona', nameExcel: 'Damnificado' },

          { name: 'Acusado', nameField: 'Acusados', type: 'persona', nameExcel: 'Vinculado' }, 
          { name: 'Detenido/Aprehendido', nameField: 'Aprehendidos', type: 'persona', nameExcel: 'Detenido/Aprehendido' },
          { name: 'Detenido/Aprehendido', nameField: 'Detenido', type: 'persona', nameExcel: 'Detenido/Aprehendido' },
          { name: 'Efectos', nameField: '', type: 'efecto', nameExcel: 'Efecto' },
          ];
          this.addCampos(info,format,tipo);

          break;
        case 'Inspección y Control':
          format = [{ name: 'FechaInicio', nameField: 'DenunciaFechaCreacion', type: 'fecha', nameExcel: 'Fecha de inicio' },
          { name: 'FechaHecho', nameField: 'HechoubicacionFechahoradesde', type: 'fecha', nameExcel: 'Fecha del hecho' },
          { name: 'Tiposumario', nameField: 'TiposumarioID', type: '', nameExcel: 'Tipo' },
          { name: 'NroSumario', nameField: 'Numero', type: '', nameExcel: 'N° de actuación' },
          { name: 'Dependencia', nameField: 'DependenciaLetra', type: '', nameExcel: 'Dependencia' },
          { name: 'Causa', nameField: 'CausaCaratula', type: 'causa', nameExcel: 'Causa' },
          { name: 'Juzgado', nameField: '', type: 'Juzgado', nameExcel: 'Juzgado' },
          { name: 'secretarioActual', nameField: '', type: 'Secretario', nameExcel: 'Secretario actual' },
          { name: 'Status', nameField: '', type: 'status', nameExcel: 'Estado' },
          ];
          break;
        case 'Estadística de Registros':
          format = [{ name: 'UsuarioActual', nameField: '', type: 'Usuario', nameExcel: 'Nombre de usuario' },
          { name: 'secretarioActual', nameField: '', type: 'Secretario', nameExcel: 'Secretario actual' },
          { name: 'Dependencia', nameField: 'DependenciaLetra', type: '', nameExcel: 'Dependencia' },
          { name: 'Registros', type: 'Contador de sumarios', nameExcel: 'Registros' }
          ];
          break;
        case 'Dpto. Asuntos Judiciales D-5':
        case 'Dpto.Criminalistica':
        case 'Lab. Informatico Forense':
          format = [{ name: 'Dependencia', nameField: 'DependenciaLetra', type: '', nameExcel: 'Dependencia' },
          { name: 'FechaInicio', nameField: 'DenunciaFechaCreacion', type: 'fecha', nameExcel: 'Fecha de inicio' },
          { name: 'FechaHecho', nameField: 'HechoubicacionFechahoradesde', type: 'fecha', nameExcel: 'Fecha Denuncia' },
          { name: 'FechaDeResumen', type: 'diligencia', nameExcel: 'Fecha de resumen' },
          { name: 'NroSumario', nameField: 'Numero', type: '', nameExcel: 'N° de sumario' },
          { name: 'Causa', nameField: 'CausaCaratula', type: 'causa', nameExcel: 'Causa' },
          { name: 'Juzgado', nameField: '', type: 'Juzgado', nameExcel: 'Juzgado' },
          { name: 'Status', nameField: '', type: 'status', nameExcel: 'Estado' },
          { name: 'Hora', nameField: 'DenunciaFechaCreacion', type: 'hora', nameExcel: 'Hora' },
          { name: 'Operandi', nameField: '', type: 'ModusoperandiNombre', nameExcel: 'Operandi' },
          { name: 'Domicilio', type: 'Domicilio', nameExcel: 'Domicilio exacto de donde Ocurrio el Hecho' },
          { name: 'Departamento', nameField: 'HechoubicacionDepartamento', type: '', nameExcel: 'Departamento' },
          { name: 'Coordenadas', type: 'Coordenadas', nameExcel: 'Coordenada' },
          { name: 'Damnificado', nameField: 'Damnificados', type: 'persona', nameExcel: 'Damnificado' },
          // { name: 'Victima', nameField: 'Victima', type: 'persona', nameExcel: 'Damnificado' },
          // { name: 'Denunciante', nameField: 'Denunciantes', type: 'persona', nameExcel: 'Damnificado' },       
          { name: 'Victima/Denunciante', nameField: 'Victima', type: 'persona', nameExcel: 'Damnificado' },
          { name: 'Victima/Denunciante', nameField: 'Denunciantes', type: 'persona', nameExcel: 'Damnificado' },

          { name: 'Acusado', nameField: 'Acusados', type: 'persona', nameExcel: 'Vinculado' }, 
          { name: 'Detenido/Aprehendido', nameField: 'Aprehendidos', type: 'persona', nameExcel: 'Detenido/Aprehendido' },
          { name: 'Detenido/Aprehendido', nameField: 'Detenido', type: 'persona', nameExcel: 'Detenido/Aprehendido' },
          
          { name: 'Efectos', nameField: '', type: 'efecto', nameExcel: 'Efecto' },
          ];
          this.addCampos(info,format);
          break;
      }
      this.dataExcel.fields = fields


      // creo el arreglo con los datos que la libreria necesita
      this.dataExcel.data = []
      await info.map((sumario, i) => {
        let data = {};
        format.map(({ type, nameField, name }) => {
          switch (type) {
            case 'x':
              break;
            case 'causa':
              
              if(sumario.TiposumarioID.includes("Expediente")){
                console.log("Expediente sumario[nameField]", nameField, sumario[nameField], sumario)
                data[name] = ""
                sumario.ActuacionArticulo.map(e=>data[name]+=data[name] ? ", "+e.Numeroarticulo: e.Numeroarticulo)
              } else if (Array.isArray(sumario['Sumariocausacaratula']) && sumario['Sumariocausacaratula'].length > 0) {
                console.log("Sumario  sumario[nameField]", nameField, sumario[nameField], sumario)
                data[name] = ""
                sumario.Sumariocausacaratula.map( e => data[name] += data[name] != "" ? ", " + e.Nombre : e.Nombre)
                // for (let index = 0; index < sumario.Sumariocausacaratula.length; index++) {
                //   const element = sumario.Sumariocausacaratula[index];
                //   data[name] += data[name] ? ", " + element.Nombre : element.Nombre
                // }
              } else {
                console.log("otros sumario[nameField]", nameField, sumario[nameField], sumario)
                data[name] = sumario[nameField];
              }
              break;
            case 'fecha':
              data[name] = (sumario[nameField]?.split(' ')[0]);
              break;

            case 'hora':
              data[name] = (sumario[nameField]?.split(' ')[1]);
              break;

            case 'diligencia':
              // let diligencia = sumario?.Diligencia?.find(e => e.Nombre?.includes('RESUMEN DE SUMARIO'));//ESTO AHORA LO TRAE YA FILTRADO DESDE LA API
              // console.log(sumario?.Diligencia)
              // data[name] = (diligencia?.Fechainivig.split(' ')[0]);
              data[name] = sumario["Diligencia"] != undefined ? sumario?.Diligencia[0]?.Fechainivig : ''
              break;

            case 'persona':
              sumario["ActuacionPersonaFisica"][nameField]?.forEach((element, i) => {
                // console.log("DICE: ",element.Apellido != 'Orden público' ? element.Apellido : "es igual")
                data[name + 'Apellido' + i] = element.Apellido != "null" ? element.Apellido : "---"
                data[name + 'Nombre' + i] = element.Nombre != "null" ? element.Nombre : "---"
                data[name + 'Doc' + i] = element.Dni != "null" ? element.Dni : "---"
                data[name + 'Sexo' + i] = element.Sexo != "null" ? element.Sexo : "---"
                data[name + 'FechaNacimiento' + i] = element.FechaDeNacimiento != "null" ? element.FechaDeNacimiento : "---"
                data[name + 'Edad' + i] = element.FechaDeNacimiento != "null" ? this.getEdad(element.FechaDeNacimiento) : "---"
                // if(name=="acusado"){
                data[name + "Apodo" + i]= element.Apodo != "null" ? element.Apodo : "---"
                // }
                data[name + 'Domicilio' + i] = element.DomicilioSipe != "null" ? element.DomicilioSipe : "---"
              });
              break;             

            case 'efecto':
              // let efectos = sumario?.Efectodenuncia?.length > 0 ? sumario.Efectodenuncia : sumario.Efectosecuestrado;
              // let efectos = sumario["Efectodenuncia"] || sumario["Efectosecuestrado"] || [];
              let efectos = sumario["Efectos"] || [];

              efectos.forEach((element, i) => {
                data[name + "Estado" + i] = element.Tipoefecto 
                data[name + "Tipo" + i] = element.Tipo
                data[name + "Cantidad" + i] = 1
                data[name + "Marca" + i] = element.Marca
                data[name + "IdSeriePatente" + i] = element.IdSeriePatente
                data[name + "Color" + i] = element.Color
                data[name + "Modelo" + i] = element.Modelo

              })
              break;

            case 'Domicilio':
              data[name] = `${this.addSinDatos(sumario.HechoubicacionDepartamento)}, ${this.addSinDatos(sumario.HechoubicacionBarrio)}, ${this.addSinDatos(sumario.HechoubicacionCalle)} ${this.addSinDatos(sumario.HechoubicacionNumero)}`
              break;
            case 'Coordenadas':
              data[name] = `Latitud: ${this.addSinDatos(sumario.HechoubicacionLatitud)}, Longitud: ${this.addSinDatos(sumario.HechoubicacionLongitud)}`
              break;
            case 'Secretario':
              let usuarioSum = this.getUsAct(sumario)
              data[name] = `${this.addSinDatos(usuarioSum?.Apellido)} ${this.addSinDatos(usuarioSum?.Nombre)}`
              break;
            case 'Usuario':
              let us = this.getUsAct(sumario)
              data[name] = us?.NombreUsuario
              break;
            case 'Juzgado':
              data[name] = sumario?.Roljuzgadosumario?.Nombre
              break;
            case 'status':
              data[name] = sumario.EstadoActuacion.find(e=>!e.FechaHasta)?.TipoEstadoActuacionID
              // data[name] = sumario.Sumarioestado.find(e=>!e.Fechafinestado)?.EstadosumarioID
              break;
            case 'ModusoperandiNombre':
              data[name] = sumario["Modusoperandi"] != undefined ? sumario.Modusoperandi.Nombre : ''
              break;              
            case 'Contador de sumarios':
              if (data[name])
                data[name]++
              else data[name] = 1;
              break;
            default:
              data[name] = sumario[nameField];
              break;
          }
        })
        return this.dataExcel.data.push(data);
      })
      if (tipo == 'Estadística de Registros') {
        let newData = []
        this.dataExcel.data.map(element => {
          let usuario = newData.find(e => e.UsuarioActual == element.UsuarioActual)
          if (element.UsuarioActual == "Sin Usuario" || !element.UsuarioActual) {
            return
          }
          if (usuario) {
            usuario.Registros++
          } else {
            newData.push({ ...element })
          }
        })
        this.dataExcel.data = newData
      }
      format = format.filter(e => e.type != 'persona' || e.type != 'efecto')
      format.map(e => { fields[e.nameExcel] = e.name })
      // console.log(format);
      this.dataExcel.loading = false
    },
    //esta funcion agrega los campos de antemano para evitar que se agregen los campos desordenados
    addCampos(info,format){
      let maximos = [0, 0, 0, 0, 0]
          info.map(e => {
            if (maximos[0] < e?.ActuacionPersonaFisica?.Denunciantes?.length) {
              maximos[0] = e?.ActuacionPersonaFisica?.Denunciantes?.length
            }            
            if (maximos[0] < e?.ActuacionPersonaFisica?.Victima?.length) {
              maximos[0] = e?.ActuacionPersonaFisica?.Victima?.length
            }            
            if (maximos[1] < e?.ActuacionPersonaFisica?.Damnificados?.length) {
              maximos[1] = e?.ActuacionPersonaFisica?.Damnificados?.length
            }
            if (maximos[2] < e?.ActuacionPersonaFisica?.Acusados?.length) {
              maximos[2] = e?.ActuacionPersonaFisica?.Acusados?.length
            }
            if (maximos[3] < e?.ActuacionPersonaFisica?.Detenido?.length) {
              maximos[3] = e?.ActuacionPersonaFisica?.Detenido?.length
            }   
            if (maximos[3] < e?.ActuacionPersonaFisica?.Aprehendido?.length) {
              maximos[3] = e?.ActuacionPersonaFisica?.Aprehendido?.length
            }                       
            if (maximos[4] < e?.Efectos?.length) {
              maximos[4] = e?.Efectos?.length
            }
            // if (maximos[5] < e?.Efectodenuncia?.length) {
            //   maximos[5] = e?.Efectodenuncia?.length
            // }

          })
          maximos.forEach((e, index) => {
            for (let i = 0; i < e; i++) {
              switch(index){
                case 0:
                  format.push(
                    { name: 'Victima/DenuncianteApellido' + i, type: 'x', nameExcel: 'Victima/Denunciante ' + i + ' apellido' },
                    { name: 'Victima/DenuncianteNombre' + i, type: 'x', nameExcel: 'Victima/Denunciante ' + i + ' Nombre' },
                    { name: 'Victima/DenuncianteDoc' + i, type: 'x', nameExcel: 'Victima/Denunciante ' + i + ' Documento' },
                    { name: 'Victima/DenuncianteSexo' + i, type: 'x', nameExcel: 'Victima/Denunciante ' + i + ' sexo' },
                    { name: 'Victima/DenuncianteFechaNacimiento' + i, type: 'x', nameExcel: 'Victima/Denunciante ' + i + ' Fecha de Nacimiento' },
                    { name: 'Victima/DenuncianteEdad' + i, type: 'x', nameExcel: 'Victima/Denunciante ' + i + ' Edad' },
                    { name: 'Victima/DenuncianteDomicilio' + i, type: 'x', nameExcel: 'Victima/Denunciante ' + i + ' domicilio' }  
                  )
                break;
                case 1:
                  format.push(
                    { name: 'DamnificadoApellido' + i, type: 'x', nameExcel: 'Damnificado ' + i + ' apellido' },
                    { name: 'DamnificadoNombre' + i, type: 'x', nameExcel: 'Damnificado ' + i + ' Nombre' },
                    { name: 'DamnificadoDoc' + i, type: 'x', nameExcel: 'Damnificado ' + i + ' Documento' },
                    { name: 'DamnificadoSexo' + i, type: 'x', nameExcel: 'Damnificado ' + i + ' sexo' },
                    { name: 'DamnificadoFechaNacimiento' + i, type: 'x', nameExcel: 'Damnificado ' + i + ' Fecha de Nacimiento' },
                    { name: 'DamnificadoEdad' + i, type: 'x', nameExcel: 'Damnificado ' + i + ' Edad' },
                    { name: 'DamnificadoDomicilio' + i, type: 'x', nameExcel: 'Damnificado ' + i + ' domicilio' }  
                  )
                break;  
                case 2:
                  format.push(
                    { name: 'AcusadoApellido' + i, type: 'x', nameExcel: 'Vinculado ' + i + ' apellido' },
                    { name: 'AcusadoNombre' + i, type: 'x', nameExcel: 'Vinculado ' + i + ' Nombre' },
                    { name: 'AcusadoDoc' + i, type: 'x', nameExcel: 'Vinculado ' + i + ' Documento' },
                    { name: 'AcusadoSexo' + i, type: 'x', nameExcel: 'Vinculado ' + i + ' Sexo' },
                    { name: 'AcusadoFechaNacimiento' + i, type: 'x', nameExcel: 'Vinculado ' + i + ' Fecha de Nacimiento' },                  
                    { name: 'AcusadoEdad' + i, type: 'x', nameExcel: 'Vinculado ' + i + ' Edad' },
                    { name: 'AcusadoApodo' + i, type: 'x', nameExcel: 'Vinculado ' + i + ' Apodo' },
                    { name: 'AcusadoDomicilio' + i, type: 'x', nameExcel: 'Vinculado ' + i + ' Domicilio' }
                  )
                break;
                case 3:
                  format.push(
                    { name: 'Detenido/AprehendidoApellido' + i, type: 'x', nameExcel: 'Detenido/Aprehendido' + i + ' apellido' },
                    { name: 'Detenido/AprehendidoNombre' + i, type: 'x', nameExcel: 'Detenido/Aprehendido' + i + ' Nombre' },
                    { name: 'Detenido/AprehendidoDoc' + i, type: 'x', nameExcel: 'Detenido/Aprehendido' + i + ' Documento' },
                    { name: 'Detenido/AprehendidoSexo' + i, type: 'x', nameExcel: 'Detenido/Aprehendido' + i + ' Sexo' },
                    { name: 'Detenido/AprehendidoFechaNacimiento' + i, type: 'x', nameExcel: 'Detenido/Aprehendido' + i + ' Fecha de Nacimiento' },                  
                    { name: 'Detenido/AprehendidoEdad' + i, type: 'x', nameExcel: 'Detenido/Aprehendido' + i + ' Edad' },
                    { name: 'Detenido/AprehendidoApodo' + i, type: 'x', nameExcel: 'Detenido/Aprehendido' + i + ' Apodo' },
                    { name: 'Detenido/AprehendidoDomicilio' + i, type: 'x', nameExcel: 'Detenido/Aprehendido' + i + ' Domicilio' }
                  )    
                break;              
                case 4:
                  format.push(     
                    { name: 'EfectosEstado' + i, type: 'x', nameExcel: 'Efecto ' + i + ' estado' },                             
                    { name: 'EfectosTipo' + i, type: 'x', nameExcel: 'Efecto ' + i + ' tipo' },
                    { name: 'EfectosCantidad' + i, type: 'x', nameExcel: 'Efecto ' + i + ' cantidad' },
                    { name: 'EfectosMarca' + i, type: 'x', nameExcel: 'Efecto ' + i + ' marca' },
                    { name: 'EfectosColor' + i, type: 'x', nameExcel: 'Efecto ' + i + ' color' },
                    { name: 'EfectosModelo' + i, type: 'x', nameExcel: 'Efecto ' + i + ' modelo' },  
                    { name: 'EfectosIdSeriePatente' + i, type: 'x', nameExcel: 'Efecto ' + i + ' N° serie/chasis' },
                    )
                break;
              }
            }
          })
    console.log(this.dataExcel);

    },
    addSinDatos(string) {
      if (!string) return 's/d'
      else return string
    },
    getEdad(dateString) {
      if(dateString == "null" || dateString == null){
        return undefined
      }
      let hoy = new Date()
      dateString = dateString.replace(/-/g, '/') //Esto se hace por que en algunas actuaciones viejas de guarda con "-" y en otras mas nuevas con "/"
      let [dia , mes , año] = dateString.split("/")
      let fechaNacimiento = new Date()
      fechaNacimiento.setFullYear(año,mes,dia)
      let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
      let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
      if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
      ) {
        edad--
      }
      return edad
    },
  },
};
