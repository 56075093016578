const state = () => ({
  isSearching: false,
  dni: null,
  data: {}
})


// actions
const actions = {
  searchPerson ({ commit, state }, dni) {
    return new Promise((resolve) => {
      // console.log("searchPerson", dni)
      if (state.data[dni] && state.data[dni].isSearching == false ) return false

      commit('isSearching', dni)
      // insert here the fecth to sipe data

      // example persona data
      let person = {
        searchData: {
          dni
        },
        name: "Pablo",
        lastName: "Marmol",
        gender: "Masculino",
        birthday: "12/12/1992",
        civilStatus: "Soltero",
        nationality: "Argentino",
        country: "Argentina",
        province: "San Juan",
        address: "Mendoza 10",
        landline: "4234645",
        cellPhone: "156189797",
        profession: "Artesano",
        email: "pmarmol@example.com",
        location: "Los Berros",
        instruction: "0",
        placeOfResidence: "hosteleria los berros",
        facebook: "pmarmol",


      }

      commit('setPerson', dni, person)
      resolve(person)

      // //si no encuenta
      // reject()
    })
  }
}


// mutations
const mutations = {
  isSearching (state, dni) {
    state.data[dni] = {
      isSearching: true
    }
  },
  setPerson (state, dni, person) {
    state.data[dni] = { ...person, isSearching: false }
  }
}


export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations
}