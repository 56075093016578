import { TipoEfectoDepositoConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/tipo-efecto-deposito'
        path: 'tipo-efecto-deposito',
        name: 'tipo-efecto-deposito',
        beforeEnter: isUserRolGuard,
        // component:   () => import ('@/pages/parametros/pais.vue'),
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { //'parametros/tipo-efecto-deposito/new'
        path: 'tipo-efecto-deposito/new',
        name: 'tipo-efecto-deposito-new',
        // component:  () => import ('@/pages/parametros/add-edit/tipo-efecto-deposito.vue'),
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/tipo-efecto-deposito/edit/:id'
        path: 'tipo-efecto-deposito/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]

