import generoRoutes from "./parametrizacion/generoRoutes";
import tipoDiligenciaRoutes from "./parametrizacion/tipoDiligenciaRoutes";
import articuloRoutes from "./parametrizacion/articuloRoutes";
import ParametrizacionPage from "@/pages/ParametrizacionPage";

import departamentalRoutes from "./parametrizacion/departamentalRoutes";
import depositoDependenciaRoutes from "./parametrizacion/depositoDependenciaRoutes";
import dependenciaRoutes from "./parametrizacion/dependenciaRoutes";
import caratulaRoutes from "./parametrizacion/caratulaRoutes";
import personalFiscalRoutes from "./parametrizacion/personalFiscalRoutes";
import estadoCivilRoutes from "./parametrizacion/estadoCivilRoutes";
import tipoMoneda from "./parametrizacion/tipoMoneda";
import efectoDenunciaRoutes from "./parametrizacion/efectoDenunciaRoutes";
import provinciaRoutes from "./parametrizacion/provinciaRoutes";
import usuarioRoutes from "./parametrizacion/usuarioRoutes";
import juzgadoRoutes from "./parametrizacion/juzgadoRoutes";
import personaFisicaRoutes from "./parametrizacion/personaFisicaRoutes";
import modusOperandiRoutes from "./parametrizacion/modusOperandiRoutes";
import marcasRoutes from "./parametrizacion/marcasRoutes";
import modelosRoutes from "./parametrizacion/modelosRoutes";
import categoriasRoutes from "./parametrizacion/categoriasRoutes";
import subCategoriasRoutes from "./parametrizacion/subCategoriasRoutes";
import tipoEfectoDepositoRoutes from "./parametrizacion/tipoEfectoDepositoRoutes";
import sitioRoutes from "./parametrizacion/sitioRoutes";
import PaisRoutes from "./parametrizacion/PaisRoutes";
import categoriaTipoArchivopdfRoutes from "./parametrizacion/categoriaTipoArchivoRoutes";
import GestorArchivosRoutes from "./parametrizacion/gestorArchivosRoutes";
import LayoutParametrizacion from '@/pages/parametros/LayoutParametrizacion'
import TemplateView from '@/pages/parametros/TemplateView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";
// import { GestorArchivosConfig } from "@/pages/parametros/moduleConfig";
// import { from } from "core-js/core/array";

export default {
    component:LayoutParametrizacion,// () => import('@/pages/parametros/LayoutParametrizacion'),
    redirect:'/parametros/list',
    // beforeEnter:isUserRolGuard,
    name:'parametros',
    children:[

      { //'parametros/listado'
        path: 'list',
        name: 'parametros-list',
        component:  ()=> import('@/pages/ParametrizacionPage.vue'),
        beforeEnter:isUserRolGuard,
      },

      //v1
      ...generoRoutes,
      ...PaisRoutes,
      ...depositoDependenciaRoutes,
      ...estadoCivilRoutes,
      ...provinciaRoutes,
      ...tipoMoneda,
      //v2
      ...departamentalRoutes,
      ...sitioRoutes,
      ...dependenciaRoutes,
      ...articuloRoutes,
      ...juzgadoRoutes,
      ...personaFisicaRoutes,
      ...modusOperandiRoutes,
      ...marcasRoutes,
      ...categoriasRoutes,
      ...tipoEfectoDepositoRoutes,
      ...caratulaRoutes,
      ...personalFiscalRoutes,
      ...subCategoriasRoutes,
      ...modelosRoutes,
      ...tipoDiligenciaRoutes,     
      ...categoriaTipoArchivopdfRoutes,
      ...GestorArchivosRoutes,
      
      ...usuarioRoutes,
      
    ],
    

  }
