<template>
  <div>
    <div class="flex flex-row-reverse">
      <span class=" p-input-icon-left" >
        <i class="pi pi-search" />
        <InputText v-model="filterModel" class="w100" placeholder="Filtrar" id="search-param" />
      </span>
    </div>
    <div class="formulario flex flex-wrap justify-content-start cards-container">
      <div v-for="param in paramsToShow" :key="param.key" class="v-for-container" :test-id="`card${param.key}`">
        <div v-if="showCardWithPermision(param.tipoUsuario)" @click="$router.push(param.url)"  class="card">
          <Card class="card-h">
            <template #title>
              <div class="card-title">
                {{param.title}}
              </div>
            </template>
            <template #content>
              <div class="card-content">
                <p>{{param.content}}</p>
              </div>
            </template>
          </Card>
        </div>
      </div> 
    </div>
    <div v-if="paramsToShow.length === 0" class="formulario flex justify-content-center cards-container">
      <p>No hay resultados para mostrar</p>
    </div>
  </div>
</template>

<script>
import importedParams from '../data/formularios_de_parametrizacion.js'

export default {
  name: 'Parametrizacion',
  data() { 
    return {
      textFilter: '',
      params: importedParams,
      paramsToShow: []
    }
  },
  created(){
    this.paramsToShow = this.params
  }, 
  computed: {
    filterModel: {
      get (){
        return this.textFilter
      },
      set (value){
        if(value === '')
          this.paramsToShow = this.params
        this.textFilter = value
        this.filterParams(value)
      }
    },
  },
  methods: {
    filterParams (text) {
      this.paramsToShow = this.params.filter((item) => item.title.toLowerCase().search(text.toLowerCase()) >= 0 );
    },
    showCardWithPermision(tipoUsuario){
      if(tipoUsuario) {
        /* true or false depending on the tipoUsuario in params */
        if(Array.isArray(tipoUsuario)){
          return tipoUsuario.some(e=> this.$rest.esTipoUsuario(e, this.$store.state.user))
        }else if(tipoUsuario.excludes){
          return  !tipoUsuario.excludes.some(e=> this.$rest.esTipoUsuario(e, this.$store.state.user))
        }else
        return this.$rest.esTipoUsuario(tipoUsuario, this.$store.state.user);
      }
      else { return true };
    }
  },
}
</script>

<style scoped>
  @media(min-width: 848px){
    .card {
      width: 25rem;
      cursor: pointer;
      margin-bottom: 5em;
      margin-left: 3rem; 
    }
    .card-title{
      height: 6.5rem;
    }
    .card-content{
      margin-top: -3rem ; 
    }
    .card-h{
      height: 13rem
    }
  }
  @media(max-width: 848px){
    .card {
      width: 25rem;
      cursor: pointer;
      margin-bottom: 5em;
    }
    .card-title{
      height: 6.5rem;
    }
    .card-content{
      margin-top: -3rem ; 
    }
    .card-h{
      height: 13rem
    }
  }
  @media(max-width: 442px){
    .card {
      width: 100%;
      cursor: pointer;
      margin-bottom: 5em;
    }
    .card-title{
      height: auto;
    }
    .card-content{
      margin-top: auto; 
    }
    .card-h{
      height: auto
    }
    .v-for-container{
      width: 100%;
    }
  }
  .cards-container{
    margin-top: 5rem ;
  }
</style>
