var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.actuaciones.length > 0)?_c('div',{staticClass:"shadow-4 border-round w-full lg:w-30rem"},[_c('h4',{staticClass:"pl-3 pt-3 m-0"},[_vm._v("Bandeja de entrada")]),_c('p',{staticClass:"pl-3 text-sm"},[_vm._v("Recepcion de extractos y reasignaciones")]),_c('ul',{staticClass:"p-3 pr-3 border-right-1 border-200"},[_vm._m(0),_vm._l((_vm.actuaciones),function(item,index){return _c('div',{key:index},[[_c('li',{class:_vm.liClass(index)},[_c('span',{staticClass:"pl-1 p-3 pb-2 w-5 max-w-6rem",attrs:{"test-id":'Numero ' + index}},[_vm._v(_vm._s(item.Numero ? item.Numero : item.Extracto))]),_c('div',{staticClass:"h-4rem flex w-10 align-items-center justify-content-between border-left-2 border-200"},[_c('span',{staticClass:"p-2 pl-1 w-6rem",attrs:{"test-id":'Actuacion ' + index}},[_vm._v(_vm._s(item.Tipo.includes("Expediente") ? "Expediente" : "Sumario")+" ")]),(
                !_vm.$rest.esTipoUsuario(
                  [
                    'Jefe de Sumario',
                    'Jefe de Dependencia',
                    'Super User',
                    'User Tester',
                    'Super Usuario de E3',
                    'Dpto. Asuntos Judiciales D-5',
                    'Dpto.Criminalistica',
                    'Lab. Informatico Forense'

                  ],
                  _vm.user
                )
              )?_c('div',{staticClass:"mr-3"},[(item.Numero && item.Usuario == _vm.user.userdata.userId)?_c('Button',{staticClass:"m-1 w-8rem p-button-danger pi pi-check lg:max-h-2rem lg:w-15rem",attrs:{"type":"button"},on:{"click":function($event){return _vm.openModal(item, 'recibir sumario')}}},[_vm._v(" Cambio de oficial ")]):(item.Usuario == _vm.user.userdata.userId)?_c('Button',{staticClass:"m-1 w-8rem p-button-danger pi pi-check lg:max-h-2rem lg:w-15rem",attrs:{"type":"button"},on:{"click":function($event){return _vm.openModal(item, 'cambio numero')}}},[_vm._v(" Extracto de denuncia ")]):_vm._e()],1):_c('div',{staticClass:"flex flex-row mr-2 lg:mr-5"},[_c('div',{staticClass:"pr-2"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Visualizar esta Actuacion'),expression:"'Visualizar esta Actuacion'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-outlined",attrs:{"icon":"pi pi-eye","test-id":'visualizar-act' + item.ActuacionID},on:{"click":function($event){return _vm.loadActuacion(item.ActuacionID)}}})],1),_c('div',[(
                    !_vm.$rest.esTipoUsuario(
                      ['Oficial Sumariante', 'Deposito Judicial'],
                      _vm.user
                    ) && _vm.$offline.onlineMode
                  )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Reasignar secretario'),expression:"'Reasignar secretario'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-outlined p-button-seccess mr-2",attrs:{"icon":"pi pi-user-plus"},on:{"click":function($event){return _vm.openModal(item, 'asignar usuario')}}}):_vm._e()],1)])])])]],2)})],2),_c('ModalForm',{attrs:{"Show":_vm.showModalFormDep,"Title":"Ingrese un Nro° de Sumario  ","Inputs":_vm.InputsNum},on:{"sendInfo":_vm.setNumSum}}),_c('ModalForm',{attrs:{"Show":_vm.showModalFormUser,"Title":"Ingrese un usuario","Inputs":_vm.InputsUser},on:{"sendInfo":_vm.setUsuario}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"flex flex-row"},[_c('span',[_vm._v("Numeros")]),_c('span',{staticClass:"pl-5 ml-2"},[_vm._v("Actuaciones")])])
}]

export { render, staticRenderFns }