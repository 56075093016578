
  <template>
    <div class="toolbar">
      <br>
      <div class="feature-intro title">
          <h1>Juzgado</h1>
      </div>
      <div >
        <TemplateTable
        
          tableTitle="Juzgado" 
          reference="juzgado"
          :loading="loading"
          v-on:selectItem="$emit('selectItem', $event)"
          :idTable="idTable"
          :url="url"
          :data="data"
          :modal="modal"
          apiversion="v2"
          :keyShow="keyShow"
          :filtersFields="filtersFields"
          :disableButtonEdit="false"
          :multiSelect="true"
          :enableSelectAllCheckbox="true"
          :enableEditButtons="true"
          :enableGlobalSearch="false"
          :enableAddButton="{ enabled: true, route: '/juzgado/add'}"/>

      </div>
      <!-- <div class="ml-5">
        <Button label="Seleccionar" @click="submit"/>
      </div> -->
      <!-- POR AHORA,  enableGlobalSearch REQUIERE QUE enableEditButtons SEA TRUE!!-->
      <!-- <button>submit</button> -->
    </div>  
</template>

  <script>

  // import axios from 'axios'


  export default {
    name: 'JuzgadoList',
    props: {
      modal: {
        type: Boolean
      }
    },
    data() { 
      return {
        filteredLocation: [],
        url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/juzgado",
        customName: [],
        data: null,
        customColumn: [],
        idTable: 'JuzgadoID',
        loading:true,
        filtersFields: [
          // { name: "Fechainivig", field: "Fechainivig" },
          { name: "Nombre", field: "Nombre" },
        ],
        keyShow: ['Nombre','Telefono','Domicilio']
      }
    },
    methods: {
      submit(){
        let userTableData = this.$refs.tableData.getTableData()
        this.$setCookie('userJuzgado', userTableData.Nombre)
        this.$setCookie('userJuzgadoID', userTableData.UsuarioID)
        this.$router.push({path: '/gestionUsers'})
      },
    },

  }
  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .title  {
      margin-left: 30px;
    }
  </style>
  