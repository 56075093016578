<template>
  <div :class="{ 'grid': !minimal }">
    <div v-if="!minimal" class="col-12 md:col-4 lg:col-3 lateral">
      <Listbox
        v-model="dataArrId"
        :options="denuncianteTodosSelect"
        dataKey="arrId"
        optionValue="arrId"
        optionLabel="name"
        ref="listBox"
        test-id="listDenunciantes"
      >
        <template #option="slotProps">
          <div class="flex justify-content-between">
            <span class="mt-2">{{ slotProps.option.name }}</span>
            <div class="flex justify-content-center align-items-center flex-nowrap">
              <Button
                v-if="slotProps.option.type !== 'empty'"
                icon="pi pi-trash"
                class="p-button-danger p-button-lg px-2 py-2 m-1"
                style="z-index: 3"
                @click.stop="
                  $_TemplateParentForm_delete(slotProps.option.arrId)
                "
              />
            </div>
          </div>
        </template>
      </Listbox>
      <div class="button_Section_process volver">
        <Button
          label="Volver"
          icon="pi pi-back"
          @click="backToTheMainForm()"
          test-id="buttonVolver"
        />
      </div>
    </div>
    <div :class="{ 'col': !minimal }">
      <div
        class="field-checkbox ml-2"
        :class="{ notVisible: checkOrdenPublico() }"
        v-if="
          arrId &&
          viewData[viewData.actuacion].checkboxPublicOrder
        "
      >
        <Checkbox id="binary" v-model="ordenPublico" :binary="true" />
        <label for="binary">Orden público</label>
      </div>
      <div
        :class="{
          card: !minimal,
          hideForm: formData.type === null || ordenPublico,
        }"
      >
        <FormPersona
          ref="denunciante"
          @updateValue="$_TemplateParentForm_handleUpdateDocValue"
          :arrId="arrId"
          :getPersonByArrId="getDenuncianteByArrId"
          :ordenPublico = "ordenPublico"
          :formType = "formType"
          typeOfPerson="denunciante"
          :hidden="viewData[viewData.actuacion].formPersona.hidden"
          :Involucrados="denuncianteTodosSelect"
        />
      </div>
      <div class="button_Section_process guardar" v-if="arrId">
        <Button
          label="Guardar"
          icon="pi pi-back"
          @click="
            validacion('/actuaciones/new/' + actuacionType + '/initial')
          "
          test-id="buttonSaveDenuciante"
        />
      </div>
    </div>
    <ModalTemplate 
      title="Información"
      type="informativo"
      message="Se requiere un email válido o dejar el campo vacío si no tienes uno disponible."
      :showIcon="true"
      @confirm="closeModal()"
      severity="danger"
      ref="ModalRefInform"
      :closable="false"
    />
    <ModalTemplate 
      title="Información"
      type="informativo"
      message="Recuerde ingresar un correo válido para notificaciones y comunicación."
      :showIcon="true"
      @confirm="closeModalWarning()"
      severity="warning"
      ref="ModalRefInformWarning"
      :closable="false"
    />
  </div>

  
</template>

<script>
import Listbox from "primevue/listbox";
import FormPersona from "../forms/FormPersona.vue";
import ModalTemplate from '../../components/ModalTemplate.vue'
/*
Este es un modelo para componente que seran re utilizables muchas veces
*/
import { mapGetters, mapState } from "vuex";
export default {
  name: "FormDenunciantes",
  components: {
    FormPersona,
    Listbox,
    ModalTemplate
  },
  props: {
    minimal: {
      type: String, // vista minima del formulario si esta definido
    },
    predefined: {
      type: Object, //string que se usara como base para llenar la declaracion de denuncia
    },
    aid: {
      type: String, //string que se usara para volver a mostrar en Editor de denuncia al recargar
    },
    formType: String,
    actuacionType: String //Defines the type of form
  },
  data() {
    return {
      arrId: this.aid || null,
      firstTime: true,
      saveOrBackButtonActive: false,
      formDataCopy: {}
    };
  },
  mounted() {
    if (
      typeof this.getDenuncianteByArrId(this.$router.currentRoute.query.id) !=
      "undefined"
    ) {
      // console.log("set idd", this.$router.currentRoute.query.id)
      this.arrId = this.$router.currentRoute.query.id;
    }
    // console.log('this.denuncianteTodosSelect', this.denuncianteTodosSelect)
    // this.changeDefaultNew()
  },
  created() { 
    // console.log('this.viewData', this.viewData[this.viewData.actuacion].mainForm.path)
    // console.log("dice: ", this.denunciante.list[0].doc.condicion.nombre)
    // if(this.denuncianteTodosSelect.length == 1 ||(this.formType == 'contravencional-oficio' && this.denuncianteTodosSelect.length == 2 ))
    // {
    //   this.arrId = this.denuncianteTodosSelect[0].arrId
    // }
    if(this.$route.query.id == 'new')
    {
      this.arrId = this.denuncianteTodosSelect[(this.denuncianteTodosSelect.length)-1].arrId
      // this.arrId = this.formType =='contravencional-oficio' ? this.denuncianteTodosSelect[(this.denuncianteTodosSelect.length)-2].arrId : this.denuncianteTodosSelect[(this.denuncianteTodosSelect.length)-1].arrId
    }    
    // this.changeDefaultNew()
  },
  computed: {
    ...mapGetters("sumario/denunciante", [
      "getDenuncianteByArrId",
      "getEmptyDocDenunciante",
      "getNewDenuncianteArrId",
      "denuncianteTodosSelect",
    ]),
    ...mapState({
      denunciante: (state) => state.sumario.denunciante,
      viewData: (state) => state.sumarioViews,
    }),
    //manejo del dato de state
    formData: {
      get() {
        if (this.predefined && !this.arrId) {
          if (this.predefined.new) {
            return {
              doc: {},
              type: "empty",
              ordenPublico: this.predefined.default.ordenPublico,
            };
          }
        }
        if (this.arrId) {
          return this.getDenuncianteByArrId(this.arrId);
        }
        return { doc: {}, type: null, ordenPublico: false };
      },
    },
    //datos que se editan en este componente y no en componentes hijos especificos de Denunciante
    ordenPublico: {
      get() {
        return this.formData.ordenPublico;
      },
      set(value) {
        this.$_TemplateParentForm_updateValue("ordenPublico", value);
      },
    },
    dataArrId: {
      get() {
        return this.arrId;
      },
      set(value) {
        if(!this.validateEmail()) return;
        this.arrId = value;
      },
    },
  },
  //comunicacion con hijos
  watch: {
    arrId: function (newValue) {
      if (
        newValue != null &&
        this.$router.currentRoute.query.id != newValue 
      ){
        this.$router.push({ path: `/actuaciones/new/${this.actuacionType}/initial/denunciante?id=${newValue}` });
      }
    },
    formData: function (newValue) {
      if (typeof newValue != "object") return;
      if(this.firstTime){
        setTimeout(() => { 
          this.updateFormPersona(newValue)
          this.firstTime = false
        }, 1)
      } else {
        this.updateFormPersona(newValue)
      }
    },
  },

  methods: {
    //Este metodo retornara true haciendo que el Checkbox de Orden publico se inabilite si ya fue marcado una vez
    checkOrdenPublico() {
      for (let item of this.denunciante.list) {
        if (item.ordenPublico == true) {
          return true;
        }
      }
      return false;
    },
    //se ejecuta al ser activado por editor para mostrar luego de estar oculto
    //revisar si es necesario que lo use en la primera carga
    activeComponent() {
      this.$refs.denunciante.setData(this.formData.doc);
    },

    $_TemplateParentFor_setValue(data) {
      if (!this.predefined) return;
      if (!this.predefined.valueFactory) return;
      let text = this.predefined.valueFactory;

      //caracteristicas + identikit
      let charters = false;
      if (text.indexOf("{textDescription}") >= 0) {
        charters = "";
        if (data.identikit.description) charters = data.identikit.description;
        for (let i in data.identikit) {
          if (i == "description") continue;
          charters =
            charters + " " + i + " " + data.identikit[i].nombre.toLowerCase();
        }
      }
      if (charters) {
        const regexCharters = /{textDescription}/gi;
        text = text.replace(regexCharters, charters);
      }

      //persona fisica
      if (data.doc.sexo) {
        text = text.replace(/{sexo}/gi, data.doc.sexo.nombre);
      }

      if (data.doc.nombre) {
        text = text.replace(/{nombre}/gi, data.doc.nombre);
      }

      if (data.doc.apellido) {
        text = text.replace(/{apellido}/gi, data.doc.apellido);
      }
      if (data.doc.documento) {
        text = text.replace(/{dni}/gi, data.doc.documento);
      }
      this.$emit("setValue", text);
    },

    // guarda cambios en stado
    async $_TemplateParentForm_updateValue(field, value) {
      let formDataType = 'formData';
      if (!this.formData.arrId) {
        this.formData.arrId = await this.getNewDenuncianteArrId;
      }
      if(field === 'ordenPublico' && value === true){
        formDataType = 'formDataCopy';
        this.formDataCopy = {...this.formData};
        const propsToKeep  = ['condicion', 'arrId', 'instruccion', 'nacionalidad', 'tipoDocumento'];
        this.formDataCopy.doc = propsToKeep.reduce((obj, key) => (key in this.formData.doc && (obj[key] = this.formData.doc[key]), obj), {});
      }
      await this.$store.dispatch("sumario/denunciante/setDenunciante", {
        ...this[formDataType],
        [field]: value,
      });

      this.$_TemplateParentForm_setArrId();
    },

    // guarda cambios en identikit (especifico para identikit del componente acusado)
    async $_TemplateParentForm_handleUpdateIdentikitValue(field, value) {
      if (!this.formData.arrId) {
        this.formData.arrId = await this.getNewDenuncianteArrId;
      }
      await this.$store.dispatch("sumario/denunciante/setDenunciante", {
        ...this.formData,
        identikit: { ...this.formData.identikit, [field]: value },
      });

      this.$_TemplateParentForm_setArrId();
    },

    // guarda cambios en doc (especifico para persona del componente acusado)
    async $_TemplateParentForm_handleUpdateDocValue(field, value) {
      if (!this.formData.arrId) {
        this.formData.arrId = await this.getNewDenuncianteArrId;
      }
      // if condicion not is 'Denunciante' or 'Damnificado'
      // if(this.formType.startsWith('ufi')){
      //   let defaultCondicion = { id: this.viewData[this.viewData.actuacion].formPersona.conditionDefault, nombre: this.viewData[this.viewData.actuacion].formPersona.conditionDefault }
      //   await this.$store.dispatch("sumario/denunciante/setDenunciante", {
      //     ...this.formData,
      //     doc: { 
      //       ...this.formData.doc, 
      //       [field]: value, 
      //       ufi: true
      //     },
      //   });
      // } else  {
        await this.$store.dispatch("sumario/denunciante/setDenunciante", {
          ...this.formData,
          doc: { ...this.formData.doc, [field]: value },
        });
      // }
      // console.log('$_TemplateParentForm_handleUpdateDocValue')
      await this.$store.dispatch("sumario/denunciante/setEmptyTitle", this.viewData[this.viewData.actuacion].formPersona.whistleblower.emptyTitle);
      this.$_TemplateParentForm_setArrId();
    },

    //le pone array id, es para el caso de nuevos desde editor de denuncia
    $_TemplateParentForm_setArrId() {
      if (!this.arrId) {
        this.arrId = this.formData.arrId;
        if (this.predefined) {
          this.$emit("setArrId", this.arrId);
        }
      }
    },

    // elimina del array
    $_TemplateParentForm_delete(arrId) {
      this.arrId = null;
      this.$store.dispatch(
        "sumario/denunciante/deleteDenuncianteByArrId",
        arrId
      );
    },
    updateFormPersona(newValue){
      // console.log(this.$refs.denunciante)
      this.$refs.denunciante.setData(newValue.doc);
      //force the value of checkbox 'personaJuridica' to not be null
      if (newValue.doc.personaJuridica) {
        this.$refs.denunciante.personaJuridica = newValue.doc.personaJuridica;
      } else {
        this.$refs.denunciante.personaJuridica = false;
      }
      this.$_TemplateParentFor_setValue(newValue);  
    },
    validacion(route){
      // console.log("route: " + route)
      this.saveOrBackButtonActive = true;
      if(!this.validateEmail(false)) return;
      let errores = this.$refs.denunciante.emitErrores()
      if(errores == true)
      {
        alert("Verifica los campos")
      }else
      {
        this.$router.push(route)
      }

    },
    async changeDefaultNew(){
      let denunciante = this.denuncianteTodosSelect.find(todo => todo.type === 'empty') 
      let index = this.denuncianteTodosSelect.indexOf( denunciante )
      await this.$store.dispatch("sumario/denunciante/setDenunciante", {
        ...this.formData,
        doc: { 
          arrId: this.denuncianteTodosSelect[index].arrId,
          name: 'Nueva víctima',
          type: 'empty'
        },
      });
    },
    validateEmail(changeRoute = true){
      if(this.validateEmailWarning()) return changeRoute;
      return this.validateEmailError();
    },
    closeModal(){
      this.$refs.ModalRefInform.close();
      this.$refs.denunciante.$el.querySelector("#emailInput").select();
    },
    closeModalWarning(){
      this.$refs.ModalRefInformWarning.close();
      this.saveOrBackButtonActive = false;
    },
    backToTheMainForm(){
      this.saveOrBackButtonActive = true;
      if(!this.validateEmail(false)) return;
      this.$router.push( '/actuaciones/new/' + this.actuacionType + '/initial/');
    },
    validateEmailWarning(){
      const currentAffected = this.getDenuncianteByArrId(this.$router.currentRoute.query.id);
      if(currentAffected?.ordenPublico === true) return false;
      if(currentAffected?.emailWarningNotified === true || currentAffected?.type === "empty") return false;
      if(!(this.viewData[this.viewData.actuacion].formPersona.whistleblower.emailWarning)) return false;
      if(!(this.$refs.denunciante?.email  === '' || this.$refs.denunciante?.email === undefined || this.$refs.denunciante?.email === null)) return false;
      this.$store.dispatch("sumario/denunciante/setDenunciante", {...this.formData, emailWarningNotified: true});
      this.$refs.ModalRefInformWarning.open();
      return true;
    },
    validateEmailError(){
      let validation = this.$rest.validateEmail(this.$refs.denunciante?.email);
      if(validation !== true){
        this.$refs.ModalRefInform.open();
      } 
      return validation;
    },
  },
};
</script>
<style scoped>
.card {
  background: #ffffff;
  padding: 2rem;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 1rem;
}
.hideForm {
  display: none;
}
.button_Section_process {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button_Section_process:not(.volver) {
  margin-right: 25px;
}

.guardar {
  margin-top: -05px;
}

.volver {
  margin-top: 10px;
}

@media screnn and (max-width: 768px) {
}
.pad {
  padding: 1rem 0.7rem;
}
.lateral > button {
  margin-top: 20px;
}
.pad > div {
  margin-right: 30px;
}
.notVisible {
  display: none;
}
</style>