import contravencionalDenuncia from "./contravencionalDenuncia.js";
import sumarioDenuncia from "./sumarioDenuncia.js";
import sumarioOficio from "./sumarioOficio.js";
import contravencionalOficio from "./contravencionalOficio.js";
import commonUFIs from "./commonUFIs.js";
import generalData from "./generalData.js";
import UFIDelitosEspeciales from "./UFIDelitosEspeciales.js";
import UFIEstablecerParadero from "./UFIEstablecerParadero.js";
import actuacionesPreliminares from "./actuacionesPreliminares.js";
import UFIGenericDenuncia from "./UFIGenericDenuncia.js";
import UFIGenericOficio from "./UFIGenericOficio.js";

export default {
  mixins: [
    contravencionalDenuncia,
    sumarioDenuncia,
    contravencionalOficio,
    commonUFIs,
    generalData,
    UFIDelitosEspeciales,
    UFIEstablecerParadero,
    actuacionesPreliminares,
    sumarioOficio,
    UFIGenericDenuncia,
    UFIGenericOficio
  ],
}
