<template>
  <div class="toolbar">
    <div class="feature-intro title flex justify-content-left">
      <h1 class="pl-0 pt-2">Bandeja de Solicitudes</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="Deposito Judicial"
        reference="deposito/solicitudes"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        :modal="modal"
        :titles="titles"
        order="police_formulario_deposito.createdAt desc"
        :getLimit="8"
        :formatDoc="formatDoc"
        apiversion="2"
        :styles="styles"
        :resizableColumns="true"
        columnResizeMode="expand"
        :keyShow="keyShow"
        :filtersFields="filtersFields"
        :multiSelect="true"
        :enableSelectAllCheckbox="true"
        :enableEditButtons="$rest.esTipoUsuario(['Super User','User Tester','Super Usuario de E3'],user)"
        :enableDeleteButton="$rest.esTipoUsuario(['Super User','User Tester','Super Usuario de E3'],user)"
        :enableViewButtons="true"
        :enableGlobalSearch="false"
        :enableAddButton="{ enabled: false, route: '/deposito/add' }"
        :enableUploadFile="false"
        :enableFilterMultiple="true"
        :enableRefresh="true"
        v-on:datosFiltros="onResultados"
        :dropdown="dropdown"
        :classesDivContainer="classesDivContainer"        
      />
    </div>

    <!-- <div class="ml-5">
        <Button label="Seleccionar" @click="submit"/>
      </div> -->
    <!-- POR AHORA,  enableGlobalSearch REQUIERE QUE enableEditButtons SEA TRUE!!-->
    <!-- <button>submit</button> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import dateMixing from "@/mixins/dateMixin"
export default {
  name: "solicitudesTable",
  props: {
    modal: {
      type: Boolean,
    },
  },
  mixins: [
    dateMixing
  ],
  async created() {
    //Los Dropdown que usan .option cargadas del store se deben inicializar cuando se carga la Page
    this.dropdown["exp_pre_EfectoDeposito.CategoriaID"].loadDropdown();
    this.dropdown["exp_pre_EfectoDeposito.SubcategoriaID"].loadDropdown();
    this.dropdown["exp_pre_EfectoDeposito.MarcaID"].loadDropdown();
    this.dropdown["exp_pre_EfectoDeposito.ModeloID"].loadDropdown();
    this.dropdown["exp_pre_EstadoSolicitud.TipoEstadoSolicitudID"].loadDropdown();
    this.dropdown["exp_pre_EstadoEfecto.TipoEstadoEfectoID"].loadDropdown();

    if(!this.$rest.esTipoUsuario(["Deposito Judicial"],this.user)){       
      this.filtersFields.push({ name: "Dependencia", field:"exp_pre_EfectoDeposito.DependenciaID" ,limit: 1})
      this.dropdown["exp_pre_EfectoDeposito.DependenciaID"].loadDropdown()  
    }
    if(this.$rest.esTipoUsuario(['User Tester', 'Super User'], this.$store.state.user)){
      this.keyShow.splice(this.keyShow.length - 1, 0, "dependencia");//adding 'ubicacion' in penultimate position
      this.titles.dependencia = "Ubicación";
    }
  },
  data() {
    return {
      user: this.$store.state.user,
      filteredLocation: [],
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/formulario-deposito",
      customName: [],
      data: null,
      customColumn: [],
      idTable: "formulario_depositoID", //"id_deposito", 
      loading: true,
      goTo:"bandeja",
      filtersFields: [
        { name: "Fecha de Creación",  field: "createdAt", type: 'date' },
        { name: "Apellido", field: "apellido" },
        { name: "Nombre", field: "nombre" },
        { name: "DNI", field: "dni" },
        { name: "Celular", field: "celular" },
        { name: "Email", field: "Email" },
        { name: "Descripción", field: "descripcion" },
        { name: "Estado", field: "exp_pre_EstadoSolicitud.TipoEstadoSolicitudID"},
        { name: "Efecto", field: "efecto", type: 'dropdown'},

        //hasta aca todos los anteriores funcionan y cumplen los criterios de aceptacion
        // { name: " N° Motor", field: "exp_pre_EfectoDeposito.NumeroMotor" },
        // { name: "N° Chasis", field: "exp_pre_EfectoDeposito.Chasis" },
        // { name: "IMEI", field: "exp_pre_EfectoDeposito.Imei" },
        // { name: "Dominio", field: "exp_pre_EfectoDeposito.Dominio" },
        // { name: "Año", field: "exp_pre_EfectoDeposito.Anio" },
             
      ],
      dropdown:{
        "efecto":{
          optionValue: "value",
          optionLabel: "name",
          options: [
            { value: "exp_pre_EfectoDeposito.CategoriaID", name: "Categoria" , type: "dropdownEspecific"},
            { value: "exp_pre_EfectoDeposito.SubcategoriaID", name: "Subcategoria", type: "dropdownEspecific" },
            { value: "exp_pre_EfectoDeposito.MarcaID", name: "Marca" , type: "dropdownEspecific"},
            { value: "exp_pre_EfectoDeposito.ModeloID", name: "Modelo" , type: "dropdownEspecific" },
            { value: "exp_pre_EfectoDeposito.Chasis", name: "Nro. de chasis" ,  type: "inputEspecific" },
            { value: "exp_pre_EfectoDeposito.NumeroMotor", name: "Nro de motor",  type: "inputEspecific"  },
            { value: "exp_pre_EfectoDeposito.Imei", name: "IMEI",  type: "inputEspecific"  },
            { value: "exp_pre_EfectoDeposito.Dominio", name: "Dominio" , type: "inputEspecific" },
            { value: "exp_pre_EfectoDeposito.Abonado", name: "Nro de abonado", type: "inputEspecific" },
            { value: "exp_pre_EfectoDeposito.Color", name: "Color", type: "inputEspecific"  },
            { value: "exp_pre_EfectoDeposito.Anio", name: "Año", type: "inputEspecific"},
            { value: "exp_pre_EfectoDeposito.DatosPublios", name: "Datos publicos" ,  type: "inputEspecific" },
            { value: "exp_pre_EfectoDeposito.Descripcion", name: "Datos del efecto", type: "inputEspecific" },
            { value: "exp_pre_EfectoDeposito.Visible", name: "Visible",type: "dropdownEspecific" },
            { value: "exp_pre_EstadoEfecto.TipoEstadoEfectoID", name: "Estado Efecto", type: "dropdownEspecific" },
            ],
        }, 
        "exp_pre_EfectoDeposito.Visible":{
          optionValue: "value",
          optionLabel: "name",
          options: [
            { value: 1, name: "Si" },
            { value: 0, name: "No" }
            ],
        },   
        "exp_pre_EstadoEfecto.TipoEstadoEfectoID": {
          optionValue: "TipoEstadoEfectoID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setEstadosEfectoDeposito", true)
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setEstadosEfectoDeposito");
            // console.log(this.$store.state);
            this.dropdown["exp_pre_EstadoEfecto.TipoEstadoEfectoID"].options = this.$store.state.siis.estadosEfectoDeposito;
          },
        },        
        "exp_pre_EfectoDeposito.CategoriaID": {
          optionValue: "CategoriaID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setCategoriasDeposito", true)
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setCategoriasDeposito");
            this.dropdown["exp_pre_EfectoDeposito.CategoriaID"].options = this.$store.state.siis.categoriasDeposito;
          },
        },
        "exp_pre_EfectoDeposito.SubcategoriaID": {
          optionValue: "SubcategoriaID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setSubCategoriasDeposito", true)},
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setSubCategoriasDeposito");
            this.dropdown["exp_pre_EfectoDeposito.SubcategoriaID"].options = this.$store.state.siis.subcategoriasDeposito;
          },
        },
        "exp_pre_EfectoDeposito.MarcaID": {
          optionValue: "MarcaID",
          optionLabel: "nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setMarca", true)},
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setMarca");
            this.dropdown["exp_pre_EfectoDeposito.MarcaID"].options = this.$store.state.siis.marca;
          },
        },
        "exp_pre_EfectoDeposito.ModeloID": {
          optionValue: "ModeloID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setModelo", true)},
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setModelo");
            this.dropdown["exp_pre_EfectoDeposito.ModeloID"].options = this.$store.state.siis.modelo;
          },
        },
        "exp_pre_EstadoSolicitud.TipoEstadoSolicitudID": {
          optionValue: "TipoEstadoSolicitudID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setEstadosSolicitudDeposito", true)
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setEstadosSolicitudDeposito");
            this.dropdown["exp_pre_EstadoSolicitud.TipoEstadoSolicitudID"].options = this.$store.state.siis.estadosSolicitudDeposito;
          },
        },        
        "exp_pre_EfectoDeposito.DependenciaID":{
          optionValue: "id",
          optionLabel: "dependencia",
          options: [],
          refreshDropdown: () => {
            this.$store.dispatch("siis/setDependencias", true)
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setDependencias");
            this.dropdown["exp_pre_EfectoDeposito.DependenciaID"].options = this.$store.state.siis.dependencia;
          },
        },
      },
      keyShow: [
        "createdAt",
        "nombre",
        "apellido",
        "dni",
        "celular",
        // "Numerodenuncia",
        "Email",
        "Estado",
        // "descripcion",
      ],
      titles: {
        createdAt: "Creación",
        nombre: "Nombre",
        apellido: "Apellido",
        dni: "D.N.I",
        celular: "Celular",
        // Numerodenuncia:"Numero de Denuncia",
        Email: "Email",
        // descripcion: "Descripcion",
        Estado: "Estado",
      },
      
      styles: {
        default: {
          header: { width: "320px" },
          body: { width: "200px" },
        },
        jerarquia: {
          header: { width: "200px" },
          body: { width: "200px" },
        },
      },
      classesDivContainer: {
        // Estado: 'eSuccess',
        Estado: function(value) {
          switch (value) {
            case "Nueva":
              return "eNew uppercase";
            case "Iniciada":
              return "eSuccess uppercase";
            case "Citación":
            case "Citación de Entrega":
              return "eWarning uppercase";
            case "Justicia":
              return "eAlert uppercase"
            case "Expirada":
            case "Efecto no reconocido":
            case "Rechazada":
              return "eDanger uppercase";
            case "Aprobada":
              return "eInfo uppercase";
            case "Finalizada":
              return "ePrimary uppercase";
          }
        },
      },
      formatDoc: (col, data) => {
        switch (col) {
          case "createdAt": 
            return this.date2Es(data[col], true)
          default:
            return data[col];
        }
      },
    };
  },

  methods: {
    onResultados(mostrar) {
      let datos = mostrar;
      // console.log("datos desde indice", datos);
    },
    downloadXls() {
      window.location.assign(`${this.url}/d1_indice?getXLS`);
      //  const r= await axios.get(`${this.url}/d1_indice?getXLS` , { withCredentials: true })
      //  console.log(r)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  margin-left: 30px;
}
</style>
