
  <template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">

      <div v-if="getMode() == 'new'" class="feature-intro">
        <h1>Nuevo {{ pageName }}</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
      <div v-else class="feature-intro">
        <h1>Editando {{ pageName }}</h1>
        <p>Modifique los datos que desea EDITAR</p>
      </div>
    </div>
    
    <div class="mb-4 text-xl">Dependencia Seleccionada</div>
    
    <TForm
      :data="data"
      :nombres="names"
      :errors="errors"
      :mensajes="mensajes"
      :mode="getMode()"
      :designEdit="designEdit"
      :designNew="design"
      :url="url"
      :viewer="viewer"
      :viewerID="viewerID"
    />
  </div>
</template>

<script>
export default {
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    }
  },
  data() {
    return {   
      data: {
        "Admitesumarios":{"value":1,"value2":0,"validation":"required|max_length[1]",type: "input"},
        "Descripcion":{"value":"descripcion","value2":"esto es una descripcion","validation":"max_length[50]",type: "input"},
        "Nombre":{"value":"leonardo","value2":"exequiel","validation":"required|max_length[50]",type: "input"},
        "Telefonos":{"value":"2645555555","value2":"2645851354","validation":"max_length[50]",type: "input"},
        "DependenciaLetra":{"value":"comisaria25","value2":"comisaria6ta","validation":"max_length[50]",type: "input"},
        "Interno":{"value":"4564","value2":"6547","validation":"max_length[50]",type: "input"},
        "ClavePolicial":{"value":"005","value2":"019","validation":"max_length[50]",type: "input"},
        "DependenciatipoID":{"value":"123abc","value2":"abc123","validation":"max_length[50]",type: "input"},
        "DepositodependenciaID":{"value":"123abc","value2":"abc123","validation":"max_length[50]",type: "input"},
        "DomicilioID":{"value":"123abc","value2":"abc123","validation":"max_length[50]",type: "input"},
        "ImpresionEncabezado":{"value":"123abc","value2":"abc123","validation":"max_length[255]",type: "input"},
        "Domicilio":{"value":"123abc","value2":"abc123","validation":"max_length[255]|required",type: "input"}
      },
      names: {
        "Admitesumarios":"","Descripcion":"","Nombre":"","Telefonos":"","DependenciaLetra":"","Interno":"","ClavePolicial":"","DependenciatipoID":"","DepositodependenciaID":"",
        "DomicilioID":"",
        "ImpresionEncabezado":"",
        "Domicilio":""
      },
      errors:  {
        "Admitesumarios":false,"Descripcion":false,"Nombre":false,"Telefonos":false,"DependenciaLetra":false,"Interno":false,"ClavePolicial":false,"DependenciatipoID":false,"DepositodependenciaID":false,"DomicilioID":false,
        "ImpresionEncabezado":false,"Domicilio":false
      },
      mensajes: {
        "Admitesumarios":"","Descripcion":"","Nombre":"","Telefonos":"","DependenciaLetra":"","Interno":"","ClavePolicial":"","DependenciatipoID":"","DepositodependenciaID":"",
        "DomicilioID":"",
        "ImpresionEncabezado":"",
        "Domicilio":""
      },
      designEdit: [["Admitesumarios","Descripcion"],["Nombre","Telefonos"],["DependenciaLetra","Interno"],["ImpresionEncabezado","Domicilio"], ["ClavePolicial"]],
      design: [["Admitesumarios","Descripcion"],["Nombre","Telefonos"],["DependenciaLetra","Interno"],["ImpresionEncabezado","DomicilioID"],["Domicilio","ClavePolicial"]],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + '/parametros/dependencia',
      pageName:'Dependencia'
    }
  },
  created(){
    let {id} = this.$route.params
    this.id=id
  },
  methods:{
    getMode(){
      return this.id=='add'?'new':'edit';
    },
  },
};
</script>
  