export const preliminarSiniestroConfig = [
    {
        path: "/actuaciones/new/preliminares-siniestro-vial/initial",
        pathName: "preliminares-siniestro-vial",
        titulo: "ACTUACIONES PRELIMINARES - SINIESTRO VIAL",
        props: { formType: "preliminar-siniestro-vial", actuacionType: "preliminares-siniestro-vial"},
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/preliminares-siniestro-vial/initial/denunciante",
        pathName: "preliminares-siniestro-vial",
        titulo: "PROTAGONISTAS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/preliminares-siniestro-vial/initial/momento",
        pathName: "preliminares-siniestro-vial",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },     
      {
        path: "/actuaciones/new/preliminares-siniestro-vial/initial/efectos",
        pathName: "preliminares-siniestro-vial",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]