<template>
    <div class="content">
      <SuccessAndFail
            :msjEnable.sync="msjEnable"
            :mensajeDialog="mensaje"
            :buttomAceptar="buttomAceptar"
            :icon.sync="iconModal"
        />
      <div class="card borderShadow" v-if="card" tabindex="0">
        <Card v-if="(formType === 'contravencional-oficio' || formType === 'contravencional-denuncia'  || this.formType === 'oficio' || this.formType.startsWith('ufi') || this.formType.startsWith('preliminar'))" :class="{'passed': (alertas() == 'ok' && (formType === 'contravencional-oficio' || formType === 'contravencional-denuncia' || this.formType === 'oficio' || this.formType.startsWith('ufi')) && showValidation == true), 'error':(alertas() != 'ok' && (formType === 'contravencional-oficio' || formType === 'contravencional-denuncia' || this.formType.startsWith('ufi')) && showValidation == true)}">
          <template #title>
            <div class="title-toolbar">
              <div class="title" test-id="cardPersonalIntervinienteTitle">
                Personal Interviniente
              </div> 
            </div>
          </template>
          <template #content>
            <div class="search">
              <div class="selectors">
                <div class="selectorOption">
                  <div class="titleSelect">
                    <label test-id="cardPersonalIntervinienteNombreLabel">Nombre y Apellido</label>
                  </div>
                  <InputText 
                    type="text" 
                    placeholder="Nombre y Apellido" 
                    class="input" 
                    v-model="nombreInterviniente" 
                    test-id='cardPersonalIntervinienteNombreInput'
                    :disabled="sumario.actuationEditModeActive"
                  />
                </div>
                <div class="selectorOption">
                  <div class="titleSelect">
                    <label test-id="cardPersonalIntervinienteJerarquiaLabel">Jerarquía</label>
                  </div>
                  <Dropdown 
                    dataKey='nombre' 
                    class="input" 
                    v-model="dataJerarquia" 
                    :options="jerarquiaList" 
                    optionLabel="nombre" 
                    placeholder="Jerarquía" 
                    test-id="cardPersonalIntervinienteJerarquiaInput" 
                    :filter="true" 
                    :disabled="sumario.actuationEditModeActive"
                  />
                </div>
                <div class="selectorOption">
                  <div class="titleSelect">
                    <label test-id="cardPersonalIntervinienteDependenciaLabel">Dependencia</label>
                    <Button 
                      icon="pi pi-refresh" 
                      class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" 
                      @click="toUpdateDependencia()" 
                      :style="[UpdateDependencia ? {'display': 'none'}: '']"
                      :disabled="sumario.actuationEditModeActive"
                    />
                    <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateDependencia ? {'display': 'none'}: '']"></i>
                  </div>
                  <Dropdown 
                    dataKey='dependencia' 
                    class="input" 
                    v-model="dataDependencia" 
                    :options="dependenciaList" 
                    optionLabel="dependencia" 
                    placeholder="Dependencia" 
                    test-id="cardPersonalIntervinienteDependenciaInput" 
                    :filter="true" 
                    :disabled="sumario.actuationEditModeActive"
                  />
                </div>
                <div class="selectorOption button">
                  <Button 
                    test-id="cardPersonalIntervinienteButton" 
                    @click="loadData()" 
                    :disabled="sumario.actuationEditModeActive"
                  >
                    Agregar
                  </Button>
                </div>
              </div>
              <div v-if="alertas() != 'ok' && showValidation == true" class="text-right mt-4 alert">
                {{alertas() }}
              </div>
              <div class="namesOfIntervinientes" v-else-if ="sumario.PersonalInterviniente.length > 0">
                <div v-for="(element, i) in sumario.PersonalInterviniente" :key="i" class="flex mt-2 intervinientes" id="intervinientes" @mouseover="changeOrAddShowButton(i)" @mouseleave="onMouseleave">
                  <span class="mt-2 miniline" test-id="namesOfIntervinientes">
                    {{ (element.nombre+', '+element.dependencia).length > 39 ? (element.nombre+', '+element.dependencia).slice(0,39) + "..." : (element.nombre+', '+element.dependencia)}}
                    <div class="flex justify-content-between flex-wrap actions__card">
                      <Button 
                        v-if="showButtons === i && !sumario.actuationEditModeActive" 
                        v-tooltip.bottom="'Eliminar'"  
                        icon="pi pi-trash"   
                        class="p-button-rounded p-button-info p-button-outlined p-button-danger ml-2 p-button-md"   
                        @click="deleteConfirmation(i, $event)" 
                        style="min-width:40px ; 
                        margin-right: 7px" 
                      /> 
                      <Button 
                        v-if="showButtons === i && !sumario.actuationEditModeActive"  
                        v-tooltip.bottom="'Pegar en el Relato'"  
                        icon="pi pi-plus" 
                        class="p-button-rounded p-button-info p-button-outlined pasteInto" 
                        @click="pasteInDeposition(element.nombre, element.dependencia, element.jerarquia.nombre)"
                      >
                        <img alt="share icon" src="../../assets/share_icon.svg" class="paste-button"/>
                      </Button>
                    </div>
                  </span>
                </div>
              </div>
              <div v-else class="voidMessage"
              test-id="cardPersonalIntervinienteSinDatos">
                <p>No se cargaron intervinientes</p>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import axios from "axios";

export default {
  props: ["minimal", "card", "editPath", "formType", "errors", "showValidation", "deleteConfirmationInProgress", "modalVisble"],
  name: 'FormPersonalInterviniente',
  computed: {
    ...mapGetters({
      dependenciaList: "siis/getDependecias"
    }),
    ...mapState({
      sumario: state => state.sumario,
      jerarquiaList: state => state.siis.jerarquia,
    }),
    data: {
      get (){
        return this.$store.getters['sumario/dataResumen']
      }
    },
    nombreInterviniente: {
      get() {
        return this.nombre
      },
      set(value) {
        return this.nombre = value
      }
    },
    dataJerarquia: {
      get() {
        return this.jerarquia
      },
      set(value) {
        return this.jerarquia = value
      }
    },
    dataDependencia: {
      get() {
        return this.dependencia
      },
      set(value) {
        return this.dependencia = value
      }
    },
  },
  data() { 
    return {
      //---------Datos para el Modal SuccessAndFail------
      buttomAceptar:{buttom:true,action:false},
      iconModal:{},
      mensaje:'',
      msjEnable:false,
      //-----------Fin datos para Modal SuccessAndFail-----      
      nombre: '',
      jerarquia: '',
      filteredJerarquia: [],
      dependencia: '',
      filteredDependencia: [],
      showButtons: false,      
      UpdateDependencia:false,
    }
  },
  created()
  {
    this.$store.dispatch("siis/setDependencias")
  },  
  methods: {
    async toUpdateDependencia(){
      this.UpdateDependencia = true;
      await this.$store.dispatch('siis/setDependencias');
      this.UpdateDependencia = false;

    },
    alertas()
    {
      // console.log("efectos denunciados:\n", JSON.stringify(this.errors))
      if(this.errors.includes("No especificó al Personal Interviniente")){ return("No especificó al Personal Interviniente")}
      else {return "ok" }
    }, 
    deleteData(i)
    {
      this.$store.dispatch('sumario/DeletePersonalInterviniente', i);
    },
    loadData()
    {
      // console.log(" dataDependencia: ", JSON.stringify(this.dataDependencia))
      if(this.nombreInterviniente != "" && this.dataJerarquia.nombre != undefined && this.dataDependencia != undefined && this.dataDependencia.dependencia)
      {
        this.$store.dispatch('sumario/setPersonalInterviniente', {'nombre':this.nombre ,'jerarquia':this.jerarquia, 'dependencia': this.dependencia.dependencia});
        this.nombre = '';
        this.jerarquia = '';
        this.dependencia = '';  
        
        //reinicia preform

       this.$store.dispatch("sumario/textResetEditsCounter","Preform")
      }else
      {
        this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }             
        this.mensaje = 'Faltan datos del Personal interviniente que intenta cargar'
        this.msjEnable = true        
      }
    },
    inputJerarquia(event) {
      let value = this.jerarquiaList.filter(item => item.nombre.toLowerCase().search(this.dataJerarquia.toLowerCase()) >= 0);
      this.jerarquia =  value[0];
    },
    filterJerarquia (event) {
     this.filteredJerarquia = this.jerarquiaList.filter(item => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0);
    },
    inputDependencia(event) {
      let value = this.dependenciaList.filter(item => item.dependencia.toLowerCase().search(this.dataDependencia.toLowerCase()) >= 0);
      this.dependencia =  value[0];
    },
    filterDependencia (event) {
     this.filteredDependencia = this.dependenciaList.filter(item => item.dependencia.toLowerCase().search(event.query.toLowerCase()) >= 0);
    },
    pasteInDeposition(nombre, dependencia, jerarquia) {
      let phraseToPaste = `${jerarquia} ${nombre} adscripto/a al numerario de ${dependencia}`
      this.$emit("moveToDeposition", phraseToPaste);
    },
    onMouseleave(){
      if(this.deleteConfirmationInProgress !== true || this.modalVisble !== true)
        this.showButtons = false
    },
    deleteConfirmation(arrId, event) {
      this.$emit('deleteConfirmationPersonaInterviniente', event, 'personal interviniente', arrId)
    },
    changeOrAddShowButton(i){
      if(this.deleteConfirmationInProgress !== true || this.modalVisble !== true)
        this.showButtons = i
    }
  }
}
</script>

<style scoped>
  .namesOfIntervinientes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 2.5;
    padding: 0 30px 0 30px;
  }
  .intervinientes {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    margin-top: 20px;
    margin-bottom: -5px;
    margin-left: 2px;
  }
  .miniline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .voidMessage{
    margin-bottom: -35px;
    margin-left: 2px;
    line-height: 2.5;
    padding: 0 30px 0 30px;
  }
  .button {
    display: flex;
    justify-content: flex-end;
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  div.card {
    width: 100%;
  }
  .borderShadow:focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
    outline: none;
  }
  .title {
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 1rem;
  }
  .title-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .search > input {
    margin-right: 10px;
  }
  .forms > div, .labeled, .inputType {
    display: flex;
    flex-wrap: wrap;
  }
  .inputType label {
    display: none;
  }
  .forms > div, .labeled {
    flex-direction: column;
  }
  .forms > div.actions {
    flex-direction: row-reverse;
    padding-top: 20px;
    padding-right: 20px;
  }
  .forms > div .input {
    min-width: 20vw;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  div.flex-inline {
    display: flex;
    flex-wrap: wrap;
  }
  .minus10 {
    margin-top: -5px;
  }
  .selectors {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .selectorOption {
    display: flex;
    flex-direction: column;
  }
  .passed{
    border-bottom: solid 2px #28a745 ;
  }
  .error{
     border-bottom: solid 2px #dc3545;
  }
  .warning{
    border-bottom: solid 2px #ffc107
  }  
  .alert
  {
    color: #dc3545;
  }  
  .paste-button{
    width: 25px
  }
  .actions__card {
    padding: 4px;
    gap: 3px;
  }   
  .titleSelect{
    height:2em;
    display:flex; 
    align-items: center;
  }
</style>
