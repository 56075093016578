<template>
  <div class="toolbar">
    <br>
    <div v-if="!modal" class="feature-intro title">
      <h1>Persona física</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="Persona física"
        reference="persona-fisica"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        :modal="modal"
        :getLimit="10"
        apiversion="v2"
        :keyShow="keyShow"
        :filtersFields="filtersFields"
        :multiSelect="true"
        :enableSelectAllCheckbox="true"
        :enableEditButtons="true"
        :enableGlobalSearch="false"
        :enableAddButton="{ enabled: true, route: '/persona-fisica/add' }"
      />
    </div>
    <!-- <div class="ml-5">
      <Button label="Seleccionar" @click="submit"/>
    </div> -->
    <!-- POR AHORA,  enableGlobalSearch REQUIERE QUE enableEditButtons SEA TRUE!!-->
    <!-- <button>submit</button> -->
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "PersonafisicaList",
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      filteredLocation: [],
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/persona-fisica",
      customName: [],
      data: null,
      customColumn: [],
      idTable: "PersonaFisicaID",
      loading: true,
      filtersFields: [{ name: "Nombre", field: "Nombre" }, { name: "DNI", field: "Dni" }, { name: "Apellido", field: "Apellido" }, { name: "Cuil", field: "Cuil" }],
      keyShow: [
        "Nombre",
        "Apellido",
        // "TipodocumentoID",
        "Dni",
        "Telefono",
        "Cuil",
        "Celular",
        "Sexo",
        "Nacionalidad",
        "LugarDeNacimiento",
      ],
    };
  },
  methods: {
    submit() {
      let userTableData = this.$refs.tableData.getTableData();
      this.$setCookie("userPersonafisica", userTableData.Nombre);
      this.$setCookie("userPersonaFisicaID", userTableData.UsuarioID);
      this.$router.push({ path: "/gestionUsers" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  margin-left: 30px;
}
</style>
  