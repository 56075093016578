<template>
    <div class="content">
      <div class="formulario" v-if="!card">

        <div :class="{'forms': !minimal, 'inputType': minimal}">
          
          <div>
            <label>Tipo</label>
            <Dropdown dataKey='name' class="input" v-model="tipo" :options="tiposList" optionLabel="name" placeholder="Seleccione Tipo" :filter="true" />
          </div>

          
          <div class="actions" v-if="!minimal">
            <Button label="Guardar" icon="pi pi-check" @click="save"/>
          </div>
        </div>
        
        
      </div>
      <div class="card" v-if="card">
        <Card>
          <template #title>
            <div class="title-toolbar">
              <div class="title">
                Acusados 
              </div> 
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-text  minus10"  @click="$router.push({path: editPath, query: {id: 'new'}})"  />
            </div>
          </template>
          <template #content>
            <div class="search">
              <div v-if="hasMoment"  >
                <div class="preAcusado preText" >

                </div>
                <div class="moment" >

                </div>
                <div style="display:none">{{strPreAcusado}} {{strMoment}}</div>
              </div>
              
              <div v-else>
                sin datos
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    
</template>

<script>

// import { mapState } from 'vuex'
export default {
  props: ["minimal", "modelo", "card", "editPath", "factory", "default", "index", "datac", "formType"],
  name: 'FormAcusado',
  data() { 
    let id = 0
    if (this.datac){
      // console.log("data ->>", this.datac.initialFunction)
      if (this.datac.initialFunction === 'add' ) id = 'new'
    }
    return {
      tiposList: [
        {name: 'Desconocido',  value: 'filiación desconocida', code:0},
        {name: 'Desconocido con Caracteristicas',  value: 'filiación desconocida', code:3},
        {name: 'Conocido', value: '', code: 1},
        {name: 'Conocido (sabe DNI)', value: '', code: 2},
      ],
      id
      // tipoAcusado: null
    }
  },

  beforeRouteEnter (to, from, next) {
    console.log("beforeRouete Enter,", to, to.query, from)
    next()
  },
  computed: {
    date: {
      get () {
        if (!this.acusado.date){
          // console.log("date def", this.default)
          if (this.default && this.default.date){
            this.setPreAcusado({...this.acusado, date: this.default.date()})    
          }
        }
        return this.acusado.date;
      },
      set (value) {
        this.setPreAcusado({...this.acusado, date: value})
      }
    },
    // tipoAcusado: {
    //   get () {
    //     // console.log("mo", this.modelo)
    //     return this.$store.state[this.modelo].acusado

    //   }
    // },
    
    acusado: {
      get () {
        // console.log("mo", this.modelo)
        return this.$store.state[this.modelo].preAcusado[this.id]
      }
    },
    
    acusadoOk: {
      get () {
        // console.log("mo", this.modelo)
        return this.$store.state[this.modelo].acusado[this.id]

      }
    },
    // }),
    
    tipo: {
      get () {
        if (!this.acusado.tipo){
          // console.log("tipo def", this.default)
          if (this.default && this.default.tipo){
            this.setPreAcusado({...this.acusado, tipo: this.default.tipo()})    
          }
        }
        return this.acusado.tipo;
      },
      set (value) {
        this.setPreAcusado({...this.acusado, tipo: value})
      }
    },
    nombre: {
      get () {
        return this.acusado.nombre;
      },
      set (value) {
        this.setPreAcusado({...this.acusado, nombre: value})
      }
    },
    apellido: {
      get () {
        return this.acusado.apellido;
      },
      set (value) {
        this.setPreAcusado({...this.acusado, apellido: value})
      }
    },
    //card
    hasMoment: {
      get () {
        return this.acusado != "" || this.preAcusado != ""
      }
    },
    strMoment: {
      get () {
        



        // if (input > 0)
        // let result = inputs > 0 ? `${dateString}, ${hour2String}` : "";
        
        return ''
      }
    },
    strPreAcusado: {
      get () {
        let inputs = 0;
        let tipoString = '-';
        if (this.acusado.tipo){
          tipoString = this.acusado.tipo.value;
          inputs++
        } 
        let result = inputs > 0 ? `${tipoString}` : "";
        this.setCardHTML(".preAcusado", result)
        return ''
      }
    }
  },
  methods: {
    enableField(v){
      if (!this.factory) return true;
      return this.factory.indexOf(`{${v}}`) >= 0
    },
    setPreAcusado (data ) {
      this.$store.dispatch(this.modelo + '/setPreAcusado', this.id, data)
      this.setValue()
    },
    setAcusado (data) {
      this.$store.dispatch(this.modelo +  '/setAcusado', this.id, data)
      this.setValue()
    },
    setValue(){
      if (!this.factory) return
      const regex1 = /{tipo}/ig;
      let value = this.factory
      if (this.tipo) value = value.replace(regex1, this.tipo.value)
      
      // .replace(regexHour, this.hour.toLocaleTimeString('es-AR', {hour: '2-digit', minute:'2-digit'}))
      // .replace(regexHour2, this.hour2.toLocaleTimeString('es-AR', {hour: '2-digit', minute:'2-digit'}))
      this.$emit("setValue", value)
    },
    setCardHTML (parent, html) {
      setTimeout(()=>{
        if ( document.querySelector( parent ) ){
          document.querySelector( parent ).innerHTML = html;
        }
      }, 100);
    },
    save(){
      this.setAcusado(this.acusado);
      this.$store.dispatch("textResetEditsCounter","Preform")
      // this.$store.dispatch("sumario/textResetEditsCounter","instructionDecree")
      this.$emit("onSave")
    }
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    
  }
  div.card {
    width: 100%;
  }

  .map {
    display: block;
    margin-top: 10px;
    height: 60vh;
    width: 50vw;
  }
  .title {
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 1rem;
  }
  .title-toolbar {
    display: flex;
    justify-content: space-between;
  }

  .search > input {
    margin-right: 10px;
  }


  .forms > div, .labeled, .inputType {
    display: flex;
    
    /* align-items:flex-start;
    flex-wrap: wrap; */
    flex-wrap: wrap;
    
  }
  .inputType label {
    display: none;
  }
  .forms > div, .labeled {
    flex-direction: column;
  }
  .forms > div.actions {
    /* flex-direction: row-reverse; */
    
    padding-top: 40px;
    padding-right: 20px;
  }

  .forms > div .input {
    min-width: 20vw;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  div.flex-inline {
    display: flex;
    flex-wrap: wrap;
  }

  .minus10 {
    margin-top: -5px;
  }
</style>
