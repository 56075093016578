import { SubCategoriasConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/sub-categorias'
        path: 'sub-categorias',
        name: 'sub-categorias',
        beforeEnter: isUserRolGuard,
        // component:   () => import ('@/pages/parametros/pais.vue'),
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        // component:   () => import ('@/pages/parametros/sub-categorias.vue'),
        props: { config: config.templateTable },
    },
    { //'parametros/sub-categorias/new'
        // path: 'sub-categorias/add',
        path:'sub-categorias/new',
        name: 'sub-categorias-new',
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        // component:   () => import ('@/pages/parametros/sub-categorias.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/sub-categorias/edit/:id'
        path: 'sub-categorias/edit/:id',
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        // component:   () => import ('@/pages/parametros/sub-categorias.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
]
     
