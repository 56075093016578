import { CaratulaConfig as config } from '@/pages/parametros/moduleConfig';
import NewView from '@/pages/parametros/add-edit/NewView.vue'
import EditView from '@/pages/parametros/add-edit/EditView.vue'
import isUserRolGuard from "@/guards/isUserRolGuard";

export default [
    { //'parametros/caratula'
        path: 'caratula',
        name: 'caratula',
        // component:   () => import ('@/pages/parametros/pais.vue'),  
        beforeEnter: isUserRolGuard,
        component:   () => import ('@/pages/parametros/TemplateView.vue'),
        props: { config: config.templateTable },
    },
    { //'parametros/caratula/new'
        path: 'caratula/new',
        name: 'caratula-new',
        component:  () => import ('@/pages/parametros/add-edit/NewView.vue'),
        beforeEnter: isUserRolGuard,
        props: { config: config.form },
    },
    { //'parametros/caratula/edit/:id'
        path: 'caratula/edit/:id',
        //  component:  ()=> import('@/pages/parametros/add-edit/caratula.vue'),
        component:  ()=> import('@/pages/parametros/add-edit/EditView.vue'),
        beforeEnter: isUserRolGuard,
       props: { config: config.form },
    },
]

