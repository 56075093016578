import { render, staticRenderFns } from "./tipo-usuario.vue?vue&type=template&id=637b8599&scoped=true&"
import script from "./tipo-usuario.vue?vue&type=script&lang=js&"
export * from "./tipo-usuario.vue?vue&type=script&lang=js&"
import style0 from "./tipo-usuario.vue?vue&type=style&index=0&id=637b8599&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637b8599",
  null
  
)

export default component.exports