export const expedienteDenunciaConfig = [
    {
        path: "/actuaciones/new/expediente-denuncia/initial",
        pathName: "expediente-denuncia",     
        titulo: "CONTRAVENCIONAL DENUNCIA",
        icon: "pi-book",
        roles: [
          "User Tester",
          "Super User",
          "Jefe de Dependencia",
          "Oficial Sumariante",
          "Jefe de Sumario",
        ],
        type: "link",
        grupo: "EXPEDIENTES",
        props: {
          formType: "contravencional-denuncia",
          actuacionType: "expediente-denuncia",    
        },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/expediente-denuncia/initial/denunciante",
        pathName: "expediente-denuncia",
        titulo: "DENUNCIANTE",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/expediente-denuncia/initial/momento",
        pathName: "expediente-denuncia",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/expediente-denuncia/initial/acusado",
        pathName: "expediente-denuncia",
        titulo: "CONTRAVENTOR",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/expediente-denuncia/initial/efectos",
        pathName: "expediente-denuncia",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]