<template>
  <div>
    <div>
      <DataTable :value="filesAndDiligences" :loading="loadingTable">
        <div id="double-ellipsis-icon-id">
          <Column headerStyle="width: 3rem" />
        </div>
        <template #header>
          <div class="table-header flex justify-content-between align-items-center">
            <h2 test-id="diligencesFilesTitle">Gestión de diligencias y archivos</h2>
            <div test-id="addDiligenceButton">
              <Button @click="addButton()" v-if="!props.viewer" test-id="addDiligenciaButton">Agregar diligencia</Button>
            </div>
          </div>
        </template>
        <template #empty> Aun no contruyes diligencias. </template>
        <template #loading> Cargando datos. </template>
        <Column field="doc.tipo" header="Tipo">
          <template #body="{ data }">
            {{ data.Nombre }}
            <div style="overflow-wrap: break-word">
              {{ data.Filename }}
            </div>
          </template>
        </Column>
        <Column field="doc.creado" header="Fecha">
          <template #body="{ data }">
            {{ data.Fechainivig }}
          </template>
        </Column>
        <Column field="doc.creado" header="Propietario de la diligencia">
          <template #body="{ data }">
            {{ apellidoNombre(data.ApellidoP, data.NombreP) }}
          </template>
        </Column>
        <Column
          headerStyle="width: 5em; text-align: right;"
          bodyStyle="padding-right:8px; text-align: right; "
        >
          <template #header>
            <!-- <Button type="button" icon="pi pi-cog"></Button> -->
          </template>
          <template
            #body="{ data }"
            class="flex flex-column md:flex-row"
            style="background-color: blue; text-align: right"
          >
            <!-- {{ data }} -->
            <div class="flex-reverse" style="text-align: right">
              <div v-if="data.type === 'diligence'">
                <Button
                  type="button"
                  v-tooltip.left="'Ver esta diligencia'"
                  icon="pi pi-search"
                  class="p-button-success mr-2"
                  test-id="button-leer-diligencia"
                  @click="readButton(data[props.idDiligenceName])"
                ></Button>
                <!-- <Button v-if="!props.viewer" type="button" v-tooltip.left="'Eliminar esta diligencia'"  icon="pi pi-trash" class="p-button-danger mr-2"  test-id="button-eliminar-diligencia" @click="deleteButton($event, data[props.idDiligenceName])"></Button> -->
                <Button
                  v-if="!props.viewer"
                  type="button"
                  v-tooltip.left="'Eliminar esta diligencia'"
                  icon="pi pi-trash"
                  class="p-button-danger mr-2"
                  test-id="button-eliminar-diligencia"
                  @click="deleteButton($event, data)"
                ></Button>
                <Button
                  v-if="!props.viewer"
                  type="button"
                  v-tooltip.left="'Editar esta diligencia'"
                  icon="pi pi-pencil"
                  class="p-button-warning mr-2"
                  test-id="button-editar-diligencia"
                  @click="editButton(data[props.idDiligenceName])"
                ></Button>
              </div>
              <div v-if="data.type === 'file'">
                <a :href="props.getURLForViewFile(data)" target="_blank">
                  <Button
                    type="button"
                    v-tooltip.left="'Ver este archivo'"
                    icon="pi pi-search"
                    class="p-button-success mr-2"
                    test-id="button-leer-diligencia"
                  ></Button>
                </a>
                <!-- <Button v-if="!props.viewer" type="button" v-tooltip.left="'Eliminar este archivo'"  icon="pi pi-trash" class="p-button-danger mr-2"  test-id="button-eliminar-diligencia" @click="deleteButton($event, data[props.idFileName])"></Button> -->
                <Button
                  v-if="!props.viewer"
                  type="button"
                  v-tooltip.left="'Eliminar este archivo'"
                  icon="pi pi-trash"
                  class="p-button-danger mr-2"
                  test-id="button-eliminar-diligencia"
                  @click="deleteButton($event, data)"
                ></Button>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
      <div v-if="props.showFileUpload && !props.viewer" class="mt-5">
        <b>Adjuntar archivo/s</b>
        <CustomFileUpload
          ref="FileUpload"
          @parentUpdateFiles="(event, clear) => updateFiles(event, clear)"
          @parentMyUploader="myUploader"
          @parentRemoveFiles="RemoveFiles"
          d="userfile[]"
          invalidFileSizeMessage="{0}: tamaño no válido, el archivo no debe pesar más de {1}."
          accept=".jpg, .jpeg, .pdf, .png"
          :auto="true"
          :customUpload="true"
          @uploader="updateFiles"
          :maxFileSize="15000000"
          :multiple="true"
          uploadLabel="Adjuntar"
          chooseLabel="Examinar..."
          class="p-button-sm mt-3"
        >
          <template #empty>
            <p>Examine o arrastre archivos aquí.</p>
          </template>
        </CustomFileUpload>
        <p
          v-if="err"
          style="color: #dc3545; margin-top: 20px; font-weight: 550"
          class="ml-1 negative-mt"
        >
          {{ err }}
        </p>
      </div>
      <ConfirmPopupSeted
        ref="ConfirmPopupSetedRef"
        :event="ConfirmPopupSetedEvent"
        :message="ConfirmPopupSetedMessage"
        :idToDelete="ConfirmPopupSetedId"
        @deleteConfirmed="(idToDelete) => deleteConfirmed(idToDelete)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineComponent, onMounted } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import CustomFileUpload from "../../components/custom-fileupload";
import { useRouter, useRoute } from "vue-router/composables";
import ConfirmPopupSeted from "../../components/ConfirmPopupSeted";

const router = useRouter();
let formData = ref(null);
let err = ref(null);

const props = defineProps({
  data: Array,
  useridProp: String,
  EfectoDepositoID: String,
  diligencesTemplates: Array,
  offlineOnlineMode: Boolean,
  rest: Object,
  url: String,
  viewer: Boolean,
  idDiligenceName: String,
  apiDiligence: String,
  apiFilesDiligences: String,
  idFileName: String,
  getURLForViewFile: Function,
  apiFilesDiligencesFilters: {
    type: String,
    default: "",
  },
  showFileUpload: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.data,
  (newValue) => {
    diligencesList.value = [...newValue];
  }
);

onMounted(() => {
  filesQuery();
});

const emit = defineEmits(["read", "add", "edit", "delete"]);
const ConfirmPopupSetedRef = ref(null);
const loading = false;
const FileUpload = ref(null);
let diligencesList = ref(props.data === null ? null : [...props.data]);
let filesAndDiligences = ref([]);
const DataToDelete = ref({});

let ConfirmPopupSetedEvent = ref(null);
let ConfirmPopupSetedMessage = ref(null);
let ConfirmPopupSetedId = ref(null);

let loadingTable = ref(false);

const apellidoNombre = (apellido, nombre) => {
  var respuesta = "";
  //apellido y nombre pueden venir de realizar un "concat" en sumario.js, lo que implica que null puede ser tomado como un string
  respuesta =
    apellido == "null" || apellido == null || apellido == "Sin Apellido Especificado"
      ? ""
      : apellido;
  respuesta =
    nombre == "null" || nombre == null || nombre == "Sin Apellido Especificado"
      ? respuesta
      : respuesta == ""
      ? nombre
      : respuesta + " ," + nombre;
  return respuesta;
};

const readButton = (id) => {
  router.push({
    name: "DiligenciasPrint",
    params: {
      id: id,
      rest: props.rest,
      offlineOnlineMode: props.offlineOnlineMode,
      DiligenciasPrintURL: process.env.VUE_APP_BACKEND_ENVIRONMENT + props.apiDiligence,
    },
  });
};

const editButton = (id) => {
  emit("edit", id);
};

const deleteButton = (event, data) => {
  ConfirmPopupSetedEvent.value = event.currentTarget;
  if (data.type === "diligence") {
    ConfirmPopupSetedMessage.value = "¿Seguro que quiere eliminar esta diligencia?";
    ConfirmPopupSetedId.value = data[props.idDiligenceName];
    DataToDelete.value.API = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${props.apiDiligence}`;
    DataToDelete.value.idProp = props.idDiligenceName;
  }
  if (data.type === "file") {
    ConfirmPopupSetedMessage.value = "¿Seguro que quiere eliminar este archivo?";
    ConfirmPopupSetedId.value = data[props.idFileName];
    DataToDelete.value.API = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${props.apiFilesDiligences}`;
    DataToDelete.value.idProp = props.idFileName;
  }
  setTimeout(() => {
    ConfirmPopupSetedRef.value.deleteConfirmation();
  }, 100);
};

const deleteConfirmed = async (id) => {
  loadingTable.value = true;
  let resp = await props.rest.delete({ url: `${DataToDelete.value.API}${id}` });
  if (resp.data.error == "") {
    let indice = filesAndDiligences.value.findIndex(
      (element) => element[DataToDelete.value.idProp] == id
    );
    filesAndDiligences.value.splice(indice, 1);
  } else {
    props.rest.toast_open({
      message: "Ocurrió un error al intentar eliminar la diligencia",
      position: "top-right",
      type: "error",
      duration: 3000,
    });
  }
  loadingTable.value = false;
};
const addButton = () => {
  emit("add");
};
const RemoveFiles = () => {
  formData = null;
};

const updateFiles = (event) => {
  if (props.offlineOnlineMode) {
    props.rest.toast_open({
      message: "OFFLINE SIIS: No se pueden descargar o subir archivos en modo offline.",
      position: "top-right",
      type: "error",
      duration: 3000,
    });
    return;
  }
  formData = new FormData();
  event.files.map((file) => {
    if (
      file.type !== "application/pdf" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/png"
    ) {
      err.value =
        "Al menos uno de los archivos es incompatible con los formatos permitidos (JPG, PNG y/o PDF)";
      FileUpload.value.clear();
      RemoveFiles();
      setTimeout(() => {
        err.value = false;
      }, 5000);
      return;
    }
    formData.append("userfile[]", file, file.name);
  });
};

const myUploader = async () => {
  try {
    if (props.offlineOnlineMode) {
      props.rest.toast_open({
        message: "OFFLINE SIIS: No se pueden descargar o subir archivos en modo offline.",
        position: "top-right",
        type: "error",
        duration: 3000,
      });
      return;
    }
    let optionsPost = {
      url: props.url,
      formData: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    await props.rest.post(optionsPost);
    filesQuery();
    RemoveFiles();
  } catch (e) {
    console.error("err", e);
    err.value = "Error al subir archivos";
    setTimeout(() => {
      err.value = false;
    }, 5000);
    RemoveFiles();
  }
};

const filesQuery = async () => {
  loadingTable.value = true;
  try {
    let resp;
    let fdata = [];
    if (props.offlineOnlineMode) {
      props.rest.toast_open({
        message: "OFFLINE SIIS: No se pueden descargar o subir archivos en modo offline.",
        position: "top-right",
        type: "info",
        duration: 1000,
      });
    } else {
      resp = await props.rest.get({
        url: props.apiFilesDiligences + props.apiFilesDiligencesFilters,
      });
      fdata = resp.data.data;
    }
    await combineFilesAndDiligences(fdata);
  } catch (error) {
    console.log("filesQuery err", error);
  }
  loadingTable.value = false;
};

const combineFilesAndDiligences = (data) => {
  let filesAndDiligencesProcessed = [];
  setTimeout(() => {
    for (let i = 0; i < diligencesList.value.length; i++) {
      let diligence = { ...diligencesList.value[i], type: "diligence" };
      filesAndDiligencesProcessed.push(diligence);
    }
    for (let i = 0; i < data.length; i++) {
      let file = { ...data[i], type: "file" };
      filesAndDiligencesProcessed.push(file);
    }
  }, 100);
  filesAndDiligences.value = filesAndDiligencesProcessed;
};
</script>
