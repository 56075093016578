import generalFunctions from '@/store/modules/generalFunctions.js';
import views from '@/store/modules/sumario/views'
import * as AltaDenuncia from '../../../data/sumario_denuncia_alta';

function formatEffects(effects) {
  let newEffectsList = buildEffectsList(effects);
  // console.log("Retorno: ", buildEffectsList(effects));
  newEffectsList.push({
    arrId: generalFunctions.uuidv4(), 
    type: "empty",
    doc: {},
    hasIdentikit:false,
    name: "Nuevo efecto",
    conocido:true
  })
  return newEffectsList;
}
function buildEffectsList(effects) {
  let newEffectsList = [];
  effects.map(effect => {
    if(effect.type = "doc"){
      // console.log("Retorno: ", buildEffectsDoc(effect));
      newEffectsList.push({
        arrId: generalFunctions.uuidv4(), 
        doc: buildEffectsDoc(effect),
        type:"doc", 
      })
    }
  })
  return newEffectsList;
}
function buildEffectsDoc(effect) {

    return {
        "newFormatEffect":true,
        "tipoEfecto":AltaDenuncia.default.objetosAllTypes.find(objectType => objectType.nombre ===  effect.estado.name),
        "arrId":generalFunctions.uuidv4(),//effect.id,
        "SubcategoriaID":effect.subcategoria.key,
        "CategoriaID":effect?.categoria.key || '1',//effect.categoria,
        "Anio":effect.año,
        "TipoID":effect?.tipo.key,
        "MarcaID":effect.marca.key,
        "ModeloID":effect.modelo.key,
        "Chasis":effect.nroChasis,
        "NumeroMotor":effect.nroMotor,
        "Dominio":effect.dominio,
        "NumeroSerie":effect.nroSerie,
        "Imei":effect.nroIMEI,
        "Abonado":effect.nroAbonado,
        "Color":effect.color,
    }
}

function formatVinculateds(actuationName, vinculateds) {
  // console.log("actuationName: ",actuationName)
  let newVinculateds = buildPersonList(vinculateds);
  let defaultValues = { identikit: [], identikitQuestion: 1, hasIdentikit: false, conocido: true, identikit: [], descriptionOffender: [] };
  let newVinculatedsWithDefaultValues = addDefaultValuesToPerson(newVinculateds, defaultValues);
  newVinculatedsWithDefaultValues.push({
    arrId: generalFunctions.uuidv4(),
    doc: {
      "instruccion":{"id":"sabe_leer_sabe_firmar","nombre":"Sabe leer y sabe firmar"},
      "nacionalidad":{"id":1,"nombre":"ARGENTINA"},
      "tipoDocumento":{"id":"DNI","nombre":"DNI"},
      "condicion": generalFunctions.determineDefaultConditionFromVinculated(actuationName)
    },
    emptyTitle: views.state()[generalFunctions.translateNameFromFormType(actuationName)]?.formPersona?.accused?.emptyTitle, 
    type:"empty",
    identikit: [], identikitQuestion: 1, hasIdentikit: false, descriptionOffender: [],
    conocido: true
  })
  return newVinculatedsWithDefaultValues;
}

function formatAffecteds(actuationName, affecteds) {
  let newAffectedsList = buildPersonList(affecteds);
  // console.log()
    newAffectedsList.push({
      arrId: generalFunctions.uuidv4(),
      doc: {
        "instruccion":{"id":"sabe_leer_sabe_firmar","nombre":"Sabe leer y sabe firmar"},
        "nacionalidad":{"id":1,"nombre":"ARGENTINA"},
        "tipoDocumento":{"id":"DNI","nombre":"DNI"},
        "condicion": generalFunctions.determineDefaultConditionFromAffected(actuationName)
      },
      emptyTitle: views.state()[generalFunctions.translateNameFromFormType(actuationName)].formPersona.accused.emptyTitle, 
      ordenPublico: false,
      type:"empty",
    })
  // console.log("newAffectedsList", newAffectedsList);
    return newAffectedsList;
}

function addDefaultValuesToPerson(persons, defaultValues) {
  let personWithDefaultValues = [];
  let defaultValuesCopy;
  persons.map(async person => {
    defaultValuesCopy = {...defaultValues};
    Object.keys(person).forEach(key => key in defaultValuesCopy && delete defaultValuesCopy[key]);
    personWithDefaultValues.push({
      ...person,
      ...defaultValuesCopy
    })
  })
  return personWithDefaultValues;
}

function buildPersonList(personList) {
  let newPersonList = [];
  personList.map(async person => {
    // console.log('person', person);

    if((person.descripcionDesconocido !== undefined && person.descripcionDesconocido !== "") && person.apellido === ""){
      newPersonList.push(buildUnknownParentage(person));
      return;
    } 
    if((person.descripcionOrdenPublico !== undefined && person.descripcionOrdenPublico !== "") && person.apellido === ""){
      newPersonList.push(buildPublicOrder(person));
      return;
    }
    newPersonList.push(buildAPersonForFront(person));
  })
  return newPersonList;
}


function buildPublicOrder(person) {
  let PublicOrder = {
    arrId: generalFunctions.uuidv4(),
    doc: {
      "condicion": { id: "Damnificado", nombre: "Damnificado" },
      "instruccion":{"id":"sabe_leer_sabe_firmar","nombre":"Sabe leer y sabe firmar"},
      "nacionalidad":{"id":1,"nombre":"ARGENTINA"},
      "tipoDocumento":{"id":"DNI","nombre":"DNI"},
    },
    type:"doc",
    sipeSearch:false,
    emptyTitle: 'Nuevo denunciante y/o damnificado/a', 
    ordenPublico:true,
  }
  return PublicOrder;
}

function buildAPersonForFront (personToConvert) {
  return {
    arrId: generalFunctions.uuidv4(), sipeSearch:false, type:"doc", emptyTitle:"Nueva víctima",
    doc:{
      apellido: personToConvert?.apellido, arrId: generalFunctions.uuidv4(), ActuacionPersonaFisicaID: personToConvert?.typeAfectado,
      condicion: transformConditionToJSON(personToConvert.typeAfectado), documento: personToConvert?.nroDocumento,
      domicilioSIPE: personToConvert?.DomicilioSipe, domicilio: personToConvert?.domicilioResidencia,
      email: personToConvert?.email, 
      apodo: personToConvert?.apodo,
      estadoCivil: AltaDenuncia.default.estadocivil.find(civilState => civilState.nombre === personToConvert?.estadoCivil),
      instruccion: AltaDenuncia.default.enum_instruccion.find(instruction => instruction.nombre === personToConvert?.instruccion),
      nacionalidad: AltaDenuncia.default.nacionalidad.find(nacionality => nacionality.nombre === personToConvert?.nacionalidad?.toUpperCase()),
      nombre: personToConvert?.nombre, profesion: personToConvert?.profesion, sexo: {id: personToConvert?.typeSexo?.charAt(0), nombre: personToConvert?.typeSexo},
      telefono: personToConvert?.telefono, tipoDocumento: {id: 'DNI', nombre: 'DNI'}, fechaNacimiento: personToConvert?.fecha,
      exhibeDocumento: AltaDenuncia.default.exhibeDocumento.find(exhibitDocument => exhibitDocument.nombre.toUpperCase() == personToConvert?.showDocument.toUpperCase()),
      tieneEstudios: AltaDenuncia.default.exhibeDocumento.find(exhibitDocument => exhibitDocument.nombre.toUpperCase() == personToConvert?.showDocument.toUpperCase()),
      vinculo: personToConvert?.vinculo
    },
    hasIdentikit: personToConvert?.Identikit?.length > 0 ? true : false,
    identikit: personToConvert?.Identikit?.length > 0 ? personToConvert.Identikit : []
  }
}

function transformConditionToJSON(condition) {
  switch (condition) {
    case 'Victima':
      return { id: "victima", nombre: "Víctima" };
    case 'Peaton':
      return { id: "Peatón", nombre: "Peatón" };
    default: 
      return { id: condition, nombre: condition };
  }
}

function buildUnknownParentage(person) {
  let unknownParentage = {
    filiacionDesconocida:true,
    arrId: generalFunctions.uuidv4(),
    doc: {
      "instruccion":{"id":"sabe_leer_sabe_firmar","nombre":"Sabe leer y sabe firmar"},
      "nacionalidad":{"id":1,"nombre":"ARGENTINA"},
      "tipoDocumento":{"id":"DNI","nombre":"DNI"},
      "condicion": { id: "Acusado", nombre: "Acusado" },
      // "ActuacionPersonaFisicaID": person.ActuacionPersonaFisicaID
    },
    emptyTitle: '', 
    type:"doc",
  }
  unknownParentage.identikit = [{ answer:"Respuesta P.10: " + person.descripcionDesconocido, question:"¿HAY ALGUNA OTRA DESCRIPCIÓN QUE QUIERA AGREGAR?", questionNumber:10 }]
  unknownParentage.identikitQuestion = 10;
  unknownParentage.hasIdentikit = true;
  return unknownParentage;
}

async function formatInfractions(offlineInfractions, dataBaseInfractions, key) {
  return offlineInfractions.map(item => {
    const formattedItem = dataBaseInfractions.find(dataItem => dataItem[key] === item);
    return formattedItem !== undefined ? formattedItem : null;
  }).filter(item => item !== null);
}

function translateActuationNameFromAppOffline(appOfflineName) {
  console.log('appOfflineName', appOfflineName);
  let nameKeyValue =  {
    'Sumario por denuncia': 'denuncia',
    'Sumario por Denuncia': 'denuncia',
    'Sumario por Oficio': 'oficio',
    'Expediente por Denuncia': 'contravencional-denuncia',
    'Expediente por Oficio': 'contravencional-oficio',
    'UFI - FLAGRANCIA': 'ufi-flagrancia',
    'UFI - CAVIG': 'ufi-cavig',
    'UFI - ANIVI': 'ufi-anivi',
    'UFI - DELITOS GENÉRICOS POR DENUNCIA': 'ufi-generica',
    'UFI - DELITOS GENÉRICOS POR OFICIO': 'ufi-generica-oficio',
    'UFI - DELITOS INFORMÁTICOS Y ESTAFAS POR DENUNCIA': 'ufi-estafas-y-delitos-informaticos',
    'UFI - DELITOS INFORMÁTICOS Y ESTAFAS POR OFICIO': 'ufi-estafas-y-delitos-informaticos-oficio',
    'UFI - DELITOS CONTRA LA PROPIEDAD POR DENUNCIA': 'ufi-delitos-contra-la-propiedad',
    'UFI - ESTABLECER PARADERO': 'ufi-establecer-paradero',
    'UFI - EMERGENCIAS MÉDICAS': 'ufi-emergencias-medicas',
    'UFI - SINIESTRO VIAL': 'ufi-siniestro-vial',
    'UFI - AHORCAMIENTO O SUICIDIO': 'ufi-ahorcamiento-suicidio',
    'UFI - INCENDIO EN VIVIENDA': 'ufi-incendio-vivienda',
    'UFI - INTOXICACIÓN': 'ufi-intoxicacion',
    'ACTUACIONES PRELIMINARES - INCENDIO EN VIVIENDA': 'preliminar-incendio-vivienda',
    'ACTUACIONES PRELIMINARES - ESTABLECER PROCEDENCIA': 'preliminar-establecer-procedencias',
    'ACTUACIONES PRELIMINARES - FUGA DE MENOR': 'preliminar-fuga-menor',
    'ACTUACIONES PRELIMINARES - SINIESTRO VIAL': 'preliminar-siniestro-vial',
    'ACTUACIONES PRELIMINARES - CAÍDA CASUAL': 'preliminar-caida-casual',
    'ACTUACIONES PRELIMINARES - INCENDIO DE AUTOMOTOR': 'preliminar-incendio-automotor',
    'ACTUACIONES PRELIMINARES - TENTATIVA DE SUICIDIO': 'preliminar-tentativa-suicidio',
    'ACTUACIONES PRELIMINARES - OTROS': 'preliminar-otros'
  } 
  return nameKeyValue[appOfflineName];
}

function formatPersonalInterviniente(payload) {
  let newInterveningPersonnel = [];
  payload.map(intervener => {
    newInterveningPersonnel.push({
      dependencia: intervener.dependencia,
      jerarquia: AltaDenuncia.default.jerarquia.find(hierarchy => hierarchy.nombre === intervener.jerarquia),
      nombre: intervener.nombre + " " + intervener.apellido
    })  
  })
  return newInterveningPersonnel;
}

export default {
  formatEffects,
  formatAffecteds,
  formatVinculateds,
  addDefaultValuesToPerson,
  buildPersonList,
  buildAPersonForFront,
  translateActuationNameFromAppOffline,
  // formatCaratulas,
  // formatArticulos,
  formatInfractions,  
  formatPersonalInterviniente
}