<template>
    <div class="flex  flex flex-column align-items-center justify-content-center card-container h-screen pl-2" >
      <h1 class="titulo text-8xl text-black font-Impact">{{title}}</h1>    
      
    </div>
  </template>
  <script>
  
  export default {
    name: "PreliminarMenu",
    components: {
   },
    props: {
    },
    components: {
    },
    data() {
      return {
        title:"CERRANDO SESIÓN",
       
      };
    },
  };
  </script>
  
  <style scoped>
    .titulo {
          font-family: 'sans-serif', sans-serif; 
    }
  </style>
  
   