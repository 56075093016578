export default {
  data: () => ({
    formData: null,
    files: {},
  }),
  props: {
    fileForm: {
      type: Boolean,
      default: false
    },

    url: {
      type: [String, Array], //string que se usará para habilitar la barra de busqueda global
    },
  },
  methods: {
    
    updateFiles(nameField, event) {
      console.log("updateFiles>>>>>>>", this.formData, event)
      // if (!this.formData)
      this.files[nameField] = event.files
      this.formData =  new FormData();

      event.files.map((file) => {
        // console.log("this.params[nameField].upload.types.indexOf(file.type)",nameField, file.type, this.params[nameField].upload.types, this.params[nameField].upload.types.indexOf(file.type))
        // console.log("type?", file.type)
        if (this.params[nameField].upload.types.indexOf(file.type) < 0) {
          // console.log("type fail")
          this.err =
            "Al menos uno de los archivos es incompatible con los formatos permitidos";
          // this.removeFiles()
          // this.$refs['FileUpload-'+nameField].clear()
          setTimeout(() => {
            this.err = false;
          }, 5000);
          return;
        }
        // console.log("type ok")
        // console.log("userfile fin>>>>>>>", file, file.name)
        this.formData.append("userfile[]", file, file.name);

      });
    },
    removeFiles(nameField) {
      this.files[nameField] = null
      this.formData = null;
    },
    async saveFileForm(){
      if (!this.fileForm) return Promise.resolve(false);
      let {doc, uploadAndSave, enabledToUpdate } = this.docParams
      
      if (uploadAndSave && ((this.id && enabledToUpdate) || (!this.id || this.id == 'add') )){
        try {
          let result = await this.uploadFiles(false, doc)
          // console.log("result>>", result)
          
          return Promise.resolve(result.data.data[0]["id"])
        } catch (e) {
          console.error(e)
          this.enableForm = true;
          return Promise.reject()
        }
      }
      return Promise.resolve(false)
    },
    async uploadFiles(nameField, doc = {}) {
      
      try {
        if (!this.formData) return Promise.resolve(false)
        let opts
        if (nameField){
          opts = this.params[nameField].upload
        } else {
          opts = {
            urlRoute: this.url
          }
        }
        let urlRoute = opts.urlRoute.replace(
          "[id]",
          this.id
        );
        if (nameField === false && this.id) {
          urlRoute = `${urlRoute}/${this.id}`
        }
        // this.formData =  new FormData();
        // console.log('.this.formData>>>', nameField, this.formData,  doc)
        for (let field in doc ){
          this.formData.append(field, doc[field]);
        }

        let post = {
          url: urlRoute,
          formData: this.formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // params: {}
        };

        let result = await this.$rest.post(post);

        if (nameField && opts.gallery && this.params[opts.gallery].gallery.refresh)
        {
          this.params[opts.gallery].gallery.refresh();
        }
        // this.gallery.images.refresh()
        this.removeFiles();
        return Promise.resolve(result)
        // this.filesQuery();
      } catch (e) {
        console.error("err", e);
        this.err = "Error al subir archivos";
        setTimeout(() => {
          this.err = false;
        }, 5000);
        return Promise.reject(e)
        // this.removeFiles()/
      }
    },
    
    async forceUpload(){
      try{
        for (let field in this.params){
          if (this.params[field].upload && this.params[field].upload.force) {
            await this.uploadFiles(field)
            break;
          }
        }
        return Promise.resolve()
      }
      catch(e){
        console.error("e", e)
        return Promise.reject()
      }
    },
  },
}