<template>
  <div class="page">
    <div >
      <Parametrizacion />
    </div>
  </div>  
</template>

<script>
// import { mapState } from 'vuex'

  import Parametrizacion from '../components/Parametrizacion.vue';
export default {
  name: 'ParametrizacionPage',
  components: {
    Parametrizacion
  },
  data() { 
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
