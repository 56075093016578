import { actuacionesConfig } from "@/router/RoutesConfig/actuacionesConfig/actuacionesConfig";
import { depositoConfig } from "@/router/RoutesConfig/depositoConfig";



const isUserRolGuard = (to, from, next) => {
  const tipoUsuario = localStorage.getItem('tipo usuario'); 

  // Busca la ruta actual en actuacionesConfig
  const routeItemActs = actuacionesConfig.find(item => item.nameOfRoute === to.name);
  // Busca la ruta actual en depositoConfig
  const routeItemDeposito = depositoConfig.find(item => item.nameOfRoute === to.name);

  // Si la ruta no está definida en actuacionesConfig ni en depositoConfig, permitimos el acceso
  
  
  // Si la ruta está definida en actuacionesConfig, verificamos sus roles
  if (routeItemActs) {
    if (!routeItemActs.roles || routeItemActs.roles.length === 0) return next();
    if (routeItemActs.roles.includes(tipoUsuario)) return next();
  }

  // Si la ruta está definida en depositoConfig, verificamos sus roles
  const routeParentDeposito = depositoConfig.find(item => item.path === 'deposito');

  // Verifica si la ruta actual es una ruta hija de '/deposito'
  const isChildRoute = depositoConfig.some(item => to.path.includes(`/${item.path}/`));

  // Si la ruta actual es una ruta hija de '/deposito'
  if (isChildRoute) {
    // Verifica los roles del padre '/deposito'
    if (routeParentDeposito && routeParentDeposito.roles && routeParentDeposito.roles.length > 0) {
      // Verifica si el tipo de usuario tiene permisos para acceder al padre '/deposito'
      if (routeParentDeposito.roles.includes(tipoUsuario)) {
        return next(); // Permitir acceso
      }
    }
  }

  if (to.path.includes('/parametros')) {
    const rolesPermitidos = ["User Tester", "Super User"];
    // Verificar si el tipo de usuario tiene uno de los roles permitidos
    if (rolesPermitidos.some(role => tipoUsuario.includes(role))) {
      return next(); // Permitir acceso
    }
  }

  // Si no, redirigimos a la página de acceso denegado
  return next('/acceso-denegado');
};

export default isUserRolGuard;