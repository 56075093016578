<template>
  <div class="page">
    <div class="content">
      <FormLugarMomento modelo="sumario" @save="onSave" :formType="this.formType" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "../../components/Icon.vue";
import { defineAsyncComponent } from "vue";

// import FormLugarMomento from "../../components/forms/FormLugarMomento.vue";
export default {
  name: "SumarioDenunciaFormMomento",
  components: {
    Icon,
    FormLugarMomento: defineAsyncComponent({
      loader: () => import("@/components/forms/FormLugarMomento.vue"),
      // loadingComponent: LoadingComponent,
      // errorComponent: ErrorComponent,
      delay: 200, // Tiempo antes de mostrar el componente de carga
      timeout: 30000, // Tiempo de espera antes de considerar la carga fallida
    }),
  },
  data() {
    return {
      filteredLocation: [],
    };
  },
  computed: {
    ...mapState({
      formType: (state) => state.sumarioViews.actuacion,
      viewData: (state) => state.sumarioViews,
    }),
  },
  props: {
    actuacionType: String, //Defines the type of form
  },
  methods: {
    onSave() {
      // if(this.formType === 'denuncia')
      //   this.$router.push('/sumario/denuncia/formulario')
      // if(this.formType === 'oficio')
      //   this.$router.push('/sumario/denuncia/formulario/oficio')
      // if(this.formType === 'contravencional-oficio')
      //   this.$router.push('/sumario/denuncia/formulario/contravencional/oficio')
      // if(this.formType === 'contravencional-denuncia')
      //   this.$router.push('/sumario/denuncia/formulario/contravencional/denuncia')
      this.$router.push(
        "/actuaciones/new/" + this.actuacionType + "/initial"
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  padding: 10px 30px;
  background-color: #f8f9fa;
  min-height: calc(100vh - 92px);
}

.feature-intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 20px;
}
.feature-intro > h1 {
  width: 150%;
  font-size: 1.5rem;
  padding-bottom: 0px;
  margin: 0px;
}

.feature-intro > p {
  font-size: 1.5rem;
  padding-top: 0px;
}
.feature-intro > i {
  align-items: flex-start;
  height: 100%;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.map {
  display: block;
  margin-top: 10px;
  height: 60vh;
  width: 50vw;
}
.title {
  padding-top: 5px;
  padding-bottom: 0px;
  font-size: 1rem;
}
.title-toolbar {
  display: flex;
  justify-content: space-between;
}
</style>
