export const ufiCavigConfig = [
    {
        path: "/actuaciones/new/ufi-cavig/initial",
        pathName: "ufi-cavig",
        titulo: "UFI - CAVIG",
        icon: "pi-building",
        roles: [
          "User Tester",
          "Super User",
          "Jefe de Dependencia",
          "Oficial Sumariante",
          "Jefe de Sumario",
        ],
        type: "link",
        grupo: "UFI",
        props: { formType: "ufi-cavig", actuacionType: "ufi-cavig" },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-cavig/initial/denunciante",
        pathName: "ufi-cavig",
        titulo: "VÍCTIMA",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-cavig/initial/momento",
        pathName: "ufi-cavig",
        titulo: "DÍA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/ufi-cavig/initial/acusado",
        pathName: "ufi-cavig",
        titulo: "APREHENDIDOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/ufi-cavig/initial/efectos",
        pathName: "ufi-cavig",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]