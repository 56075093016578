var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-container"},[_c('div',{class:`border-round-sm
      bg-white
      shadow-3
      flex flex-wrap
      h-9rem
      border-left-3
      `,style:({ 'border-color': `var(--${_vm.color})`, })},[_c('div',{staticClass:"flex flex-row h-2rem pt-3 pl-3 h-3rem"},[_c('span',{staticClass:"overview-icon flex align-items-end"},[_c('i',{class:`pi pi-pencil flex align-items-center p-2 border-round`,style:({ 'background-color': `var(--${_vm.color})` })})]),_c('span',{staticClass:"vertical-align-middle text-2xl font-semibold ml-2 flex align-items-center"},[_vm._v(" "+_vm._s(_vm.cardName)+" ")])]),_c('div',{staticClass:"flex flex-row min-w-full justify-content-center pb-3"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-6"},[_c('div',{staticClass:"text-2xl font-semibold flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.pendings)+" ")]),_c('div',{staticClass:"font-semibold mt-1 text-600"},[_vm._v("En curso")])]),_c('div',{staticClass:"w-6 border-left-1 border-300 pl-3"},[_c('div',{staticClass:"text-2xl font-semibold flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.expireds)+" ")]),_c('div',{staticClass:"font-semibold mt-1 text-600"},[_vm._v("Vencidos")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }