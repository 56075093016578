<template>
  <div>
    <Button type="button" label="Vinculación Manual"  iconPos="right" class="p-button-link" @click="toggle" />

    <OverlayPanel ref="op" :style="{width: '450px'}" :showCloseIcon="true" :test-id="'OverlayPanelVinculacionManual'">
      <div class="flex justify-content-center flex-wrap card-container " :test-id="'divContenedorOverlayPanelVinculacionManual'">        
        <h3>Ingreso manual de los datos.</h3>
        <TabView class="w-25rem">
          <TabPanel header="Sumarios">
            <div class="flex flex-column w-11 justify-content-center" :test-id="'divContenedorCamposOverlayPanelVinculacionManual'">
              <p>Nro.Actuación</p>
              <InputText  class="" v-model="dataNumeroActuacion"  placeholder="Nro.Actuación" :test-id="'InputNroActuacion'"/>
              <p>Juzgado Interviniente</p>
              <InputText  class="" v-model="dataJuzgadoInterviniente"  placeholder="Juzgado Interviniente" :test-id="'InputJuzgadoInterviniente'"/>
              <p>Causa Carátula</p>
              <InputText  class="" v-model="dataCaratula"  placeholder="Causa Carátula" :test-id="'InputCaratula'"/>
              <p>Dependencia donde se realizó el sumario</p>
              <InputText  class="" v-model="dataDependencia"  placeholder="Dependencia donde se realizó el sumario" :test-id="'InputDependencia'"/>
              <p>Tipo de Actuación</p>
              <!-- <InputText  class="" v-model="tipoActuacion"  placeholder="Tipo de Actuación" /> -->
          
              <Dropdown dataKey='Nombre' v-model="tipoFormulario" optionLabel="Nombre" :options="tiposSumariosList" optionValue="Nombre"  placeholder="Formulario" class="flex" :filter="true" :test-id="'DropTipoActuacion'"/>

            </div>
            <Button
              label="Agregar"
              class="p-button-sm mt-4 mb-2 w-12"
              @click="addVinculo"
              test-id="btnAgregarVinculoSumarios"
              :disabled="actuationValidations.buttonDisabled"
            />
          </TabPanel>
          <TabPanel header="Infracciones">
            <div class="flex flex-column w-11 justify-content-center" :test-id="'divContenedorCamposOverlayPanelVinculacionManualInfracciones'">
              <p>Fecha de la Infracción</p>
              <CustomCalendar
                id="time24"
                class="input"
                :selectOtherMonths="true"
                onkeydown="return false"
                :showTime="true"
                placeholder="Fecha de la Infracción"
                dateFormat="dd/mm/yy"
                v-model="dataInfractionDate"
                :maxDate="today"
                test-id="desdeCalendar"
                :baseZIndex="999999"
                :timeByKeyboard="true"
              />
              <p>Nro. Infracción</p>
              <InputText  class="" v-model="infractionNumber"  placeholder="Nro. Infracción" :test-id="'InputNroInfraccion'"/>
              <p>Juzgado Asignado</p>
              <InputText  class="" v-model="infractionJuzgadoInterviniente"  placeholder="Juzgado Asignado" :test-id="'InputJuzgadoInterviniente'"/>
            </div>
            <Button
              label="Agregar"
              class="p-button-sm mt-4 mb-2 w-12"
              @click="addVinculoInfraction"
              test-id="btnAgregarVinculoInfracciones"
              :disabled="infractionValidations.buttonDisabled"
            />
          </TabPanel>
        </TabView>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel';
import CustomCalendar from './custom-calendar/CustomCalendar.vue';
import axios from "axios";

export default {
  name: "VinculacionManual",
  components: {
    OverlayPanel,
    CustomCalendar
  },
  data() {
    return {
      filteredFormulario: [],      
      formulario: '',      
      tiposSumariosList:[],
      numeroActuacion: '',
      juzgadoInterviniente:'',
      caratula:'',
      dependencia:'',
      tipoActuacion:'',
      infractionDate:'',
      infractionNumber: '',
      infractionJuzgadoInterviniente: '',
      infractionValidations:{ 
        items: [
          { field: 'infractionNumber', minLength: 2 },
          { field: 'infractionJuzgadoInterviniente', minLength: 2 },
          { field: 'infractionDate', emptyString: true }
        ],
        buttonDisabled: true
      },
      actuationValidations:{ 
        items: [
          { field: 'juzgadoInterviniente', minLength: 2 },
          { field: 'numeroActuacion', minLength: 2 },
          { field: 'caratula', minLength: 2 },
          { field: 'dependencia', minLength: 2},
          { field: 'tipoFormulario', emptyString: true }
        ],
        buttonDisabled: true
      },
    };
  },
  watch: {
    infractionNumber(newValue, oldValue) {
      let theNewValueisNAN = this.evaluateIfUseNaNCharacters(newValue);
      let charactersLimitExceeded = this.evaluateCharactersLimit(newValue, 30);
      theNewValueisNAN || charactersLimitExceeded ? this.infractionNumber = oldValue : this.infractionNumber = newValue;
      this.validateLength('infractionValidations');
    },
    infractionJuzgadoInterviniente(newValue, oldValue) {
      let charactersLimitExceeded = this.evaluateCharactersLimit(newValue, 50);
      charactersLimitExceeded ? this.infractionJuzgadoInterviniente = oldValue : this.infractionJuzgadoInterviniente = newValue;
      this.validateLength('infractionValidations');
    },
  },
  computed: {
    abc:{
      get ()
      {
        return this.sumarios.data.data;       
      }
    },    
    tipoFormulario:{
      get(){
        return this.formulario
      },
      set(value){
        this.formulario = value;
        this.selecFormulario()
        this.validateLength('actuationValidations');
        return
      }    
    },
    today() {
      return new Date();
    },
    dataInfractionDate: {
      get () { return this.infractionDate },
      set (value) { 
        this.infractionDate = value;
        this.validateLength('infractionValidations');
      }
    },
    dataJuzgadoInterviniente: {
      get () { return this.juzgadoInterviniente },
      set (value) { 
        this.juzgadoInterviniente = value;
        this.validateLength('actuationValidations');
      }
    },
    dataNumeroActuacion: {
      get () { return this.numeroActuacion },
      set (value) { 
        this.numeroActuacion = value;
        this.validateLength('actuationValidations');
      }
    },
    dataCaratula: {
      get () { return this.caratula },
      set (value) { 
        this.caratula = value;
        this.validateLength('actuationValidations');
      }
    },
    dataDependencia: {
      get () { return this.dependencia },
      set (value) { 
        this.dependencia = value;
        this.validateLength('actuationValidations');
      }
    },
  },
  async created(){
    this.sumarios= await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-actuacion/`, { withCredentials: true });
    //Quitamos Unidades Fiscales sin especializacion (no se quita de la BD directamente, por que la ausencia de este dato puede generar errores)
    this.tiposSumariosList = this.sumarios.data.data.filter(e =>e.Nombre != "Unidades Fiscales de Investigacion")
    //Adaptamos los nombres de las Actuaciones "UFIs" a los nombres pedidos
    // this.tiposSumariosList.map(e => e.Nombre = ( e.Nombre == 'Unidades Fiscales de Investigacion Flagrancia') ? 'UFI - FLAGRANCIA' : (
    //   e.Nombre == 'Unidades Fiscales de Investigacion CAVIG' ? 'UFI - CAVIG' : (
    //     e.Nombre == "Unidades Fiscales de Investigacion ANIVI" ? 'UFI - ANIVI' : e.Nombre
    //   )
    // ))
    //LAS LINEAS ANTERIORES SE COMENTARON, POR QUE DE LOS VINCULOS (NO MANUALES) SE CARGAN CON EL NOMBRE COMPLETO Y SI SE MANTIENEN AMBOS, EL FILTRO POR UFI TRAERA UNO O EL OTRO

  },  
  methods: {
    addVinculo(){
      //Si en algun momento se piden Validaciones eso iria aca
     let objet = {
      "Tipo": 'actuacion',
      "NumeroActuacion": this.numeroActuacion,
      "JuzgadoInterviniente": this.juzgadoInterviniente,
      "Caratula":this.caratula,
      "DependenciaActuacion":this.dependencia,
      "TipoActuacion":this.tipoFormulario,
      "url": "/vinculo-actuacion-deposito/",
      "NumeroVinculacion": this.numeroActuacion
     }
     this.$refs.op.hide();
     this.resetInputs();
     this.$emit("event",objet);

    },
    addVinculoInfraction(){
      let objet = {
        "Tipo": 'infraccion',
        "FechaInfraccion": this.formatDate(this.infractionDate),
        "NumeroInfraccion": this.infractionNumber,
        "JuzgadoAsignado": this.infractionJuzgadoInterviniente,
        "url": "/vinculo-infraccion-deposito",
        "NumeroVinculacion": this.infractionNumber
      };
      this.$refs.op.hide();
      this.resetInputs();
      this.$emit("event",objet);
    },
    resetInputs(){   
      this.formulario =  '';      
      this.numeroActuacion =  '';
      this.juzgadoInterviniente = '';
      this.caratula = '';
      this.dependencia = '';
      this.tipoActuacion = '';
      this.infractionDate = '';
      this.infractionNumber = '';
      this.infractionJuzgadoInterviniente = '';
      this.infractionValidations.buttonDisabled = true,
      this.actuationValidations.buttonDisabled = true
    },
    selecFormulario(){

      if(typeof(this.formulario) != 'object')//Esto lo hacemos ya que solo queremos se ejecute cuando el dato sea String, osea ingresado por teclado(de lo contrario los Object daran error por los woLowerCase)
      {
        let value =  this.abc.filter((item) => item.Nombre.toLowerCase().search(this.formulario.toLowerCase()) >= 0  );

        if(value[0] == undefined)
        {
          value[0] = ''
        }

        this.formulario = value[0].Nombre;
        this.idSumario = value[0].TiposumarioID
      }
    },
    filterFormulario(event) {

      this.filteredFormulario = this.abc.filter((item) => item.Nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );

    },     
    
    toggle(event) {
        this.$refs.op.toggle(event);
    },
    evaluateCharactersLimit(dataToEvaluate, charactersLimit){
      if(dataToEvaluate.length > charactersLimit) {
        return true;
      } else {
        return false;
      }
    },
    evaluateIfUseNaNCharacters(dataToEvaluate){
      let theDataisNAN = false;
      let dataToEvaluateAsArray = dataToEvaluate.split('');
      dataToEvaluateAsArray.map((character) => {
        if(isNaN(parseInt(character)))	{ 
          theDataisNAN = true;
          return
        }
      });
      return theDataisNAN;
    },
    formatDate(originalDate){
      let date = new Date(originalDate);
      let year = date.getFullYear();
      let month = ('0' + (date.getMonth() + 1)).slice(-2);  // Months are zero-based, so we add 1 and add leading zero if necessary
      let day = ('0' + date.getDate()).slice(-2);  // Add leading zero if necessary
      let hours = ('0' + date.getHours()).slice(-2);  // Add leading zero if necessary
      let minutes = ('0' + date.getMinutes()).slice(-2);  // Add leading zero if necessary
      let seconds = ('0' + date.getSeconds()).slice(-2);  // Add leading zero if necessary
      // Format the date in the desired format
      let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDate;
    },
    validateLength(validationsType){
      const allValid = this[validationsType].items.every(item => {
        if ('minLength' in item){
          return this[item.field].length > item.minLength;
        } 
        if ('emptyString' in item){
          return this[item.field] !== '';
        } 
        throw new Error("Invalid item properties in " + validationsType);
      });
      allValid ? this[validationsType].buttonDisabled = false : this[validationsType].buttonDisabled = true;
    }
  },
}
</script>

<style scoped>

</style>
