<template>
  <div :class="'page '">
    <div v-if="!this.$route.path.includes('/initial/efectos')">
      <div v-if="!viewer">
        <div v-if="getMode() == 'new'" class="feature-intro">
          <h1>Nuevo {{ pageName }}</h1>
          <p>Ingrese los datos del formulario</p>
        </div>
        <div v-else>
          <h1> {{ pageName }}</h1>
          <div class="feature-intro">
            <h1>Editando {{ pageName }}</h1>
            <p>Modifique los datos que desea EDITAR</p>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="onlyEdit.length > 0">
          <h1> {{ pageName }}</h1>
          <div  class="feature-intro">
            <h1>Editando {{ pageName }}</h1>
            <p>Modifique los datos que desea EDITAR</p>
          </div>
        </div>
        <div v-else> 
          <h1> {{ pageName }}</h1>
          <div class="flex">
            <p>{{ pageSubtitle }}</p>
            <Button
              v-if="$rest.esTipoUsuario(['Super User','User Tester','Super Usuario de E3', 'Deposito Judicial', 'Jefe de Dependencia'], user)"
              icon="pi pi-pencil"
              class="p-button-rounded p-button-outlined p-button-warning roundeds-buttons ml-4 mt-1"
              @click="goToEditMode()"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- :nombres="names" -->
    <!-- :errors="errors" -->
    <TForm
      v-if="ready"
      idFieldName="EfectoDepositoID"
      :params="params"
      :mode="getMode()"
      tooltipView="Visualizar este efecto"
      :cards="false"
      :designEdit="designEdit"
      :designNew="design"
      :backPath="backPath"
      :cancelButton="false"
      :put="true"
      apiversion="2"
      :enableEditFields="enableEditFields()"
      :url="url"
      :viewer="viewer"
      :viewerID="vID"
      :toListAfterSave="true"
      :formatDoc="formatDoc"
      @openModal="openModal('ModalRefInform')"
      :onlyEdit="onlyEdit"
      :specificUrlForPut="specificUrlForPut"
      ref="EfectoFormTForm"
      :showSaveButton="showSaveButton"
      @postResult="value => saveVinculatedActuations(value)"
      @putResult="value => saveVinculatedActuations(value)"
    >
      <template #VisibleViewer="slotProps">
        <div v-if="slotProps.data.Visible == 1" class="danger-text font-semibold">Visible</div>
        <div v-else class="success-text font-semibold">NO Visible</div>
      </template>
      <template #solicitud>
        <b>DATOS DEL FORMULARIO DE SOLICITUD</b>
      </template>
      <template #solicitudData v-if="showSolicitud">
        <div class="p-3 pl-0">
          <div class="font-bold">Fecha solicitud</div>
          <div>{{ DataSolicitante[0]?.createdAt }}</div>
        </div>

        <div class="p-3 pl-0">
          <div class="font-bold">Nombre</div>
          <div>{{ DataSolicitante[0]?.nombre }}</div>
        </div>
        <div class="p-3 pl-0">
          <div class="font-bold">Apellido</div>
          <div>{{ DataSolicitante[0]?.apellido }}</div>
        </div>
        <div class="p-3 pl-0">
          <div class="font-bold">DNI</div>
          <div>{{ DataSolicitante[0]?.dni }}</div>
        </div>
        <div class="p-3 pl-0">
          <div class="font-bold">Celular</div>
          <div>{{ DataSolicitante[0]?.celular }}</div>
        </div>
        <div class="p-3 pl-0">
          <div class="font-bold">Email</div>
          <div>{{ DataSolicitante[0]?.Email }}</div>
        </div>
        <div class="p-3 pl-0">
          <div class="font-bold">Descripción</div>
          <div>{{ DataSolicitante[0]?.Descripcion }}</div>
        </div>
      </template>
      <template #gestorDiligenciasDeposito="slotProps"> 
        <!-- {{ slotProps.data}} -->
        
        <GestorDiligenciasDeposito 
          :useridProp="user.uid" 
          :DepositoArticuloID="$route.params.id" 
          :diligencesTemplates="$store.state.siis.tipoDiligencia" 
          :efectoData="slotProps.data"
          :offlineOnlineMode = "$offline.onlineMode"
          :rest = "$rest"
          :viewer="viewer"
          :showFileUpload="true"
          :url="`/files/deposito-diligencias/?ArticuloID=${$route.params.id}`" 
          v-if="slotProps.data.id_categoria && ( $route.path !== '/efectos/new' && !actuationMode ) " 
        />
      </template>
      <template  #solicitudesTable v-if="!this.$route.path.includes('/initial/efectos')">
        <SolicitudesTable
          :efectoID="$route.params.id"       
        />
      </template>
    </TForm>
    <ModalTemplate 
      title="Información"
      type="informativo"
      message="La vinculación del objeto es obligatoria"
      :showIcon="true"
      @confirm="closeModal('ModalRefInform')"
      severity="danger"
      ref="ModalRefInform"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import GestorDiligenciasDeposito from '@/components/gestorDiligencias/GestorDiligenciasDeposito.vue'
import SolicitudesTable from "@/components/solicitudes/SolicitudesTable.vue";
import axios from "axios";
import ModalTemplate from '../../components/ModalTemplate.vue'
import store from '../../store/index.js'

export default {
  props: {
    viewerID: {
      type: String,
    },
    showSolicitud: {
      type: Boolean,
      default: false,
    },   
    viewSolicitud: {
      type: Boolean,
      default: false,
    },
    actuationMode: {
      type: Boolean,
      default: false,
    },
    showSaveButton: {
      type: Boolean,
      default: true
    },
  },
  components:{
    GestorDiligenciasDeposito,
    SolicitudesTable,
    ModalTemplate
  },
  data() {
    return {
      params: {
        gestorDiligenciasDeposito:{
          type: "template",
          label: "Gestión de diligencias",
        },
        solicitudesTable:{
          type: "template",
          label: "Solicitudes",
        },
        solicitud: { 
          type: "template",
          label: "",
        },
        solicitudData: { 
          type: "template",
          label: "",
        },
        efecto: {
          type: "",
          label: "DATOS DEL EFECTO",
        },
        Visible: {
          validation: "required",
          type: "SelectButton",
          label: "Visible",
          default: "0",
          options: [
            {
              name: "Visible",
              value: "1",
            },
            {
              name: "No Visible",
              value: "0",
            },
          ],
          setMsg: (val) => {
            if (!val === 1 || !val === "1") return "";
            if (val === 1 || val === "1") return "Este objeto sera visible en la web de recuperos";
          },
          removeIn: ['/initial/efectos']
        },
        EstadoEfectoActuacionID: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: " ",
          placeholder: " ",
          dropdown: {
            optionValue: "id",
            optionLabel: "nombre",
            options: [
              { "id": "Recuperado", "nombre": "Recuperado" }, 
              { "id": "Secuestrado", "nombre": "Secuestrado" } 
            ],
            changeDropdown: () => {
            },
          },
          onlyIncludeIn:['efectos/new']
        },
        CategoriaID: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Categorias",
          placeholder: "Elija una Categoria",
          dropdown: {
            optionValue: "CategoriaID",
            optionLabel: "Nombre",
            options: [],
            refreshDropdown: () => this.$store.dispatch("siis/setCategoriasDeposito", true),
            changeDropdown: (v) => {
              // console.log('changeDropdown');
              this.params.SubcategoriaID.dropdown.options = this.subcategorias.filter(
                (item) => item.id_categoria === v
              );
            },
          },
        },
        SubcategoriaID: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Sub-Categorias",
          placeholder: "Elija una sub-categoria",
          dropdown: {
            optionValue: "SubcategoriaID",
            optionLabel: "Nombre",
            options: [],
            refreshDropdown: () => this.$store.dispatch("siis/setSubCategoriasDeposito", true),
            changeDropdown: (v) => {
              this.params.TipoID.dropdown.options = this.tipo.filter(
                (item) => item.id_subcategoria === v
              );
            },
          },
        },
        "MarcaID": {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Marca",
          placeholder: "Elija una Marca",
          dropdown: {
            optionValue: "MarcaID",
            optionLabel: "nombre",
            options: [],
            refreshDropdown: () => this.$store.dispatch("siis/setMarca", true),
            changeDropdown: (v, doc) => {
              //La siguientes lineas de codigo se comentaron por que no todas los modelos estan vinculados a una marca, lo que ocaciona problemas para los usuarios
              // this.params.ModeloID.dropdown.options = this.ModeloID.filter(
              //   (item) => item.MarcaID === v
              // );
            },
          },
        },
        TipoEstadoEfecto: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Estado",
          placeholder: "Seleccione un Estado",
          dropdown: {
            optionValue: "TipoEstadoEfectoID",
            optionLabel: "Nombre",
            options: [],
            refreshDropdown: () => this.$store.dispatch("siis/setEstadosEfectoDeposito", true),
            changeDropdown: (v) => {
            // console.log("this.estadosEfectoDeposito",this.estadosEfectoDeposito);
              if(v === 'precarga-c42a-11ed-8320-0242ac120005'){
                this.params.TipoEstadoEfecto.dropdown.options  = this.estadosEfectoDeposito.filter(e =>e.TipoEstadoEfectoID === 'endependencia01-c42a-11ed-8320-0242ac120005');
                this.params.TipoEstadoEfecto.type = "textInfo";
              }else{
                this.params.TipoEstadoEfecto.dropdown.options = this.estadosEfectoDeposito;
              }
            },
          },
          removeIn: ['deposito/efectos/new', '/initial/efectos']
        },     
        TipoEstadoSolicitud: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Estado",
          placeholder: "Seleccione un Estado",
          dropdown: {
            optionValue: "TipoEstadoSolicitudID",
            optionLabel: "Nombre",
            options: [],
            refreshDropdown: () => this.$store.dispatch("siis/setEstadosSolicitudDeposito", true),
            changeDropdown: (v) => {
              this.params.TipoEstadoSolicitud.dropdown.options = this.dataEstadosSolicitudDeposito;
            },
          },
          removeIn: ['/deposito/efectos']
        },     
        ModeloID: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Modelo",
          placeholder: "Elija un Modelo",
          dropdown: {
            optionValue: "ModeloID",
            optionLabel: "Nombre",
            options: [],
            refreshDropdown: () => this.$store.dispatch("siis/setModelo", true),
          },
        },
        TipoID: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Tipo",
          placeholder: "Elija un Tipo",
          dropdown: {
            optionValue: "tipoID",
            optionLabel: "nombre",
            options: [],
            refreshDropdown: () => this.$store.dispatch("siis/setTipoDeposito", true),
          },
        },
        Anio: {
          validation: "max_length[50]",
          type: "input",
          label: "Año",
          placeholder: "Escribir año",
        },
        Chasis: {
          validation: "max_length[150]",
          type: "input",
          label: "N° Chasis",
          placeholder: "Escribir N° Chasis",
        },
        NumeroMotor: {
          validation: "max_length[150]",
          type: "input",
          label: "N° Motor",
          placeholder: "Escribir N° Motor",
        },
        Dominio: {
          validation: "max_length[150]",
          type: "input",
          label: "Dominio",
          placeholder: "Escribir Dominio",
        },
        NumeroSerie: {
          validation: "max_length[150]",
          type: "input",
          label: "N° Serie",
          placeholder: "Escribir N° Serie",
        },
        Imei: {
          validation: "max_length[150]",
          type: "input",
          label: "N° IMEI",
          placeholder: "Escribir N° IMEI",
        },
        Abonado: {
          validation: "max_length[150]",
          type: "input",
          label: "N° Abonado",
          placeholder: "Escribir N° Abonado",
        },
        Color: {
          validation: "max_length[150]",
          type: "input",
          label: "Color",
          placeholder: "Color",
        },
        DatosPublicos: {
          validation: "required|max_length[500]",
          type: "textarea",
          label: "Datos Publicos",
          placeholder: "Escribir Descripcion",
          removeIn: ['/initial/efectos']
        },
        Descripcion: {
          validation: "required|max_length[500]",
          type: "textarea",
          label: "Datos del Efecto (privado)",
          placeholder: "Escribir Descripcion",
          removeIn: ['/initial/efectos']
        },
        file: {
          validation: "required|min_length[2]",
          type: "FileUpload",
          upload: {
            enableWhenNew: true,
            uploadAndSave: true,
            accept: ".jpg, .jpeg, .png",
            maxFileSize: 15000000,
            multiple: true,
            gallery: "images",
            force: true,
            types: ["image/jpeg", "image/png"],
            urlRoute: "/files/deposito?ArticuloID=[id]",
          },
          label: !this.$route.query.view ? "Subir imagen" : "imagenes",
          removeIn: ['/initial/efectos']
        },
        // ↓  this contents the 'sumario' data but the infractions data too
        sumario: {
          removeIn: ['/initial/efectos'],
          type: "table",
          button: "Vincular",
          manualVinculation: true,
          title: "Vinculación de Objetos",
          validation: "min_length[1]",
          label: " ",
          keyShow: [
            { title: "Fecha", fieldName: "Fechadesde", sortButton: true },
            { title: "Nro", fieldName: "NumeroActuacion", sortButton: true },
            { title: "Tipo", fieldName: "TipoActuacion", sortButton: true },
            { title: "Caratula/Causa", fieldName: "Caratula", sortButton: true },
            { title: "Juzgado", fieldName: "Juzgado", sortButton: true },
            { title: "Dependencia", fieldName: "Dependencia", sortButton: true },
            { title: "Estado", fieldName: "Estado", sortButton: false },
            { title: "Acción", fieldName: "Accion", sortButton: false },
          ],
          formatDoc(col, data) {
            try {
              switch (col) {
                case "Fecha":
                  //Aqui habian puesto la fecha desde y hasta del Hecho, eso se borro, por que este apartado es para fecha de Creacion
                  return data.Fecha ? data.Fecha.slice(0, -8) : "Sin datos";
                case "Tipo":
                  // Retorna el nombre del tipo de actuación mapeado o el tipo original si no está en el mapa.
                  const actuationTypeFound  =  Object.values(store.state.sumarioViews).find(item => item?.actuationName?.[data.Tipo] !== undefined);
                  return actuationTypeFound?.actuationName?.[data.Tipo] || data.Tipo;
                case "Caratula/Causa":
                  return data.Caratula;
                case "Juzgado":
                  let Juzgado =
                    data["Juzgado"] != undefined
                      ? data.Juzgado
                      : data["Sumarioufi"] != undefined
                      ? "UFI N°" + data.Sumarioufi[0].Numero
                      : "Sin datos";
                  return Juzgado;
                case "Nro":
                  return data.Numero !== "" ? data.Numero : "Sin datos";
                case "Estado":
                  return data["Estado"] ? data["Estado"] : "Sin Estado";
                case "Dependencia":
                  return data["Dependencia"];
                case "Acción":
                  return "delete";
                default:
                  return data[col] ? data[col] : "No especificado";
              }
            } catch (e) {
              console.error("formatDoc err", col, data, e);
              return "";
            }
          },
          getClass(column, sumario) {
            if (column.title == "Estado") {
              switch (sumario.Estado) {
                case "En Curso":
                  return "bg-green-200 min-w-full flex justify-content-center white-space-nowrap p-1 font-medium text-green-800 border-round";
                case "Vencida":
                  return "bg-yellow-200 min-w-full flex justify-content-center white-space-nowrap p-1 font-medium text-yellow-800 border-round";
                case "Finalizada":
                  return "bg-red-200 min-w-full flex justify-content-center white-space-nowrap p-1 font-medium custom-text-red-800 border-round";
                case "- - -":
                  return "";
                default:
                  return "surface-500 min-w-full flex justify-content-center white-space-nowrap p-1 font-medium custom-text-red-800 border-round";
              }
            }
          },
          "dataTable":{
            "loading":false,
            "list":[],
            refresh: async () =>{
              try{
                this.params.sumario.dataTable.loading = true;
                let respActuation = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/vinculo-actuacion-deposito/?search={"DepositoArticuloID": [{"operator": "=", "value": "${this.id}"}]}`,{ withCredentials: true });//, params: { method: "own" }
                let resp2Infraction = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/vinculo-infraccion-deposito/?search={"DepositoArticuloID": [{"operator": "=", "value": "${this.id}"}]}`,{ withCredentials: true });//, params: { method: "own" }
                let resp3Actuation = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-efecto/?DepositoEfectoID=${this.id}`,{ withCredentials: true });//, params: { method: "own" }
                resp3Actuation = resp3Actuation.data.data.map(item => ({ ...item, formatType: 'new' })); 
                respActuation = respActuation.data.data.map(item => ({ ...item, formatType: 'old' })); 
                let vinculatedActuations = this.vinculatedActuations.map(item => ({ ...item, formatType: 'creating it', id: Date.now().toString(36) + Math.random().toString(36).substr(2) })); 
                this.$store.dispatch("siis/replaceVinculatedActuations", vinculatedActuations)
                let combinedVinculatedActuations = [...vinculatedActuations, ...respActuation, ...resp3Actuation];
                combinedVinculatedActuations = this.standardizeActuationAndInfraction(combinedVinculatedActuations, resp2Infraction.data.data);
                this.params.sumario.dataTable.list = combinedVinculatedActuations;
                this.params.sumario.dataTable.loading = false;
                if(this.viewer === false) this.removeDeleteAndEditButtons(this.params.sumario.dataTable.list);
              }catch(e){
                this.params.sumario.dataTable.loading = false;
                this.$rest.toast_open({message: "Ocurrio un error al traer las Actuaciones vinculadas a este Articulo",position: "top-right",type: "error", duration: 3000,});
              }
            },
            deleteConfirmation: (event,data) =>{
                this.$confirm.require({
                  target: event.currentTarget,
                  message: '¿Está seguro que desea eliminar esta vinculación?',
                  icon: 'pi pi-info-circle',
                  acceptLabel: "Sí",
                  rejectLabel: "No",
                  accept: () => {
                     this.params.sumario.dataTable.deleteVinculation(data)
                  },
                  reject: () => {
                  }
              });
            },
            deleteVinculation: async (data) =>{
              //Activamos el loding
              this.params.sumario.dataTable.loading = true;
              //Si tiene "formatType" igual a 'creating it', es una vinculación con formato nuevo que se está creando en el 
              //modo creación y que debe eliminarse del store, no de la base de datos
              if(data?.formatType === 'creating it'){
                this.$store.dispatch("siis/deleteItemFromVinculatedActuations", data);
                await this.params.sumario.dataTable.refresh();
                return;
              }
              try{
                //Eliminamos el elemento, notificamos si la eliminacion fue un Exito, refrescamos los elementos y desactivamos el loading
                let resp = await axios.delete(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/${data.url}/${data.vinculoID}`,{ withCredentials: true});    
                if(resp.data.error == ""){
                  this.$rest.toast_open({message: `La Vinculacion con ${data.Numero} se Elimino con exito`,position: "top-right",type: "success",duration: 3000,});
                }
                await this.params.sumario.dataTable.refresh()
              }
              catch(e){
                //Informamos que ocurrio un error y desactivamos el loading
                 this.$rest.toast_open({message: "Ocurrio un error al intentar eliminar la Vinculacion",position: "top-right",type: "error", duration: 3000,});
                 this.params.sumario.dataTable.loading = false;
              }

            },
            vincularObjeto: async (datos, id)=>{ 
              //Activamos el loding
              this.params.sumario.dataTable.loading = true;
              try{
                datos.DepositoArticuloID = id
                let resp = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/${datos.url}`,datos,{ withCredentials: true});    
                if(resp.status == 200){
                  this.$rest.toast_open({message: `La Vinculacion con ${datos.NumeroVinculacion} se Realizo exito`,position: "top-right",type: "success",duration: 3000,});
                }
                await this.params.sumario.dataTable.refresh();
                this.params.sumario.dataTable.loading = false;
              }
              catch(e){
                //Informamos que ocurrio un error y desactivamos el loading
                this.$rest.toast_open({message: "Ocurrio un error al intentar guardar la Vinculacion",position: "top-right",type: "error", duration: 3000,});
                this.params.sumario.dataTable.loading = false;
              }
            },
          }
        },   
        DepositarioOriginal: {
          type:  "textInfo",
          label: "Depositario Original" ,
          removeIn: ['/initial/efectos']
        },          
        DepositarioActual: {
          type: "textInfo",
          label:"Depositario Actual",
          removeIn: ['/initial/efectos']
        },           
        "images": {
          "type": "image_gallery",
          "gallery": {
            "list": [],
            refresh: async () => {
              try {
                let resp = await axios.get(
                  `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/vinculo-actuacion-deposito/?search={"DepositoArticuloID": [{"operator": "=", "value": "${this.id}"}]}`,
                  { withCredentials: true }
                ); 
                this.params.sumario.dataTable.list = resp.data.data;
              } catch (e) {
                this.$rest.toast_open({
                  message: "Ocurrio un error al traer las Actuaciones vinculadas a este Articulo",
                  position: "top-right",
                  type: "error",
                  duration: 3000,
                });
              }
            },
            deleteConfirmation: (event, data) => {
              this.$confirm.require({
                target: event.currentTarget,
                message: "Está seguro que desea eliminar esta vinculación",
                icon: "pi pi-info-circle",
                acceptLabel: "Sí",
                rejectLabel: "No",
                accept: () => {
                  this.params.sumario.dataTable.deleteActuacion(data);
                },
                reject: () => {},
              });
            },
            deleteActuacion: async (data) => {
              //Activamos el loding
              this.params.sumario.dataTable.loading = true;
              try {
                //Eliminamos el elemento, notificamos si la eliminacion fue un Exito, refrescamos los elementos y desactivamos el loading
                let resp = await axios.delete(
                  `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/vinculo-actuacion-deposito/${data.VinculoActuacionDepositoID}`,
                  { withCredentials: true }
                );
                if (resp.data.error == "") {
                  this.$rest.toast_open({
                    message: "La Vinculacion se Elimino con exito",
                    position: "top-right",
                    type: "success",
                    duration: 3000,
                  });
                }
                await this.params.sumario.dataTable.refresh();
                this.params.sumario.dataTable.loading = false;
              } catch (e) {
                //Informamos que ocurrio un error y desactivamos el loading
                this.$rest.toast_open({
                  message: "Ocurrio un error al intentar eliminar la Vinculacion",
                  position: "top-right",
                  type: "error",
                  duration: 3000,
                });
                this.params.sumario.dataTable.loading = false;
              }
            },
            vincularObjeto: async (datos, id) => {
              //Activamos el loding
              this.params.sumario.dataTable.loading = true;
              try {
                //Vinculamos el elemento, notificamos si la eliminacion fue un Exito, refrescamos los elementos y desactivamos el loading
                datos.DepositoArticuloID = id;
                let resp = await axios.post(
                  `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/vinculo-actuacion-deposito/`,
                  datos,
                  { withCredentials: true }
                );
                if (resp.data.error == "") {
                  this.$rest.toast_open({
                    message: "La Vinculacion se Realizo exito",
                    position: "top-right",
                    type: "success",
                    duration: 3000,
                  });
                }
                await this.params.sumario.dataTable.refresh();
                this.params.sumario.dataTable.loading = false;
              } catch (e) {
                //Informamos que ocurrio un error y desactivamos el loading
                this.$rest.toast_open({
                  message: "Ocurrio un error al intentar guardar la Vinculacion",
                  position: "top-right",
                  type: "error",
                  duration: 3000,
                });
                this.params.sumario.dataTable.loading = false;
              }
            },
          },
        },
        images: {
          type: "image_gallery",
          gallery: {
            list: [],
            refresh: async () => {
              if (this.id == "add") return;
              this.params.images.gallery.list = await this.$store.dispatch(
                "siis/getImagesForGallery",
                `/files/deposito/?search={"ArticuloID": [{"operator": "=", "value": "${this.id}"}]}`
              );
            },
            imgError: (e, i, type) => {
              if (typeof i == "object") {
                for (let x in this.params.images.gallery.list) {
                  if (this.params.images.gallery.list[x].itemImageSrc == i.itemImageSrc) i = x;
                  break;
                }
              }
              let prop = type == "sd" ? "thumbnailImageSrc" : "itemImageSrc";
              let img = this.params.images.gallery.list[i];
              if (e.target.src == img[prop + "2"]) return false;
              this.params.images.gallery.list[i][prop] = img[prop + "2"];
            },
          },
          label: " ",
        },
      },
      id: "",
      DataSolicitante: "",    
      design:[],
      designEdit: [],
      vID: null,
      ready: false,
      onlyEdit: [],
    };
  },
  async created() {
    this.getDiligencesTemplate()    

    let { id } = this.$route.params;
    this.vID = this.viewerID || id
    if ( this.$route.path.includes('solicitudes') ) {
      let data1 = await this.$rest.get({url: `/deposito_view/${id}`})
      // console.log("data 1",data1)
      id = data1.data.data[0].EfectoDepositoID
      this.vID = id;
      // console.log("id 1",this.vID)
    }
   
    this.id = id;
    this.ready = true
    await this.createDesign()
    this.$store.dispatch("siis/setJuzgados");
    this.$store.dispatch("siis/setMarca"),
    this.$store.dispatch("siis/setModelo");
    this.$store.dispatch("siis/setCategoriasDeposito");
    this.$store.dispatch("siis/setSubCategoriasDeposito");
    this.$store.dispatch("siis/setTipoDeposito");
    this.$store.dispatch("siis/setEstadosEfectoDeposito");
    this.$store.dispatch("siis/setEstadosSolicitudDeposito");
    this.getDataSolicitante();
   

    //CATEGORIAS Y SUBCATEGORIAS
    // await this.$store.dispatch("siis/setCategoriasArticulos");
    // console.log(">>>> this.categoriasArticulos", this.categoriasArticulos);
    // this.params.CategoriaID.dropdown.options = this.categoriasArticulos;

    //IMAGENES
    this.params.images.gallery.refresh();

    if(this.params?.sumario?.dataTable !== undefined){
      //Tabla de sumarios vinculados
      this.params.sumario.dataTable.loading = true;
      await this.params.sumario.dataTable?.refresh();
      this.params.sumario.dataTable.loading = false;
    }
  },
  computed: {
    ...mapState({
      juzgado: (state) => state.siis.juzgados,
      MarcaID: (state) => state.siis.marca,
      ModeloID: (state) => state.siis.modelo,
      categorias: (state) => state.siis.categoriasDeposito,
      subcategorias: (state) => state.siis.subcategoriasDeposito,
      tipo: (state) => state.siis.tipoDeposito,
      estadosEfectoDeposito: (state) => state.siis.estadosEfectoDeposito,
      estadosSolicitudDeposito: (state) => state.siis.estadosSolicitudDeposito,
      vinculatedActuations: (state) => state.siis.vinculatedActuations,
      user: (state) => state.user,
    }),
    showEfecto() {
      if(this.showSolicitud){
        return false
      } else {
        return true
      }
    },
    pageName() {
      let pageName = '';
      if(this.showSolicitud)
        pageName = 'Solicitud';
      if(this.showEfecto)
        pageName = 'Efecto';
      return pageName;
    },
    pageSubtitle() {
      let pageSubtitle = '';
      if(this.showSolicitud)
        pageSubtitle = 'Datos de la Solicitud';
      if(this.showEfecto)
        pageSubtitle = 'Datos del Efecto';
      return pageSubtitle;
    },
    viewer: {
      get (){
        if(this.showEfecto)
          return this.$route.query.view  === 'true';
        if(this.showSolicitud){
          if(this.$route.query.view){
            return true; 
          } else{
            this.onlyEdit = ['TipoEstadoSolicitud'];
            return true; 
          }
        }
      },
      set(v){
        return v;
      }
    },
    backPath(){
      if(this.actuationMode)
        return false;
      if(this.showEfecto)
        return '/deposito';
      if(this.showSolicitud)
        return '/deposito/solicitudes';
    },
    url(){
      if(this.showEfecto)
        return process.env.VUE_APP_BACKEND_ENVIRONMENT + "/deposito";
      if(this.showSolicitud)
        return [process.env.VUE_APP_BACKEND_ENVIRONMENT + '/deposito/' + this.vID, process.env.VUE_APP_BACKEND_ENVIRONMENT + '/formulario-deposito/' + this.$route.params.id];
    },
    specificUrlForPut(){
      if(this.showEfecto)
        return "";
      if(this.showSolicitud)
        return process.env.VUE_APP_BACKEND_ENVIRONMENT + "/estado-solicitud/" + this.$route.params.id;
    },
    dataEstadosSolicitudDeposito: {
      get(){
        if(this.onlyEdit.length > 0){
          let estadosSolicitudDepositoWithoutNueva = this.estadosSolicitudDeposito.filter((estado) => estado.Nombre !== "Nueva");
          this.params.TipoEstadoSolicitud.dropdown.options = estadosSolicitudDepositoWithoutNueva;
          return estadosSolicitudDepositoWithoutNueva;
        } else{
          return this.estadosSolicitudDeposito;
        }
      },
      set(v){
        return v;
      }
    },
    originFromEffect: {
      get(){
        return this.$refs?.EfectoFormTForm?._data?.values?.Origen;
      },
      set(v){
        return v;
      }
    }
  },
  async mounted(){
    if(this.showSolicitud){
      this.removeValidations(['file', 'sumario']);
    }
    // let respActuation = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/vinculo-actuacion-deposito/?search={"DepositoArticuloID": [{"operator": "=", "value": "${this.id}"}]}`,{ withCredentials: true });//, params: { method: "own" }
    // console.log('respActuation.data.data', respActuation.data.data);
    // if(respActuation?.data?.data?.length > 0){
    //   this.$store.dispatch("siis/setVinculatedActuations", ...respActuation.data.data);
    // } 
    setTimeout(() => {
      if(this.$route.path.includes('efectos/new')) this.$refs.EfectoFormTForm.values = {...this.$refs.EfectoFormTForm.values, EstadoEfectoActuacionID: "Recuperado" }
    }, 100);
  },
  watch: {
    Juzgado(v) {
      this.params.Juzgado.dropdown.options = v;
    },

    MarcaID(v) {
      let marcas = [];
      v.reduce((acc, item) => {
        if (!acc.includes(item.nombre)) {
          acc.push(item.nombre);
          marcas.push(item);
        }
        return acc;
      }, []);
      this.params.MarcaID.dropdown.options = marcas;
    },
    ModeloID(v) {
      this.params.ModeloID.dropdown.options = v;
    },

    categorias(v) {
      this.params.CategoriaID.dropdown.options = v;
    },
    tipo(v) {
      // this.params.TipoID.dropdown.options = v;
    },

    subcategorias(v) {
      this.params.SubcategoriaID.dropdown.options = v;
    },
    onlyEdit(v){
      if(v.length > 0){
        this.dataEstadosSolicitudDeposito = this.estadosSolicitudDeposito.filter((estado) => estado.Nombre !== "Nueva");
        this.params.TipoEstadoSolicitud.dropdown.options = this.estadosSolicitudDeposito.filter((estado) => estado.Nombre !== "Nueva");
      } else {
        this.dataEstadosSolicitudDeposito = this.estadosSolicitudDeposito;
        this.params.TipoEstadoSolicitud.dropdown.options = this.estadosSolicitudDeposito;
      }
    }
  },
  methods: {
    createDesign(){
      let group1 = {
        design: [["efecto"]],
      }
      let group2 = {
        design: [
          ["Visible"],
          ["EstadoEfectoActuacionID"],
          ["CategoriaID", "SubcategoriaID", "TipoID"],
          ["MarcaID", "ModeloID"],
          ["DatosPublicos"],
          ["Descripcion"],
        ],
      }
      let group3 = {
        design: [["DepositarioOriginal"],["DepositarioActual"]],
      }
      let group4 = {
        design: [
          ["Anio", "Chasis"],
          ["NumeroMotor", "Dominio"],
          ["NumeroSerie", "Imei"],
          ["Abonado", "Color"],
        ],
      }
      let group5 = {
        design: [["file"]],
      }
      let group6 = {
        design: [["images", "sumario"]],
      }
      let group7 = {
        design: [["sumario"]],
      }

      let group8 = {
        design: [["TipoEstadoEfecto"]],
      };

      let group9 = {
        design: [["solicitud"]],
      }
      let group10 = {
        design: [["solicitudData"]],
      }

      let group11 = {
        design: [["gestorDiligenciasDeposito"]], 
      }

      let group12 = {
        design: [["solicitudesTable"]], 
      }
      
      let col1 = {
        cards: [
          group2,
          group4,
        ],
        class: "col-6"        
      }
      let col2 = {
        cards: [
          group3,
        ],
        class: "col-6"        
      }

      let col3 = {
        cards: [
          group9,   
          group10  
        ],
        class: "col-6" 
      }

      let col4 = {
        cards: [
          group5,
          group6
        ],
        class: "col-12" 
      }
      let col5 ={
        cards: [
          group5,
          group7
        ],
        class: "col-12" 
      }
      let col6 = {
        cards: [
          group11,
          group12
        ],
        class: "col-12" 
      }
      let col7 = {
        cards: [
          group8,
        ],
        class: "col-12" 
      }

      if (this.showEfecto) {
        this.designEdit = [col1, col2, col4, col6, col7];
        this.design = [col1,col2, col5, col6, col7];
      }
      
      if(this.showSolicitud) {
        group8.design = [["TipoEstadoSolicitud"]];
        col1.cards = [group1, group2, group4,];
        col7.cards = [group8];
        this.designEdit = [col3, col1, col4, col7];
      }

      return Promise.resolve()
    },
    enableEditFields() {
      if (
        this.$rest.esTipoUsuario(
          ["Super User", "User Tester", "Super Usuario de E3", "Deposito Judicial"],
          this.user
        )
      )
        return [];
      return ["file", "DatosPublicos"];
    },
    formatDoc(col, data) {
      if (!data || col == "images" || col == "file") return;
      switch (col) {
        case "Visible":
          return data[col] == 1 ? "Si" : "No";
          break;
        case "CategoriaID":
          return this.categorias.find((e) => e.CategoriaID === data[col]).Nombre;
          break;
        case "SubcategoriaID":
          return this.subcategorias.find((e) => e.SubcategoriaID === data[col]).Nombre;
          break;
        case "MarcaID":
          if (data[col]){
          return this.MarcaID.find((e) => e.MarcaID === data[col]).nombre;
          }  
          else return "No especificado";
          break;
        case "ModeloID":
          if (data[col]) return this.ModeloID.find((e) => e.ModeloID === data[col]).Nombre;
          else return "No especificado";
          break;
        case "TipoEstadoEfecto":
          let tipoEstadoEfectoNombre = '';
          // console.log("this.$route.query.view : ",this.$route.query.view );
          // console.log("data[col]: ",data[col]);
          if(data[col] === 'precarga-c42a-11ed-8320-0242ac120005' && this.$route.query.view == undefined){
            tipoEstadoEfectoNombre =  this.estadosEfectoDeposito.find((e) => e.TipoEstadoEfectoID === 'endependencia01-c42a-11ed-8320-0242ac120005').Nombre;
            this.params.TipoEstadoEfecto.type = "textInfo";
          }else{
            tipoEstadoEfectoNombre =  this.estadosEfectoDeposito.find((e) => e.TipoEstadoEfectoID === data[col]).Nombre;
          }          
          return tipoEstadoEfectoNombre;
          break;
        case "TipoEstadoSolicitud":
          let tipoEstadoSolicitudNombre = '';
          tipoEstadoSolicitudNombre =  this.estadosSolicitudDeposito.find((e) => e.TipoEstadoSolicitudID === data[col]).Nombre;
          return tipoEstadoSolicitudNombre;
          break;
        case "TipoID":
          if (data[col]) {
            return this.tipo.find((e) => e.tipoID === data[col]).nombre;
          } else {
            return "No especificado";
          }
          break;
        case "sumario":
        case "efecto":
          return "";
          break;
        case "DepositarioOriginal":
          let DepositarioOriginal = this.apellidoNombre(data?.ApellidoDepositarioOriginal, data?.NombreDepositarioOriginal);
          let texto = DepositarioOriginal != '' ? DepositarioOriginal+', '+data?.TipoUsuarioDepositarioOriginal : 'SIN DEPOSITARIO ASIGNADO'
          return texto;
          break;        
        case "DepositarioActual":
         let DepositarioActual = this.apellidoNombre(data?.ApellidoDepositarioActual, data?.NombreDepositarioActual);
          let texto2 = DepositarioActual != '' ? DepositarioActual+', '+data?.TipoUsuarioDepositarioActual : 'SIN DEPOSITARIO ASIGNADO'
          return texto2;
          break;     
        case "EstadoEfectoActuacionID":      
          return ''  
          break;     
        default:
          return data[col] ? data[col] : "No especificado";
      }
    },
    apellidoNombre(apellido, nombre)
    {
      var respuesta = ''
      //apellido y nombre pueden venir de realizar un "concat" en sumario.js, lo que implica que null puede ser tomado como un string
      respuesta = apellido == 'null' || apellido == null || apellido == 'Sin Apellido Especificado' ? '' : apellido
      respuesta = nombre == 'null'|| nombre == null || nombre == 'Sin Apellido Especificado' ? respuesta :(respuesta == '' ? nombre : respuesta+" "+nombre)
      return respuesta
    },
    getMode() {
      if(this.actuationMode) return "new";
      return this.id == "add" ? "new" : "edit";
    },
    goToEditMode() {
      if(this.showEfecto){
        this.$router.push("/deposito/efectos/" + this.$route.params.id);
        this.viewer = false;
        this.removeDeleteAndEditButtons(this.params.sumario.dataTable.list);
      }
      if(this.showSolicitud){
        this.$router.push("/deposito/solicitudes/" + this.$route.params.id);
        this.onlyEdit.push('TipoEstadoSolicitud');
      }
    },
    async getDataSolicitante(data) {
      let { id } = this.$route.params;
      if ( !this.$route.path.includes('solicitudes') ) {
        id = this.id;
      }
      data = await this.$rest.get({
        url: process.env.VUE_APP_BACKEND_ENVIRONMENT+"/formulario-deposito/"+id,
      });
      this.DataSolicitante =  data.data.data ;

    },
    async getDiligencesTemplate() {
      await this.$store.dispatch('siis/setTipoDiligencia')
    },
    openModal(type) {
      this.$refs[type].open();
    },
    closeModal(type) {
      this.$refs[type].close();
    },
    removeValidations(paramsToDeleteValidations){
      paramsToDeleteValidations.map( paramName => {
        this.params[paramName].validation = '';
      });
    },
    changeThePropsName(arrayToChange, propsNameToChange){
      arrayToChange.map( item => {
        propsNameToChange.map( prop => {
          for (let key in item) {
            if (prop.hasOwnProperty(key)) {
              item[prop[key]] = item[Object.keys(prop)[0]];
              delete item[key];
            }
          }
        });
      });
      let arrayChanged = arrayToChange;
      return arrayChanged;
    },
    addStaticProps(arrayToChange, arrayType){
      arrayToChange.map( item => {
        if(arrayType === "Infraction"){
          item.Tipo = "Infracción de Tránsito";
          item.Caratula = "- - -";
          item.Estado = "- - -";
          item.ActuacionID = "";
          item.url = "vinculo-infraccion-deposito"
        }
        if(arrayType === "Actuation"){
          item.url = "vinculo-actuacion-deposito"
        }
      });
      let arrayChanged = arrayToChange;
      return arrayChanged;
    },
    standardizeActuationAndInfraction(actuationData, infractionData){
      let propsNameToChange = [
        { VinculoActuacionDepositoID: "vinculoID" },
        { JuzgadoInterviniente: "Juzgado" },
        { NumeroActuacion: "Numero" },
        { TipoActuacion: "Tipo" },
        { Fechadesde: "Fecha" },
        { FechaCreacion: "Fecha" },
        { DependenciaActuacion: "Dependencia" },
        { EstadoActuacion: "Estado" },
      ];
      actuationData = this.changeThePropsName(actuationData, propsNameToChange);
      actuationData =  this.addStaticProps(actuationData, "Actuation");
      actuationData = actuationData.filter((item, index, self) => index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item)));
      propsNameToChange = [{VinculoInfraccionDepositoID: "vinculoID" }, { JuzgadoAsignado: "Juzgado" }, { NumeroInfraccion: "Numero" }, { Fechadesde: "Fecha" }, { dependencia: "Dependencia" }];
      infractionData = this.changeThePropsName(infractionData, propsNameToChange);
      infractionData = this.addStaticProps(infractionData, "Infraction");
      return actuationData.concat(infractionData);
    },
    async saveVinculatedActuations(value){
      this.$store.commit("sumarioList/loadingUpdate", true);
      let EfectoDepositoID = this.id === 'add' ? value.data.data.EfectoDepositoID : this.id;
      this.vinculatedActuations.map( async actuation => {
        let newActuation = {};
        newActuation.EfectoDepositoID = EfectoDepositoID;
        newActuation.ActuacionID = actuation.ActuacionID;
        if(value?.doc?.EstadoEfectoActuacionID !== undefined) newActuation.EstadoEfectoActuacionID = value.doc.EstadoEfectoActuacionID;
        let resp = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-efecto/`,newActuation,{ withCredentials: true});
        if(resp.status == 200){
          this.$store.commit("sumarioList/loadingUpdate", false);
          this.$rest.toast_open({message: `Vinculacion con ${newActuation.NumeroActuacion} Exitosa!`,position: "top-right",type: "success",duration: 3000,});
        }
        this.$store.dispatch("siis/setVinculatedActuations", []);
      });
    },
    removeDeleteAndEditButtons(vinculations){
      let i = 0;
      let newParams = {...this.params};
      setTimeout(() => {
        vinculations.map(vinculation => {
          newParams.sumario.dataTable.list[i].editButtonEnabled = true;
          newParams.sumario.dataTable.list[i].deleteButtonEnabled = true;
          if  ( 
            (
              vinculation?.formatType === 'new' || 
              vinculation?.formatType === 'creating it'
            ) || 
            this.originFromEffect === 'Actuacion'
          ) {
            newParams.sumario.dataTable.list[i].editButtonEnabled = false;
          }
          if(vinculation?.formatType === 'new' || this.originFromEffect === 'Actuacion') newParams.sumario.dataTable.list[i].deleteButtonEnabled = false;
          i++
        });
        this.params = newParams;
        if(vinculations.length>0){//Si el Efecto no tiene ninguna vinculacion, es por que es un Efecto antiguo. Para estos casos dejamos habilitados los botones para realizar vinculaciones.
          this.removeButtonForVinculate()
        }
      }, 100);
    },
    removeButtonForVinculate(){
      if(this.originFromEffect === 'Actuacion'){
        setTimeout(() => {
          let newParams = {...this.params};
          delete newParams.sumario.button;
          newParams.sumario.manualVinculation = false;
          this.params = newParams;
        }, 100);
      }
    }
  },
};
</script>
