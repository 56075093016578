<template>
  <div class="toolbar">
    <DataTable :value="forms" :paginator="true" class="p-datatable-forms col-12" :rows="10"
    dataKey="id" :filters="filters" :loading="loading">
      <template #header>
          <div class="table-header">
              Lista de Formularios
              <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global']" placeholder="Buscar formulario" />
              </span>
          </div>
      </template>
      <template #empty>
          No hay formularios para mostrar.
      </template>
      <template #loading>
          Descargando, espere por favor.
      </template>
      <Column field="name" headerStyle="display: none;">
        <template #body="slotProps">
          <Button :label="slotProps.data.name.toUpperCase()" :test-id="slotProps.data.name.toLowerCase().replace(/ /g, '-')"  class="p-button-link" @click="$router.push(slotProps.data.link)" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    data() { 
      return {
        filters: {},
        loading: false,
        forms: [
          {name: 'Sumario Prevencional por denuncia', link: "/actuaciones/new/sumario-denuncia/initial"},
          {name: 'Sumario Prevencional por oficio', link: "/actuaciones/new/sumario-oficio/initial"},
          {name: 'Expediente Contravencional por denuncia', link: "/actuaciones/new/expediente-denuncia/initial"},
          {name: 'Expediente Contravencional por oficio', link: "/actuaciones/new/expediente-oficio/initial"},
          {name: 'Unidades Fiscales de Investigación (UFI) - Flagrancia', link: "/actuaciones/new/ufi-flagrancia/initial"},
          {name: 'Unidades Fiscales de Investigación (UFI) - Generica por Denuncia', link: "/actuaciones/new/ufi-generica-denuncia/initial"},  
          {name: 'Unidades Fiscales de Investigación (UFI) - Generica por oficio', link: "/actuaciones/new/ufi-generica-oficio/initial"},  
          {name: 'Unidades Fiscales de Investigación (UFI) - Estafas y Delitos Informaticos por Denuncia', link: "/actuaciones/new/ufi-informatica-denuncia/initial"},
          {name: 'Unidades Fiscales de Investigación (UFI) - Estafas y Delitos Informaticos por oficio', link: "/actuaciones/new/ufi-informatica-oficio/initial"},
          {name: 'Unidades Fiscales de Investigación (UFI) - Delitos Contra la Propiedad por Denuncia', link: "/actuaciones/new/ufi-propiedad-denuncia/initial"},
          {name: 'Unidades Fiscales de Investigación (UFI) - Delitos Contra la Propiedad por oficio', link: "/actuaciones/new/ufi-propiedad-oficio/initial"},
          {name: 'Unidades Fiscales de Investigación (UFI) - CAVIG', link: "/actuaciones/new/ufi-cavig/initial"},
          {name: 'Unidades Fiscales de Investigación (UFI) - ANIVI', link: "/actuaciones/new/ufi-anivi/initial"},
          {name: 'Unidades Fiscales de Investigación (UFI) - DELITOS ESPECIALES', link: "/actuaciones/ufi-delitos-especiales/menu"},
          {name: 'ACTUACIONES PRELIMINARES', link: "/actuaciones/preliminares/menu"},
        ]
      }
    },
    name: 'NewFormPage',
    computed: {
      ...mapState({
        products: state => state.products.all
      })
    },
    methods: mapActions('route', [
      'setRoute'
    ]),
    created() {
      this.$store.dispatch('sumario/resetState');  
    },
    
  }
  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .toolbar {
    display: flex;
    
    justify-content: space-between;
    padding: 20px;
  }
  .toolbar > div > * {
    margin-left: 10px;
  }
  .toolbar > .right {
    display: flex;
    flex-direction: row-reverse;
  }
</style>
