<template>
   <div class="content"  tabindex="0">
      
        <Card class="card borderShadow" v-if="cardMode" >
          <template #title>
            <div class="title-toolbar">
              <div class="title">
                {{title}}
              </div> 
              <slot name="title" class="titleSideObject"></slot>
            </div>
          </template>
          <template #content>
              <slot name="content"></slot>
          </template>
        </Card>
        <div class="noCard" v-if="!cardMode">

          <div class="title-toolbar" v-if="title">
            <div class="title">
              {{title}}
            </div> 
            <slot name="title" class="titleSideObject"></slot>
          </div>
          <div class="content">
            <slot name="content"></slot>
          </div>
        </div>
    </div>
</template>

<script>

//import Card from 'primevue/card/Card'

import Card from 'primevue/card';
export default {
  name: 'SimpleCard',
  components: {
    Card,
  },
  data() { 
    return {
    }
  },

  props: {
    cardMode: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String
    }
  }
}
</script>
<style scoped>
  .content {
    width: 100%;
  }
  .noCard {
    padding: 10px;
  }
  div.card {
    width: 100%;
  }
  .borderShadow:focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
    outline: none;
  }
  .title {
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 1rem;
  }
  .title-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .titleSideObject{
    font-size: medium;
  }
</style>