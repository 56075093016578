<template>
  <div class="toolbar">
    <div class="feature-intro title flex justify-content-center">
      <h1>Actuaciones</h1>
    </div>
    <div>
      <!-- tableTitle="Deposito Judicial" -->
      <TemplateTable
        reference="sumario"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        getLimit="8"
        :modal="modal"
        :titles="titles"
        :styles="styles"
        :formatDoc="formatDoc"
        :resizableColumns="true"
        columnResizeMode="expand"
        :keyShow="keyShow"
        :filtersFields="filtersFields"
        :multiSelect="true"
        :enableSelectAllCheckbox="true"
        :enableGlobalSearch="false"
        :enableUploadFile="false"
        :enableFilterMultiple="true"
        v-on:datosFiltros="onResultados"
      />
      
      <!-- :enableEditButtons="true" -->
      <!-- :enableAddButton="{ enabled: true, route: '/deposito/add' }" -->
    </div>

    <!-- <div class="ml-5">
        <Button label="Seleccionar" @click="submit"/>
      </div> -->
    <!-- POR AHORA,  enableGlobalSearch REQUIERE QUE enableEditButtons SEA TRUE!!-->
    <!-- <button>submit</button> -->
  </div>
</template>

<script>
export default {
  name: "SumarioList",
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      filteredLocation: [],
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/sumario",
      customName: [],
      data: null,

      customColumn: [],
      idTable: "ActuacionID",
      loading: true,
      filtersFields: [
      //  { name: "Actuaciones", field: "exp_pre_Tiposumario@@TiposumarioID" },
        { name: "Numero de Sumarío", field: "exp_pre_Actuacion@@Numero" },
        { name: "DNI Denunciante/Damnificado", field:"exp_pre_ActuacionPersonaFisica@@RolesdenunciaID=Denunciante&exp_pre_PersonaFisica@@Dni" },
        { name: "DNI Acusado", field: "exp_pre_ActuacionPersonaFisica@@RolesdenunciaID=Acusado&exp_pre_PersonaFisica@@Dni" },
        //{ name: "Jerarquia", field: "jerarquia" },
        // { name: "Antiguedad", field: "antiguedad" },
        // { name: "Fecha de ingreso", field: "fecha_ing" },
        // { name: "Jerarquia", field: "jerarquia@@nombre" },
        //{ name: "N Arma", field: "police_arma@@nro_arma" },
      ],
      keyShow: [
        "Denunciante",
        "Fecha del hecho",
        "Caratula/Causa",
        "Actuaciones",
        "Juzgado",
        "N° de Actuacion",
      ],
      titles: {},
      formatDoc: (col, data) => {
        // console.log("data es: "+ JSON.stringify(data))
        
      // console.log("formatDoc>>>>>>>>>>>>>>>>", col, data);
        switch (col) {
          case "Denunciante":
            var name = "DENUNCIANTE ANONIMO";
            // si existe el denunciante entonces
            if (data.Denunciantes.length > 0) {
              // si tenemos el apellido del denunciante
              if (data.Denunciantes[0].Apellido != null) {
                name = data.Denunciantes[0].Apellido;
              }
              // si tenemos el nombre del denunciante
              if (data.Denunciantes[0].Nombre != null) {
                name = name + ", " + data.Denunciantes[0].Nombre;
              }
            }
            return name;
          case "Fecha del hecho":
            let fechaDelHecho = "";
            //si tenemos fecha desde y fecha hasta.. incluimos ambas
            if (
              data.HechoubicacionFechahoradesde != null &&
              data.HechoubicacionFechahorahasta != null
            ) {
              fechaDelHecho =
                data.HechoubicacionFechahoradesde.slice(0, -8) +
                " / " +
                data.HechoubicacionFechahorahasta.slice(0, -8);
            }
            //si fechaDelHecho aun no se cargo, vemos cual de las 2 fecha se cargaron
            else {
              if (data.HechoubicacionFechahoradesde != null) {
                fechaDelHecho = data.HechoubicacionFechahoradesde.slice(0, -8);
              } else if (data.HechoubicacionFechahorahasta != null) {
                fechaDelHecho = data.HechoubicacionFechahorahasta.slice(0, -8);
              }
            }
            return fechaDelHecho;
          case "Actuaciones":
            return data.TiposumarioNombre;
          case "Caratula/Causa":
            //en el caso de que no tenga caratula, es por que se trata de un expediente, el cual cuenta con articulos

            if (data.SumarioCausaCaratula == null) {
              // console.log(JSON.stringify(data.ActuacionArticulo))
              //como aun dataResumen no fue cargado, tenemos que generar los articulos a mostrar
              let Articulos = "";
              for (let item of data.ActuacionArticulo) {
                Articulos =
                  Articulos == ""
                    ? item.Numeroarticulo + "°"
                    : Articulos + ", " + item.Numeroarticulo + "°";
              }
              return Articulos;
            } else return data.SumarioCausaCaratula;
          case "Juzgado":
            return data.Roljuzgadosumario[0].Nombre;
          case "N° de Actuacion":
            return data.Numero;
        }
      },
      styles: {
        default: {
          header: { width: "320px" },
          body: { width: "200px" },
        },
      },
    };
  },
  methods: {
    onResultados(mostrar) {
      let datos = mostrar;
      console.log("datos desde indice", datos);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  margin-left: 30px;
}
</style>
