
export default {
    methods: {
        //si incluye alguna de las palabras del array retorna true, sino false
        includesWords(array){
            let i = 0;
            while( i < array.length && !this.resumen.formType.includes(array[i])){
            i++;
            }
            return i< array.length ? true : false;
        },
    }
}